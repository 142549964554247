import React, {useContext, useState} from "react";
import {UniswapPosition} from "../../../../model/crypto/CryptoModels";
import {Col, Row} from "react-bootstrap";
import {ThemeContext} from "../../../../App";
import PriceRangeIndicatorComponent from "../positionsList/PriceRangeIndicatorComponet";
import {Link} from "react-router-dom";

const PositionPreviewComponent: React.FC<{ position: UniswapPosition }> = ({position}) => {

    const [token0Active, setToken0Active] = useState(true);
    const [token1Active, setToken1Active] = useState(false);

    const context = useContext(ThemeContext);
    const isDarkTheme = context?.theme === "dark";

    // Extract price data safely
    const lower = position.priceRange?.lower.token1PerToken0 ?? 0;
    const upper = position.priceRange?.upper.token1PerToken0 ?? 0;
    const current = position.currentPrice?.token1PerToken0 ?? 0;

    // Extend width by 10% on each side
    const minValue = Math.min(lower, upper, current);
    const maxValue = Math.max(lower, upper, current);
    const extendedMin = minValue - (maxValue - minValue) * 0.1;
    const extendedMax = maxValue + (maxValue - minValue) * 0.1;

    // Calculate positions as percentages
    const lowerPos = ((lower - extendedMin) / (extendedMax - extendedMin)) * 100;
    const upperPos = ((upper - extendedMin) / (extendedMax - extendedMin)) * 100;
    const currentPos = ((current - extendedMin) / (extendedMax - extendedMin)) * 100;

    // Determine fill color based on the current price
    const fillColor = rangeColor(lower, upper, current);

    // Theme-based styling
    const backgroundColor = isDarkTheme ? "#222" : "#DDD";
    const borderColor = fillColor;
    const markerColor = fillColor;
    const markerColorCurrent = "#1d74f7";

    function rangeColor(lower: number, upper: number, price: number): string {
        if (price < lower || price > upper) return '#DC3545'; // Red (Out of range)

        const delta = (upper - lower) * 0.15;
        if ((price > lower && price < lower + delta) || (price > upper - delta && price < upper)) {
            return '#FFC107'; // Yellow (Near boundaries)
        }

        return '#28A745'; // Green (Within range)
    }

    function handleToken0Select() {
        setToken0Active(true);
        setToken1Active(false);
    }

    function handleToken1Select() {
        setToken1Active(true);
        setToken0Active(false);
    }

    return (
        <>
            <Row>
                <Col sm={2} className="">
                    <PriceRangeIndicatorComponent position={position}/>
                </Col>
                <Col sm={10} className="">
                    <Link to={"/crypto/uniswap/positions/v3/arbitrum/" + position.id}>Position ID : {position.id}</Link>
                    <p>Liquidity USD: {position.liquidityUSD}</p>
                    <p>Fees token0: {position.tokensOwed0}</p>
                    <p>Fees token1: {position.tokensOwed1}</p>
                    <p>Fees total USD: {position.feesTotalUSD}</p>
                </Col>
            </Row>
        </>
    )
        ;
};

export default PositionPreviewComponent;
