import React, { useState, useMemo } from 'react';
import { Card, Table, Pagination, Badge, Dropdown } from 'react-bootstrap';
import { UniswapPosition } from "../../../model/crypto/CryptoModels";
import { Link } from 'react-router-dom';

type Props = {
    positions: UniswapPosition[];
    pageSize?: number;
};

type SortField = 'id' | 'token0' | 'token1' | 'amount0' | 'amount1' | 'liquidityUSD' | 'feesTotalUSD' | 'currentPrice';
type SortDirection = 'asc' | 'desc';

const UniswapPositionsManager: React.FC<Props> = ({ positions, pageSize = 10 }) => {
    // Filtering state
    const [filters, setFilters] = useState({
        network: '',
        address: '',
        pool: '',
        token: ''
    });

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);

    // Sorting state
    const [sortConfig, setSortConfig] = useState<{
        field: SortField;
        direction: SortDirection;
    }>({ field: 'liquidityUSD', direction: 'desc' });

    // Handle filter changes
    const handleFilterChange = (e: React.ChangeEvent<HTMLElement>) => {
        const target = e.target as HTMLInputElement;
        setFilters({ ...filters, [target.name]: target.value });
        setCurrentPage(1); // Reset to first page when filters change
    };

    // Clear all filters
    const clearFilters = () => {
        setFilters({
            network: '',
            address: '',
            pool: '',
            token: ''
        });
        setCurrentPage(1);
    };

    // Handle sorting
    const requestSort = (field: SortField) => {
        const direction: SortDirection =
            sortConfig.field === field && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ field, direction });
    };

    // Get sorting indicator
    const getSortIndicator = (field: SortField) => {
        if (sortConfig.field !== field) return null;
        return sortConfig.direction === 'asc' ? ' ▲' : ' ▼';
    };

    // Filter positions
    const filteredPositions = useMemo(() => {
        console.log("UniswapPositionsManager received positions:", positions.length);

        return positions.filter(position => {
            // Skip positions that are null or undefined
            if (!position) return false;

            // More permissive filtering - only filter on properties that exist
            return (
                // Network filter - only apply if network property exists
                (!filters.network ||
                    !position.pool?.network ||
                    position.pool.network.toLowerCase().includes(filters.network.toLowerCase())) &&

                // Address filter - only apply if address property exists
                (!filters.address ||
                    !position.address ||
                    position.address.toLowerCase().includes(filters.address.toLowerCase())) &&

                // Pool filter - only apply if pool address property exists
                (!filters.pool ||
                    !position.pool?.address ||
                    position.pool.address.toLowerCase().includes(filters.pool.toLowerCase())) &&

                // Token filter - only apply if token properties exist
                (!filters.token ||
                    (position.token0?.symbol && position.token0.symbol.toLowerCase().includes(filters.token.toLowerCase())) ||
                    (position.token1?.symbol && position.token1.symbol.toLowerCase().includes(filters.token.toLowerCase())))
            );
        });
    }, [positions, filters]);

    // Sort filtered positions
    const sortedPositions = useMemo(() => {
        const sortablePositions = [...filteredPositions];

        sortablePositions.sort((a, b) => {
            let comparison = 0;

            switch (sortConfig.field) {
                case 'id':
                    // Safe comparison regardless of ID type
                    comparison = String(a.id || '').localeCompare(String(b.id || ''));
                    break;
                case 'token0':
                    comparison = (a.token0?.symbol || '').localeCompare(b.token0?.symbol || '');
                    break;
                case 'token1':
                    comparison = (a.token1?.symbol || '').localeCompare(b.token1?.symbol || '');
                    break;
                case 'amount0':
                    comparison = (a.amount0 || 0) - (b.amount0 || 0);
                    break;
                case 'amount1':
                    comparison = (a.amount1 || 0) - (b.amount1 || 0);
                    break;
                case 'liquidityUSD':
                    comparison = (a.liquidityUSD || 0) - (b.liquidityUSD || 0);
                    break;
                case 'feesTotalUSD':
                    comparison = (a.feesTotalUSD || 0) - (b.feesTotalUSD || 0);
                    break;
                case 'currentPrice':
                    comparison = (a.currentPrice?.token1PerToken0 || 0) - (b.currentPrice?.token1PerToken0 || 0);
                    break;
                default:
                    break;
            }

            return sortConfig.direction === 'asc' ? comparison : -comparison;
        });

        console.log("Sorted positions:", sortablePositions.length);
        return sortablePositions;
    }, [filteredPositions, sortConfig]);

    // Paginate positions
    const totalPages = Math.ceil(sortedPositions.length / pageSize);
    const paginatedPositions = useMemo(() => {
        const startIndex = (currentPage - 1) * pageSize;
        const paginatedResults = sortedPositions.slice(startIndex, startIndex + pageSize);
        console.log("Paginated positions:", paginatedResults.length);
        return paginatedResults;
    }, [sortedPositions, currentPage, pageSize]);

    // Handle page changes
    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Calculate position performance
    const getPositionPerformance = (position: UniswapPosition): string => {
        if (!position.liquidityUSD || position.liquidityUSD === 0) return "0.00";
        const feesROI = (position.feesTotalUSD || 0) / position.liquidityUSD * 100;
        return feesROI.toFixed(2);
    };

    // Helper to get event type counts from positionEvents
    const getEventCounts = (position: UniswapPosition) => {
        if (!position.positionEvents || !Array.isArray(position.positionEvents)) {
            return { collects: 0, increaseLiquidity: 0, decreaseLiquidity: 0 };
        }

        const counts = {
            collects: 0,
            increaseLiquidity: 0,
            decreaseLiquidity: 0
        };

        position.positionEvents.forEach(event => {
            if (!event || !event.eventType) return;

            if (event.eventType === 'collect') counts.collects++;
            else if (event.eventType === 'increaseLiquidity') counts.increaseLiquidity++;
            else if (event.eventType === 'decreaseLiquidity') counts.decreaseLiquidity++;
        });

        return counts;
    };

    // Generate pagination items
    const paginationItems = [];

    // Add "First" and "Previous" buttons
    paginationItems.push(
        <Pagination.First
            key="first"
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
        />
    );
    paginationItems.push(
        <Pagination.Prev
            key="prev"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
        />
    );

    // Add page numbers
    const maxPageItems = 5;
    let startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, startPage + maxPageItems - 1);

    if (endPage - startPage < maxPageItems - 1) {
        startPage = Math.max(1, endPage - maxPageItems + 1);
    }

    for (let page = startPage; page <= endPage; page++) {
        paginationItems.push(
            <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => handlePageChange(page)}
            >
                {page}
            </Pagination.Item>
        );
    }

    // Add "Next" and "Last" buttons
    paginationItems.push(
        <Pagination.Next
            key="next"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages || totalPages === 0}
        />
    );
    paginationItems.push(
        <Pagination.Last
            key="last"
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages || totalPages === 0}
        />
    );

    return (
        <Card>
            <Card.Body className="p-0">
                <Table striped bordered hover responsive className="mb-0">
                    <thead className="bg-light">
                    <tr>
                        <th
                            onClick={() => requestSort('id')}
                            className="cursor-pointer"
                        >
                            ID {getSortIndicator('id')}
                        </th>
                        <th
                            onClick={() => requestSort('token0')}
                            className="cursor-pointer"
                        >
                            Token0 {getSortIndicator('token0')}
                        </th>
                        <th
                            onClick={() => requestSort('token1')}
                            className="cursor-pointer"
                        >
                            Token1 {getSortIndicator('token1')}
                        </th>
                        <th
                            onClick={() => requestSort('amount0')}
                            className="cursor-pointer"
                        >
                            Amount0 {getSortIndicator('amount0')}
                        </th>
                        <th
                            onClick={() => requestSort('amount1')}
                            className="cursor-pointer"
                        >
                            Amount1 {getSortIndicator('amount1')}
                        </th>
                        <th>Pool</th>
                        <th
                            onClick={() => requestSort('liquidityUSD')}
                            className="cursor-pointer"
                        >
                            Liquidity USD {getSortIndicator('liquidityUSD')}
                        </th>
                        <th
                            onClick={() => requestSort('feesTotalUSD')}
                            className="cursor-pointer"
                        >
                            Fees Collected {getSortIndicator('feesTotalUSD')}
                        </th>
                        <th
                            onClick={() => requestSort('currentPrice')}
                            className="cursor-pointer"
                        >
                            Current Price {getSortIndicator('currentPrice')}
                        </th>
                        <th>ROI (%)</th>
                        <th>Events</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {paginatedPositions.length > 0 ? (
                        paginatedPositions.map((position) => {
                            const eventCounts = getEventCounts(position);

                            return (
                                <tr key={position.id}>
                                    <td className="text-decoration-none">
                                        <Link to={`/crypto/uniswap/positions/v3/arbitrum/${position.id}`}>
                                            {position.id}
                                        </Link>
                                    </td>
                                    <td>
                                        <Badge bg="light" text="dark" className="me-1">
                                            {position.token0?.symbol || 'Unknown'}
                                        </Badge>
                                    </td>
                                    <td>
                                        <Badge bg="light" text="dark" className="me-1">
                                            {position.token1?.symbol || 'Unknown'}
                                        </Badge>
                                    </td>
                                    <td>{(position.amount0 || 0).toFixed(4)}</td>
                                    <td>{(position.amount1 || 0).toFixed(4)}</td>
                                    <td>
                                        <div className="text-truncate" style={{ maxWidth: '120px' }}>
                                            {position.pool?.address || 'Unknown'}
                                        </div>
                                    </td>
                                    <td>${(position.liquidityUSD || 0).toFixed(2)}</td>
                                    <td>${(position.feesTotalUSD || 0).toFixed(2)}</td>
                                    <td>{(position.currentPrice?.token1PerToken0 || 0).toFixed(6)}</td>
                                    <td>
                                        <Badge
                                            bg={parseFloat(getPositionPerformance(position)) > 10 ? "success" : "warning"}
                                        >
                                            {getPositionPerformance(position)}%
                                        </Badge>
                                    </td>
                                    <td>
                                        {/* Display event counts from positionEvents */}
                                        {eventCounts.collects > 0 && (
                                            <Badge bg="success" className="me-1" title="Fee Collections">
                                                {eventCounts.collects} FC
                                            </Badge>
                                        )}
                                        {eventCounts.increaseLiquidity > 0 && (
                                            <Badge bg="primary" className="me-1" title="Liquidity Additions">
                                                {eventCounts.increaseLiquidity} LA
                                            </Badge>
                                        )}
                                        {eventCounts.decreaseLiquidity > 0 && (
                                            <Badge bg="warning" text="dark" className="me-1" title="Liquidity Removals">
                                                {eventCounts.decreaseLiquidity} LR
                                            </Badge>
                                        )}
                                    </td>
                                    <td>
                                        <Dropdown>
                                            <Dropdown.Toggle
                                                variant="outline-secondary"
                                                size="sm"
                                                id={`dropdown-${position.id}`}
                                            >
                                                Actions
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <Dropdown.Item as={Link} to={`/crypto/uniswap/positions/v3/arbitrum/${position.id}`}>
                                                    View Details
                                                </Dropdown.Item>
                                                <Dropdown.Item as={Link} to={`/position/${position.id}/edit`}>
                                                    Edit Position
                                                </Dropdown.Item>
                                                <Dropdown.Divider />
                                                <Dropdown.Item as={Link} to={`/position/${position.id}/withdraw`} className="text-danger">
                                                    Withdraw Liquidity
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={12} className="text-center py-4">
                                No positions found matching the current filters
                            </td>
                        </tr>
                    )}
                    </tbody>
                </Table>
            </Card.Body>
            {totalPages > 1 && (
                <Card.Footer>
                    <div className="d-flex justify-content-center">
                        <Pagination>{paginationItems}</Pagination>
                    </div>
                </Card.Footer>
            )}
        </Card>
    );
};

export default UniswapPositionsManager;