import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { fetchPoolHistoricalData, PoolHistoricalData } from '../../../../service/crypto/pool/PoolService';
import { calculateVolatilityIndex } from '../../../../service/crypto/pool/PollAnalyticsService';
import { TimePeriod } from '../../../../service/crypto/pool/TimePeriodService';
import { Form } from 'react-bootstrap';

interface VolatilityIndexChartProps {
    poolId: string;
    network: string;
    timePeriod: TimePeriod;
}

interface ChartDataPoint {
    date: string;
    volatilityIndex: number;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: Array<{
        value: number;
        payload: ChartDataPoint;
    }>;
    label?: string;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) {
        return null;
    }

    const value = payload[0].value;
    const date = new Date(label || '');

    if (!isNaN(date.getTime())) {
        const time = date.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit'
        });
        return (
            <div className="bg-white p-2 border rounded shadow-sm">
                <p className="mb-1">{`Time: ${time}`}</p>
                <p className="mb-0">{`Volatility: ${value.toFixed(2)}%`}</p>
            </div>
        );
    }

    return (
        <div className="bg-white p-2 border rounded shadow-sm">
            <p className="mb-1">{`Date: ${label}`}</p>
            <p className="mb-0">{`Volatility: ${value.toFixed(2)}%`}</p>
        </div>
    );
};

const VolatilityIndexChart: React.FC<VolatilityIndexChartProps> = ({ poolId, network }) => {
    const [data, setData] = useState<ChartDataPoint[]>([]);
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriod>('1month');
    const [isLoading, setIsLoading] = useState(false);

    const getTimeRange = (period: TimePeriod): string => {
        switch (period) {
            case '1day': return '1d';
            case '7days': return '7d';
            case '1month': return '30d';
            case '1year': return '1y';
            case 'alltime': return 'max';
            default: return '30d';
        }
    };

    const getMinWindowSize = (period: TimePeriod): number => {
        switch (period) {
            case '1day': return 6;
            case '7days': return 2;
            case '1month': return 5;
            case '1year': return 30;
            case 'alltime': return 30;
            default: return 5;
        }
    };

    const formatXAxisTick = (value: string): string => {
        if (selectedTimePeriod === '1day') {
            try {
                const date = new Date(value);
                return date.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                });
            } catch {
                return value;
            }
        }
        return value;
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const timeRange = getTimeRange(selectedTimePeriod);
                const historicalData = await fetchPoolHistoricalData(poolId, network, timeRange);

                if (!historicalData || historicalData.length === 0) {
                    console.error('No historical data received');
                    setData([]);
                    return;
                }

                const minWindowSize = getMinWindowSize(selectedTimePeriod);
                const chartData: ChartDataPoint[] = [];

                for (let i = minWindowSize; i < historicalData.length; i++) {
                    const windowSize = Math.min(i, minWindowSize);
                    const windowData = historicalData.slice(i - windowSize, i + 1);

                    if (windowData.length >= minWindowSize) {
                        const volatility = calculateVolatilityIndex(windowData);

                        const date = new Date(historicalData[i].date);
                        const dateStr = selectedTimePeriod === '1day'
                            ? date.toISOString()
                            : date.toLocaleDateString();

                        chartData.push({
                            date: dateStr,
                            volatilityIndex: volatility
                        });
                    }
                }

                setData(chartData);
            } catch (error) {
                console.error('Error fetching volatility index data:', error);
                setData([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [poolId, network, selectedTimePeriod]);

    const handleTimePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTimePeriod(e.target.value as TimePeriod);
    };

    if (data.length === 0 && !isLoading) {
        return (
            <div>
                <div className="mb-3">
                    <Form.Select
                        value={selectedTimePeriod}
                        onChange={handleTimePeriodChange}
                        disabled={isLoading}
                    >
                        <option value="1day">1 Day</option>
                        <option value="7days">7 Days</option>
                        <option value="1month">1 Month</option>
                        <option value="1year">1 Year</option>
                        <option value="alltime">All Time</option>
                    </Form.Select>
                </div>
                <div className="text-center py-4">No data available for the selected time period</div>
            </div>
        );
    }

    const maxVolatility = Math.max(...data.map(d => d.volatilityIndex));
    const yAxisMax = Math.ceil(maxVolatility / 50) * 50;

    // Calculate the ideal interval based on data length
    const calculateXAxisInterval = () => {
        const length = data.length;
        if (length <= 10) return 0; // Show all labels
        if (length <= 20) return 1; // Show every other label
        if (length <= 60) return Math.floor(length / 10); // Show ~10 labels
        return Math.floor(length / 8); // Show ~8 labels for larger datasets
    };

    return (
        <div>
            <div className="mb-3">
                <Form.Select
                    value={selectedTimePeriod}
                    onChange={handleTimePeriodChange}
                    disabled={isLoading}
                >
                    <option value="1day">1 Day</option>
                    <option value="7days">7 Days</option>
                    <option value="1month">1 Month</option>
                    <option value="1year">1 Year</option>
                    <option value="alltime">All Time</option>
                </Form.Select>
            </div>
            <ResponsiveContainer width="100%" height={300}>
                <BarChart
                    data={data}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                    barSize={selectedTimePeriod === '7days' ? 65 : 25}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="date"
                        angle={-45}
                        textAnchor="end"
                        height={75}
                        interval={calculateXAxisInterval()}
                        tickFormatter={formatXAxisTick}
                    />
                    <YAxis
                        domain={[0, yAxisMax]}
                        tickFormatter={(value) => `${value}%`}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                        dataKey="volatilityIndex"
                        fill="#8884d8"
                        isAnimationActive={!isLoading}
                    />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default VolatilityIndexChart;