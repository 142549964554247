import React, {useEffect, useState} from "react";
import Layout from "../../components/layout/Layout";
import Row from "react-bootstrap/Row";
import {
    Accordion,
    Badge,
    Breadcrumb,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Image,
    ProgressBar,
    Stack,
    Tooltip,
    Spinner
} from "react-bootstrap";
import CryptoPositionsService from "../../service/crypto/CryptoPositionsService";
import {UniswapPosition} from "../../model/crypto/CryptoModels";
import {Link, useParams} from "react-router-dom";
import moment from "moment";
import FormatUtils from "../../service/utils/FormatUtils";
import FeeCollectionTable from "../../components/crypto/uniswap/position/FeeCollectionTable";
import SubgraphUniswapService from "../../service/crypto/SubgraphUniswapService";
import InfoTooltip from "../../components/utils/InfoTooltip";
import PriceRangeSlider from "../../components/crypto/uniswap/position/PriceRangeSlider";
import PositionHeader from "../../components/crypto/uniswap/position/PositionHeader";
import PositionChart from "../../components/crypto/uniswap/position/PositionChart";
import UniswapPredictionService from "../../service/crypto/UniswapPredictionService";
import CreatePositionModal from "./CreatePositionModal";
import PriceHistoryAnalyzer from "../../components/crypto/uniswap/risk/PriceHistoryAnalyzer";
import TokenUtilsService from "../../service/utils/TokenUtilsService";

const CryptoPositionArbitrumPage = () => {
    const {id} = useParams();
    const positionId = Number(id);

    if (isNaN(positionId)) {
        return <p>Invalid position ID</p>;
    }

    const [position, setPosition] = useState<UniswapPosition>({} as UniswapPosition);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [priceOnPoolEdges, setPriceOnPoolEdges] = useState<{
        token0AtLower: number;
        token1AtLower: number;
        token0AtUpper: number;
        token1AtUpper: number
    }>({
        token0AtLower: 0,
        token1AtLower: 0,
        token0AtUpper: 0,
        token1AtUpper: 0
    });

    useEffect(() => {
        const fetchPositionDetails = async () => {
            try {
                setLoading(true);
                setError(false);

                // Fetch position details with integrated initial value
                const pos = await SubgraphUniswapService.getPositionDetails(positionId);

                if (!pos) {
                    setError(true);
                    return;
                }

                setPosition(pos);

                // Fetch additional fee collection events
                const collectEvents = await SubgraphUniswapService.fetchCollects(positionId);
                setPosition((prevPosition) => ({
                    ...prevPosition,
                    positionEvents: collectEvents.map(event => ({
                        ...event,
                        eventType: 'collect'
                    }))
                }));


                // Fetch unclaimed fees (if needed for validation)
                const unclaimedFees = await CryptoPositionsService.getUnclaimedFees(positionId);
                setPosition((prevPosition) => ({
                    ...prevPosition,
                    tokensOwed0: unclaimedFees.amount0,
                    tokensOwed1: unclaimedFees.amount1,
                    // Use optional chaining and nullish coalescing for safe access
                    uncollectedFeesUSD: unclaimedFees.amount0 * (prevPosition.token0?.derivedUSD ?? 0) +
                        unclaimedFees.amount1 * (prevPosition.token1?.derivedUSD ?? 0),
                    // Update totalFeesUSD safely
                    totalFeesUSD: prevPosition.feesCollectedUSD + (unclaimedFees.amount0 * (prevPosition.token0?.derivedUSD ?? 0) +
                        unclaimedFees.amount1 * (prevPosition.token1?.derivedUSD ?? 0))
                }));
            } catch (error) {
                console.error("Error fetching position details:", error);
                setError(true);
            } finally {
                setLoading(false);
            }
        };

        fetchPositionDetails();
    }, [positionId]);

    useEffect(() => {
        // Calculate price on edges when position data is available
        if (position.liquidity && position.priceRange?.lower?.token1PerToken0 && position.priceRange?.upper?.token1PerToken0) {
            const priceOnEdges = UniswapPredictionService.getTokenAmountsOnPositionEdges(
                position.liquidity,
                position.priceRange.lower.token1PerToken0,
                position.priceRange.upper.token1PerToken0,
                position.token0?.decimals || 18,
                position.token1?.decimals || 18
            );

            setPriceOnPoolEdges(priceOnEdges);
        }
    }, [position]);

    if (loading) {
        return (
            <Layout>
                <div className="d-flex justify-content-center align-items-center" style={{height: "50vh"}}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </Layout>
        );
    }

    if (error || !position.id) {
        return (
            <Layout>
                <div className="alert alert-danger">
                    <h4>Error loading position</h4>
                    <p>There was an error fetching position details. Please try again later.</p>
                </div>
            </Layout>
        );
    }

    // Calculate fee yield ratio (for display)
    const feeYieldRatio = position.totalFeesUSD * 100 / position.liquidityUSD;

    return (
        <Layout>
            <Row>
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col className="d-flex justify-content-between align-items-end">
                                    <Breadcrumb className="m-0">
                                        <Breadcrumb.Item linkAs="span" className="d-flex align-items-end">
                                            <h5 className="m-0"><Link to="/crypto/positions"
                                                                      className="text-decoration-none">My
                                                positions</Link></h5>
                                        </Breadcrumb.Item>
                                        <Breadcrumb.Item active className="d-flex align-items-end mb-0">
                                            <h5 className="fw-bolder m-0 d-inline-block">
                                                {position.id}
                                            </h5>
                                            <Badge bg="danger" className="text-body fw-bold ms-2 my-auto">
                                                {position.liquidityUSD === 0 && <>position closed</>}
                                            </Badge>
                                        </Breadcrumb.Item>
                                    </Breadcrumb>
                                    <h6 className="mt-0">
                                        created {moment(position.mintTimestamp).fromNow()}
                                    </h6>
                                </Col>
                            </Row>
                        </CardHeader>

                        <CardBody>
                            <div className="float-end">
                                <CreatePositionModal position={position}/>
                            </div>

                            <PositionHeader position={position}/>

                            <Row className="my-2 g-3">

                                {/* Total Return Card */}
                                <Col xs={6} md={3}>
                                    <Card className="h-100">
                                        <CardBody
                                            className="d-flex flex-column h-100 justify-content-evenly text-center px-1 py-2">
                                            <h5>Total Return
                                                <InfoTooltip
                                                    text="Total return including position value change, collected fees, and uncollected fees"/>
                                            </h5>
                                            {!position.initialLiquidityUSD ? (
                                                <div className="text-muted">
                                                    <p>Initial value data unavailable</p>
                                                </div>
                                            ) : (
                                                <>
                                                    {/* Calculate correct total return */}
                                                    {(() => {
                                                        // Get direct values from position object
                                                        const initialValue = position.initialLiquidityUSD;
                                                        const currentLiquidity = position.liquidityUSD;

                                                        // IMPORTANT: Use the pre-calculated totalFeesUSD from the service
                                                        // instead of recalculating, which could lead to double-counting
                                                        const totalFees = position.totalFeesUSD;

                                                        // Total value is current liquidity plus total fees
                                                        const totalValue = currentLiquidity + totalFees;
                                                        const absoluteReturn = totalValue - initialValue;

                                                        // Calculate return percentage with safeguards
                                                        const percentReturn = initialValue > 0
                                                            ? (absoluteReturn / initialValue) * 100
                                                            : 0;

                                                        // Calculate APR with enhanced protection against unrealistic values
                                                        const ageInYears = position.ageInSeconds / 31536000; // seconds in a year
                                                        let annualizedReturn = 0;

                                                        if (ageInYears >= 0.01) { // At least ~3.65 days old
                                                            annualizedReturn = percentReturn / ageInYears;
                                                        } else if (ageInYears > 0) {
                                                            // For very new positions, cap the annualization factor
                                                            const maxAnnualizationFactor = 100; // Cap for very new positions
                                                            annualizedReturn = percentReturn * Math.min(1 / ageInYears, maxAnnualizationFactor);
                                                        }

                                                        // Apply a realistic cap on APR
                                                        const maxRealisticAPR = 10000; // 10,000% APR cap
                                                        annualizedReturn = Math.min(annualizedReturn, maxRealisticAPR);

                                                        const isProfitable = absoluteReturn >= 0;

                                                        return (
                                                            <>
                                                                <h4 className={`mx-auto ${isProfitable ? "text-success" : "text-danger"}`}>
                                                                    {isProfitable ? "+" : ""}{FormatUtils.formatNumber(absoluteReturn)}$
                                                                    ({FormatUtils.formatNumber(percentReturn)}%)
                                                                </h4>
                                                                <p className="m-0 small">
                                                                    ${FormatUtils.formatNumber(initialValue)} →
                                                                    ${FormatUtils.formatNumber(totalValue)}
                                                                </p>
                                                                <p className="m-0 small fw-bolder mt-1">
                                                                    APR: <span
                                                                    className={`mx-1 ${isProfitable ? "text-success" : "text-danger"}`}>
                                                                    {FormatUtils.formatNumber(annualizedReturn)}%
                                                                </span>
                                                                </p>
                                                                <p className="m-0 small">
                                                                    (Includes ${FormatUtils.formatNumber(totalFees)} in
                                                                    fees)
                                                                </p>
                                                            </>
                                                        );
                                                    })()}
                                                </>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Col>

                                {/* Position Value Card */}
                                <Col xs={6} md={3}>
                                    <Card className="h-100">
                                        <CardBody
                                            className="d-flex flex-column h-100 justify-content-evenly text-center px-1 py-2">
                                            <h5>Position Value
                                                <InfoTooltip
                                                    text="Initial and current position value with percentage change"/>
                                            </h5>
                                            <div
                                                className={`fw-bold fs-4 ${position.profitLossUSD > 0 ? "text-success" : "text-danger"}`}>
                                                {/*{position.isProfitable ? "+" : ""}*/}
                                                {FormatUtils.formatNumber(position.profitLossUSD)}$
                                                ({FormatUtils.formatNumber(position.returnPercentage)}%)
                                            </div>
                                            <div className="small mx-1">
                                                <div className="d-flex justify-content-between">
                                                    <span>Initial:</span>
                                                    <span
                                                        className="fw-bold">${FormatUtils.formatNumber(position.initialLiquidityUSD)}</span>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span>Current:</span>
                                                    <span
                                                        className="fw-bold">${FormatUtils.formatNumber(position.liquidityUSD)}</span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>



                                {/* Total Fees Card */}
                                <Col xs={6} md={3}>
                                    <Card className="h-100">
                                        <CardBody
                                            className="d-flex flex-column h-100 justify-content-evenly text-center px-1 py-2">
                                            <h5>Total Fees
                                                <InfoTooltip
                                                    text="Sum of collected and uncollected fees earned by this position"/>
                                            </h5>
                                            <h4>${FormatUtils.formatNumber(position.totalFeesUSD)}</h4>
                                            <div className="small mx-1">
                                                <div className="d-flex justify-content-between">
                                                    <span>Collected:</span>
                                                    <span
                                                        className="fw-bold">${FormatUtils.formatNumber(position.feesCollectedUSD)}</span>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    <span>Uncollected:</span>
                                                    <span
                                                        className="fw-bold">${FormatUtils.formatNumber(position.uncollectedFeesUSD)}</span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                {/* Fee Yield Card with improved calculations */}
                                <Col xs={6} md={3}>
                                    <Card className="h-100">
                                        <CardBody
                                            className="d-flex flex-column h-100 justify-content-evenly text-center px-1 py-2">
                                            <h5>Fee Yield
                                                <InfoTooltip
                                                    text="Annual percentage rate (APR) and fee yield ratio. The yield ratio is displayed in green if it's above 0.125%, indicating that half of the position can be swapped on Uniswap without loss (considering a 0.25% swap fee)."/>
                                            </h5>
                                            <div className="small mb-1 mx-1">
                                                <div className="d-flex justify-content-between">
                                                    <span>APR:</span>
                                                    <span className="text-success fw-bolder">
                        {FormatUtils.formatNumber(position.annualizedReturn)}%
                    </span>
                                                </div>
                                                <div className="d-flex justify-content-between ">
                                                    <span>Fee Yield:</span>
                                                    <span
                                                        className={`fw-bold ${feeYieldRatio < 0.125 ? "text-danger" : "text-success"}`}>
                        {FormatUtils.formatNumber(feeYieldRatio)}%
                    </span>
                                                </div>
                                            </div>
                                            {position.positionEvents?.filter(event => event.eventType === 'collect')?.length > 0 && (
                                                <p className="m-0 small">
                                                    APR since last fees collected:&nbsp;
                                                    <span className="text-success fw-bolder">
                        {(() => {
                            try {
                                // Find timestamp of last collection event
                                const events = position.positionEvents.filter(e => e.eventType === 'collect');
                                if (!events.length) return "0.00";

                                const lastCollectTimestamp = events[events.length - 1]?.timestamp;
                                if (!lastCollectTimestamp) return "0.00";

                                // Time since last collection in seconds
                                const timeSinceLastCollect = (Date.now() / 1000) - lastCollectTimestamp;
                                if (timeSinceLastCollect <= 0) return "0.00";

                                // Calculate APR based on uncollected fees only
                                const annualizationFactor = 31536000 / timeSinceLastCollect;
                                let aprSinceLastCollect = 0;

                                if (position.liquidityUSD > 0) {
                                    aprSinceLastCollect = annualizationFactor * position.uncollectedFeesUSD * 100 / position.liquidityUSD;
                                }

                                // Apply realistic cap (100,000% would be extremely rare)
                                return FormatUtils.formatNumber(Math.min(aprSinceLastCollect, 100000));
                            } catch (error) {
                                console.error("Error calculating recent APR:", error);
                                return "0.00";
                            }
                        })()}%
                    </span>
                                                </p>
                                            )}
                                        </CardBody>
                                    </Card>

                                </Col>
                            </Row>

                            <PriceHistoryAnalyzer position={position}
                                                  onRangeRecommendation={(lowerPrice, upperPrice) => 100}/>

                            <PositionChart position={position}/>

                            <Card className="my-3">
                                <CardBody>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h5 className=""> LIQUIDITY</h5>
                                            <h2 className="pb-0"> ${FormatUtils.formatNumber(position?.liquidityUSD)} </h2>
                                            <p>
                                                ({FormatUtils.formatNumber(priceOnPoolEdges?.token0AtLower)} {position?.token0?.symbol} - {FormatUtils.formatNumber(priceOnPoolEdges?.token1AtUpper)} {position?.token1?.symbol})
                                                <InfoTooltip
                                                    text="This represents pool liquidity values when the price is at the lower or upper edge"/>
                                            </p>
                                        </Col>

                                        <Col xs={12} md={6}>
                                            <Card className="bg-body-tertiary">
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="float-start">{TokenUtilsService.normalizeSymbol(position.token0?.symbol)}</h6>
                                                            <h6 className="float-end">
                                                                {FormatUtils.NumberFormatter(position.amount0, 2)}
                                                                (${FormatUtils.NumberFormatter(position.amount0 * (position.token0?.derivedUSD ?? 0), 2)})&nbsp;
                                                                {((position.amount0 * (position.token0?.derivedUSD ?? 0) * 100) / (position.liquidityUSD || 1)).toFixed(2)}%
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="float-start">{TokenUtilsService.normalizeSymbol(position.token1?.symbol)}</h6>
                                                            <h6 className="float-end">
                                                                {FormatUtils.NumberFormatter(position.amount1, 2)}
                                                                (${FormatUtils.NumberFormatter(position.amount1 * (position.token1?.derivedUSD ?? 0), 2)})&nbsp;
                                                                {FormatUtils.NumberFormatter((position.amount1 * (position.token1?.derivedUSD ?? 0) * 100) / (position.liquidityUSD || 1), 2)}%
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="my-3">
                                <CardBody>
                                    <PriceRangeSlider position={position}/>
                                </CardBody>
                            </Card>

                            <Card className="my-3">
                                <CardBody>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <h6>Uncollected Fees</h6>
                                            <h3 className="pb-2">
                                                ${FormatUtils.formatNumber(position.uncollectedFeesUSD)}
                                            </h3>
                                        </Col>

                                        <Col xs={12} md={6}>
                                            <Card className="bg-body-tertiary">
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="float-start">{TokenUtilsService.normalizeSymbol(position.token0?.symbol)}</h6>
                                                            <h6 className="float-end">
                                                                {position.tokensOwed0?.toFixed(2)}
                                                                (${((position.tokensOwed0 ?? 0) * (position.token0?.derivedUSD ?? 0))?.toFixed(2)})
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <h6 className="float-start">{TokenUtilsService.normalizeSymbol(position.token1?.symbol)}</h6>
                                                            <h6 className="float-end">
                                                                {position.tokensOwed1?.toFixed(2)}
                                                                (${((position.tokensOwed1 ?? 0) * (position.token1?.derivedUSD ?? 0))?.toFixed(2)})
                                                            </h6>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <FeeCollectionTable position={position}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Layout>
    );
};

export default CryptoPositionArbitrumPage;