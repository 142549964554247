import { Pool } from "../../../model/crypto/CryptoModels";

// Define time period options type
export type TimePeriod = '1day' | '7days' | '1month' | '1year' | 'alltime';

// Convert string values to numbers safely
export const toNumber = (value: string | number | undefined): number => {
    if (value === undefined || value === null) return 0;

    if (typeof value === 'number') return value;

    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
};

// Convert numbers to strings safely
export const toString = (value: number | string | undefined): string => {
    if (value === undefined || value === null) return '0';
    return String(value);
};

// Format a value with appropriate suffix (K, M, B)
export const formatValue = (value: number | string, decimals = 2): string => {
    const num = toNumber(value);

    if (num >= 1e9) {
        return (num / 1e9).toFixed(decimals) + 'B';
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(decimals) + 'M';
    } else if (num >= 1e3) {
        return (num / 1e3).toFixed(decimals) + 'K';
    }

    return num.toFixed(decimals);
};

// Determine color based on APR value
export const getAprColor = (apr: number): string => {
    if (apr >= 100) return 'danger';  // Very high (potentially risky) APR
    if (apr >= 50) return 'warning';  // High APR
    if (apr >= 20) return 'success';  // Good APR
    if (apr >= 5) return 'info';      // Moderate APR
    return 'secondary';               // Low APR
};

// Get color and icon for badge based on network
export const getNetworkBadgeInfo = (network: string): { bg: string; icon: string } => {
    switch(network.toLowerCase()) {
        case 'arbitrum':
            return { bg: 'primary', icon: '🔵' };
        case 'ethereum':
            return { bg: 'info', icon: '💠' };
        case 'optimism':
            return { bg: 'danger', icon: '🔴' };
        case 'base':
            return { bg: 'success', icon: '🟢' };
        case 'polygon':
            return { bg: 'purple', icon: '🟣' };
        default:
            return { bg: 'secondary', icon: '🌐' };
    }
};

// Compare APR values and return indicators
export const getAprComparison = (aprValue: number, sevenDayApr: number, thirtyDayApr: number): {
    trend: 'up' | 'down' | 'stable';
    percentage: number;
    description: string
} => {
    // Default values
    let trend: 'up' | 'down' | 'stable' = 'stable';
    let percentage = 0;
    let description = 'Stable APR';

    // Calculate percentage difference between current APR and 30-day APR
    if (thirtyDayApr > 0) {
        percentage = Math.abs(((aprValue - thirtyDayApr) / thirtyDayApr) * 100);

        if (percentage >= 5) {  // Only report significant changes (5% or more)
            if (aprValue > thirtyDayApr) {
                trend = 'up';
                description = `APR is ${percentage.toFixed(1)}% higher than 30-day average`;
            } else {
                trend = 'down';
                description = `APR is ${percentage.toFixed(1)}% lower than 30-day average`;
            }
        }
    }
    // If no 30-day data, compare with 7-day data
    else if (sevenDayApr > 0) {
        percentage = Math.abs(((aprValue - sevenDayApr) / sevenDayApr) * 100);

        if (percentage >= 5) {
            if (aprValue > sevenDayApr) {
                trend = 'up';
                description = `APR is ${percentage.toFixed(1)}% higher than 7-day average`;
            } else {
                trend = 'down';
                description = `APR is ${percentage.toFixed(1)}% lower than 7-day average`;
            }
        }
    }

    return { trend, percentage, description };
};

// Calculate APR based on time period selection
export const calculateTimeBasedApr = (pool: Pool, timePeriod: string): number => {
    const tvlUSD = toNumber(pool.tvlUSD);
    if (tvlUSD <= 0) return 0;

    switch(timePeriod) {
        case '1day': {
            const feeRevenue = toNumber(pool.feesUSD24h);
            return (feeRevenue / tvlUSD) * 365 * 100;
        }
        case '7days': {
            const feeRevenue = toNumber(pool.feesUSD7d);
            // Convert 7-day total to daily average
            return feeRevenue > 0 ? ((feeRevenue / 7) / tvlUSD) * 365 * 100 : 0;
        }
        case '1month': {
            const feeRevenue = toNumber(pool.feesUSD30d);
            // Convert 30-day total to daily average
            return feeRevenue > 0 ? ((feeRevenue / 30) / tvlUSD) * 365 * 100 : 0;
        }
        case '1year': {
            // Use the annualized APR provided by the API
            return toNumber(pool.apr);
        }
        case 'alltime': {
            // Calculate all-time APR using the most comprehensive data available
            return calculateAllTimeApr(pool);
        }
        default:
            return toNumber(pool.apr);
    }
};

// Calculate all-time APR using the best available data
export const calculateAllTimeApr = (pool: Pool): number => {
    const tvlUSD = toNumber(pool.tvlUSD);
    if (tvlUSD <= 0) return 0;

    // Prefer 30-day data as it's more stable for long-term calculations
    const totalFees30d = toNumber(pool.feesUSD30d);

    if (totalFees30d > 0) {
        // Calculate average daily fee using 30-day window
        const avgDailyFees = totalFees30d / 30;
        // Annualize fees
        const annualizedFees = avgDailyFees * 365;
        // Calculate APR
        return (annualizedFees / tvlUSD) * 100;
    }

    // Fall back to 7-day data if 30-day not available or zero
    const totalFees7d = toNumber(pool.feesUSD7d);
    if (totalFees7d > 0) {
        const avgDailyFees = totalFees7d / 7;
        const annualizedFees = avgDailyFees * 365;
        return (annualizedFees / tvlUSD) * 100;
    }

    // Fall back to 24h data as last resort
    const dailyFees = toNumber(pool.feesUSD24h);
    if (dailyFees > 0) {
        return (dailyFees * 365 / tvlUSD) * 100;
    }

    // If no fee data available, return 0
    return 0;
};