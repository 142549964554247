import React from "react";
import Col from "react-bootstrap/Col";
import { Toaster } from 'react-hot-toast';
import SignUpComponent from "../../../components/public/sign-up/SignUpComponent";
import LayoutEmpty from "../../../components/layout/LayoutEmpty";

function SignUpPage() {
    const handleSignUpSuccess = () => {
        // This function will be called after successful registration
        console.log("User registered successfully");
    };

    const subtitle = (
        <>
            <span className="mx-2">Already have an account?</span>
            <a href="sign-in">Sign in here</a>
        </>
    );

    const content = (
        <>
            <Col className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5 pb-2 align-content-center">
                <SignUpComponent
                    title="Sign Up"
                    subtitle={subtitle}
                    onSignUpSuccess={handleSignUpSuccess}
                />
            </Col>
            {/* Toaster component for toast notifications */}
            <Toaster position="top-right" />
        </>
    );

    return (
        <>
            <LayoutEmpty center>
                {content}
            </LayoutEmpty>
        </>
    );
}

export default SignUpPage;