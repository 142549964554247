import React, {useState, useEffect} from "react";
import {Container, Card, Spinner, Alert, Button} from "react-bootstrap";
import UniswapBestPoolRangesService from "../../../../service/crypto/prediction-services/UniswapBestPoolRangesService";
import Layout from "../../../layout/Layout";

interface PoolRangeData {
    lowerPrice0: number;
    upperPrice0: number;
    lowerPrice1: number;
    upperPrice1: number;
    roi: number;
}

const UniswapBestPoolRangesComponent: React.FC = () => {
    const poolAddress = ""; // Example ETH/USDT Uniswap V3 Pool

    const [bestRange, setBestRange] = useState<PoolRangeData>({
        lowerPrice0: 0,
        upperPrice0: 0,
        lowerPrice1: 0,
        upperPrice1: 0,
        roi: 0,
    });

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const fetchBestRange = async () => {
        try {
            setLoading(true);
            setError(null);
            const data = await UniswapBestPoolRangesService.fetchBestPoolRanges(poolAddress);
            setBestRange(data);
        } catch (err) {
            setError(err instanceof Error ? err.message : "Failed to fetch Uniswap V3 best pool ranges.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBestRange();
    }, [poolAddress]);

    return (
        <Layout>
            <Container className="mt-4">
                <Card className="shadow-lg p-4">
                    <Card.Body>
                        <Card.Title className="text-center">📊 Best Uniswap V3 Pool Price Range</Card.Title>

                        {loading ? (
                            <div className="text-center my-3">
                                <Spinner animation="border" variant="primary"/>
                                <p>Fetching best pool price ranges...</p>
                            </div>
                        ) : error ? (
                            <Alert variant="danger" className="text-center">
                                ❌ {error}
                                <div className="mt-2">
                                    <Button variant="outline-danger" onClick={fetchBestRange}>
                                        🔄 Retry
                                    </Button>
                                </div>
                            </Alert>
                        ) : (
                            <>
                                <h5 className="mt-3">🔹 Token0 per Token1 (e.g., WETH/USDT)</h5>
                                <p>💰 <strong>Lower Price:</strong> {bestRange.lowerPrice0.toFixed(6)}</p>
                                <p>💰 <strong>Upper Price:</strong> {bestRange.upperPrice0.toFixed(6)}</p>

                                <h5 className="mt-3">🔸 Token1 per Token0 (e.g., USDT/WETH)</h5>
                                <p>💲 <strong>Lower Price:</strong> {bestRange.lowerPrice1.toFixed(6)}</p>
                                <p>💲 <strong>Upper Price:</strong> {bestRange.upperPrice1.toFixed(6)}</p>

                                <h5 className="mt-3">💰 Return on Investment (ROI)</h5>
                                <p>📈 <strong>ROI:</strong> {bestRange.roi.toFixed(2)}</p>

                                <div className="text-center mt-3">
                                    <Button variant="primary" onClick={fetchBestRange}>
                                        🔄 Refresh Data
                                    </Button>
                                </div>
                            </>
                        )}
                    </Card.Body>
                </Card>
            </Container>
        </Layout>
    );
};

export default UniswapBestPoolRangesComponent;
