import axiosClient, { showAlert } from "./axios/AxiosClient";
import { IUser } from "../model/User";
import AuthService from "./auth/AuthService";

const BASE_URL = process.env.REACT_APP_BASE_URL;

class UserConnectionsService {
    /**
     * Follow a user by ID
     * @param id User ID to follow
     * @returns Promise with the response
     */
    async followUser(id: number) {
        try {
            const response = await axiosClient.put(BASE_URL + '/user/follow', {id: id});
            showAlert('success', 'You are now following this user');
            return response;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || 'Failed to follow user';
            showAlert('danger', errorMessage);
            return Promise.reject(error);
        }
    }

    /**
     * Unfollow a user by ID
     * @param id User ID to unfollow
     * @returns Promise with the response
     */
    async unfollowUser(id: number) {
        try {
            const response = await axiosClient.put(BASE_URL + '/user/unfollow', id);
            showAlert('success', 'You have unfollowed this user');
            return response;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || 'Failed to unfollow user';
            showAlert('danger', errorMessage);
            return Promise.reject(error);
        }
    }

    /**
     * Check if current user is following a user by ID
     * @param id User ID to check
     * @returns Promise with boolean result
     */
    async isFollowing(id: number) {
        try {
            const currentUser = AuthService.getCurrentUser();

            // If checking self, always return false (can't follow yourself)
            if (currentUser?.id === id) {
                return { data: false };
            }

            const response = await axiosClient.get<boolean>(BASE_URL + '/user/following/' + id);
            return response;
        } catch (error: any) {
            console.error('Error checking follow status:', error);
            // For this method, we don't show an alert as it's likely a background check
            return { data: false };
        }
    }

    /**
     * Get confirmed following (users that the specified user follows)
     * @param id User ID
     * @returns Promise with list of users
     */
    async getConfirmedFollowing(id: number) {
        try {
            return await axiosClient.get<IUser[]>(BASE_URL + '/user/following/confirmed/' + id);
        } catch (error: any) {
            console.error('Error fetching confirmed following:', error);
            return { data: [] };
        }
    }

    /**
     * Get pending follow requests initiated by user
     * @param id User ID
     * @returns Promise with list of pending requests
     */
    async getFollowingConnectionRequests(id: number) {
        try {
            return await axiosClient.get(BASE_URL + '/user/following/req/' + id);
        } catch (error: any) {
            console.error('Error fetching following requests:', error);
            return { data: [] };
        }
    }

    /**
     * Get pending follow requests received by user
     * @param id User ID
     * @returns Promise with list of pending requests
     */
    async getFollowersConnectionRequests(id: number) {
        try {
            return await axiosClient.get(BASE_URL + '/user/followers/req/' + id);
        } catch (error: any) {
            console.error('Error fetching follower requests:', error);
            return { data: [] };
        }
    }

    /**
     * Get confirmed followers (users that follow the specified user)
     * @param id User ID
     * @returns Promise with list of users
     */
    async getConfirmedFollowers(id: number) {
        try {
            return await axiosClient.get<IUser[]>(BASE_URL + '/user/followers/confirmed/' + id);
        } catch (error: any) {
            console.error('Error fetching confirmed followers:', error);
            return { data: [] };
        }
    }

    /**
     * Get count of users that the specified user follows
     * @param id User ID
     * @returns Promise with count
     */
    async getFollowingCount(id: number) {
        try {
            return await axiosClient.get<number>(BASE_URL + '/user/following/count/' + id);
        } catch (error: any) {
            console.error('Error fetching following count:', error);
            return { data: 0 };
        }
    }

    /**
     * Get count of users that follow the specified user
     * @param id User ID
     * @returns Promise with count
     */
    async getFollowersCount(id: number) {
        try {
            return await axiosClient.get<number>(BASE_URL + '/user/followers/count/' + id);
        } catch (error: any) {
            console.error('Error fetching followers count:', error);
            return { data: 0 };
        }
    }

    /**
     * Get mutual connections between current user and specified user
     * @param id User ID
     * @returns Promise with list of mutual connections
     */
    async getMutualConnections(id: number) {
        try {
            return await axiosClient.get<IUser[]>(BASE_URL + '/user/connections/mutual/' + id);
        } catch (error: any) {
            console.error('Error fetching mutual connections:', error);
            return { data: [] };
        }
    }

    /**
     * Get count of mutual connections between current user and specified user
     * @param id User ID
     * @returns Promise with count
     */
    async getMutualConnectionsCount(id: number) {
        try {
            return await axiosClient.get<number>(BASE_URL + '/user/connections/mutual/count/' + id);
        } catch (error: any) {
            console.error('Error fetching mutual connections count:', error);
            return { data: 0 };
        }
    }

    /**
     * Remove a user from your following list
     * @param userId User ID to remove
     * @returns Promise with the response
     */
    async removeFollowing(userId: number) {
        try {
            const response = await axiosClient.delete(BASE_URL + '/user/following/remove/' + userId);
            showAlert('success', 'User removed from your following list');
            return response;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || 'Failed to remove user from following';
            showAlert('danger', errorMessage);
            return Promise.reject(error);
        }
    }

    /**
     * Remove a user from your followers list
     * @param userId User ID to remove
     * @returns Promise with the response
     */
    async removeFollower(userId: number) {
        try {
            const response = await axiosClient.delete(BASE_URL + '/user/follower/remove/' + userId);
            showAlert('success', 'User removed from your followers list');
            return response;
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || 'Failed to remove follower';
            showAlert('danger', errorMessage);
            return Promise.reject(error);
        }
    }
}

export default new UserConnectionsService();