import React from "react";
import './Footer.css';
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from 'react-bootstrap/Nav';
import {
    FaRegUser,
    FaChartLine,
    FaEnvelope,
    FaCreditCard
} from "react-icons/fa";
import AuthService from "../../../service/auth/AuthService";

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <>
            {/* Desktop Footer */}
            <Navbar className="d-none d-md-inline bg-body-secondary mt-3">
                <div className="justify-content-center w-100">
                    <Nav className="flex-nowrap justify-content-center gap-2 d-flex flex-row border-bottom border-secondary mx-5">
                        <Nav.Link href="/home" className="px-2 text-muted">Home</Nav.Link>
                        <Nav.Link href="/crypto/pools" className="px-2 text-muted">Liquidity Pools</Nav.Link>
                        <Nav.Link href="/privacy-policy" className="px-2 text-muted">Privacy Policy</Nav.Link>
                        <Nav.Link href="/terms-and-conditions" className="px-2 text-muted">Terms & Conditions</Nav.Link>
                        <Nav.Link href="/faq" className="px-2 text-muted">FAQs</Nav.Link>
                        <Nav.Link href="/landing" className="px-2 text-muted">About</Nav.Link>

                    </Nav>
                    <p className="text-center text-muted py-1 my-0">
                        © {currentYear} FinMates Inc
                    </p>
                </div>
            </Navbar>

            {/* Mobile Navigation Bar */}
            {/*{AuthService.isLoggedIn() && (
                <Navbar
                    className="d-md-none d-flex justify-content-around text-body bg-body-secondary mt-3 fixed-bottom"
                    sticky="bottom"
                >
                    <Nav.Item className="text-center">
                        <Nav.Link href="/account" className="p-0">
                            <div><FaRegUser size={18}/></div>
                            <small className="x-small">Account</small>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="text-center">
                        <Nav.Link href="/stocks" className="p-0">
                            <div><FaChartLine size={18}/></div>
                            <small className="x-small">Stocks</small>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="text-center">
                        <Nav.Link href="/messages" className="p-0">
                            <div><FaEnvelope size={18}/></div>
                            <small className="x-small">Messages</small>
                        </Nav.Link>
                    </Nav.Item>

                    <Nav.Item className="text-center">
                        <Nav.Link href="/subscriptions" className="p-0">
                            <div><FaCreditCard size={18}/></div>
                            <small className="x-small">Subscriptions</small>
                        </Nav.Link>
                    </Nav.Item>
                </Navbar>
            )}*/}
        </>
    );
};

export default Footer;