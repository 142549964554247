class UniswapMath {
    /**
     * Converts a given price (token1/token0) to sqrtPriceX96 using BigInt.
     *
     * @param price - The current price (token1/token0).
     * @returns The sqrtPriceX96 as a BigInt.
     */
    priceToSqrtPriceX96(price: number): bigint {
        const Q96 = 2n ** 96n; // 2^96 for Uniswap fixed-point math

        // Compute sqrt(price) * 2^96
        const sqrtPrice = Math.sqrt(price);
        return BigInt(Math.floor(sqrtPrice * Number(Q96)));
    }

    /**
     * Converts feeGrowthInsideX128 to actual fee value
     * @param feeGrowthX128 Fee growth in Uniswap Q128 format
     * @param liquidity Liquidity of the position
     * @param tokenDecimals Decimals of the token
     * @returns Actual fee earned in token units
     */
    convertFeeGrowthToActual(
        feeGrowthX128: string,
        liquidity: bigint,
        tokenDecimals: number
    ): number {
        // Handling potential null/undefined/empty values
        if (!feeGrowthX128 || !liquidity) {
            return 0;
        }

        try {
            // The Q128 representation for fixed-point math in Uniswap
            const Q128 = BigInt("0x100000000000000000000000000000000"); // 2^128

            // Parse the fee growth value - must be treated as a BigInt for precision
            const feeGrowth = BigInt(feeGrowthX128);

            // Calculate actual fees earned = (feeGrowthX128 * liquidity) / 2^128
            const rawFee = (feeGrowth * liquidity) / Q128;

            // Convert the raw fee to human-readable format with proper decimal adjustment
            // We need to be careful with the conversion to avoid precision loss
            const divisor = 10 ** tokenDecimals;

            // For high precision, handle whole and fractional parts separately
            const wholePart = Number(rawFee / BigInt(divisor));
            const fractionalPart = Number(rawFee % BigInt(divisor)) / divisor;
            const adjustedFee = wholePart + fractionalPart;

            // Sanity check - implement a reasonable cap based on typical Uniswap economics
            // Fees rarely exceed a few percent of position value in a short time period
            const positionValueEstimate = Number(liquidity) / (10 ** tokenDecimals);
            const reasonableFeeLimit = positionValueEstimate * 0.5; // 50% of position value as absolute maximum

            if (adjustedFee > reasonableFeeLimit) {
                console.warn(`Fee calculation produced unusually high result: ${adjustedFee}. ` +
                    `Capping to ${reasonableFeeLimit} (50% of estimated position value)`);
                return reasonableFeeLimit;
            }

            return adjustedFee;
        } catch (error) {
            console.error("Error in fee calculation:", error);
            return 0;
        }
    }

    /**
     * Computes token0 and token1 amounts from Uniswap V3 liquidity using tick values.
     * @param liquidity The liquidity amount as a BigInt
     * @param sqrtPriceX96 The current sqrt price as a BigInt (from pool)
     * @param tickLower The tick at the lower bound
     * @param tickUpper The tick at the upper bound
     * @param token0Decimals The number of decimals for token0
     * @param token1Decimals The number of decimals for token1
     * @returns The computed amounts of token0 and token1 (as numbers)
     */
    getTokenAmountsFromLiquidity(
        liquidity: bigint,
        sqrtPriceX96: bigint,
        tickLower: number,
        tickUpper: number,
        token0Decimals: number,
        token1Decimals: number
    ): { token0Amount: number; token1Amount: number } {
        // Handle edge cases
        if (!liquidity || liquidity === 0n) {
            return { token0Amount: 0, token1Amount: 0 };
        }

        if (!sqrtPriceX96 || sqrtPriceX96 === 0n) {
            return { token0Amount: 0, token1Amount: 0 };
        }

        const Q96 = 1n << 96n; // Equivalent to 2^96 in BigInt

        try {
            // Convert ticks to sqrtPriceX96 values
            const sqrtPriceLowerX96 = BigInt(Math.floor(Math.sqrt(1.0001 ** tickLower) * Number(Q96)));
            const sqrtPriceUpperX96 = BigInt(Math.floor(Math.sqrt(1.0001 ** tickUpper) * Number(Q96)));

            let token0Amount = 0n;
            let token1Amount = 0n;

            if (sqrtPriceX96 <= sqrtPriceLowerX96) {
                // Price is below the lower bound → Only token0 is present
                token0Amount = (liquidity * (Q96 * Q96 / sqrtPriceLowerX96 - Q96 * Q96 / sqrtPriceUpperX96)) / Q96;
            } else if (sqrtPriceX96 >= sqrtPriceUpperX96) {
                // Price is above the upper bound → Only token1 is present
                token1Amount = (liquidity * (sqrtPriceUpperX96 - sqrtPriceLowerX96)) / Q96;
            } else {
                // Price is within range → Both token0 and token1 are present
                token0Amount = (liquidity * (Q96 * Q96 / sqrtPriceX96 - Q96 * Q96 / sqrtPriceUpperX96)) / Q96;
                token1Amount = (liquidity * (sqrtPriceX96 - sqrtPriceLowerX96)) / Q96;
            }

            // Convert token amounts to decimal format using token decimals
            return {
                token0Amount: Number(token0Amount) / 10 ** token0Decimals,
                token1Amount: Number(token1Amount) / 10 ** token1Decimals
            };
        } catch (error) {
            console.error("Error calculating token amounts:", error);
            return { token0Amount: 0, token1Amount: 0 };
        }
    }

    /**
     * Converts tick to price
     * @param tick The tick value
     * @param token0Decimals Decimals for token0
     * @param token1Decimals Decimals for token1
     * @returns Price as token1/token0
     */
    tickToPrice(tick: number, token0Decimals: number, token1Decimals: number): number {
        try {
            const decimalAdjustment = 10 ** (token0Decimals - token1Decimals);
            return Math.pow(1.0001, tick) * decimalAdjustment;
        } catch (error) {
            console.error("Error in tickToPrice:", error);
            return 0;
        }
    }
}

export default new UniswapMath();