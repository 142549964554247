import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { Alert, Button, FloatingLabel, Form, Spinner } from "react-bootstrap";
import toast from 'react-hot-toast';
import * as yup from "yup";
import { Formik } from "formik";
import { IUserRegistrationFormDTO } from "../../../model/User";
import AuthService from "../../../service/auth/AuthService";
import { useNavigate } from "react-router-dom";

interface SignUpComponentProps {
    title?: string;
    subtitle?: any;
    onSignUpSuccess?: () => void;
}

function SignUpComponent({ title, subtitle, onSignUpSuccess }: SignUpComponentProps) {
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const navigate = useNavigate();

    // Validation schema using yup
    const validationSchema = yup.object().shape({
        firstName: yup.string()
            .max(20, "First name must be less than 20 characters")
            .required("First name is required"),
        lastName: yup.string()
            .max(20, "Last name must be less than 20 characters")
            .required("Last name is required"),
        email: yup.string()
            .required("Email is required")
            .email("Please enter a valid email"),
        password: yup.string()
            .required("Password is required")
            .min(8, 'Password must be at least 8 characters long')
            .matches(/[0-9]/, 'Password requires a number')
            .matches(/[a-z]/, 'Password requires a lowercase letter')
            .matches(/[A-Z]/, 'Password requires an uppercase letter')
            .matches(/[^\w]/, 'Password requires a symbol'),
        passwordConfirmation: yup.string()
            .required("Please confirm your password")
            .oneOf([yup.ref("password")], "Passwords must match"),
        termsAccepted: yup.boolean()
            .required("You must accept the terms")
            .oneOf([true], "You must accept the terms to continue")
    });

    // Empty initial values
    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        termsAccepted: false
    };

    // Handle continue to login button click
    const handleContinueToLogin = () => {
        navigate('/sign-in', {
            state: {
                showAlert: true,
                alertType: 'success',
                alertMessage: 'Account created successfully! Please check your email for verification instructions before logging in.'
            }
        });
    };

    return (
        <Card className="card card-body rounded-3 p-2 p-sm-5">
            <div className="text-center text-body">
                {title && <h1 className="mb-2">{title}</h1>}
                {!showSuccessAlert && subtitle && <p className="d-block">{subtitle}</p>}

                {/* Success Screen */}
                {showSuccessAlert && (
                    <div className="success-container">
                        <Alert
                            variant="success"
                            className="mb-4"
                        >
                            <Alert.Heading>Account created successfully!</Alert.Heading>
                            <p>
                                Please check your email for the next steps. You&apos;ll need to confirm your email address before you can log in.
                            </p>
                        </Alert>

                        <div className="d-grid gap-2 mt-4">
                            <Button
                                variant="primary"
                                size="lg"
                                onClick={handleContinueToLogin}
                            >
                                Continue to Login
                            </Button>
                        </div>
                    </div>
                )}

                {/* Registration Form */}
                {!showSuccessAlert && (
                    <Formik
                        validateOnChange={true}
                        validateOnBlur={true}
                        validationSchema={validationSchema}
                        initialValues={initialValues}
                        onSubmit={async (values, {setSubmitting, resetForm, setStatus}) => {
                            try {
                                setSubmitting(true);
                                await AuthService.register(values as IUserRegistrationFormDTO);
                                toast.success("Registration successful!");
                                resetForm();
                                setStatus({success: true});

                                // Show the success alert
                                setShowSuccessAlert(true);

                                if (onSignUpSuccess) {
                                    onSignUpSuccess();
                                }
                            } catch (err: any) {
                                const errorMessage = err.response?.data?.message || "Registration failed. Please try again.";
                                toast.error(errorMessage);
                                setStatus({success: false, message: errorMessage});
                            } finally {
                                setSubmitting(false);
                            }
                        }}
                    >
                        {({
                              isSubmitting,
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              errors,
                              isValid,
                          }) => (
                            <Form
                                className="mt-4 mb-4 g-3 text-start"
                                name="registrationForm"
                                onSubmit={handleSubmit}
                                aria-label="Sign up form"
                            >
                                <Form.Group controlId="firstName">
                                    <FloatingLabel
                                        label="First Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            name="firstName"
                                            size="lg"
                                            placeholder="First Name"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.firstName && !errors.firstName}
                                            isInvalid={touched.firstName && !!errors.firstName}
                                            aria-required="true"
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.firstName}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group controlId="lastName">
                                    <FloatingLabel
                                        label="Last Name"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="text"
                                            name="lastName"
                                            size="lg"
                                            placeholder="Last Name"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.lastName && !errors.lastName}
                                            isInvalid={touched.lastName && !!errors.lastName}
                                            aria-required="true"
                                        />
                                        <Form.Control.Feedback
                                            type="invalid">{errors.lastName}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group controlId="email">
                                    <FloatingLabel
                                        label="Email address"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            size="lg"
                                            placeholder="Enter email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.email && !errors.email}
                                            isInvalid={touched.email && !!errors.email}
                                            aria-required="true"
                                        />
                                        {touched.email && !errors.email && (
                                            <div className="text-center">
                                                <small className="text-center text-muted">
                                                    We&apos;ll never share your email with anyone else.
                                                </small>
                                            </div>
                                        )}
                                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group controlId="password">
                                    <FloatingLabel
                                        label="Password"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            size="lg"
                                            placeholder="Enter new password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.password && !errors.password}
                                            isInvalid={touched.password && !!errors.password}
                                            aria-required="true"
                                        />
                                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                                    </FloatingLabel>
                                    {values.password && (
                                        <div className="password-strength-indicator mb-3">
                                            <small>Password strength requirements:</small>
                                            <ul className="ps-3 mb-0">
                                                <li className={values.password.length >= 8 ? "text-success" : "text-muted"}>
                                                    At least 8 characters
                                                </li>
                                                <li className={/[0-9]/.test(values.password) ? "text-success" : "text-muted"}>
                                                    At least one number
                                                </li>
                                                <li className={/[a-z]/.test(values.password) ? "text-success" : "text-muted"}>
                                                    At least one lowercase letter
                                                </li>
                                                <li className={/[A-Z]/.test(values.password) ? "text-success" : "text-muted"}>
                                                    At least one uppercase letter
                                                </li>
                                                <li className={/[^\w]/.test(values.password) ? "text-success" : "text-muted"}>
                                                    At least one symbol
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </Form.Group>

                                <Form.Group controlId="passwordConfirmation">
                                    <FloatingLabel
                                        label="Password confirmation"
                                        className="mb-3"
                                    >
                                        <Form.Control
                                            type="password"
                                            name="passwordConfirmation"
                                            placeholder="Confirm password"
                                            size="lg"
                                            value={values.passwordConfirmation}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isValid={touched.passwordConfirmation && !errors.passwordConfirmation}
                                            isInvalid={touched.passwordConfirmation && !!errors.passwordConfirmation}
                                            aria-required="true"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.passwordConfirmation}
                                        </Form.Control.Feedback>
                                    </FloatingLabel>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="termsAccepted">
                                    <Form.Check
                                        type="checkbox"
                                        name="termsAccepted"
                                        label="I agree to the terms of use"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.termsAccepted && !!errors.termsAccepted}
                                        feedback={errors.termsAccepted}
                                        feedbackType="invalid"
                                    />
                                    <div className="mt-1">
                                        <a href="/terms" target="_blank" rel="noopener noreferrer">
                                            Read terms of use
                                        </a>
                                    </div>
                                </Form.Group>

                                <div className="d-grid mt-4">
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        type="submit"
                                        disabled={isSubmitting || (!isValid && Object.keys(touched).length > 0)}
                                        aria-label="Sign up"
                                    >
                                        {isSubmitting ? (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    className="me-2"
                                                />
                                                Please wait...
                                            </>
                                        ) : "Sign Up"}
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </Card>
    );
}

export default SignUpComponent;