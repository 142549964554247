"use client"; // Ensures compatibility with React Server Components (RSC) in Next.js

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Form, Button, Container, Row, Col } from "react-bootstrap";

const UniswapSearchForm: React.FC = () => {
    const [searchType, setSearchType] = useState<string>("position"); // Default search type
    const [network, setNetwork] = useState<string>("arbitrum");
    const [poolFee, setPoolFee] = useState<string>("");
    const [poolVersion, setPoolVersion] = useState<string>("v3");
    const [inputValue, setInputValue] = useState<string>("");

    const navigate = useNavigate(); // Hook for navigation

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (!inputValue) {
            alert("Please enter a valid search value.");
            return;
        }

        let url = "/crypto/uniswap";

        if (searchType === "position") {
            url += `/positions/${poolVersion}/${network}/${inputValue}`;
        } else if (searchType === "pool") {
            url += `/pools/${poolVersion}/${network}/${inputValue}`;
        } else if (searchType === "token") {
            url += `/tokens/${network}/${inputValue}`;
        } else if (searchType === "wallet") {
            url += `/wallets/${network}/${inputValue}`;
        }

        console.log("Redirecting to:", url);
        navigate(url);
    };


    return (
        <Container className="px-0 py-3 d-flex justify-content-center align-items-center">
            <Row className="w-100 justify-content-center">
                <Col xs={12} lg={6}>
                    <Card className="shadow-sm p-4 mt-4">
                        <Card.Title className="text-center mb-3">🔍 Uniswap Search</Card.Title>
                        <Form onSubmit={handleSubmit}>
                            <Row className="mb-4">
                                {/* Search Type Dropdown */}
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Search Type</Form.Label>
                                        <Form.Select
                                            value={searchType}
                                            onChange={(e) => setSearchType(e.target.value)}
                                            required
                                        >
                                            <option value="position">Position</option>
                                            <option value="pool">Pool</option>
                                            <option value="token">Token</option>
                                            <option value="wallet">Wallet</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>

                                {/* Network Selection */}
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Network</Form.Label>
                                        <Form.Select
                                            value={network}
                                            onChange={(e) => setNetwork(e.target.value)}
                                            required
                                        >
                                            <option value="arbitrum">Arbitrum</option>
                                            <option value="ethereum">Ethereum</option>
                                            <option value="base">Base</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Pool Fee & Pool Version (Only if Position or Pool is selected) */}
                            {(searchType === "position" || searchType === "pool") && (
                                <Row className="mb-3">
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Pool Fee</Form.Label>
                                            <Form.Select
                                                value={poolFee}
                                                onChange={(e) => setPoolFee(e.target.value)}
                                                required
                                            >
                                                <option value="">Select Pool Fee</option>
                                                <option value="0.05">0.05%</option>
                                                <option value="0.3">0.3%</option>
                                                <option value="1">1%</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>

                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Pool Version</Form.Label>
                                            <Form.Select
                                                value={poolVersion}
                                                onChange={(e) => setPoolVersion(e.target.value)}
                                                required
                                            >
                                                <option value="v2">V2</option>
                                                <option value="v3">V3</option>
                                                <option value="v4">V4</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}

                            {/* Input Field */}
                            <Row className="mb-3">
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Enter Value</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter position ID, pool ID, token address, or wallet address"
                                            value={inputValue}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            {/* Submit Button */}
                            <div className="text-center">
                                <Button type="submit" variant="primary" className="mt-3">
                                    🔍 Search
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default UniswapSearchForm;
