

class TokenUtilsService {

    normalizeSymbol(symbol: string): string {
        if (!symbol) return "";
        return symbol && symbol.replace('USD₮0', 'USDT');
    }

}


export default new TokenUtilsService();