import React, {useContext, useEffect, useState} from "react";
import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    Form,
    Dropdown,
    Modal,
    Button,
    Table,
    Spinner,
    Alert,
    Badge,
    Container
} from "react-bootstrap";
import {MetaMaskInpageProvider} from "@metamask/providers";
import {useWallet} from "../../components/context/WalletContext";
import ConnectWalletComponent from "../../components/crypto/wallet/ConnectWalletComponent";
import CryptoService from "../../service/crypto/CryptoService";
import {ThemeContext} from "../../App";
import {ICryptoWallet} from "../../model/crypto/CryptoWallet";
import Layout from "../../components/layout/Layout";

declare global {
    interface Window {
        ethereum?: MetaMaskInpageProvider & { providers?: MetaMaskInpageProvider[] };
    }
}

export const CryptoWalletsPage: React.FC = () => {
    const context = useContext(ThemeContext);
    const {wallet, setWallet, disconnectWallet} = useWallet();
    const [metaMaskProvider, setMetaMaskProvider] = useState<MetaMaskInpageProvider | null>(null);
    const [uniswapProvider, setUniswapProvider] = useState<MetaMaskInpageProvider | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [monitoredWallets, setMonitoredWallets] = useState<ICryptoWallet[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [copySuccess, setCopySuccess] = useState<string | null>(null);

    // New wallet form state
    const [showAddWalletModal, setShowAddWalletModal] = useState(false);
    const [newWalletAddress, setNewWalletAddress] = useState("");
    const [newWalletError, setNewWalletError] = useState<string | null>(null);
    const [isAddingWallet, setIsAddingWallet] = useState(false);

    // Confirmation modal for wallet removal
    const [showConfirmRemoveModal, setShowConfirmRemoveModal] = useState(false);
    const [walletToRemove, setWalletToRemove] = useState<string | null>(null);

    // Update label modal state
    const [showUpdateLabelModal, setShowUpdateLabelModal] = useState(false);
    const [walletToUpdateLabel, setWalletToUpdateLabel] = useState<ICryptoWallet | null>(null);
    const [newLabel, setNewLabel] = useState<string>("");

    useEffect(() => {
        if (window.ethereum?.providers) {
            window.ethereum.providers.forEach((provider) => {
                if (provider.isMetaMask) {
                    setMetaMaskProvider(provider);
                } else {
                    setUniswapProvider(provider);
                }
            });
        } else if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                setMetaMaskProvider(window.ethereum);
            } else {
                setUniswapProvider(window.ethereum);
            }
        }

        // Fetch monitored wallets from backend
        fetchMonitoredWallets();
    }, []);

    const fetchMonitoredWallets = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await CryptoService.getAllWalletsCurrentUser();
            const wallets = response.map(wallet => ({
                address: wallet.address,
                isActive: wallet.isActive ?? true,  // Defaults to true if undefined
                label: wallet.label || ""  // Default to empty string if label is undefined
            }));

            // Update local state with fetched wallets
            setMonitoredWallets(wallets);
        } catch (err) {
            console.error("Error fetching wallets:", err);
            setError("Failed to load monitored wallets. Please try again later.");
        } finally {
            setLoading(false);
        }
    };

    const addWallet = async () => {
        // Validate wallet address
        if (!newWalletAddress || !newWalletAddress.match(/^0x[a-fA-F0-9]{40}$/)) {
            setNewWalletError("Please enter a valid Ethereum wallet address (0x...)");
            return;
        }

        // Check for duplicates
        if (monitoredWallets.some(wallet => wallet.address.toLowerCase() === newWalletAddress.toLowerCase())) {
            setNewWalletError("This wallet is already being monitored");
            return;
        }

        setIsAddingWallet(true);
        setNewWalletError(null);

        try {
            // Call the API to add the wallet
            await CryptoService.addWallet(newWalletAddress);

            // Update the local state
            setMonitoredWallets([
                ...monitoredWallets,
                {address: newWalletAddress, isActive: true, label: ""} // Set default label to empty
            ]);

            // Reset form and close modal
            setNewWalletAddress("");
            setShowAddWalletModal(false);
        } catch (err) {
            console.error("Error adding wallet:", err);
            setNewWalletError("Failed to add wallet. Please try again.");
        } finally {
            setIsAddingWallet(false);
        }
    };

    const removeWallet = async (address: string) => {
        if (address === walletToRemove) {
            try {
                // Call the API to remove the wallet
                await CryptoService.removeWallet(address);

                // Update the local state
                setMonitoredWallets(monitoredWallets.filter(wallet => wallet.address !== address));

                // Clear any previous errors
                setError(null);
                setShowConfirmRemoveModal(false); // Close confirmation modal
            } catch (err) {
                console.error("Error removing wallet:", err);
                setError("Failed to remove wallet. Please try again.");

                // Auto-clear error after 5 seconds
                setTimeout(() => setError(null), 5000);
            }
        }
    };

    const toggleWalletStatus = async (address: string, currentStatus: boolean) => {
        try {
            // Call the API to update the wallet status
            await CryptoService.updateWalletStatus(address, !currentStatus);

            // Update the local state
            setMonitoredWallets(monitoredWallets.map(wallet =>
                wallet.address === address
                    ? {...wallet, isActive: !currentStatus}
                    : wallet
            ));

            // Clear any previous errors
            setError(null);
        } catch (err) {
            console.error("Error updating wallet status:", err);
            setError("Failed to update wallet status. Please try again.");

            // Auto-clear error after 5 seconds
            setTimeout(() => setError(null), 5000);
        }
    };

    const updateWalletLabel = async () => {
        if (walletToUpdateLabel) {
            try {
                await CryptoService.updateWalletLabel(walletToUpdateLabel.address, newLabel);

                // Update the local state with the new label
                setMonitoredWallets(monitoredWallets.map(wallet =>
                    wallet.address === walletToUpdateLabel.address
                        ? {...wallet, label: newLabel}
                        : wallet
                ));

                // Close modal and reset state
                setShowUpdateLabelModal(false);
                setNewLabel("");
                setWalletToUpdateLabel(null);
            } catch (err) {
                console.error("Error updating wallet label:", err);
                setError("Failed to update wallet label. Please try again.");
                setTimeout(() => setError(null), 5000);
            }
        }
    };

    const copyToClipboard = async (address: string) => {
        try {
            await navigator.clipboard.writeText(address);
            setCopySuccess(address);
            setTimeout(() => setCopySuccess(null), 2000);
        } catch (err) {
            console.error("Failed to copy address:", err);
        }
    };

    return (
        <Layout>
            <Container fluid>
                <ConnectWalletComponent/>
                <Card className="mt-3 min-vh-50">
                    <CardHeader className="d-flex justify-content-between align-items-center">
                        <h4 className="mb-0">My Monitored Wallets</h4>
                        <div>
                            <Button
                                variant="success"
                                size="sm"
                                className="me-2"
                                onClick={() => setShowAddWalletModal(true)}
                            >
                                Add Wallet
                            </Button>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={fetchMonitoredWallets}
                            >
                                Refresh
                            </Button>
                        </div>
                    </CardHeader>
                    <CardBody>
                        {error && (
                            <Alert variant="danger" className="mb-3">
                                {error}
                            </Alert>
                        )}

                        {loading ? (
                            <div className="d-flex justify-content-center p-3">
                                <Spinner animation="border" variant="primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        ) : monitoredWallets.length === 0 ? (
                            <div className="text-center p-4">
                                <p>No monitored wallets found.</p>
                                <Button
                                    variant="primary"
                                    onClick={() => setShowAddWalletModal(true)}
                                >
                                    Add Your First Wallet
                                </Button>
                            </div>
                        ) : (
                            <Table hover responsive className="align-middle">
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Wallet Address</th>
                                    <th>Label</th>
                                    <th>Status</th>
                                    <th className="text-end ">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {monitoredWallets.map((wallet, index) => (
                                    <tr key={index} className={!wallet.isActive ? "table-active" : ""}>
                                        <td>{index + 1}</td>
                                        <td>
                                            <code className="text-break">
                                                {wallet.address}
                                            </code>
                                        </td>
                                        <td>
                                            <div
                                                className="d-flex align-items-center"
                                                onClick={() => {
                                                    setWalletToUpdateLabel(wallet);
                                                    setNewLabel(wallet.label || "");
                                                    setShowUpdateLabelModal(true);
                                                }}
                                                style={{cursor: 'pointer'}}
                                                title="Click to edit"
                                            >
                                                <span>{wallet.label || "Unnamed Wallet"}</span>
                                                <i className="bi bi-pencil ms-2 edit-icon d-none"
                                                   style={{fontSize: '0.8rem'}}></i>
                                            </div>
                                        </td>
                                        <td>
                                            <Badge bg={wallet.isActive ? "success" : "secondary"}>
                                                {wallet.isActive ? "Active" : "Inactive"}
                                            </Badge>
                                        </td>
                                        <td className="text-end">
                                            <div className="d-none d-md-flex justify-content-end">
                                                <Button
                                                    variant={copySuccess === wallet.address ? "success" : "outline-secondary"}
                                                    size="sm"
                                                    className="me-1"
                                                    onClick={() => copyToClipboard(wallet.address)}
                                                >
                                                    {copySuccess === wallet.address ? "Copied!" : "Copy"}
                                                </Button>
                                                <Button
                                                    variant="outline-primary"
                                                    size="sm"
                                                    className="me-1"
                                                    onClick={() => window.open(`https://etherscan.io/address/${wallet.address}`, '_blank')}
                                                >
                                                    View
                                                </Button>
                                                <Button
                                                    variant={wallet.isActive ? "outline-warning" : "outline-success"}
                                                    size="sm"
                                                    className="me-2"
                                                    onClick={() => toggleWalletStatus(wallet.address, wallet.isActive ?? true)}
                                                >
                                                    {wallet.isActive ? "Deactivate" : "Activate"}
                                                </Button>
                                                <Button
                                                    variant="outline-info"
                                                    size="sm"
                                                    className="me-2"
                                                    onClick={() => {
                                                        setWalletToUpdateLabel(wallet);
                                                        setNewLabel(wallet.label || "");
                                                        setShowUpdateLabelModal(true);
                                                    }}
                                                >
                                                    Edit Label
                                                </Button>
                                                <Button
                                                    variant="outline-danger"
                                                    size="sm"
                                                    onClick={() => {
                                                        setWalletToRemove(wallet.address);
                                                        setShowConfirmRemoveModal(true);
                                                    }}
                                                >
                                                    Remove
                                                </Button>
                                            </div>

                                            {/* Mobile view - dropdown */}
                                            <div className="d-md-none">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="primary" size="sm"
                                                                     id={`dropdown-${index}`}>
                                                        Actions
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={() => copyToClipboard(wallet.address)}>
                                                            {copySuccess === wallet.address ? "✓ Copied!" : "Copy Address"}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => window.open(`https://etherscan.io/address/${wallet.address}`, '_blank')}
                                                        >
                                                            View on Etherscan
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            onClick={() => toggleWalletStatus(wallet.address, wallet.isActive ?? true)}
                                                        >
                                                            {wallet.isActive ? "Deactivate" : "Activate"}
                                                        </Dropdown.Item>
                                                        <Dropdown.Divider/>
                                                        <Dropdown.Item
                                                            className="text-danger"
                                                            onClick={() => removeWallet(wallet.address)}
                                                        >
                                                            Remove
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>

                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        )}
                    </CardBody>
                </Card>
            </Container>

            {/* Add Wallet Modal */}
            <Modal
                centered
                show={showAddWalletModal}
                onHide={() => setShowAddWalletModal(false)}
                data-bs-theme={context?.theme}
                className="text-body"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Wallet Address</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Ethereum wallet address (0x...)"
                                value={newWalletAddress}
                                onChange={(e) => setNewWalletAddress(e.target.value)}
                                isInvalid={!!newWalletError}
                            />
                            {newWalletError && (
                                <Form.Control.Feedback type="invalid">
                                    {newWalletError}
                                </Form.Control.Feedback>
                            )}
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddWalletModal(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={addWallet}
                        disabled={isAddingWallet}
                    >
                        {isAddingWallet ? (
                            <>
                                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"
                                         className="me-1"/>
                                Adding...
                            </>
                        ) : (
                            'Add Wallet'
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Confirmation Modal */}
            <Modal
                centered
                show={showConfirmRemoveModal}
                onHide={() => setShowConfirmRemoveModal(false)}
                data-bs-theme={context?.theme}
                className="text-body"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this wallet?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmRemoveModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => walletToRemove && removeWallet(walletToRemove)}
                    >
                        Remove
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Update Label Modal */}
            <Modal
                centered
                show={showUpdateLabelModal}
                onHide={() => setShowUpdateLabelModal(false)}
                data-bs-theme={context?.theme}
                className="text-body"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Update Wallet Label</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Label</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter new label"
                                value={newLabel}
                                onChange={(e) => setNewLabel(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowUpdateLabelModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={updateWalletLabel}>
                        Update Label
                    </Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    );
};