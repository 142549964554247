import React from 'react';
import { Pagination } from 'react-bootstrap';

interface PoolsPaginationProps {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    totalPools: number;
    poolsPerPage: number;
}

const PoolsPagination: React.FC<PoolsPaginationProps> = ({
                                                             currentPage,
                                                             setCurrentPage,
                                                             totalPools,
                                                             poolsPerPage
                                                         }) => {
    // Calculate the total number of pages
    const totalPages = Math.ceil(totalPools / poolsPerPage);

    // Don't show pagination if there's only one page
    if (totalPages <= 1) return null;

    // Create an array of page numbers we want to show
    const getPageNumbers = (): number[] => {
        const maxPagesToShow = 5;
        const pages: number[] = [];

        // Always show the first page
        pages.push(1);

        // Calculate the range of pages to show around the current page
        let start = Math.max(2, currentPage - 1);
        let end = Math.min(totalPages - 1, currentPage + 1);

        // Adjust if we're too close to the beginning
        if (currentPage <= 3) {
            end = Math.min(totalPages - 1, maxPagesToShow - 1);
        }

        // Adjust if we're too close to the end
        if (currentPage >= totalPages - 2) {
            start = Math.max(2, totalPages - maxPagesToShow + 2);
        }

        // Add ellipsis after first page if necessary
        if (start > 2) {
            pages.push(-1); // -1 represents an ellipsis
        }

        // Add the middle pages
        for (let i = start; i <= end; i++) {
            pages.push(i);
        }

        // Add ellipsis before last page if necessary
        if (end < totalPages - 1) {
            pages.push(-2); // -2 represents another ellipsis (to maintain key uniqueness)
        }

        // Always show the last page if there are multiple pages
        if (totalPages > 1) {
            pages.push(totalPages);
        }

        return pages;
    };

    const pageNumbers = getPageNumbers();

    return (
        <div className="d-flex justify-content-center mt-4">
            <Pagination>
                {/* Previous button */}
                <Pagination.Prev
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                />

                {/* Page numbers */}
                {pageNumbers.map((pageNumber, index) => {
                    if (pageNumber < 0) {
                        // This is an ellipsis
                        return <Pagination.Ellipsis key={pageNumber} disabled />;
                    }
                    return (
                        <Pagination.Item
                            key={pageNumber}
                            active={pageNumber === currentPage}
                            onClick={() => setCurrentPage(pageNumber)}
                        >
                            {pageNumber}
                        </Pagination.Item>
                    );
                })}

                {/* Next button */}
                <Pagination.Next
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                />
            </Pagination>
        </div>
    );
};

export default PoolsPagination;