import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {AreaSeries, ColorType, createChart} from 'lightweight-charts';
import FormatUtils from "../../../../service/utils/FormatUtils";
import {UniswapPosition} from "../../../../model/crypto/CryptoModels";
import SubgraphUniswapService from "../../../../service/crypto/SubgraphUniswapService";
import {ThemeContext} from "../../../../App";
import {Form} from 'react-bootstrap';
import UniswapMath from "../../../../service/crypto/uniswap/UniswapMath";

interface PositionSnapshot {
    liquidity: string;
    timestamp: string;
    derivedETHToken0: string;
    derivedETHToken1: string;
    ethPriceUSD: string;
    pool: {
        sqrtPrice: string;
    };
}

interface ChartData {
    time: number; // Format: "YYYY-MM-DD"
    value: number; // Liquidity in USD
}

const apiKey = 'd59545755cf1974d72a4b387c64e85e9'

const SUBGRAPH_URL =
    "https://gateway.thegraph.com/api/" + apiKey + "/subgraphs/id/3V7ZY6muhxaQL5qvntX1CFXJ32W7BxXZTGTwmpH5J4t3";

interface Position {
    liquidity: bigint; // Liquidity provided by the position
    tickLower: number; // Lower tick of the position
    tickUpper: number; // Upper tick of the position
    currentPrice: number; // Current price of the pool (token1/token0)
}

interface TokenQuantities {
    amount0: number; // Quantity of token0
    amount1: number; // Quantity of token1
}

function tickToPrice(tick: number): number {
    return Math.pow(1.0001, tick);
}

function sqrtPrice(price: number): number {
    return Math.sqrt(price);
}


const PositionChart: React.FC<{ position: UniswapPosition }> = ({position}) => {

    const context = useContext(ThemeContext);

    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [chartData, setChartData] = useState<ChartData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const [chart, setChart] = useState("liquidity"); // Default chart type

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setChart(event.target.value);
    };


    const GET_POSITION_SNAPSHOTS = `{
        positionSnapshots(where: { position: "${position?.id}" }, orderBy: timestamp, orderDirection: asc) {
            liquidity
            timestamp
            derivedETHToken0
            derivedETHToken1
            ethPriceUSD
                pool {
      sqrtPrice
    }
        }
    }
`;


    // Fetch data using Axios
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.post(
                    SUBGRAPH_URL,
                    {
                        query: GET_POSITION_SNAPSHOTS
                    }
                );

                const snapshots: PositionSnapshot[] = response.data.data.positionSnapshots;

                // Remove duplicates and sort by timestamp
                const uniqueSnapshots = FormatUtils.removeDuplicatesByAttribute(snapshots, "timestamp");
                /*const sortedSnapshots = sortByTimestamp(uniqueSnapshots);*/

                console.log("uniqueSnapshots = ", JSON.stringify(uniqueSnapshots))
                // Transform data for the chart
                const transformedData = uniqueSnapshots.map((snapshot) => ({
                    time: parseInt(snapshot.timestamp),
                    value: UniswapMath.getTokenAmountsFromLiquidity(BigInt(snapshot.liquidity),
                            BigInt(snapshot.pool.sqrtPrice),
                            position.tickLower,
                            position.tickUpper,
                            position.token0?.decimals ?? 18,
                            position.token1?.decimals ?? 18).token0Amount * Number(snapshot.derivedETHToken0) * Number(snapshot.ethPriceUSD) +
                        UniswapMath.getTokenAmountsFromLiquidity(BigInt(snapshot.liquidity),
                            BigInt(snapshot.pool.sqrtPrice),
                            position.tickLower,
                            position.tickUpper,
                            position.token0?.decimals ?? 18,
                            position.token1?.decimals ?? 18).token1Amount * Number(snapshot.derivedETHToken1) * Number(snapshot.ethPriceUSD)
                }));

                // **Append current liquidity value as the last point**
                const lastDataPoint: ChartData = {
                    time: Math.floor(Date.now() / 1000), // Current timestamp in seconds
                    value: position.liquidityUSD, // Latest liquidity in USD
                };

                transformedData.push(lastDataPoint);

                setChartData(transformedData);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch data');
                setLoading(false);
            }
        };

        fetchData();

    }, [position]);


    function sortByTimestamp(snapshots: PositionSnapshot[]): PositionSnapshot[] {
        return snapshots.sort((a, b) => parseInt(a.timestamp) - parseInt(b.timestamp));
    }

    const areaSeriesRef = useRef<any>(null);

    // Reapply bottomColor when theme changes
    useEffect(() => {
        if (areaSeriesRef.current) {
            areaSeriesRef.current.applyOptions({
                bottomColor: context?.theme === 'light' ? 'white' : '#212529',
            });
        }
    }, [context?.theme]); // Reapply on theme change


    // Render chart
    useEffect(() => {
        if (chartContainerRef.current && chartData.length > 0) {

            const chart = createChart(chartContainerRef.current, {
                width: chartContainerRef.current?.clientWidth,
                height: window.innerHeight * 0.3,
                layout: {
                    background: {color: 'transparent'},
                    textColor: context?.theme === 'light' ? 'black' : 'white',
                    fontFamily: "'Roboto', sans-serif",
                    attributionLogo: false,
                },
                grid: {
                    horzLines: {
                        visible: false
                    },
                    vertLines: {
                        visible: false
                    }
                },
                timeScale: {
                    fixRightEdge: true,
                    rightBarStaysOnScroll: true,
                    lockVisibleTimeRangeOnResize: true
                }
            });

            const areaSeries = chart.addSeries(AreaSeries, {
                lineColor: '#2962FF', // Customize line color
                lineWidth: 2,
                topColor: '#2962FF',
                relativeGradient: true,
                bottomColor: context?.theme === 'light' ? 'white' : '#212529',
                pointMarkersVisible: false,
            });

            // Store reference to areaSeries for theme changes
            areaSeriesRef.current = areaSeries;

            const handleResize = () => {
                if (chartContainerRef.current) {
                    chart.applyOptions({
                        width: chartContainerRef.current.clientWidth,
                        height: chartContainerRef.current.clientHeight
                    });
                    chart.timeScale().fitContent();
                }
            };

            // Fix the TypeScript error by properly typing the areaSeries methods
            // This uses type assertion to tell TypeScript that setData exists
            (areaSeries as any).setData(chartData);

            // Optional: Fit content
            chart.timeScale().fitContent();

            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
                chart.remove();
            };
        }
    }, [chartData, context?.theme]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (<>
        <div className="d-flex justify-content-start">
            <Form.Group controlId="chartSelect" className="d-flex align-items-center">
                <Form.Select value={chart} onChange={handleChange} className="w-auto">
                    <option value="price0">Price: {position.token0?.symbol}/{position.token1?.symbol}</option>
                    <option value="price1">Price: {position.token1?.symbol}/{position.token0?.symbol}</option>
                    <option value="liquidity">Liquidity USD</option>
                    <option value="fees">Position Fees Gained</option>
                </Form.Select>
            </Form.Group>
        </div>
        <div ref={chartContainerRef}/>
    </>)
};

export default PositionChart;