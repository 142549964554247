import React, {useState, useEffect, useCallback, useContext} from "react";
import Layout from "../../../components/layout/Layout";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import {CardBody, Button, Tab, Nav, Form, InputGroup, Offcanvas} from "react-bootstrap";
import CryptoPositionsManagementService from "../../../service/crypto/CryptoPositionsManagementService";
import PositionPreviewComponent from "../../../components/crypto/uniswap/position/PositionPreviewComponent";
import {UniswapPosition} from "../../../model/crypto/CryptoModels";
import Spinner from "react-bootstrap/Spinner";
import "../../../components/crypto/uniswap/position/css/PositionPreviewComponent.css";
import UniswapPositionsManager from "./UniswapPositionsManager";
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import {ThemeContext} from "../../../App";
import CryptoService from "../../../service/crypto/CryptoService";
import PositionSummaryComponent from "../../../components/crypto/uniswap/positions/PositionSummaryComponent";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export const CryptoPositionsPage = () => {
    const context = useContext(ThemeContext);
    const [positionsIds, setPositionIds] = useState<number[]>([]);
    const [positions, setPositions] = useState<UniswapPosition[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [totalLiquidityUSD, setTotalLiquidityUSD] = useState<number>(0);
    const [viewMode, setViewMode] = useState<'cards' | 'table'>('table');
    // Track which position IDs have been processed to avoid duplicates
    const [processedIds, setProcessedIds] = useState<Set<number>>(new Set());
    // Filter visibility state (set to true by default)
    const [showFilters, setShowFilters] = useState<boolean>(true);

    // Current timestamp in seconds (for age calculations)
    const currentTimestamp = Math.floor(Date.now() / 1000);

    // Max age in days for any position in the data
    const [maxPositionAge, setMaxPositionAge] = useState<number>(365); // Default to 1 year

    // Filter states
    const [filters, setFilters] = useState({
        token: '',
        network: '',
        label: '',
        minimumApr: 0,
        liquidityRange: [0, 100000], // Default range in USD
        positionAgeRange: [0, 365],   // Default range in days (0 to 1 year)
        nonZeroLiquidity: true        // Default to showing only non-zero liquidity positions
    });

    // Networks for dropdown
    const networks = ['', 'Ethereum', 'Arbitrum', 'Base'];

    // Max liquidity for slider range
    const [maxLiquidity, setMaxLiquidity] = useState<number>(1000000);

    // Check if we're on mobile view
    const isMobile = useMediaQuery('(max-width:992px)');

    // Handle offcanvas visibility for mobile
    const [showMobileFilters, setShowMobileFilters] = useState(false);

    // Helper function to check if any filters are active
    const hasActiveFilters = () => {
        return Object.entries(filters).some(([key, value]) => {
            if (Array.isArray(value)) {
                if (key === 'liquidityRange') {
                    return value[0] > 0 || value[1] < maxLiquidity;
                } else if (key === 'positionAgeRange') {
                    return value[0] > 0 || value[1] < maxPositionAge;
                }
                return false;
            } else if (typeof value === 'string') {
                return value !== '';
            } else if (typeof value === 'boolean') {
                return value === true; // For boolean filters like nonZeroLiquidity
            } else {
                return value > 0;
            }
        });
    };

    const fetchPositions = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            // First fetch the user's wallets
            const wallets = await CryptoService.getAllWalletsCurrentUser();

            if (!wallets || wallets.length === 0) {
                console.warn("No wallets found for current user.");
                setPositionIds([]);
                setPositions([]);
                setLoading(false);
                return;
            }

            console.log(`Found ${wallets.length} wallets for current user`);

            // Use CryptoService to fetch all position IDs for the current user
            const positionIds = await CryptoService.getAllPositionsForCurrentUser();

            if (!positionIds || positionIds.length === 0) {
                console.warn("No active positions found.");
                setPositionIds([]);
                setPositions([]);
            } else {
                console.log(`Found ${positionIds.length} positions`);
                setPositionIds(positionIds);
            }
        } catch (err) {
            console.error("Error fetching position IDs:", err);
            setError("Failed to load positions. Please try again later.");
            setPositionIds([]);
        } finally {
            setLoading(false);
        }
    }, []);

    // Note: We need the two-step process:
    // 1. First fetch position IDs from the service
    // 2. Then fetch details for those IDs using CryptoPositionsManagementService

    useEffect(() => {
        fetchPositions();
    }, [fetchPositions]);

    // We need to fetch position details once we have the IDs - Modified to show positions incrementally
    const fetchPositionDetails = useCallback(async () => {
        if (!positionsIds || positionsIds.length === 0) {
            console.warn("Skipping position details fetch due to empty positionsIds.");
            setLoading(false);
            return;
        }

        // Set loading to true for initial fetch or for continuing loads
        setLoading(true);

        try {
            console.log("Fetching details for position IDs:", positionsIds);

            // Fetch details in batches to avoid overwhelming the system
            const batchSize = 20;
            let fetchedAnyPositions = false;

            for (let i = 0; i < positionsIds.length; i += batchSize) {
                const batch = positionsIds.slice(i, i + batchSize);
                // Filter out already processed IDs
                const unprocessedBatch = batch.filter(id => !processedIds.has(id));

                if (unprocessedBatch.length === 0) {
                    continue; // Skip this batch if all IDs have been processed
                }

                try {
                    const batchPositions = await CryptoPositionsManagementService.fetchUniswapPositions(unprocessedBatch);
                    if (batchPositions && batchPositions.length > 0) {
                        // Get new IDs to mark as processed
                        const newProcessedIds = batchPositions.map(pos => pos.id);

                        // Update positions incrementally as each batch is retrieved
                        setPositions(prevPositions => {
                            // Create a map of existing positions by ID for quick lookup
                            const existingPositionsMap = new Map(
                                prevPositions.map(pos => [pos.id, pos])
                            );

                            // Add new positions, replace if ID already exists
                            batchPositions.forEach(pos => {
                                existingPositionsMap.set(pos.id, pos);
                            });

                            // Convert map back to array
                            return Array.from(existingPositionsMap.values());
                        });

                        // Update processed IDs
                        setProcessedIds(prev => {
                            const newSet = new Set(prev);
                            newProcessedIds.forEach(id => newSet.add(id));
                            return newSet;
                        });

                        fetchedAnyPositions = true;
                    }
                } catch (batchErr) {
                    console.error(`Error fetching details for batch ${i}-${i + batchSize}:`, batchErr);
                    // Continue with next batch instead of failing completely
                }
            }

            if (!fetchedAnyPositions && positions.length === 0) {
                console.warn("No valid positions returned.");
                setError("No position details could be retrieved. The service may be temporarily unavailable.");
            } else {
                console.log(`Successfully retrieved details for positions`);
                setError(null);
            }
        } catch (err) {
            console.error("Error fetching position details:", err);
            setError("Failed to load position details. Please try again later.");
        } finally {
            setLoading(false);
        }
    }, [positionsIds, processedIds, positions.length]); // Include positions.length in dependency array

    useEffect(() => {
        fetchPositionDetails();
    }, [fetchPositionDetails]);

    useEffect(() => {
        if (positions.length > 0) {
            // Calculate total liquidity
            const totalLiquidityUSD = positions
                .map(position => position.liquidityUSD || 0)
                .reduce((acc: number, curr: number) => acc + curr, 0);
            setTotalLiquidityUSD(totalLiquidityUSD);

            // Find maximum liquidity value for the slider
            const maxPositionLiquidity = Math.max(
                ...positions.map(position => position.liquidityUSD || 0)
            );

            // Round up to a nice number for the slider max
            const roundedMaxLiquidity = Math.max(
                Math.ceil(maxPositionLiquidity / 1000) * 1000,
                20000  // Set minimum max to $20,000 for usability
            );

            // Find the oldest position to set max age range
            let oldestPositionTimestamp = currentTimestamp;
            positions.forEach((position) => {
                if (position.mintTimestamp && position.mintTimestamp < oldestPositionTimestamp) {
                    oldestPositionTimestamp = position.mintTimestamp;
                }
            });

            // Calculate the max age in days and round up to the nearest week
            const oldestPositionAgeDays = Math.ceil((currentTimestamp - oldestPositionTimestamp / 1000) / (60 * 60 * 24));
            const maxAgeDays = Math.max(
                Math.ceil(oldestPositionAgeDays / 7) * 7, // Round up to nearest week
                365 // Minimum of 1 year for usability
            );

            // Update both state values and filter ranges at once to prevent timing issues
            setMaxLiquidity(roundedMaxLiquidity);
            setMaxPositionAge(maxAgeDays);

            // Important: Use the calculated values directly, not the state values which might not be updated yet
            setFilters(prev => ({
                ...prev,
                liquidityRange: [prev.liquidityRange[0], roundedMaxLiquidity],
                positionAgeRange: [prev.positionAgeRange[0], maxAgeDays]
            }));
        }
    }, [positions, currentTimestamp]);

    const refreshPositions = () => {
        setPositions([]); // Clear existing positions before refreshing
        setProcessedIds(new Set()); // Reset processed IDs
        fetchPositions();
    };

    const handleFilterChange = (e: React.ChangeEvent<HTMLElement>) => {
        const target = e.target as HTMLInputElement;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setFilters(prev => ({...prev, [target.name]: value}));
    };

    const handleLiquidityRangeChange = (event: Event, newValue: number | number[]) => {
        setFilters(prev => ({...prev, liquidityRange: newValue as number[]}));
    };

    const handlePositionAgeChange = (event: Event, newValue: number | number[]) => {
        setFilters(prev => ({...prev, positionAgeRange: newValue as number[]}));
    };

    const clearFilters = () => {
        setFilters({
            token: '',
            network: '',
            label: '',
            minimumApr: 0,
            liquidityRange: [0, maxLiquidity],
            positionAgeRange: [0, maxPositionAge],
            nonZeroLiquidity: false
        });
    };

    // Toggle filter panel visibility only - never resets filter values
    const toggleFilters = () => {
        if (isMobile) {
            setShowMobileFilters(!showMobileFilters);
        } else {
            // Only toggle visibility, never reset filter values
            setShowFilters(!showFilters);
        }
    };

    // Close mobile filters
    const handleCloseFilters = () => {
        setShowMobileFilters(false);
    };

    // Format age in days to a readable string
    const formatAge = (ageInDays: number) => {
        if (ageInDays < 1) {
            return "< 1 day";
        } else if (ageInDays === 1) {
            return "1 day";
        } else if (ageInDays < 30) {
            return `${Math.floor(ageInDays)} days`;
        } else if (ageInDays < 365) {
            const months = Math.floor(ageInDays / 30);
            return `${months} ${months === 1 ? 'month' : 'months'}`;
        } else {
            const years = Math.floor(ageInDays / 365);
            const remainingMonths = Math.floor((ageInDays % 365) / 30);
            return remainingMonths > 0
                ? `${years} ${years === 1 ? 'year' : 'years'}, ${remainingMonths} ${remainingMonths === 1 ? 'month' : 'months'}`
                : `${years} ${years === 1 ? 'year' : 'years'}`;
        }
    };

    // This is the fixed getFilteredPositions function that works with your existing interfaces
// Replace this function in your CryptoPositionsPage component

    const getFilteredPositions = () => {
        // Always use the current filter values, regardless of whether the filter panel is visible
        const activeFilters = filters;

        return positions.filter(position => {
            const [minLiquidity, maxLiquidity] = activeFilters.liquidityRange;
            const [minAge, maxAge] = activeFilters.positionAgeRange;

            const token0Match = position.token0.symbol.toLowerCase().includes(activeFilters.token.toLowerCase());
            const token1Match = position.token1.symbol.toLowerCase().includes(activeFilters.token.toLowerCase());
            const aprMatch = (position.apr || 0) >= activeFilters.minimumApr;
            const liquidityMatch = position.liquidityUSD >= minLiquidity && position.liquidityUSD <= maxLiquidity;
            const networkMatch = !activeFilters.network || (position.pool.network || '').toLowerCase() === activeFilters.network.toLowerCase();

            // Modified label matching logic that works with positionEvents instead of events
       /*     const labelMatch = !activeFilters.label || (
                position.positionEvents ?
                    position.positionEvents.some(event =>
                        event && event.eventType && event.eventType.toLowerCase().includes(activeFilters.label.toLowerCase())
                    ) : false
            );*/

            const labelMatch = (() => {
                // If no label filter is applied, match everything
                if (!activeFilters.label || activeFilters.label === '') {
                    return true;
                }

                // If positionEvents array is missing or empty, don't match on label
                if (!position.positionEvents || !Array.isArray(position.positionEvents) || position.positionEvents.length === 0) {
                    return false;
                }

                // Otherwise check if any event matches the label
                return position.positionEvents.some(event =>
                    event && event.eventType &&
                    event.eventType.toLowerCase().includes(activeFilters.label.toLowerCase())
                );
            })();

            // Check if position has non-zero liquidity (when filter is active)
            const hasLiquidity = (() => {
                // First check liquidityUSD which is likely a number
                if (typeof position.liquidityUSD === 'number' && position.liquidityUSD > 0) {
                    return true;
                }

                // Then check the liquidity property which might be a string or another type
                if (!position.liquidity) {
                    return false;
                }

                // Safe comparison for bigint
                if (typeof position.liquidity === 'bigint' && position.liquidity === BigInt(0)) {
                    return false;
                }

                // For bigint values
                if (typeof position.liquidity === 'bigint') {
                    return position.liquidity > BigInt(0);
                }

                // For other cases, just check if it's truthy
                return !!position.liquidity;
            })();

            const nonZeroLiquidityMatch = !activeFilters.nonZeroLiquidity || hasLiquidity;

            // Position age matching
            let ageMatch = true;
            if (position.mintTimestamp) {
                const positionAgeInSeconds = currentTimestamp - position.mintTimestamp / 1000;
                const positionAgeInDays = positionAgeInSeconds / (60 * 60 * 24);
                ageMatch = positionAgeInDays >= minAge && positionAgeInDays <= maxAge;
            }

            return (
                (activeFilters.token === '' || token0Match || token1Match) &&
                (activeFilters.minimumApr === 0 || aprMatch) &&
                liquidityMatch &&
                (activeFilters.network === '' || networkMatch) &&
                (activeFilters.label === '' || labelMatch) &&
                ageMatch &&
                nonZeroLiquidityMatch
            );
        });
    };


    // Modified renderContent to show positions incrementally
    const renderContent = () => {
        // Only show loading spinner when no positions are available yet
        if (loading && positions.length === 0) {
            return (
                <Col className="d-flex justify-content-center align-items-center w-100 text-center">
                    <Spinner className="mt-4" animation="border" variant="primary"/>
                </Col>
            );
        }

        if (error && positions.length === 0) {
            return (
                <Col sm={12}>
                    <Card className="text-center">
                        <CardBody>
                            <Card.Text className="text-danger">{error}</Card.Text>
                            <Button
                                variant="outline-primary"
                                onClick={refreshPositions}
                                className="mt-2"
                            >
                                <i className="bi bi-arrow-clockwise me-1"></i>
                                Try Again
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
            );
        }

        if (positions.length === 0) {
            return (
                <Col sm={12}>
                    <Card className="text-center">
                        <Card.Body>
                            <Card.Text>No positions found. Add a wallet or position to get started.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            );
        }

        const filteredPositions = getFilteredPositions();

        // Show position data with loading indicator when more are being fetched
        if (viewMode === 'table') {
            return (
                <>
                    {filteredPositions.length > 0 ? (
                        <UniswapPositionsManager positions={filteredPositions} pageSize={10}/>
                    ) : (
                        <Col sm={12}>
                            <Card className="text-center">
                                <Card.Body>
                                    <Card.Text>No positions match the current filters.</Card.Text>
                                    <Button
                                        variant="outline-secondary"
                                        onClick={clearFilters}
                                        className="mt-2"
                                    >
                                        Clear Filters
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    )}

                    {/* Show loading indicator if more positions are being fetched */}
                    {loading && (
                        <Col className="d-flex justify-content-center mt-3 w-100">
                            <Spinner animation="border" variant="primary" size="sm"/>
                            <span className="ms-2">Loading more positions...</span>
                        </Col>
                    )}
                </>
            );
        }

        return (
            <>
                {filteredPositions.length > 0 ? filteredPositions.map((position) => (
                    <Col sm={12} key={position.id}>
                        <Card className="position-card my-1 py-2">
                            <Card.Body>
                                {position ? (
                                    <PositionPreviewComponent position={position}/>
                                ) : (
                                    <p className="text-warning">Position data is unavailable.</p>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                )) : (
                    <Col sm={12}>
                        <Card className="text-center">
                            <Card.Body>
                                <Card.Text>No positions match the current filters.</Card.Text>
                                <Button
                                    variant="outline-secondary"
                                    onClick={clearFilters}
                                    className="mt-2"
                                >
                                    Clear Filters
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                )}

                {/* Show loading indicator if more positions are being fetched */}
                {loading && (
                    <Col className="d-flex justify-content-center mt-3 w-100">
                        <Spinner animation="border" variant="primary" size="sm"/>
                        <span className="ms-2">Loading more positions...</span>
                    </Col>
                )}
            </>
        );
    };

    // Render filter content - reused in both desktop and mobile views
    const renderFilterContent = () => (
        <Form>
            {/* Token Filter */}
            <Form.Group className="mb-3">
                <Form.Label>Token</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="ETH, USDC, etc."
                    name="token"
                    value={filters.token}
                    onChange={handleFilterChange}
                />
            </Form.Group>

            {/* Network Filter */}
            <Form.Group className="mb-3">
                <Form.Label>Network</Form.Label>
                <Form.Select
                    name="network"
                    value={filters.network}
                    onChange={handleFilterChange}
                >
                    <option value="">All Networks</option>
                    <option value="ethereum">Ethereum</option>
                    <option value="arbitrum-one">Arbitrum One</option>
                    <option value="base">Base</option>
                </Form.Select>
            </Form.Group>

            {/* Label Filter */}
            <Form.Group className="mb-3">
                <Form.Label>Label</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Label"
                    name="label"
                    value={filters.label}
                    onChange={handleFilterChange}
                />
            </Form.Group>

            {/* Min APR Filter */}
            <Form.Group className="mb-3">
                <Form.Label>Minimum APR (%)</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="number"
                        placeholder="0"
                        name="minimumApr"
                        value={filters.minimumApr}
                        onChange={handleFilterChange}
                        min="0"
                        max="1000"
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
            </Form.Group>

            {/* Liquidity Range Slider with Non-zero Checkbox */}
            <Form.Group className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                    <Form.Label>Liquidity Range (USD)</Form.Label>
                    <Form.Check
                        type="checkbox"
                        id="nonZeroLiquidity"
                        name="nonZeroLiquidity"
                        label="Non-zero"
                        checked={filters.nonZeroLiquidity}
                        onChange={handleFilterChange}
                        className="ms-2"
                    />
                </div>
                <div className="px-2">
                    <Slider
                        value={filters.liquidityRange}
                        onChange={handleLiquidityRangeChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={maxLiquidity}
                        step={100}
                        valueLabelFormat={(value) => `${value.toLocaleString()}`}
                    />
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <small className="text-muted">${filters.liquidityRange[0].toLocaleString()}</small>
                    <small className="text-muted">${filters.liquidityRange[1].toLocaleString()}</small>
                </div>
            </Form.Group>

            {/* Position Age Range Slider */}
            <Form.Group className="mb-3">
                <Form.Label>Position Age Range (days)</Form.Label>
                <div className="px-2">
                    <Slider
                        value={filters.positionAgeRange}
                        onChange={handlePositionAgeChange}
                        valueLabelDisplay="auto"
                        min={0}
                        max={maxPositionAge}
                        step={maxPositionAge > 100 ? 7 : 1}  // Use weekly steps for larger ranges
                        valueLabelFormat={(value) => formatAge(value)}
                    />
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <small className="text-muted">{formatAge(filters.positionAgeRange[0])}</small>
                    <small className="text-muted">{formatAge(filters.positionAgeRange[1])}</small>
                </div>
            </Form.Group>

            {/* Apply/Clear Filter Buttons */}
            <div className="d-grid gap-2 mt-4">
                <Button
                    variant="primary"
                    onClick={() => {
                        // Filters are already applied on change
                        if (isMobile) {
                            handleCloseFilters();
                        }
                    }}
                    disabled={loading}
                >
                    Apply Filters
                </Button>
                <Button
                    variant="outline-secondary"
                    onClick={clearFilters}
                    className="mt-2"
                >
                    Clear All Filters
                </Button>
            </div>
        </Form>
    );

    return (
        <Layout>
            {/* Mobile Filter Offcanvas */}
            {isMobile && (
                <Offcanvas show={showMobileFilters} data-bs-theme={context?.theme} onHide={handleCloseFilters}
                           placement="top" className="h-75">
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Filter Positions</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        {renderFilterContent()}
                    </Offcanvas.Body>
                </Offcanvas>
            )}

            <Row className="g-2 mb-3">
                {/* Main Content Column - Full width when filters are hidden */}
                <Col lg={showFilters ? 9 : 12}>
                    <Card>
                        <CardHeader className="d-flex align-items-center">
                            <h5 className="mb-0 flex-grow-1">My Crypto Positions</h5>
                            <Button
                                variant="outline-secondary"
                                size="sm"
                                onClick={() => {
                                    // ONLY toggle visibility, never reset filters
                                    toggleFilters();
                                }}
                                className="me-2"
                            >
                                <i className={`bi text-nowrap bi-funnel${showFilters || showMobileFilters || hasActiveFilters() ? '-fill' : ''} me-1`}></i>
                                {showFilters || showMobileFilters ? 'Hide Filters' : 'Show Filters'}
                            </Button>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                onClick={refreshPositions}
                                disabled={loading}
                                className="me-2"
                            >
                                {loading ? (
                                    <Spinner size="sm" animation="border" className="me-1"/>
                                ) : (
                                    <i className="bi bi-arrow-clockwise me-1"></i>
                                )}
                                Refresh
                            </Button>
                        </CardHeader>
                        <CardBody>
                            {/* Position Summary Component */}
                            {positions.length > 0 && getFilteredPositions().length > 0 && (
                                <PositionSummaryComponent positions={getFilteredPositions()} />
                            )}

                            <Card className="mb-3">
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <div className="d-flex align-items-center mb-2">
                                                <h6 className="mb-0 me-2">Total Positions:</h6>
                                                {loading && positions.length === 0 ? (
                                                    <Spinner size="sm" animation="border" variant="primary"/>
                                                ) : (
                                                    <span className="badge bg-primary">{positions.length}</span>
                                                )}
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <h6 className="mb-0 me-2">Total Liquidity:</h6>
                                                {loading && positions.length === 0 ? (
                                                    <Spinner size="sm" animation="border" variant="primary"/>
                                                ) : (
                                                    <span
                                                        className="badge bg-success">${totalLiquidityUSD.toFixed(2)}</span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col md={6} className="d-flex justify-content-md-end mt-3 p-1 mt-md-0">
                                            <ButtonGroup size="sm">
                                                <Button
                                                    variant={viewMode === 'table' ? 'secondary' : 'outline-secondary'}
                                                    onClick={() => setViewMode('table')}
                                                >
                                                    <i className="bi bi-table me-1"></i>
                                                    Table View
                                                </Button>
                                                <Button
                                                    variant={viewMode === 'cards' ? 'secondary' : 'outline-secondary'}
                                                    onClick={() => setViewMode('cards')}
                                                >
                                                    <i className="bi bi-grid me-1"></i>
                                                    Card View
                                                </Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            <Tab.Container defaultActiveKey="uniswap">
                                <Nav variant="tabs" className="mb-3">
                                    <Nav.Item>
                                        <Nav.Link eventKey="uniswap">Uniswap V3</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="balancer" disabled>Balancer</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="curve" disabled>Curve</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane eventKey="uniswap">
                                        <Row className="g-1 text-body">
                                            {renderContent()}
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </CardBody>
                    </Card>
                </Col>

                {/* ADD THIS SECTION: Filter Sidebar Column */}
                {showFilters && !isMobile && (
                    <Col lg={3}>
                        <Card>
                            <CardHeader>
                                <h5 className="mb-0">Filters</h5>
                            </CardHeader>
                            <CardBody>
                                {renderFilterContent()}
                            </CardBody>
                        </Card>
                    </Col>
                )}
            </Row>
        </Layout>
    );

}