import React from "react";
import { Container, Card, Image, Row, Col, Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import LayoutPublic from "../../components/layout/LayoutPublic";
import styled from "styled-components";


const CustomContainer = styled.div`
    width: 100%;

    @media (min-width: 992px) {
        width: 75%;
    }
`;


const BlockLivesMatterPage: React.FC = () => {
    return (
        <LayoutPublic>
            <CustomContainer className="container-fluid my-3">
                <Card className="shadow-lg p-3 text-center">
                    <h1 className="text-body-emphasis fw-semibold display-4 mb-4 mt-2">Block Lives Matter</h1>

                    {/* First Image - Full Width with mx-2 */}
                    <Image
                        src="/images/blockchain_protest_rally_16x9.png"
                        alt="Blockchain protest rally"
                        fluid
                        className="w-100 px-lg-3 mb-4"
                    />

                    <p className="lead fw-semibold text-muted">
                        In the decentralized world of blockchain, every block matters. Each
                        block plays a crucial role in maintaining the integrity of the ledger,
                        ensuring that transactions remain tamper-proof, secure, and immutable.
                    </p>

                    <h2 className="text-primary px-lg-3 mt-4">The Power of Every Block</h2>
                    <p>
                        A blockchain is a **chain of immutable blocks**, each storing **a record
                        of transactions** that cannot be altered. This principle of **permanence
                        and accountability** mirrors real life.
                    </p>
                    <p>
                        - Just as you cannot erase your past, you cannot **rewrite blockchain history**.<br />
                        - Every decision we make **builds upon previous choices**, forming the unchangeable timeline of our existence.<br />
                        - Similar to how **blocks are linked together**, our experiences shape the **path ahead**.<br />
                    </p>

                    {/* Second Image - Full Width with mx-2 */}
                    <Image
                        src="/images/blockchain_protest_scene_16x9.png"
                        alt="Blockchain protest with more banners"
                        fluid
                        className="w-100 px-lg-3 mb-4"
                    />

                    <h2 className="text-primary mt-4">The Blockchain Protest: Why Blocks Are Fighting Back</h2>
                    <p>
                        Imagine a world where **blockchain blocks could protest**. They might
                        **fight for fair gas fees, demand immutability protection, and stand
                        strong against double-spending attacks**.
                    </p>

                    <p>In this **humorous blockchain revolution**, blocks would **rally for decentralization** with slogans like:</p>

                    <Row className="justify-content-center my-4">
                        <Col xs={12} md={10} lg={8}>
                            <Alert variant="secondary">
                                <strong>&quot;No Forking Way!&quot;</strong> - Opposing unnecessary hard forks.
                            </Alert>
                            <Alert variant="secondary">
                                <strong>&quot;Proof of Workin&apos; Hard!&quot;</strong> - Advocating for fair consensus.
                            </Alert>
                            <Alert variant="secondary">
                                <strong>&quot;Immutable and Proud!&quot;</strong> - Standing against transaction tampering.
                            </Alert>
                        </Col>
                    </Row>

                    {/* Third Image - Full Width with mx-2 */}
                    <Image
                        src="/images/life_blockchain_similarities_16x9.png"
                        alt="Life and Blockchain Similarities"
                        fluid
                        className="w-100 mb-4 px-lg-3"
                    />

                    <h2 className="text-primary my-4">Why Join Finmates?</h2>
                    <Row className="justify-content-center text-center">
                        <Col xs={12}  className="mb-3">
                            <h5 className="fw-semibold"><span className="me-2">📊</span> View your friends&apos; transactions and wallets in real-time</h5>
                        </Col>
                        <Col xs={12}  className="mb-3">
                            <h5 className="fw-semibold"><span className="me-2">💧</span> Analyze liquidity pools on Uniswap</h5>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <h5 className="fw-semibold"><span className="me-2">💡</span> Share and discuss blockchain investment ideas</h5>
                        </Col>
                        <Col xs={12}  className="mb-3">
                            <h5 className="fw-semibold"><span className="me-2">🤝</span> Collaborate to find the best DeFi strategies</h5>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <h5 className="fw-semibold"><span className="me-2">🚀</span> Learn how to make money in crypto—together</h5>
                        </Col>
                    </Row>


                    {/* Call to Action - Centered */}
                    <div className="my-5">
                        <h3 className="text-success">Join the Future of Social Blockchain</h3>
                        <p>Be part of the next big thing in blockchain—track, connect, and profit together!</p>
                        <Link to="/sign-up">
                            <Button variant="primary" size="lg">Join Finmates Now</Button>
                        </Link>
                    </div>
                </Card>
            </CustomContainer>
        </LayoutPublic>
    );
};

export default BlockLivesMatterPage;
