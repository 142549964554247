import * as React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.scss";
import {
    FaChartBar, FaCoins,
    FaListUl,
    FaRegComment,
    FaRegUser, FaThList
} from "react-icons/fa";
import { RxDashboard } from "react-icons/rx";
import { SlWallet } from "react-icons/sl";
import { FiUsers } from "react-icons/fi";
import { MdOutlineSsidChart } from "react-icons/md";
import { GrBarChart } from "react-icons/gr";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Accordion from "react-bootstrap/Accordion";
import { BsCoin } from "react-icons/bs";
import {NavDropdown} from "react-bootstrap";
import { FaBitcoin } from "react-icons/fa6";
import {GoTasklist} from "react-icons/go";

// ✅ Helper function for active NavLink styling
function getNavLinkClass({ isActive }: { isActive: boolean }) {
    return isActive ? "nav-link active" : "nav-link";
}

function Sidebar() {
    return (
        <Card border="0" className="d-flex flex-column flex-shrink-0 p-2 sticky-top" style={{ top: 66 }}>
            <Navbar sticky="top" expand="lg" className="p-0">
                <Nav className="sidebar nav-pills flex-column mb-auto w-100">
                    <Nav.Item as="li" className="mw-100 p-1">
                        <NavLink to="/dashboard" className={getNavLinkClass}>
                            <RxDashboard className="ms-1" /> Dashboard
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/profile" className={getNavLinkClass}>
                            <FaRegUser className="ms-1" /> My Profile
                        </NavLink>
                    </Nav.Item>

                    {/* ✅ Crypto Accordion Styled Like Other Items */}



                    <Nav.Item as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/connections" className={getNavLinkClass}>
                            <FiUsers className="ms-1" /> Connections
                        </NavLink>
                    </Nav.Item>


                    <Nav.Item as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/messages" className={getNavLinkClass}>
                            <FaRegComment className="ms-1" /> Messages
                        </NavLink>
                    </Nav.Item>


                    <Nav.Item as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/crypto/wallets" className={getNavLinkClass}>
                            <SlWallet className="ms-1" /> My Wallets
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/crypto/positions" className={getNavLinkClass}>
                            <FaThList className="ms-1" /> My Positions
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/crypto/pools" className={getNavLinkClass}>
                            <FaCoins  className="ms-1" /> Pools
                        </NavLink>
                    </Nav.Item>


                    <Nav.Item as="li" className="mw-100 pb-1 px-1">
                        <NavLink to="/stock-market" className={getNavLinkClass}>
                            <GrBarChart className="ms-1" /> Stock Market
                        </NavLink>
                    </Nav.Item>

                    <Nav.Item as="li" className="mw-100 p-1">
                        <NavLink to="/watchlist" className={getNavLinkClass}>
                            <FaListUl className="ms-1" /> Watchlist
                        </NavLink>
                    </Nav.Item>
                </Nav>
            </Navbar>
        </Card>
    );
}

export default Sidebar;
