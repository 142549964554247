import axios from "axios";
import {ISearchResponse} from "../../model/Search";
import {cacheExchange, createClient, fetchExchange, useQuery} from "urql";
import {IGraphData} from "../../model/crypto/Graph";
import {Provider} from "react";
import axiosClient from "../axios/AxiosClient";
import {ICryptoWallet} from "../../model/crypto/CryptoWallet";


const apiKey = 'd59545755cf1974d72a4b387c64e85e9'

const client = createClient({
    url: `https://gateway.thegraph.com/api/${apiKey}/subgraphs/id/HUZDsRpEVP2AvzDCyzDHtdc64dyDxx8FQjzsmqSg4H3B`,
    exchanges: [cacheExchange, fetchExchange],
});


const CRYPTO_URL = process.env.REACT_APP_CRYPTO_URL;

class CryptoService {

    /**
     * Get all wallets for the current user
     */
    async getAllWalletsCurrentUser() {
        return axiosClient.get<ICryptoWallet[]>(`${CRYPTO_URL}/wallets`)
            .then(response => response.data);
    }



    /**
     * Get all positions for all wallets for the current user
     */
    async getAllPositionsForCurrentUser() {
        return axiosClient.get<number[]>(`${CRYPTO_URL}/wallets/position-ids`)
            .then(response => response.data);
    }


    /**
     * Add a new wallet to monitor
     * @param address Ethereum wallet address
     */
    async addWallet(address: string) {
        return axiosClient.post(`${CRYPTO_URL}/wallet/add`, {address})
            .then(response => response.data);
    }

    /**
     * Remove a wallet from monitoring
     * @param address Ethereum wallet address
     */
    async removeWallet(address: string) {
        return axiosClient.delete(`${CRYPTO_URL}/wallet/remove/${address}`)
            .then(response => response.data);
    }


    /**
     * Update wallet status (active/inactive)
     * @param address Ethereum wallet address
     * @param isActive New status
     */
    async updateWalletStatus(address: string, isActive: boolean) {
        return axiosClient.patch(`${CRYPTO_URL}/wallet/update-status/${address}`, {isActive})
            .then(response => response.data);
    }



    async updateWalletLabel(address: string, label: string) {
        return axiosClient.patch(`${CRYPTO_URL}/wallet/update-label/${address}`, { label })
            .then(response => response.data);
    }



    getData(query: string) {
        const [result] = useQuery<IGraphData>({query: query});
        const {data, fetching, error} = result;
        return result.data;
    }

    getVolume() {

        const QUERY = `{
  poolDayDatas(orderDirection: desc, where: {date_gt: 10}) {
    date
    feesUSD
    high
    low
    volumeUSD
  }
  bundles(first: 5) {
    id
    ethPriceUSD
  }
}`;

        const [result] = useQuery<IGraphData>({query: QUERY});
        const {data, fetching, error} = result;

        return result.data?.poolDayDatas[0].volumeUSD;

    }


}

export default new CryptoService();