import React from 'react';
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sidebar from "./sidebar/Sidebar";
import './layout.css';
import AuthService from "../../service/auth/AuthService";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import {Button} from "react-bootstrap";
import {Link, NavLink} from "react-router-dom";

function Layout(props: any) {

    const {children} = props;


    return (
        <>
            <Header/>

            <Container className="main-container container-fluid">
                <Row>

                    <Col className="d-none d-md-inline-grid sticky-top-under-header col-md-3 col-lg-2 col-xxl-1-5 px-0 h-100">
                        {AuthService.isLoggedIn() ? <Sidebar/> :
                        <Col className="d-block w-100">
                            <ButtonGroup className="w-100">
                                <Button href="/sign-in">Login</Button>
                                <Button  href="/sign-up" variant="outline-primary">Sign Up</Button>
                            </ButtonGroup>

                            <NavLink to="/crypto" className="h5 mt-3 link-secondary float-start">Crypto</NavLink>
                        </Col>
                        }
                        </Col>

                        <Col className="col-12 col-md-9 col-lg-10 col-xxl-10-5">
                    {children}
                    </Col>

                </Row>
            </Container>

                <Footer/>
        </>
    )
}


export default Layout;
