import { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import { CardBody, Button } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { UniswapPosition } from '../../../../model/crypto/CryptoModels';
import FormatUtils from '../../../../service/utils/FormatUtils';
import PropTypes from 'prop-types';

interface PriceHistoryProps {
    position: UniswapPosition;
    onRangeRecommendation: (lowerPrice: number, upperPrice: number) => void;
}

interface PriceDataPoint {
    timestamp: number;
    price: number;
    date: string;
}

interface RangeRecommendation {
    timeframe: string;
    lowerPrice: number;
    upperPrice: number;
    confidence: number;
    volatility: number;
    description: string;
    feeAPR: number; // Expected fee APR percentage
}

const PriceHistoryAnalyzer = ({ position, onRangeRecommendation }: PriceHistoryProps) => {
    const [priceHistory, setPriceHistory] = useState<PriceDataPoint[]>([]);
    const [recommendations, setRecommendations] = useState<RangeRecommendation[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [timeframe, setTimeframe] = useState<string>('7d');

    useEffect(() => {
        if (position.pool?.address) {
            fetchPriceHistory(timeframe);
        }
    }, [position, timeframe]);

    // Fetch real price history data from The Graph
    const fetchPriceHistory = async (period: string) => {
        setLoading(true);
        setError(null);

        try {
            if (!position.pool?.address || !position.currentPrice?.token1PerToken0) {
                throw new Error("Invalid position data: missing pool address or currentPrice");
            }

            const currentPrice = position.currentPrice.token1PerToken0;

            // Calculate timestamp for start date based on period
            const now = Math.floor(Date.now() / 1000);
            let startTimestamp;

            switch (period) {
                case '24h':
                    startTimestamp = now - (24 * 60 * 60);
                    break;
                case '7d':
                    startTimestamp = now - (7 * 24 * 60 * 60);
                    break;
                case '30d':
                    startTimestamp = now - (30 * 24 * 60 * 60);
                    break;
                case '90d':
                    startTimestamp = now - (90 * 24 * 60 * 60);
                    break;
                default:
                    startTimestamp = now - (7 * 24 * 60 * 60);
            }

            // Create GraphQL query for Uniswap subgraph
            const query = `
            {
              poolDayDatas(
                where: {
                  pool: "${position.pool.address.toLowerCase()}"
                  date_gt: ${startTimestamp}
                }
                orderBy: date
                orderDirection: asc
              ) {
                date
                sqrtPrice
                token0Price
                token1Price
                volumeUSD
                tvlUSD
                feesUSD
              }
            }`;

            // API endpoint for The Graph
            const endpoint = "https://gateway.thegraph.com/api/d59545755cf1974d72a4b387c64e85e9/subgraphs/id/FbCGRftH4a3yZugY7TnbYgPJVEv2LvMT6oF1fxPe9aJM";

            const response = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query }),
            });

            if (!response.ok) {
                throw new Error(`Error fetching data: ${response.statusText}`);
            }

            const result = await response.json();

            if (result.errors) {
                throw new Error(`GraphQL errors: ${result.errors.map((e: any) => e.message).join(', ')}`);
            }

            if (!result.data || !result.data.poolDayDatas) {
                throw new Error("No data returned from the API");
            }

            // Process the data
            const priceData: PriceDataPoint[] = result.data.poolDayDatas.map((dayData: any) => {
                // Use the appropriate price field based on the position
                // token0Price is the price of token0 in terms of token1
                // token1Price is the price of token1 in terms of token0
                const price = position.currentPrice.token1PerToken0 > 1
                    ? parseFloat(dayData.token1Price)
                    : parseFloat(dayData.token0Price);

                return {
                    timestamp: parseInt(dayData.date) * 1000, // Convert to milliseconds
                    price,
                    date: formatDate(new Date(parseInt(dayData.date) * 1000), period),
                };
            });

            // If we don't have enough data, fall back to simulation
            if (priceData.length < 3) {
                console.warn("Not enough data from API, falling back to simulation");
                const simulatedData = generateSimulatedPriceData(currentPrice, period);
                setPriceHistory(simulatedData);

                // Calculate recommendations based on simulation
                const recommendations = calculateRangeRecommendations(simulatedData, currentPrice);
                setRecommendations(recommendations);
            } else {
                setPriceHistory(priceData);

                // Calculate recommendations based on real data
                const recommendations = calculateRangeRecommendations(priceData, currentPrice);
                setRecommendations(recommendations);
            }
        } catch (err) {
            console.error("Error fetching from The Graph:", err);
            setError(`Error fetching price data: ${err instanceof Error ? err.message : String(err)}`);

            // Fall back to simulation if API fails
            if (position.currentPrice?.token1PerToken0) {
                const currentPrice = position.currentPrice.token1PerToken0;
                const simulatedData = generateSimulatedPriceData(currentPrice, timeframe);
                setPriceHistory(simulatedData);

                // Calculate recommendations based on simulation
                const recommendations = calculateRangeRecommendations(simulatedData, currentPrice);
                setRecommendations(recommendations);
            }
        } finally {
            setLoading(false);
        }
    };

    // Generate simulated price data for demo purposes
    const generateSimulatedPriceData = (currentPrice: number, period: string): PriceDataPoint[] => {
        const dataPoints: PriceDataPoint[] = [];
        let days: number;

        switch (period) {
            case '24h':
                days = 1;
                break;
            case '7d':
                days = 7;
                break;
            case '30d':
                days = 30;
                break;
            case '90d':
                days = 90;
                break;
            default:
                days = 7;
        }

        // For longer periods, we'll use daily data points instead of hourly
        const pointsPerDay = days <= 7 ? 24 : 1;
        const numPoints = days * pointsPerDay;

        // Volatility factor based on token pair
        const volatilityFactor = getVolatilityFactor(position);

        // Scale volatility based on timeframe (longer periods have more stable daily moves)
        const scaledVolatility = volatilityFactor * (days <= 7 ? 1 : 0.5);

        // Generate trend bias for long-term price movement
        const trendBias = (Math.random() - 0.5) * 0.01; // Small daily drift

        // Generate price data in reverse (from now backwards)
        const now = new Date();
        let tempPrice = currentPrice;

        // Pre-calculate a volatility pattern to create more realistic price movements
        const volatilityPattern: number[] = [];
        let currentVolatility = scaledVolatility;

        for (let i = 0; i < numPoints; i++) {
            // Volatility clustering - periods of high/low volatility tend to cluster
            if (Math.random() < 0.1) {
                // 10% chance to shift volatility regime
                currentVolatility = scaledVolatility * (0.5 + Math.random());
            }
            volatilityPattern.push(currentVolatility);
        }

        // Generate the actual price series
        for (let i = 0; i < numPoints; i++) {
            const pointVolatility = volatilityPattern[i];
            const timestamp = now.getTime() - (i * (24 / pointsPerDay) * 3600 * 1000);
            const date = new Date(timestamp);

            // Random walk with mean reversion and trend bias
            const dailyVolatility = pointVolatility * (1 + 0.2 * Math.sin(i / 10)); // Add cyclical component
            const randomChange = (Math.random() - 0.5) * dailyVolatility * tempPrice;
            const meanReversion = (currentPrice - tempPrice) * 0.01; // Weak mean reversion
            const trendComponent = tempPrice * trendBias;

            // Update price with all components
            tempPrice = tempPrice + randomChange + meanReversion + trendComponent;

            // Ensure price doesn't go negative
            tempPrice = Math.max(0.01, tempPrice);

            // Add to our array
            dataPoints.push({
                timestamp,
                price: tempPrice,
                date: formatDate(date, period)
            });
        }

        // Reverse to get chronological order
        return dataPoints.reverse();
    };

    // Format date based on timeframe
    const formatDate = (date: Date, period: string): string => {
        if (period === '24h') {
            return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else if (period === '7d') {
            return date.toLocaleDateString([], { weekday: 'short', day: 'numeric' });
        } else {
            return date.toLocaleDateString([], { month: 'short', day: 'numeric' });
        }
    };

    // Estimate volatility based on token pair
    const getVolatilityFactor = (positionData: UniswapPosition): number => {
        if (!positionData.token0 || !positionData.token0.symbol ||
            !positionData.token1 || !positionData.token1.symbol) {
            return 0.05; // Default if data is missing
        }

        const token0Symbol = positionData.token0.symbol.toUpperCase();
        const token1Symbol = positionData.token1.symbol.toUpperCase();

        // Stablecoin pairs have very low volatility
        const stablecoins = ['USDC', 'USDT', 'DAI', 'BUSD'];

        // Both stablecoins
        if (stablecoins.includes(token0Symbol) && stablecoins.includes(token1Symbol)) {
            return 0.01; // 1% volatility
        }

        // One stablecoin with major crypto
        if ((stablecoins.includes(token0Symbol) || stablecoins.includes(token1Symbol)) &&
            (['WETH', 'WBTC', 'ETH', 'BTC'].includes(token0Symbol) ||
                ['WETH', 'WBTC', 'ETH', 'BTC'].includes(token1Symbol))) {
            return 0.05; // 5% volatility
        }

        // One stablecoin with altcoin
        if (stablecoins.includes(token0Symbol) || stablecoins.includes(token1Symbol)) {
            return 0.08; // 8% volatility
        }

        // Major crypto pairs (ETH/BTC)
        if ((['WETH', 'ETH'].includes(token0Symbol) && ['WBTC', 'BTC'].includes(token1Symbol)) ||
            (['WBTC', 'BTC'].includes(token0Symbol) && ['WETH', 'ETH'].includes(token1Symbol))) {
            return 0.04; // 4% volatility
        }

        // Default for other pairs
        return 0.1; // 10% volatility
    };

    // Calculate statistical measures for the price history
    const calculateStatistics = (priceData: PriceDataPoint[]): {
        mean: number;
        stdDev: number;
        min: number;
        max: number;
        volatility: number;
    } => {
        if (priceData.length === 0) {
            return { mean: 0, stdDev: 0, min: 0, max: 0, volatility: 0 };
        }

        const prices = priceData.map(point => point.price);

        // Calculate mean
        const mean = prices.reduce((sum, price) => sum + price, 0) / prices.length;

        // Calculate standard deviation
        const squaredDiffs = prices.map(price => Math.pow(price - mean, 2));
        const variance = squaredDiffs.reduce((sum, diff) => sum + diff, 0) / prices.length;
        const stdDev = Math.sqrt(variance);

        // Calculate min and max
        const min = Math.min(...prices);
        const max = Math.max(...prices);

        // Calculate volatility (stdDev as percentage of mean)
        const volatility = (stdDev / mean) * 100;

        return { mean, stdDev, min, max, volatility };
    };

    // Calculate range recommendations based on price history
    const calculateRangeRecommendations = (
        priceData: PriceDataPoint[],
        currentPrice: number
    ): RangeRecommendation[] => {
        const stats = calculateStatistics(priceData);
        const recommendations: RangeRecommendation[] = [];

        // Calculate expected fee APR based on range width (narrower ranges = higher APR when in range)
        // These calculations are approximate and based on typical Uniswap V3 fee tiers
        const baseFeeAPR = position.fee ? (position.fee / 10000 * 100) : 5; // Fee tier as baseline APR (e.g., 0.3% fee = 3% base APR)
        const volumeMultiplier = 365; // Estimated daily volume turnover in a year

        // Conservative range (±1 stdDev)
        const conservativeLower = Math.max(0.01, currentPrice * (1 - stats.volatility / 100));
        const conservativeUpper = currentPrice * (1 + stats.volatility / 100);
        const conservativeRangeWidth = (conservativeUpper - conservativeLower) / currentPrice * 100;
        // Narrower ranges get higher fee concentration but spend less time in range
        const conservativeFeeAPR = baseFeeAPR * volumeMultiplier * (25 / conservativeRangeWidth) * 0.7; // 70% time in range estimate

        recommendations.push({
            timeframe: 'Conservative',
            lowerPrice: conservativeLower,
            upperPrice: conservativeUpper,
            confidence: 80,
            volatility: stats.volatility,
            description: 'Narrow range with high fee concentration. Good for low volatility pairs or short-term positions.',
            feeAPR: Math.min(500, conservativeFeeAPR) // Cap at 500% to avoid unrealistic numbers
        });

        // Moderate range (±2 stdDev)
        const moderateLower = Math.max(0.01, currentPrice * (1 - stats.volatility / 100 * 2));
        const moderateUpper = currentPrice * (1 + stats.volatility / 100 * 2);
        const moderateRangeWidth = (moderateUpper - moderateLower) / currentPrice * 100;
        const moderateFeeAPR = baseFeeAPR * volumeMultiplier * (25 / moderateRangeWidth) * 0.9; // 90% time in range estimate

        recommendations.push({
            timeframe: 'Balanced',
            lowerPrice: moderateLower,
            upperPrice: moderateUpper,
            confidence: 95,
            volatility: stats.volatility,
            description: 'Balanced range covering most price movements. Good for medium-term positions.',
            feeAPR: Math.min(300, moderateFeeAPR) // Cap at 300% to avoid unrealistic numbers
        });

        // Wide range (±3 stdDev)
        const wideLower = Math.max(0.01, currentPrice * (1 - stats.volatility / 100 * 3));
        const wideUpper = currentPrice * (1 + stats.volatility / 100 * 3);
        const wideRangeWidth = (wideUpper - wideLower) / currentPrice * 100;
        const wideFeeAPR = baseFeeAPR * volumeMultiplier * (25 / wideRangeWidth) * 0.98; // 98% time in range estimate

        recommendations.push({
            timeframe: 'Wide',
            lowerPrice: wideLower,
            upperPrice: wideUpper,
            confidence: 99,
            volatility: stats.volatility,
            description: 'Wide range with high probability of staying in-range. Good for long-term passive positions.',
            feeAPR: Math.min(200, wideFeeAPR) // Cap at 200% to avoid unrealistic numbers
        });

        return recommendations;
    };

    // Apply a recommendation to the position
    const applyRecommendation = (recommendation: RangeRecommendation) => {
        onRangeRecommendation(recommendation.lowerPrice, recommendation.upperPrice);
    };

    return (
        <Card className="mt-3">
            <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="mb-0">Price History Analysis</h5>
                    <div className="btn-group">
                        <Button
                            size="sm"
                            variant={timeframe === '24h' ? 'primary' : 'outline-primary'}
                            onClick={() => setTimeframe('24h')}
                        >
                            24H
                        </Button>
                        <Button
                            size="sm"
                            variant={timeframe === '7d' ? 'primary' : 'outline-primary'}
                            onClick={() => setTimeframe('7d')}
                        >
                            7D
                        </Button>
                        <Button
                            size="sm"
                            variant={timeframe === '30d' ? 'primary' : 'outline-primary'}
                            onClick={() => setTimeframe('30d')}
                        >
                            30D
                        </Button>
                        <Button
                            size="sm"
                            variant={timeframe === '90d' ? 'primary' : 'outline-primary'}
                            onClick={() => setTimeframe('90d')}
                        >
                            90D
                        </Button>
                    </div>
                </div>

                {loading && (
                    <div className="text-center my-4">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                        <p className="mt-2">Loading price history...</p>
                    </div>
                )}

                {error && (
                    <div className="alert alert-warning" role="alert">
                        {error}
                    </div>
                )}

                {priceHistory.length > 0 && !loading && (
                    <div style={{ height: 250 }}>
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart
                                data={priceHistory}
                                margin={{ top: 5, right: 20, left: 20, bottom: 5 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="date"
                                    tickFormatter={(value) => value}
                                />
                                <YAxis
                                    domain={['auto', 'auto']}
                                    tickFormatter={(value) => FormatUtils.formatNumber(value)}
                                />
                                <Tooltip
                                    formatter={(value) => [FormatUtils.formatNumber(value as number), 'Price']}
                                    labelFormatter={(label) => `Date: ${label}`}
                                />
                                <Line
                                    type="monotone"
                                    dataKey="price"
                                    stroke="#8884d8"
                                    activeDot={{ r: 8 }}
                                    isAnimationActive={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                )}

                <div className="mt-4">
                    <h5 className="mb-3">Range Recommendations</h5>

                    {recommendations.length > 0 && (
                        <div className="row">
                            {recommendations.map((rec, index) => (
                                <div key={index} className="col-md-4 mb-3">
                                    <div className="border rounded p-3 h-100 position-relative recommendation-card">
                                        <h6>{rec.timeframe} Range ({rec.confidence}% Confidence)</h6>
                                        <p className="mb-2">
                                            <small className="text-muted">
                                                Volatility: {FormatUtils.formatNumber(rec.volatility)}%
                                            </small>
                                        </p>
                                        <p className="mb-2">
                                            <small className="text-success fw-bold">
                                                Expected Fee APR: {FormatUtils.formatNumber(rec.feeAPR)}%
                                            </small>
                                        </p>
                                        <p className="mb-1">
                                            <strong>Lower:</strong> {FormatUtils.formatNumber(rec.lowerPrice)}
                                        </p>
                                        <p className="mb-1">
                                            <strong>Upper:</strong> {FormatUtils.formatNumber(rec.upperPrice)}
                                        </p>
                                        <p className="mb-3">
                                            <small className="text-muted">{rec.description}</small>
                                        </p>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            className="position-absolute bottom-0 end-0 m-2"
                                            onClick={() => applyRecommendation(rec)}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </CardBody>
        </Card>
    );
};

// Add prop types validation
PriceHistoryAnalyzer.propTypes = {
    position: PropTypes.object.isRequired,
    onRangeRecommendation: PropTypes.func.isRequired
};

export default PriceHistoryAnalyzer;