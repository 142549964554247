import React, { useState, useEffect } from 'react';
import { Row, Col, Form, InputGroup, Button, ButtonGroup, Badge, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { Search, RefreshCw, Filter, Globe, Info } from 'lucide-react';
import { fetchPoolsData } from '../../../service/crypto/pool/PoolService';
import PoolsTable from './PoolsTable';
import PoolsPagination from './PoolsPagination';
import { Pool } from '../../../model/crypto/CryptoModels';
import { getNetworkBadgeInfo } from './PoolUtils';

const PoolsExplorer: React.FC = () => {
    const [pools, setPools] = useState<Pool[]>([]);
    const [filteredPools, setFilteredPools] = useState<Pool[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const [protocolFilter, setProtocolFilter] = useState<string>('all');
    const [feeFilter, setFeeFilter] = useState<string>('all');
    const [networkFilter, setNetworkFilter] = useState<string>('arbitrum'); // Default to Arbitrum
    const [sortConfig, setSortConfig] = useState({key: 'tvlUSD', direction: 'desc'});
    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [poolsPerPage] = useState(10);

    // Common fee tiers in Uniswap
    const feeTiers = [
        { value: 'all', label: 'All Fees' },
        { value: '100', label: '0.01%' },
        { value: '500', label: '0.05%' },
        { value: '3000', label: '0.3%' },
        { value: '10000', label: '1%' }
    ];

    // Available networks
    const networks = [
        { value: 'arbitrum', label: 'Arbitrum', icon: '🔵', color: 'primary' },
        { value: 'ethereum', label: 'Ethereum', icon: '💠', color: 'info' },
        { value: 'optimism', label: 'Optimism', icon: '🔴', color: 'danger' },
        { value: 'base', label: 'Base', icon: '🟢', color: 'success' },
        { value: 'polygon', label: 'Polygon', icon: '🟣', color: 'purple' },
        { value: 'all', label: 'All Networks', icon: '🌐', color: 'secondary' }
    ];

    useEffect(() => {
        fetchPools();
    }, [networkFilter]); // Refetch when network changes

    // Reset to first page when filters change
    useEffect(() => {
        setCurrentPage(1);
    }, [searchTerm, protocolFilter, feeFilter, networkFilter]);

    useEffect(() => {
        if (pools.length > 0) {
            // Apply all filters
            let filtered = pools;

            if (protocolFilter !== 'all') {
                filtered = filtered.filter(pool => pool.protocol === protocolFilter);
            }

            if (feeFilter !== 'all') {
                filtered = filtered.filter(pool => String(pool.feeTier) === feeFilter);
            }

            if (networkFilter !== 'all' && filtered.some(pool => pool.network)) {
                filtered = filtered.filter(pool => pool.network === networkFilter);
            }

            // Apply search filter - handle potential undefined values safely
            filtered = filtered.filter(pool =>
                (pool.token0?.symbol || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (pool.token1?.symbol || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (pool.token0?.name || '').toLowerCase().includes(searchTerm.toLowerCase()) ||
                (pool.token1?.name || '').toLowerCase().includes(searchTerm.toLowerCase())
            );

            // Fix sorting - handle all possible types correctly
            const sorted = [...filtered].sort((a, b) => {
                if (sortConfig.key === 'tvlUSD' || sortConfig.key === 'volumeUSD24h') {
                    const valueA = Number(a[sortConfig.key] || 0);
                    const valueB = Number(b[sortConfig.key] || 0);
                    return sortConfig.direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else if (sortConfig.key === 'feeTier') {
                    const valueA = Number(a.feeTier || 0);
                    const valueB = Number(b.feeTier || 0);
                    return sortConfig.direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else if (sortConfig.key === 'feesUSD24h') {
                    const valueA = Number(a.feesUSD24h || 0);
                    const valueB = Number(b.feesUSD24h || 0);
                    return sortConfig.direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else if (sortConfig.key === 'apr') {
                    const valueA = Number(a.apr || 0);
                    const valueB = Number(b.apr || 0);
                    return sortConfig.direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else if (sortConfig.key === 'sevenDayApr') {
                    const valueA = Number(a.sevenDayApr || 0);
                    const valueB = Number(b.sevenDayApr || 0);
                    return sortConfig.direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else if (sortConfig.key === 'thirtyDayApr') {
                    const valueA = Number(a.thirtyDayApr || 0);
                    const valueB = Number(b.thirtyDayApr || 0);
                    return sortConfig.direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else if (sortConfig.key === 'pair') {
                    const pairA = `${a.token0?.symbol || ''}/${a.token1?.symbol || ''}`;
                    const pairB = `${b.token0?.symbol || ''}/${b.token1?.symbol || ''}`;
                    return sortConfig.direction === 'asc'
                        ? pairA.localeCompare(pairB)
                        : pairB.localeCompare(pairA);
                }
                return 0;
            });

            setFilteredPools(sorted);
        }
    }, [pools, searchTerm, sortConfig, protocolFilter, feeFilter, networkFilter]);

    // Get current pools for pagination
    const indexOfLastPool = currentPage * poolsPerPage;
    const indexOfFirstPool = indexOfLastPool - poolsPerPage;
    const currentPools = filteredPools.slice(indexOfFirstPool, indexOfLastPool);

    const fetchPools = async () => {
        setIsLoading(true);
        try {
            // Pass the network filter to the service
            const poolsData = await fetchPoolsData(networkFilter);

            setPools(poolsData);
            setFilteredPools(poolsData);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching pools:', error);
            setIsLoading(false);
            // Set empty arrays to avoid undefined errors
            setPools([]);
            setFilteredPools([]);
        }
    };

    const handleSort = (key: string) => {
        let direction = 'desc';
        if (sortConfig.key === key && sortConfig.direction === 'desc') {
            direction = 'asc';
        }
        setSortConfig({key, direction});
    };

    // Reset all filters
    const resetFilters = () => {
        setSearchTerm('');
        setProtocolFilter('all');
        setFeeFilter('all');
        // Don't reset network filter since changing it causes a new data fetch
    };

    // Get the current network object
    const currentNetwork = networks.find(n => n.value === networkFilter) || networks[0];

    return (
        <>
            {/* Search, Network, and Protocol Filters */}
            <Row className="mb-4">
                <Col md={5} className="mb-3 mb-md-0">
                    <InputGroup>
                        <InputGroup.Text>
                            <Search size={18}/>
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="Search tokens..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputGroup>
                </Col>
                <Col md={3} className="mb-3 mb-md-0">
                    <Form.Select
                        value={networkFilter}
                        onChange={(e) => setNetworkFilter(e.target.value)}
                        aria-label="Filter by network"
                        className="border-primary"
                    >
                        {networks.map(network => (
                            <option key={network.value} value={network.value}>
                                {network.icon} {network.label}
                            </option>
                        ))}
                    </Form.Select>
                </Col>
                <Col md={3} className="mb-3 mb-md-0">
                    <Form.Select
                        value={protocolFilter}
                        onChange={(e) => setProtocolFilter(e.target.value)}
                        aria-label="Filter by protocol"
                    >
                        <option value="all">All Protocols</option>
                        <option value="v2">Uniswap v2</option>
                        <option value="v3">Uniswap v3</option>
                        <option value="v4">Uniswap v4</option>
                    </Form.Select>
                </Col>
                <Col md={1} className="d-flex justify-content-md-end">
                    <Button
                        variant="primary"
                        onClick={fetchPools}
                        className="d-flex align-items-center w-100 justify-content-center"
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <Spinner animation="border" size="sm" />
                        ) : (
                            <>
                                <RefreshCw size={18} className="me-md-2"/>
                                <span className="d-none d-md-inline px-2">Refresh</span>
                            </>
                        )}
                    </Button>
                </Col>
            </Row>

            {/* Network Selection and Fee Filter */}
            <Row className="mb-4">
                <Col xs={12} md={6} lg={8} className="mb-3 mb-md-0">
                    <div className="d-flex align-items-center flex-wrap">
                        <div className="me-3 mb-2 mb-sm-0">
                            <Badge
                                bg={currentNetwork.color as string}
                                className="py-2 px-3 d-flex align-items-center"
                            >
                                <Globe size={16} className="me-1" />
                                <span className="fw-medium">{currentNetwork.label}</span>
                            </Badge>
                        </div>
                        <div className="d-flex align-items-center flex-wrap">
                            <Filter size={18} className="me-2 text-primary" />
                            <span className="me-2">Fee:</span>
                            <ButtonGroup size="sm" className="flex-wrap">
                                {feeTiers.map(tier => (
                                    <Button
                                        key={tier.value}
                                        variant={feeFilter === tier.value ? 'primary' : 'outline-primary'}
                                        onClick={() => setFeeFilter(tier.value)}
                                        className="mb-1 mb-sm-0"
                                    >
                                        {tier.label}
                                    </Button>
                                ))}
                            </ButtonGroup>
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} lg={4} className="d-flex justify-content-md-end align-items-center">
                    {(searchTerm !== '' || protocolFilter !== 'all' || feeFilter !== 'all') && (
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            onClick={resetFilters}
                            className="d-flex align-items-center me-2"
                        >
                            Clear Filters
                        </Button>
                    )}
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip>
                                Filter pools by network, protocol, fee tier, or search for specific tokens
                            </Tooltip>
                        }
                    >
                        <Button variant="light" size="sm" className="d-flex align-items-center p-1">
                            <Info size={16} />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>

            <PoolsTable
                currentPools={currentPools}
                isLoading={isLoading}
                filteredPools={filteredPools}
                handleSort={handleSort}
                sortConfig={sortConfig}
                networkFilter={networkFilter}
            />

            <Row className="mt-3">
                <Col md={6} className="small text-secondary">
                    {isLoading ? (
                        <span>Loading pools...</span>
                    ) : (
                        <span>
                            Showing {filteredPools.length > 0 ? `${indexOfFirstPool + 1}-${Math.min(indexOfLastPool, filteredPools.length)} of ` : ''}{filteredPools.length} pools
                        </span>
                    )}
                </Col>
                <Col md={6} className="small text-secondary text-md-end">
                    Data from Uniswap {currentNetwork.label} Subgraph
                </Col>
            </Row>

            <PoolsPagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPools={filteredPools.length}
                poolsPerPage={poolsPerPage}
            />
        </>
    );
};

export default PoolsExplorer;