import React from 'react';
import {Activity, Users, LineChart, Wallet, Search, Globe, TrendingUp, Shield} from 'lucide-react';
import PublicRoutes from "../../components/routing/PublicRoutes";
import LayoutPublic from "../../components/layout/LayoutPublic";

export default function LandingPage() {
    return (
        <LayoutPublic>
            <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                {/* Hero Section */}
                <div className="relative overflow-hidden bg-white">
                    <div className="max-w-7xl mx-auto">
                        <div className="relative z-10 pt-16 pb-8 sm:pt-24 sm:pb-16 lg:pt-32 lg:pb-24">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="text-center">
                                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                                        <span className="block text-indigo-600">Finmates</span>
                                        <span className="block mt-2">Where Investors Connect</span>
                                    </h1>
                                    <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                                        Your social network for smart investing. Connect with fellow investors, track
                                        top-performing pools, and manage your crypto portfolio across multiple wallets.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Features Grid */}
                <div className="py-12 bg-white">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center">
                            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                                Empowering Your Investment Journey
                            </h2>
                            <p className="mt-4 text-lg text-gray-500">
                                Everything you need to make informed investment decisions
                            </p>
                        </div>

                        <div className="mt-16">
                            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                                {/* Feature 1 */}
                                <div className="pt-6">
                                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <Search className="h-6 w-6 text-white"/>
                      </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium text-gray-900">Find Top Pools</h3>
                                            <p className="mt-5 text-base text-gray-500">
                                                Discover the highest-performing liquidity pools across multiple
                                                networks. Real-time analytics and performance metrics at your
                                                fingertips.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Feature 2 */}
                                <div className="pt-6">
                                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <Wallet className="h-6 w-6 text-white"/>
                      </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium text-gray-900">Multi-Wallet
                                                Management</h3>
                                            <p className="mt-5 text-base text-gray-500">
                                                Track and manage your positions across multiple wallets. Unified
                                                dashboard for complete portfolio oversight.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Feature 3 */}
                                <div className="pt-6">
                                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <Users className="h-6 w-6 text-white"/>
                      </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium text-gray-900">Social Network</h3>
                                            <p className="mt-5 text-base text-gray-500">
                                                Connect with experienced investors, share insights, and learn from the
                                                community. Build your investment network.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Feature 4 */}
                                <div className="pt-6">
                                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <Activity className="h-6 w-6 text-white"/>
                      </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium text-gray-900">Advanced
                                                Analytics</h3>
                                            <p className="mt-5 text-base text-gray-500">
                                                Deep insights into pool performance, volatility metrics, and return
                                                analysis. Make data-driven decisions.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Feature 5 */}
                                <div className="pt-6">
                                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <Globe className="h-6 w-6 text-white"/>
                      </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium text-gray-900">Cross-Chain
                                                Support</h3>
                                            <p className="mt-5 text-base text-gray-500">
                                                Monitor investments across multiple blockchain networks. Unified view of
                                                your cross-chain portfolio.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                {/* Feature 6 */}
                                <div className="pt-6">
                                    <div className="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                                        <div className="-mt-6">
                                            <div>
                      <span className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg">
                        <TrendingUp className="h-6 w-6 text-white"/>
                      </span>
                                            </div>
                                            <h3 className="mt-8 text-lg font-medium text-gray-900">Performance
                                                Tracking</h3>
                                            <p className="mt-5 text-base text-gray-500">
                                                Track your investment performance over time. Detailed ROI analysis and
                                                historical trends.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* CTA Section */}
                <div className="bg-indigo-600">
                    <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                            <span className="block">Ready to get started?</span>
                            <span className="block">Join Finmates today.</span>
                        </h2>
                        <p className="mt-4 text-lg leading-6 text-indigo-200">
                            Connect with fellow investors, discover opportunities, and grow your portfolio.
                        </p>
                        <button
                            className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
                        >
                            Sign up for free
                        </button>
                    </div>
                </div>
            </div>
        </LayoutPublic>
    );
}