import React from 'react';
import { TrendingUp, Users, Wallet, Search, LineChart, Shield, Activity } from 'lucide-react';
import LayoutPublic from "../../../components/layout/LayoutPublic";
import banner1 from '../homepage/images/position_preview.bmp';
import LayoutHomepage from "../../../components/layout/LayoutHomepage";

const SUPPORTED_NETWORKS = [
    {
        id: 'ethereum',
        name: 'Ethereum',
        logoUrl: '/images/crypto/network/logo-ethereum.png', // Replace with actual Ethereum logo URL
        chainId: 1
    },
    {
        id: 'arbitrum',
        name: 'Arbitrum',
        logoUrl: '/images/crypto/network/logo-arbitrum.png', // Replace with actual Arbitrum logo URL
        chainId: 42161
    },
    {
        id: 'optimism',
        name: 'Optimism',
        logoUrl: '/images/crypto/network/logo-optimism.png', // Replace with actual Optimism logo URL
        chainId: 10
    },
    {
        id: 'polygon',
        name: 'Polygon',
        logoUrl: '/images/crypto/network/logo-polygon.png', // Replace with actual Polygon logo URL
        chainId: 137
    },
    {
        id: 'base',
        name: 'Base',
        logoUrl: '/images/crypto/network/logo-base.png', // Replace with actual Base logo URL
        chainId: 8453
    }
];

const Homepage2 = () => {
    return (
        <LayoutHomepage>
            <div className="min-vh-100 bg-body">
                {/* Hero Section */}
                <div style={{
                    background: 'linear-gradient(135deg, rgba(13, 110, 253, 0.85) 0%, rgba(48, 129, 255, 0.9) 100%)',
                    boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.1)'
                }} className="text-white py-0">
                    <div className="container py-5">
                        <div className="row align-items-center">
                            <div className="col-lg-7 text-center text-lg-start">
                                <h1 className="display-4 fw-bold mb-3">
                                    Finmates
                                </h1>
                                <h2 className="display-6 mb-4">
                                    Where Smart Investors Connect
                                </h2>
                                <p className="lead mb-4">
                                    Join the future of DeFi investing. Connect with fellow investors, track top-performing pools,
                                    and manage your crypto portfolio across multiple chains.
                                </p>
                                <div className="d-flex gap-3 justify-content-center justify-content-lg-start">
                                    <button
                                        className="btn btn-light btn-lg"
                                        onClick={() => window.location.href = '/sign-up'}
                                    >
                                        Get Started
                                    </button>
                                    <button
                                        className="btn btn-outline-light btn-lg"
                                        onClick={() => window.location.href = '/sign-in'}
                                    >
                                        Sign In
                                    </button>
                                </div>
                            </div>
                            <div className="col-lg-5 mt-5 mt-lg-0">
                                <img
                                    src={banner1}
                                    alt="Dashboard Preview"
                                    className="img-fluid rounded-4 shadow"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Stats Section */}
                <div className="bg-body py-5">
                    <div className="container">
                        <div className="row text-center g-4 mb-5">
                            <div className="col-md-4">
                                <h2 className="display-4 fw-bold text-primary">10k+</h2>
                                <p className="lead text-body-secondary">Active Users</p>
                            </div>
                            <div className="col-md-4">
                                <h2 className="display-4 fw-bold text-primary">1000+</h2>
                                <p className="lead text-body-secondary">Pools Tracked</p>
                            </div>
                            <div className="col-md-4">
                                <h2 className="display-4 fw-bold text-primary">5+</h2>
                                <p className="lead text-body-secondary">Networks</p>
                            </div>
                        </div>

                        {/* Networks List */}
                        <div className="text-center mt-4">
                            <h3 className="h4 text-body-secondary mb-4">Supported Networks</h3>
                            <div className="d-flex justify-content-center gap-4 flex-wrap">
                                {SUPPORTED_NETWORKS.map((network) => (
                                    <div
                                        key={network.id}
                                        className="d-flex align-items-center bg-body-tertiary rounded-3 px-4 py-2"
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => window.location.href = `/crypto/pools?network=${network.id}`}
                                    >
                                        <img
                                            src={network.logoUrl}
                                            alt={network.name}
                                            className="me-2"
                                            width="48"
                                            height="48"
                                        />
                                        <h4 className="text-body">{network.name}</h4>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Features Section */}
                <div className="container py-5">
                    <h2 className="text-center text-body-emphasis display-5 mb-5">Key Features</h2>
                    <div className="row g-4">
                        <div className="col-md-6 col-lg-4">
                            <div className="card h-100 bg-body-tertiary border-0">
                                <div className="card-body text-center p-4">
                                    <div className="bg-primary p-3 rounded d-inline-flex mb-3">
                                        <Search className="text-white" size={24} />
                                    </div>
                                    <h3 className="card-title h4 mb-3">Pool Analytics</h3>
                                    <p className="card-text text-body-secondary">
                                        Find the best performing liquidity pools with advanced analytics and real-time data tracking.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="card h-100 bg-body-tertiary border-0">
                                <div className="card-body text-center p-4">
                                    <div className="bg-primary p-3 rounded d-inline-flex mb-3">
                                        <Wallet className="text-white" size={24} />
                                    </div>
                                    <h3 className="card-title h4 mb-3">Multi-Chain Portfolio</h3>
                                    <p className="card-text text-body-secondary">
                                        Track and manage your positions across multiple networks and wallets in one place.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="card h-100 bg-body-tertiary border-0">
                                <div className="card-body text-center p-4">
                                    <div className="bg-primary p-3 rounded d-inline-flex mb-3">
                                        <LineChart className="text-white" size={24} />
                                    </div>
                                    <h3 className="card-title h4 mb-3">Strategy Tools</h3>
                                    <p className="card-text text-body-secondary">
                                        Advanced tools for optimal position management and profit maximization.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Advanced Features Section */}
                <div className="bg-primary text-white py-5">
                    <div className="container py-3">
                        <h2 className="text-center display-6 mb-5">Advanced Features</h2>
                        <div className="row g-4">
                            <div className="col-md-6">
                                <div className="d-flex align-items-start">
                                    <div className="me-3">
                                        <Activity size={24} />
                                    </div>
                                    <div>
                                        <h3 className="h5">Real-time Analytics</h3>
                                        <p className="text-white-50">
                                            Monitor pool performance, volatility, and returns in real-time with advanced analytics tools.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex align-items-start">
                                    <div className="me-3">
                                        <Users size={24} />
                                    </div>
                                    <div>
                                        <h3 className="h5">Social Network</h3>
                                        <p className="text-white-50">
                                            Connect with experienced investors, share insights, and learn from the community.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex align-items-start">
                                    <div className="me-3">
                                        <TrendingUp size={24} />
                                    </div>
                                    <div>
                                        <h3 className="h5">Position Management</h3>
                                        <p className="text-white-50">
                                            Advanced tools for managing and optimizing your liquidity positions.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="d-flex align-items-start">
                                    <div className="me-3">
                                        <Shield size={24} />
                                    </div>
                                    <div>
                                        <h3 className="h5">Security First</h3>
                                        <p className="text-white-50">
                                            Best-in-class security practices to keep your investments safe.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* CTA Section */}
                <div className="bg-body py-5">
                    <div className="container text-center">
                        <h2 className="display-5 text-body-emphasis mb-4">Ready to get started?</h2>
                        <p className="lead mb-4 text-body-secondary">
                            Join Finmates today and take your DeFi investments to the next level.
                        </p>
                        <button
                            className="btn btn-primary btn-lg px-5"
                            onClick={() => window.location.href = '/sign-up'}
                        >
                            Create free account
                        </button>
                    </div>
                </div>
            </div>
        </LayoutHomepage>
    );
};

export default Homepage2;