import React from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { FcInfo } from "react-icons/fc";

interface InfoTooltipProps {
    text: string;
    imageUrl?: string; // Optional image URL
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ text, imageUrl }) => {
    return (
        <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            trigger="click"
            rootClose
            overlay={
                <Tooltip id="button-tooltip">
                    {imageUrl && (
                        <img
                            src={imageUrl}
                            alt="tooltip"
                            style={{ width: "100px", marginBottom: "5px", borderRadius: "5px" }}
                        />
                    )}
                    <div>{text}</div>
                </Tooltip>
            }
        >
      <span
          className="d-inline-flex align-items-center"
          style={{ cursor: "pointer", marginLeft: "5px" }}
      >
        <FcInfo size={16} />
      </span>
        </OverlayTrigger>
    );
};

export default InfoTooltip;
