import {createClient, cacheExchange, fetchExchange} from "urql";
import {UniswapPosition, PositionEvent, Token} from "../../model/crypto/CryptoModels";
import {ethers} from "ethers";
import axios from "axios";
import UniswapMath from "./uniswap/UniswapMath";
import CryptoPositionsService from "./CryptoPositionsService";

const apiKey = 'd59545755cf1974d72a4b387c64e85e9';

const SUBGRAPH_URL =
    "https://gateway.thegraph.com/api/" + apiKey + "/subgraphs/id/3V7ZY6muhxaQL5qvntX1CFXJ32W7BxXZTGTwmpH5J4t3";

// Create a reusable GraphQL Client
const client = createClient({
    url: SUBGRAPH_URL,
    exchanges: [cacheExchange, fetchExchange],
});

class SubgraphUniswapService {

    /**
     * Fetch collection events for a specific token ID
     * @param tokenId - The token ID to fetch collects for
     * @returns Array of position events
     */
    async fetchCollects(tokenId: number): Promise<PositionEvent[]> {
        const query = `
            {
              collects(where: {tokenId: "${tokenId}"}, orderBy: blockTimestamp) {
                amount0
                amount1
                blockNumber
                blockTimestamp
                transactionHash
                recipient
              }
            }
        `;

        const SUBGRAPH_ENDPOINT = `https://gateway.thegraph.com/api/${apiKey}/subgraphs/id/CeXVNSNdYN4adbdXtKWJRxvG9nKa7Lg6bzevBAG6T7ub`;

        try {
            const response = await fetch(SUBGRAPH_ENDPOINT, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({query})
            });

            const json = await response.json();
            if (json.errors) {
                throw new Error("Error fetching subgraph data: " + JSON.stringify(json.errors));
            }

            // Transform data to match PositionEvent structure
            return json.data.collects.map((event: any): PositionEvent => ({
                blockNumber: Number(event.blockNumber),
                timestamp: Number(event.blockTimestamp),
                amount0: Number(event.amount0) / 1e18, // Convert from wei
                amount1: Number(event.amount1) / 1e6,  // Convert from token decimals
                transactionHash: event.transactionHash || undefined,
                recipient: event.recipient || undefined,
                eventType: 'collect'
            }));

        } catch (error) {
            console.error("Error fetching collects:", error);
            return [];
        }
    }

    /**
     * Fetch position details from The Graph (Uniswap V3) with integrated unclaimed fees
     * @param positionId - The Uniswap V3 position ID
     * @returns UniswapPosition object or null
     */
    async getPositionDetails(positionId: number): Promise<UniswapPosition | null> {
        const query = `
        query getPosition($positionId: ID!) {
          position(id: $positionId) {
            transaction {
              collects {
                amount0
                amount1
                amountUSD
                id
                timestamp
              }
              mints {
                timestamp
              }
            }
            owner
            collectedFeesToken0
            collectedFeesToken1
            feeGrowthInside0LastX128
            feeGrowthInside1LastX128
            liquidity
            depositedToken0
            depositedToken1
            amountDepositedUSD
            amountWithdrawnUSD
            amountCollectedUSD
            tickLower
            tickUpper
            pool {
              id
              feeTier
              token0Price
              token1Price
              sqrtPrice
              token0 {
                id
                decimals
                name
                symbol
              }
              token1 {
                id
                decimals
                name
                symbol
              }
            }
          }
        }`;

        try {
            // Step 1: Fetch position details from The Graph
            const response = await axios.post(
                SUBGRAPH_URL,
                {query, variables: {positionId}},
                {timeout: 5000} // 5s timeout
            );

            if (!response.data || !response.data.data.position) {
                console.warn(`⚠️ Position ${positionId} not found.`);
                return null;
            }

            const position = response.data.data.position;

            // Extract token details safely
            const token0 = position.pool.token0;
            const token1 = position.pool.token1;
            const token0Decimals = Number(token0.decimals) || 18;
            const token1Decimals = Number(token1.decimals) || 18;

            // Convert on-chain values properly
            const liquidity = BigInt(position.liquidity);
            const sqrtPrice = BigInt(position.pool.sqrtPrice);
            const tickLower = Number(position.tickLower);
            const tickUpper = Number(position.tickUpper);

            const token0DerivedUSD = await this.getTokenUsdValue(token0.id);
            const token1DerivedUSD = await this.getTokenUsdValue(token1.id);

            const {token0Amount: amount0, token1Amount: amount1} = this.getTokenAmountsFromLiquidity(
                liquidity,
                sqrtPrice,
                tickLower,
                tickUpper,
                token0Decimals,
                token1Decimals
            );

            // Step 2: Get collected fees from The Graph (reliable source)
            const feesCollectedUSD = Number(position.amountCollectedUSD) || 0;
            const feesCollectedToken0 = Number(position.collectedFeesToken0) / 10 ** token0Decimals;
            const feesCollectedToken1 = Number(position.collectedFeesToken1) / 10 ** token1Decimals;

            // Step 3: Fetch unclaimed fees directly (similar to CryptoPositionsService.fetchUnclaimedFees)
            // This replaces the separate call to fetchUnclaimedFees
            let unclaimedFees;
            try {
                // If you have a direct implementation in this service, use it
                unclaimedFees = await CryptoPositionsService.getUnclaimedFees(positionId);
                console.log("unclaimed fees = ", JSON.stringify(unclaimedFees))
            } catch (error) {
                console.error("Error fetching unclaimed fees, using fallback values:", error);
                unclaimedFees = {
                    amount0: 0,
                    amount1: 0
                };
            }

            // Calculate uncollected fees in USD
            const tokensOwed0 = unclaimedFees.amount0;
            const tokensOwed1 = unclaimedFees.amount1;
            const uncollectedFeesUSD = tokensOwed0 * token0DerivedUSD + tokensOwed1 * token1DerivedUSD;

            console.log("tokensOwed1 = " + tokensOwed1)
            console.log("token1DerivedUSD = " + token1DerivedUSD)

            // Step 4: Calculate total fees (collected + uncollected)
            const totalFeesUSD = feesCollectedUSD + uncollectedFeesUSD;

            // Calculate liquidityUSD based on token amounts
            const liquidityUSD = amount0 * token0DerivedUSD + amount1 * token1DerivedUSD;

            // Set up position events from collects
            const positionEvents: PositionEvent[] = position.transaction.collects.map((collect: any) => ({
                amount0: Number(collect.amount0) / 10 ** token0Decimals,
                amount1: Number(collect.amount1) / 10 ** token1Decimals,
                amountUSD: Number(collect.amountUSD),
                id: collect.id,
                timestamp: Number(collect.timestamp) * 1000, // Convert to milliseconds
                blockNumber: 0, // Default as we don't have this from the query
                eventType: 'collect'
            }));

            // Add the unclaimed fees as a virtual "collect" event for better tracking
            if (tokensOwed0 > 0 || tokensOwed1 > 0) {
                positionEvents.push({
                    amount0: tokensOwed0,
                    amount1: tokensOwed1,
                    amountUSD: uncollectedFeesUSD,
                    timestamp: Date.now(),
                    blockNumber: 0,
                    eventType: 'uncollected' // Mark as uncollected
                });
            }

            // Get mint timestamp
            const mintTimestamp = position.transaction.mints.length
                ? Number(position.transaction.mints[0].timestamp) * 1000
                : 0;

            // Calculate position age
            const now = Date.now();
            const positionAge = now - mintTimestamp;
            const ageInSeconds = positionAge / 1000;

            // Calculate initial liquidity USD
            const initialLiquidityUSD = Number(position.amountDepositedUSD) || liquidityUSD;

            // Calculate profit/loss
            const profitLossUSD = liquidityUSD - initialLiquidityUSD;
            const returnPercentage = initialLiquidityUSD > 0 ? (profitLossUSD / initialLiquidityUSD) * 100 : 0;

            // Calculate APR based on total fees
            let apr = 0;
            if (mintTimestamp > 0 && liquidityUSD > 0 && positionAge > 0) {
                // Calculate APR based on all fees as percentage of liquidity, annualized
                const annualizationFactor = 31536000000 / positionAge; // 365 days in milliseconds
                apr = annualizationFactor * totalFeesUSD * 100 / liquidityUSD;

                // Cap APR at a realistic value
                if (apr > 10000) {
                    console.warn(`Calculated APR (${apr}%) is unrealistically high for position ${positionId}, capping at 10000%`);
                    apr = 10000;
                }
            }

            // Calculate return metrics
            const totalReturnUSD = profitLossUSD + totalFeesUSD;
            const isProfitable = totalReturnUSD > 0;
            const totalReturnPercentage = initialLiquidityUSD > 0 ? (totalReturnUSD / initialLiquidityUSD) * 100 : 0;
            const totalCurrentValue = liquidityUSD + uncollectedFeesUSD;

            return {
                id: positionId,
                owner: position.owner || '',
                token0: {
                    address: token0.id,
                    decimals: token0Decimals,
                    name: token0.name,
                    symbol: token0.symbol,
                    derivedUSD: token0DerivedUSD || 0,
                },
                token1: {
                    address: token1.id,
                    decimals: token1Decimals,
                    name: token1.name,
                    symbol: token1.symbol,
                    derivedUSD: token1DerivedUSD || 0,
                },
                amount0,
                amount1,
                pool: {
                    address: position.pool.id,
                    network: 'ethereum' // Default to ethereum if not specified
                },
                address: "", // This might need to be populated from a different query
                fee: Number(position.pool.feeTier),
                ratio: Number(position.pool.token1Price) / Number(position.pool.token0Price),
                apr,
                mintTimestamp,
                mintBlock: position.transaction.mints.length
                    ? Number(position.transaction.mints[0].timestamp)
                    : 0,
                tickLower,
                tickUpper,
                tokensOwed0,
                tokensOwed1,
                liquidity: BigInt(position.liquidity),
                feesCollectedToken0,
                feesCollectedToken1,
                feesTotalUSD: totalFeesUSD,
                liquidityUSD,
                feesCollectedUSD,
                currentPrice: {
                    token1PerToken0: Number(position.pool.token1Price),
                    token0PerToken1: Number(position.pool.token0Price),
                },
                priceRange: {
                    lower: {
                        token1PerToken0: this.tickToPrice(tickLower, token0Decimals, token1Decimals),
                        token0PerToken1: 1 / this.tickToPrice(tickLower, token0Decimals, token1Decimals),
                    },
                    upper: {
                        token1PerToken0: this.tickToPrice(tickUpper, token0Decimals, token1Decimals),
                        token0PerToken1: 1 / this.tickToPrice(tickUpper, token0Decimals, token1Decimals),
                    },
                },
                // Additional fields required by interface
                initialLiquidityUSD,
                uncollectedFeesUSD,
                totalFeesUSD,
                profitLossUSD,
                totalReturnUSD,
                isProfitable,
                returnPercentage,
                totalReturnPercentage,
                totalCurrentValue,
                ageInSeconds,
                annualizedReturn: apr,
                positionEvents
            };
        } catch (error: any) {
            console.error(`❌ Error fetching position details for ${positionId}:`, error.response?.data || error.message);
            return null;
        }
    }


    /**
     * Converts tick to price
     * @param tick The tick value
     * @param token0Decimals Decimals for token0
     * @param token1Decimals Decimals for token1
     * @returns Price as token1/token0
     */
    tickToPrice(tick: number, token0Decimals: number, token1Decimals: number): number {
        const decimalAdjustment = 10 ** (token0Decimals - token1Decimals);
        return Math.pow(1.0001, tick) * decimalAdjustment;
    }

    /**
     * Converts a given price (token1/token0) to sqrtPriceX96 using BigInt.
     * @param price - The current price (token1/token0).
     * @returns The sqrtPriceX96 as a BigInt.
     */
    priceToSqrtPriceX96(price: number): bigint {
        const Q96 = 2n ** 96n; // 2^96 for Uniswap fixed-point math

        // Compute sqrt(price) * 2^96
        const sqrtPrice = Math.sqrt(price);
        return BigInt(Math.floor(sqrtPrice * Number(Q96)));
    }



    /**
     * Fetches the derived USD value of a token using Uniswap V3 subgraph
     * @param tokenAddress The address of the token (ERC-20)
     * @returns The derived USD value of the token or 0 if not found.
     */
    async getTokenUsdValue(tokenAddress: string): Promise<number> {
        interface TokenUSDResponse {
            token: { derivedETH: string } | null;
            bundle: { ethPriceUSD: string } | null;
        }

        const query = `
            {
              token(id: "${tokenAddress.toLowerCase()}") {
                derivedETH
              }
              bundle(id: "1") {
                ethPriceUSD
              }
            }
        `;

        try {
            const response = await fetch(SUBGRAPH_URL, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({query}),
            });

            const json = await response.json();
            const data: TokenUSDResponse = json.data;

            if (data.token && data.bundle) {
                const derivedETH = parseFloat(data.token.derivedETH);
                const ethPriceUSD = parseFloat(data.bundle.ethPriceUSD);
                return derivedETH * ethPriceUSD;
            } else {
                return 0;
            }
        } catch (error) {
            console.error("Error fetching token price:", error);
            return 0;
        }
    }

    /**
     * Computes token0 and token1 amounts from Uniswap V3 liquidity using tick values.
     * @param liquidity The liquidity amount as a BigInt
     * @param sqrtPriceX96 The current sqrt price as a BigInt (from pool)
     * @param tickLower The tick at the lower bound
     * @param tickUpper The tick at the upper bound
     * @param token0Decimals The number of decimals for token0
     * @param token1Decimals The number of decimals for token1
     * @returns The computed amounts of token0 and token1 (as numbers)
     */
    getTokenAmountsFromLiquidity(
        liquidity: bigint,
        sqrtPriceX96: bigint,
        tickLower: number,
        tickUpper: number,
        token0Decimals: number,
        token1Decimals: number
    ): { token0Amount: number; token1Amount: number } {
        const Q96 = 1n << 96n; // Equivalent to 2^96 in BigInt

        // Convert ticks to sqrtPriceX96 values
        const sqrtPriceLowerX96 = BigInt(Math.floor(Math.sqrt(1.0001 ** tickLower) * Number(Q96)));
        const sqrtPriceUpperX96 = BigInt(Math.floor(Math.sqrt(1.0001 ** tickUpper) * Number(Q96)));

        let token0Amount = 0n;
        let token1Amount = 0n;

        if (sqrtPriceX96 <= sqrtPriceLowerX96) {
            // Price is below the lower bound → Only token0 is present
            token0Amount = (liquidity * (Q96 * Q96 / sqrtPriceLowerX96 - Q96 * Q96 / sqrtPriceUpperX96)) / Q96;
        } else if (sqrtPriceX96 >= sqrtPriceUpperX96) {
            // Price is above the upper bound → Only token1 is present
            token1Amount = (liquidity * (sqrtPriceUpperX96 - sqrtPriceLowerX96)) / Q96;
        } else {
            // Price is within range → Both token0 and token1 are present
            token0Amount = (liquidity * (Q96 * Q96 / sqrtPriceX96 - Q96 * Q96 / sqrtPriceUpperX96)) / Q96;
            token1Amount = (liquidity * (sqrtPriceX96 - sqrtPriceLowerX96)) / Q96;
        }

        // Convert token amounts to decimal format using token decimals
        return {
            token0Amount: Number(token0Amount) / 10 ** token0Decimals,
            token1Amount: Number(token1Amount) / 10 ** token1Decimals
        };
    }



    /**
     * Fetch unclaimed fees for a Uniswap V3 position directly from The Graph
     * @param positionId - The Uniswap V3 position ID
     * @returns Object with amount0 and amount1 representing unclaimed fees in token units
     */
    async fetchUnclaimedFeesFromGraph(positionId: number): Promise<{ amount0: number; amount1: number }> {
        const query = `
        query getUnclaimedFees($positionId: ID!) {
          position(id: $positionId) {
            id
            tokensOwed0
            tokensOwed1
            pool {
              token0 {
                decimals
              }
              token1 {
                decimals
              }
            }
          }
        }`;

        const variables = { positionId };

        const apiKey = process.env.REACT_APP_GRAPH_API_KEY;
        const GRAPH_API_URL_1 = `https://gateway.thegraph.com/api/${apiKey}/subgraphs/id/FbCGRftH4a3yZugY7TnbYgPJVEv2LvMT6oF1fxPe9aJM`


        try {
            const response = await axios.post(
                GRAPH_API_URL_1,
                { query, variables },
                { timeout: 5000 } // 5-second timeout
            );


            if (!response.data || !response.data.data.position) {
                console.warn(`⚠️ Position ${positionId} not found in The Graph when fetching unclaimed fees.`);
                return { amount0: 0, amount1: 0 };
            }

            const position = response.data.data.position;
            const token0Decimals = Number(position.pool.token0.decimals) || 18;
            const token1Decimals = Number(position.pool.token1.decimals) || 18;

            // Convert on-chain values to human-readable with proper decimal adjustment
            const amount0 = Number(position.tokensOwed0) / 10 ** token0Decimals;
            const amount1 = Number(position.tokensOwed1) / 10 ** token1Decimals;

            // Apply sanity checks - unclaimed fees shouldn't be unreasonably large
            const sanitizedAmount0 = isNaN(amount0) ? 0 : amount0;
            const sanitizedAmount1 = isNaN(amount1) ? 0 : amount1;

            return {
                amount0: sanitizedAmount0,
                amount1: sanitizedAmount1
            };
        } catch (error) {
            console.error(
                `❌ Error fetching unclaimed fees from The Graph for position ${positionId}:`,
                error instanceof Error ? error.message : String(error)
            );

            // Return zeros on error to avoid breaking the UI
            return {
                amount0: 0,
                amount1: 0
            };
        }
    }


}

export default new SubgraphUniswapService();