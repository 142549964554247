import React, {useContext, useState} from "react";
import {UniswapPosition} from "../../../../model/crypto/CryptoModels";
import {Col} from "react-bootstrap";
import {ThemeContext} from "../../../../App";


const PriceRangeIndicatorComponent: React.FC<{ position: UniswapPosition }> = ({position}) => {
    const [feesToLiquidityRatio, setFeesToLiquidityRatio] = useState<number>(0);
    const [isInRange, setIsInRange] = useState(true);
    const context = useContext(ThemeContext);
    const isDarkTheme = context?.theme === "dark";

    const [token0Active, setToken0Active] = useState(true)
    const [token1Active, setToken1Active] = useState(false)

    // Extract price data
    const lower = position.priceRange?.lower.token1PerToken0;
    const upper = position.priceRange?.upper.token1PerToken0;
    const current = position.currentPrice?.token1PerToken0;

    // Extract price data in token0 terms
    const lowerToken0 = position.priceRange?.lower.token0PerToken1;
    const upperToken0 = position.priceRange?.upper.token0PerToken1;
    const currentToken0 = position.currentPrice?.token0PerToken1;

    // Extend width by 10% on each side
    const minValue = Math.min(lower, upper, current);
    const maxValue = Math.max(lower, upper, current);
    const extendedMin = minValue - (maxValue - minValue) * 0.1;
    const extendedMax = maxValue + (maxValue - minValue) * 0.1;

    // Calculate positions as percentages
    const lowerPos = ((lower - extendedMin) / (extendedMax - extendedMin)) * 100;
    const upperPos = ((upper - extendedMin) / (extendedMax - extendedMin)) * 100;
    const currentPos = ((current - extendedMin) / (extendedMax - extendedMin)) * 100;


    // Determine the fill color based on the current price
    //const fillColor = current >= lower && current <= upper ? "#2ECC71" : "#FF0044"; // Green if current >= lower, otherwise Red
    const fillColor = rangeColor(position.priceRange?.lower.token1PerToken0, position.priceRange?.upper.token1PerToken0, position.currentPrice?.token1PerToken0)


    // Theme-based styling
    const backgroundColor = isDarkTheme ? "#222" : "#DDD";
    const borderColor = fillColor
    /*const markerColor = isDarkTheme ? "white" : "black";*/
    const markerColor = fillColor
    const markerColorCurrent = "#1d74f7"
    const textColor = isDarkTheme ? "white" : "black";


    function rangeColor(lower: number, upper: number, price: number): string {

        if (price < lower || price > upper)
            return '#DC3545'

        const delta = (upper - lower) * 0.15

        if ((price > lower && price < (lower + delta)) || (price > (upper - delta) && price < upper))
            return '#FFC107'

        else return '#28A745'

    }


    function handleToke0Select() {
        setToken0Active(true)
        setToken1Active(false)
    }

    function handleToke1Select() {
        setToken1Active(true)
        setToken0Active(false)
    }



    return (
    <Col className="d-flex align-items-center justify-content-center px-2 mt-2 mb-4">
        <div
            className="position-relative"
            style={{
                width: "100%",
                maxWidth: "500px",
                height: "12px",
                backgroundColor: backgroundColor,
                borderRadius: "6px",
                border: `2px solid ${borderColor}`,
            }}
        >
            {/* Filled Range */}
            <div
                className="position-absolute"
                style={{
                    height: "100%",
                    backgroundColor: fillColor,
                    borderRadius: "6px",
                    left: `${lowerPos}%`,
                    width: `${upperPos - lowerPos}%`,
                }}
            />

            {/* Lower Marker */}
            <div
                className="position-absolute"
                style={{
                    top: "50%",
                    left: `${lowerPos}%`,
                    transform: "translate(-50%, -50%)",
                    width: "14px",
                    height: "28px",
                    backgroundColor: markerColor,
                    borderRadius: "6px",
                    border: `2px solid ${markerColor}`,
                }}
            />

            {/* Upper Marker */}
            <div
                className="position-absolute"
                style={{
                    top: "50%",
                    left: `${upperPos}%`,
                    transform: "translate(-50%, -50%)",
                    width: "14px",
                    height: "28px",
                    backgroundColor: markerColor,
                    borderRadius: "6px",
                    border: `2px solid ${markerColor}`,
                }}
            ></div>

            {/* Current Price Marker */}
            <div
                className="position-absolute"
                style={{
                    top: "50%",
                    left: `${currentPos}%`,
                    transform: "translate(-50%, -50%)",
                    width: "14px",
                    height: "28px",
                    backgroundColor: markerColorCurrent,
                    borderRadius: "6px",
                    border: `2px solid ${markerColor}`,
                }}
            />
        </div>
    </Col>
)
}


export default PriceRangeIndicatorComponent;