import React, {useEffect, useState} from "react";
import './StockFeed.css';
import {IStock, IStockComment} from "../../../model/Stock";
import {FloatingLabel, Modal, OverlayTrigger, Spinner} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import {FaRegShareFromSquare} from "react-icons/fa6";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from 'react-bootstrap/Image';
import {Formik, FormikValues} from 'formik';
import StockCommentsService from "../../../service/StockCommentsService";
import toast from "react-hot-toast";
import {auto} from "@popperjs/core";
import moment from "moment";
import {FiEdit3} from "react-icons/fi";
import {AiOutlineLike} from "react-icons/ai";
import {BiRepost} from "react-icons/bi";
import Dropdown from 'react-bootstrap/Dropdown';
import {RxShare2} from "react-icons/rx";
import {MdOutlineQuickreply} from "react-icons/md";
import {Tooltip} from 'react-bootstrap';

import SignInComponent from "../../public/sign-in/SignInComponent";
import * as yup from "yup";
import AuthService from "../../../service/auth/AuthService";


const StockFeedComponent = (props: any) => {


    const stock: IStock = props;
    const [comments, setComments] = useState<IStockComment[]>([]);
    const [currentComment, setCurrentComment] = useState<IStockComment>();
    const [index, setIndex] = useState<number>();


    useEffect(() => {
        StockCommentsService.getAllForStockId(stock.id)
            .then(resp => setComments(resp.data))
    }, [])

    useEffect(() => {
        if (comments !== undefined) {
            setComments(comments.sort((a, b) =>
                (a.createdAt && b.createdAt && a.createdAt > b.createdAt) ? -1 : 1))
        }
    }, [comments]);


    const renderTooltip = (props: any) => (
        <Tooltip id={`tooltip-${props}`} {...props} >
            {props}
        </Tooltip>
    );

    function RenderArrayOfComments() {
        const listItems = comments?.map((comment: IStockComment, i) => {
            return (
                <Row key={i}>

                    <div className="d-flex mb-1">
                        <a className="flex-shrink-0" href="#">
                            <Image roundedCircle className="img-sm"
                                   src="https://bootdey.com/img/Content/avatar/avatar2.png"/>
                        </a>
                        <div className="flex-grow-1 ms-3">
                            <div>
                                <a href={'/user/' + comment.userId}
                                   className="btn-link fw-medium">{comment.firstName} {comment.lastName}</a>
                                <p className="text-muted text-sm pt-1 mb-2"><i className="fa fa-mobile fa-lg"></i>
                                    @{comment.username} &nbsp;-&nbsp; {moment(comment.createdAt).fromNow()}
                                    {comment.updatedAt &&
                                        <em>&nbsp; ( updated {moment(comment.updatedAt).fromNow()} )</em>
                                    }
                                </p>
                            </div>

                            <p>{comment.message}</p>

                            <div className="d-flex flex-row justify-content-around text-black mx-4">
                                <a className="comment-icon" href="#" style={{verticalAlign: "middle"}}>
                                    <div><AiOutlineLike size={16}/>&nbsp;12</div>
                                </a>
                                <OverlayTrigger
                                    placement="right"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderTooltip("Reply")}
                                >
                                    <a href="#" className="comment-icon"><MdOutlineQuickreply size={16}/></a>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    placement="right"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderTooltip("Repost")}
                                >
                                    <a href="#" className="comment-icon"><BiRepost size={19}/></a>
                                </OverlayTrigger>

                                <OverlayTrigger
                                    placement="right"
                                    delay={{show: 250, hide: 400}}
                                    overlay={renderTooltip("Share")}
                                >
                                    <Dropdown className="comment-icon">
                                        <button style={{borderStyle: "hidden", background: "none"}}
                                                type="button"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                            <RxShare2 size={16}/>
                                        </button>
                                        <ul className="dropdown-menu">
                                            <li><a className="dropdown-item" href="#">Copy likn to Post</a></li>
                                            <li><a className="dropdown-item" href="#">Embeded Post</a></li>
                                        </ul>
                                    </Dropdown>
                                </OverlayTrigger>
                                {AuthService.isLoggedIn() && AuthService.getCurrentUser().id == comment.userId &&
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{show: 250, hide: 400}}
                                        overlay={renderTooltip("Edit")}
                                    >
                                        <span role='button' className="comment-icon"
                                              onClick={() => {
                                                  setCurrentComment(comment);
                                                  setIndex(i);
                                                  setEditCommentModalShow(true);
                                              }}>
                                            <FiEdit3 size={16}/>
                                        </span>
                                    </OverlayTrigger>
                                }

                            </div>
                            <hr/>
                        </div>
                    </div>
                </Row>


            );
        });
        return <div className="container">{listItems}</div>;
    }

    const validationSchema = yup.object().shape({
        message: yup.string().required().min(10)
    });


    const [showLogin, setShowLogin] = useState(false);
    const handleCloseLogin = () => setShowLogin(false);
    const handleShowLogin = () => setShowLogin(true);
    const [commentEditModalShow, setEditCommentModalShow] = useState(false);


    const updateCommentsArray = (comment: IStockComment) => {
        const newComments = comments.map((c) => {
            if (c.id === comment.id) {
                return {...c, message: comment.message}
            }
            return c;
        });
        setComments(newComments);
    }


    const content =

        <>

            <Formik
                validateOnMount={true}
                validationSchema={validationSchema}
                onSubmit={(values, {resetForm, setSubmitting}) => {
                    if (!AuthService.isLoggedIn()) {
                        setSubmitting(false)
                        handleShowLogin()
                    } else {
                        StockCommentsService.postComment(values as IStockComment)
                            .then((response) => {
                                setSubmitting(false)
                                toast.success("You comment has been posted!")
                                updateCommentsArray(values as IStockComment)
                                setEditCommentModalShow(false);
                                resetForm()
                            })
                    }
                }}
                enableReinitialize={true}
                initialValues={currentComment!}
            >
                {({
                      isSubmitting,
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      initialValues,
                      touched,
                      errors,
                      isValid
                  }) => (
                    <Modal centered show={commentEditModalShow} onHide={() => setEditCommentModalShow(false)}
                           {...props}
                           size="md"
                           aria-labelledby="edit-comment-modal"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="edit-comment-modal">
                                Edit your comment
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>


                            <Form onSubmit={handleSubmit}>
                                <FloatingLabel controlId="addCommentTextarea" label="Comments">
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        style={{minHeight: '100px', height: auto, overflow: "hidden"}}
                                        value={values?.message}
                                        onBlur={handleBlur} // This apparently updates `touched`
                                        onChange={handleChange}
                                        //isValid={touched.message && !errors.message}
                                        //isInvalid={touched.message && !!errors.message}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{errors.message as string}</Form.Control.Feedback>
                                </FloatingLabel>
                                <Row className="mb-3">
                                    <Col className="col-6 col-md-8">
                                        <Form.Text id="passwordHelpBlock" muted>
                                            You can reference stock with #stock_symbol or user by @username
                                        </Form.Text>
                                    </Col>
                                    <Col className="col-6 col-md-4 d-flex">
                                        <Button size="sm" variant="danger" className="mt-2 me-2 float-end nowr"
                                                type="submit"
                                                style={{overflow: "auto", whiteSpace: "nowrap"}}
                                                title="Delete"
                                        >
                                            Delete
                                        </Button>
                                        <Button size="sm" variant="primary" className="mt-2 float-end nowr"
                                                type="submit"
                                                style={{overflow: "auto", whiteSpace: "nowrap"}}
                                                disabled={isSubmitting || !isValid || !touched}>
                                            {isSubmitting ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="me-2"
                                                    />
                                                    Please wait...
                                                </> : <span>Update</span>}
                                        </Button>
                                    </Col>

                                </Row>
                            </Form>


                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </Formik>

            <div className="comments-panel">
                <div className="comments-panel-body">

                    <Modal show={showLogin} onHide={handleCloseLogin} centered  {...props} size="md">
                        <Modal.Header closeButton>
                            <Modal.Title>Please sign-in to add comments</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="m-1 m-md-3 px-2 px-md-4"><SignInComponent redirectTo={'/stock/' + stock.id}/></div>
                        </Modal.Body>
                    </Modal>


                    <Formik
                        validateOnMount={true}
                        validationSchema={validationSchema}
                        onSubmit={(values, {resetForm, setSubmitting}) => {
                            if (!AuthService.isLoggedIn()) {
                                setSubmitting(false)
                                handleShowLogin()
                            } else {
                                StockCommentsService.postComment({stockId: stock.id, message: values.message})
                                    .then((response) => {
                                        setSubmitting(false)
                                        toast.success("You comment has been posted!")
                                        /*setComments(old => [...old, response!.data]);*/
                                        updateCommentsArray(response!.data)
                                        resetForm()
                                    })
                            }
                        }}
                        enableReinitialize={true}
                        initialValues={{
                            stockId: stock.id,
                            message: ''
                        }}
                    >
                        {({
                              isSubmitting,
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              initialValues,
                              touched,
                              errors,
                              isValid
                          }) => (
                            <Form onSubmit={handleSubmit}>
                                <FloatingLabel controlId="addCommentTextarea" label="Comments">
                                    <Form.Control
                                        as="textarea"
                                        name="message"
                                        placeholder="Leave a comment here"
                                        style={{minHeight: '100px', height: auto, overflow: "hidden"}}
                                        value={values.message}
                                        onBlur={handleBlur} // This apparently updates `touched`
                                        onChange={handleChange}
                                        //isValid={touched.message && !errors.message}
                                        //isInvalid={touched.message && !!errors.message}
                                    />
                                    <Form.Control.Feedback
                                        type="invalid">{errors.message as string}</Form.Control.Feedback>
                                </FloatingLabel>
                                <Row className="mb-3">
                                    <Col className="col-8 col-md-10">
                                        <Form.Text id="passwordHelpBlock" muted>
                                            You can reference stock with #stock_symbol or user by @username
                                        </Form.Text>
                                    </Col>
                                    <Col className="col-4 col-md-2">
                                        <Button size="sm" variant="primary" className="mt-2 float-end nowr"
                                                type="submit"
                                                style={{overflow: "auto", whiteSpace: "nowrap"}}
                                                disabled={isSubmitting || !isValid || !touched}>
                                            {isSubmitting ?
                                                <>
                                                    <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        className="me-2"
                                                    />
                                                    Please wait...
                                                </> : <span><FaRegShareFromSquare className="mb-1"/> Share</span>}
                                        </Button>
                                    </Col>

                                </Row>
                            </Form>
                        )}
                    </Formik>


                    <Row>
                        <RenderArrayOfComments/>
                    </Row>
                </div>
            </div>

        </>

    return (
        <>
            {content}
        </>
    );

}

export default StockFeedComponent;