class FormatUtils {

    NumberFormatter = (value: any, decimal: any) => {
        return parseFloat(parseFloat(value).toFixed(decimal)).toLocaleString(
            "en-IN",
            {
                useGrouping: true,
            }
        );
    };



    // Format currency values
     formatCurrency = (value: number): string => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value || 0);
    };



    /**
     * Function takes a number as input and formats it based on the following rules:
     * If the number has a real part (i.e., it has a non-zero fractional part), format it to 2 decimal places.
     * If the number does not have a real part (i.e., it is an integer or has a zero fractional part),
     * format it to show at least 2 non-zero digits after any number of zeros.
     */
    formatNumber(num: number | undefined): string {
        // Check if the input is undefined
        if (typeof num === 'undefined') {
            return 'undefined';
        }

        // Handle zero explicitly
        if (num === 0 || Math.abs(num) < 1e-12) {
            return "0.00"; // Zero is formatted with 2 decimal places
        }

        // Use scientific notation for very small numbers (< 1e-9)
        if (Math.abs(num) < 1e-9) {
            return num.toExponential(2); // 2 decimal places in scientific notation
        }

        // Check if the number has a real (fractional) part
        const hasRealPart = num % 1 !== 0;

        if (hasRealPart) {
            // For numbers with significant digits after decimal point
            // If it's a small number with leading zeros, preserve those
            if (Math.abs(num) < 0.01) {
                // Find the position of the first non-zero digit
                const numStr = Math.abs(num).toString();
                const match = numStr.match(/0\.0*/);
                const leadingZerosCount = match ? match[0].length - 2 : 0;

                // Format to show at least 3 significant digits
                const significantDigits = leadingZerosCount + 3;
                return num.toFixed(significantDigits);
            }

            // Standard formatting for numbers with a decimal part (2 decimal places)
            return num.toFixed(2);
        } else {
            // For integers, we need to show at least 2 non-zero digits after any number of zeros
            const absNum = Math.abs(num);
            let precision = 2; // Start with 2 digits of precision
            let result = '';

            // Find the appropriate precision to show at least 2 non-zero digits
            const MAX_PRECISION = 15;

            while (precision <= MAX_PRECISION) {
                // Try with current precision
                const formatted = absNum.toFixed(precision);
                // Count non-zero digits after decimal
                const decimalPart = formatted.split('.')[1];
                const nonZeroCount = decimalPart.replace(/0/g, '').length;

                if (nonZeroCount >= 2) {
                    // We've found at least 2 non-zero digits
                    result = (num < 0 ? '-' : '') + formatted;
                    break;
                }
                precision++;
            }

            // If we reached max precision without finding 2 non-zero digits
            if (result === '') {
                result = (num < 0 ? '-' : '') + absNum.toFixed(MAX_PRECISION);
            }

            return result;
        }
    }


    removeDuplicatesByAttribute<T>(array: T[], attribute: keyof T): T[] {
        const countMap = new Map<T[keyof T], number>();

        // Count occurrences of each attribute value
        for (const item of array) {
            const key = item[attribute];
            countMap.set(key, (countMap.get(key) || 0) + 1);
        }

        // Filter out items that have duplicates
        return array.filter(item => countMap.get(item[attribute]) === 1);
    }


}


export default new FormatUtils();