import React from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CryptoBanner = () => {
    // Sample data for the chart
    const data = [
        { name: 'Jan', liquidity: 65, yield: 30 },
        { name: 'Feb', liquidity: 50, yield: 45 },
        { name: 'Mar', liquidity: 40, yield: 50 },
        { name: 'Apr', liquidity: 60, yield: 35 },
        { name: 'May', liquidity: 45, yield: 40 },
        { name: 'Jun', liquidity: 70, yield: 25 },
    ];

    return (
        <div className="bg-gray-100 p-4">
            <div className="max-w-6xl mx-auto">
                <div className="border rounded shadow-sm bg-white">
                    {/* Header */}
                    <div className="bg-blue-600 text-white p-3 flex justify-between items-center rounded-t">
                        <div className="flex items-center">
                            <h4 className="text-xl font-medium m-0">Crypto Dashboard</h4>
                            <div className="ml-4">
                                <span className="bg-white text-blue-600 px-2 py-1 rounded-full text-xs font-semibold mr-1">ETH</span>
                                <span className="bg-white text-blue-600 px-2 py-1 rounded-full text-xs font-semibold mr-1">BTC</span>
                                <span className="bg-white text-blue-600 px-2 py-1 rounded-full text-xs font-semibold">UNI</span>
                            </div>
                        </div>
                    </div>

                    <div className="p-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {/* Left column - info card */}
                            <div>
                                <div className="border rounded shadow-sm">
                                    <div className="bg-gray-100 p-3 border-b">
                                        <h5 className="text-lg font-medium m-0">Manage Your Crypto Liquidity Pools</h5>
                                    </div>
                                    <div className="p-4">
                                        <p className="text-gray-600 mb-4">
                                            Monitor and optimize your Uniswap liquidity positions
                                            in real-time with our comprehensive dashboard tools.
                                            Track performance, analyze returns, and maximize yield.
                                        </p>
                                        <button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 transition-colors">
                                            Get Started
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Right column - chart card */}
                            <div>
                                <div className="border rounded shadow-sm">
                                    <div className="bg-gray-100 p-3 border-b">
                                        <h5 className="text-lg font-medium m-0">Real-Time Analytics</h5>
                                    </div>
                                    <div className="p-4">
                                        <div className="h-40">
                                            <ResponsiveContainer width="100%" height="100%">
                                                <BarChart data={data}>
                                                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                                                    <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                                                    <YAxis hide={true} />
                                                    <Tooltip />
                                                    <Bar dataKey="liquidity" fill="#2563eb" radius={[2, 2, 0, 0]} />
                                                    <Line type="monotone" dataKey="yield" stroke="#16a34a" strokeWidth={2} />
                                                </BarChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="bg-gray-800 p-2 rounded-b"></div>
                </div>
            </div>
        </div>
    );
};

export default CryptoBanner;