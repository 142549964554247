import React, {useContext, useState} from "react";
import {UniswapPosition} from "../../../../model/crypto/CryptoModels";
import {Badge, Col} from "react-bootstrap";
import TokenUtilsService from "../../../../service/utils/TokenUtilsService";
import {FaCircle} from "react-icons/fa6";
import Row from "react-bootstrap/Row";

const PositionHeader: React.FC<{ position: UniswapPosition }> = ({position}) => {

    const isInRange = position.currentPrice?.token1PerToken0 >= position.priceRange?.lower.token1PerToken0 &&
        position.currentPrice?.token1PerToken0 <= position.priceRange?.upper?.token1PerToken0

    console.log()


    function rangeColor(lower: number, upper: number, price: number): string {

        if (price < lower || price > upper)
            return 'danger'

        const delta = (upper - lower) * 0.15

        if ((price > lower && price < (lower + delta)) || (price > (upper - delta) && price < upper))
            return 'warning'

        else return 'success'

    }


    return (
        <Row>
            <Col className="d-flex d-inline-block">

                <div>
                    <img src="/images/crypto/arbitrum.png" height={54} width={54} className="me-3"
                         alt="Arbitrum network"/>
                </div>

                <div className="d-flex flex-column">
                    <div className="d-flex flex-row">
                        <h5 className="mt-auto">{TokenUtilsService.normalizeSymbol(position.token0?.symbol)} {position.token0 && "/"} {TokenUtilsService.normalizeSymbol(position.token1?.symbol)} </h5>
                        &nbsp;&nbsp;<span><Badge bg="secondary">v3</Badge></span>
                        &nbsp;&nbsp;<span><Badge bg="secondary">{position.fee / 1000}%</Badge></span>
                    </div>
                    <div className={`d-flex align-items-center text-${rangeColor(position.priceRange?.lower.token1PerToken0, position.priceRange?.upper.token1PerToken0, position.currentPrice?.token1PerToken0)}`}>
                        <FaCircle size={8} className="me-2"/>
                        <span>{isInRange ? "Price in range (earning fees)" : "Out of range"}</span>
                    </div>
                </div>



            </Col>
        </Row>
    )


}


export default PositionHeader;