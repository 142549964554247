import React, {useContext, useEffect, useState} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CardBody} from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import Card from "react-bootstrap/Card";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {MetaMaskInpageProvider} from "@metamask/providers";
import {useWallet} from "../../context/WalletContext";
import Layout from "../../layout/Layout";
import {ThemeContext} from "../../../App";

// Import Wallet Context

declare global {
    interface Window {
        ethereum?: MetaMaskInpageProvider & { providers?: MetaMaskInpageProvider[] };
    }
}

export const ConnectWalletComponent: React.FC = () => {
    const context = useContext(ThemeContext);
    const {wallet, setWallet, disconnectWallet} = useWallet(); // Use Wallet Context
    const [metaMaskProvider, setMetaMaskProvider] = useState<MetaMaskInpageProvider | null>(null);
    const [uniswapProvider, setUniswapProvider] = useState<MetaMaskInpageProvider | null>(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (window.ethereum?.providers) {
            window.ethereum.providers.forEach((provider) => {
                if (provider.isMetaMask) {
                    setMetaMaskProvider(provider);
                } else {
                    setUniswapProvider(provider);
                }
            });
        } else if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                setMetaMaskProvider(window.ethereum);
            } else {
                setUniswapProvider(window.ethereum);
            }
        }
    }, []);

    const connectWallet = async (provider: MetaMaskInpageProvider | null, walletName: string): Promise<void> => {
        setShowModal(false);

        if (!provider) {
            alert(`No ${walletName} found. Please install it first.`);
            return;
        }

        try {
            const accounts = (await provider.request({method: "eth_requestAccounts"})) as string[];
            if (accounts.length > 0) {
                setWallet(accounts[0]); // Save in context & localStorage
            }
        } catch (error) {
            console.error(`Error connecting to ${walletName}:`, error);
        }
    };

    return (
        <>
            <Row className="g-3">
                <Col sm={12}>
                    <Card>
                        <CardHeader>
                            <h4>My Connected Wallets</h4>
                        </CardHeader>
                        <CardBody>
                            {wallet ? (
                                <>
                                    <p>Connected: {wallet}</p>
                                    <Button className="btn-danger fw-semibold"
                                        onClick={disconnectWallet}
                                    >
                                        Disconnect
                                    </Button>
                                </>
                            ) : (
                                <Button onClick={() => setShowModal(true)} >
                                    Connect Wallet
                                </Button>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {/* Modal for Wallet Selection */}
            <Modal show={showModal} data-bs-theme={context?.theme} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="text-body">Select a Wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button
                        onClick={() => connectWallet(metaMaskProvider, "MetaMask")}
                        style={{width: "100%", marginBottom: "10px"}}
                    >
                        Connect with MetaMask
                    </Button>
                    <Button
                        onClick={() => connectWallet(uniswapProvider, "Uniswap")}
                        style={{width: "100%"}}
                    >
                        Connect with Uniswap
                    </Button>
                </Modal.Body>
            </Modal>
        </>
    );
};


export default ConnectWalletComponent