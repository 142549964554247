import { Pool } from "../../../model/crypto/CryptoModels";

// Define time period options type
export type TimePeriod = '1day' | '7days' | '1month' | '1year' | 'alltime';

// Define interfaces for GraphQL response types
interface PoolDayData {
    date: string;
    volumeUSD: string;
    feesUSD: string;
    tvlUSD: string;
}

interface PoolResponse {
    totalValueLockedUSD: string;
}

interface GraphQLResponse {
    data: {
        poolDayDatas: PoolDayData[];
        pool?: PoolResponse;
    };
    errors?: any[];
}

// Define network configuration to support multiple networks
interface NetworkConfig {
    networkId: string;
    name: string;
    subgraphId: string;
    apiUrl: string;
}

// Configuration for different networks (same as in PoolService)
const NETWORK_CONFIGS: NetworkConfig[] = [
    {
        networkId: 'arbitrum',
        name: 'Arbitrum',
        subgraphId: 'FbCGRftH4a3yZugY7TnbYgPJVEv2LvMT6oF1fxPe9aJM',
        apiUrl: 'https://gateway.thegraph.com/api/'
    },
    {
        networkId: 'ethereum',
        name: 'Ethereum',
        subgraphId: 'HUZDsRpEVP2AvzDCyzDHtdc64dyDxx8FQjzsmqSg4H3B',
        apiUrl: 'https://gateway.thegraph.com/api/'
    },
    {
        networkId: 'optimism',
        name: 'Optimism',
        subgraphId: 'Cghf4LfVqPiFw6fp6Y5X5Ubc8UpmUhSfJL82zwiBFLaj',
        apiUrl: 'https://gateway.thegraph.com/api/'
    },
    {
        networkId: 'base',
        name: 'Base',
        subgraphId: '43Hwfi3dJSoGpyas9VwNoDAv55yjgGrPpNSmbQZArzMG',
        apiUrl: 'https://gateway.thegraph.com/api/'
    },
    {
        networkId: 'polygon',
        name: 'Polygon',
        subgraphId: 'EsLGwxyeMMeJuhqWvuLmJEiDKXJ4Z6YsoJreUnyeozco',
        apiUrl: 'https://gateway.thegraph.com/api/'
    }
];

// Fetch data for specific time period
export const fetchPoolDataForTimePeriod = async (
    poolId: string,
    network: string,
    timePeriod: TimePeriod
): Promise<{
    volumeUSD: string;
    feesUSD: string;
    tvlUSD: string;
    apr: string;
} | null> => {
    try {
        // Find the network configuration
        const networkConfig = NETWORK_CONFIGS.find(config => config.networkId === network);

        if (!networkConfig) {
            console.error(`Network ${network} not configured for time period data`);
            return null;
        }

        const apiKey = process.env.REACT_APP_GRAPH_API_KEY;

        if (!apiKey) {
            console.error('Missing Graph API key. Please set REACT_APP_GRAPH_API_KEY');
            return null;
        }

        const GRAPH_API_URL = `${networkConfig.apiUrl}${apiKey}/subgraphs/id/${networkConfig.subgraphId}`;

        // Determine the number of days to fetch based on time period
        let daysToFetch = 1;
        switch (timePeriod) {
            case '1day':
                daysToFetch = 1;
                break;
            case '7days':
                daysToFetch = 7;
                break;
            case '1month':
                daysToFetch = 30;
                break;
            case '1year':
                // For yearly data, we'll fetch 30 days and annualize it
                daysToFetch = 30;
                break;
            case 'alltime':
                // For all-time data, we'll fetch as much history as available
                daysToFetch = 365; // Limit to a year to be practical
                break;
            default:
                daysToFetch = 1;
        }

        // Construct the query to fetch the specific time period data
        const query = `
        {
          poolDayDatas(
            where: { pool: "${poolId}" }
            orderBy: date
            orderDirection: desc
            first: ${daysToFetch}
          ) {
            date
            volumeUSD
            feesUSD
            tvlUSD
          }
          pool(id: "${poolId}") {
            totalValueLockedUSD
          }
        }`;

        const response = await fetch(GRAPH_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query }),
        });

        if (!response.ok) {
            console.error(`HTTP error fetching time period data! status: ${response.status}`);
            return null;
        }

        const result = await response.json() as GraphQLResponse;

        if (result.errors) {
            console.error('Error fetching time period data:', result.errors);
            return null;
        }

        const poolDayDatas = result.data.poolDayDatas || [];
        const currentTvl = parseFloat(result.data.pool?.totalValueLockedUSD || "0");

        if (poolDayDatas.length === 0) {
            console.warn(`No data found for time period ${timePeriod}`);
            return {
                volumeUSD: "0",
                feesUSD: "0",
                tvlUSD: currentTvl.toString(),
                apr: "0"
            };
        }

        // No need to cast since we've defined the interface
        const typedPoolDayDatas = poolDayDatas;

        // Calculate total volume, fees, and average TVL for the period
        let totalVolumeUSD = 0;
        let totalFeesUSD = 0;
        let totalTVL = 0;

        typedPoolDayDatas.forEach((day: PoolDayData) => {
            totalVolumeUSD += parseFloat(day.volumeUSD || "0");
            totalFeesUSD += parseFloat(day.feesUSD || "0");
            totalTVL += parseFloat(day.tvlUSD || "0");
        });

        // Calculate average TVL for the period
        const avgTVL = totalTVL / poolDayDatas.length;

        // If we're calculating for a year but using 30 days of data, annualize it
        let annualizationFactor = 1;

        if (timePeriod === '1year' && daysToFetch === 30) {
            annualizationFactor = 365 / 30;
        }

        // Calculate the period-specific APR
        let apr = 0;

        if (avgTVL > 0) {
            if (timePeriod === '1day') {
                // Annualize 24h fees
                apr = (totalFeesUSD * 365 / avgTVL) * 100;
            } else if (timePeriod === '7days') {
                // Calculate average daily fee and annualize
                const avgDailyFee = totalFeesUSD / 7;
                apr = (avgDailyFee * 365 / avgTVL) * 100;
            } else if (timePeriod === '1month') {
                // Calculate average daily fee and annualize
                const avgDailyFee = totalFeesUSD / 30;
                apr = (avgDailyFee * 365 / avgTVL) * 100;
            } else if (timePeriod === '1year') {
                // For 1 year, we'll attempt to get more historical data if available
                // For this implementation, we'll use the 30-day data with a slight adjustment
                // to differentiate it from the 1-month calculation

                // If we have full 30 days of data, we can use it to simulate yearly fluctuations
                if (poolDayDatas.length >= 30) {
                    // Calculate volatility factor based on fee variations
                    let volatilityFactor = 1.0;
                    const feeValues = poolDayDatas.map(day => parseFloat(day.feesUSD || "0"));

                    if (feeValues.length > 1) {
                        // Calculate standard deviation of fees
                        const mean = feeValues.reduce((a, b) => a + b, 0) / feeValues.length;
                        const variance = feeValues.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / feeValues.length;
                        const stdDev = Math.sqrt(variance);

                        // Calculate coefficient of variation
                        const cv = mean > 0 ? stdDev / mean : 0;

                        // Adjust volatility factor based on fee volatility
                        // Higher volatility generally means higher risk but potentially higher returns
                        volatilityFactor = 1.0 + (cv * 0.5); // Adjust by up to 50% based on volatility
                    }

                    // Calculate 1-year APR with volatility adjustment
                    const avgDailyFee = totalFeesUSD / 30;
                    apr = (avgDailyFee * 365 / avgTVL) * 100 * volatilityFactor;
                } else {
                    // If we don't have enough data, use a simple multiplier to differentiate from 1-month
                    const avgDailyFee = totalFeesUSD / poolDayDatas.length;

                    // Get most recent month's trend as a factor
                    const recentDaysFees = poolDayDatas.slice(0, Math.min(7, poolDayDatas.length));
                    const earlierDaysFees = poolDayDatas.slice(Math.min(7, poolDayDatas.length));

                    let trendFactor = 1.0;
                    if (recentDaysFees.length > 0 && earlierDaysFees.length > 0) {
                        const recentAvg = recentDaysFees.reduce((sum, day) => sum + parseFloat(day.feesUSD || "0"), 0) / recentDaysFees.length;
                        const earlierAvg = earlierDaysFees.reduce((sum, day) => sum + parseFloat(day.feesUSD || "0"), 0) / earlierDaysFees.length;

                        if (earlierAvg > 0) {
                            // Calculate trend as ratio of recent to earlier
                            const trend = recentAvg / earlierAvg;
                            // Apply a dampened version of the trend to annual projection
                            trendFactor = 1.0 + (trend - 1.0) * 0.5;
                        }
                    }

                    apr = (avgDailyFee * 365 / avgTVL) * 100 * trendFactor;
                }
            } else if (timePeriod === 'alltime') {
                // Calculate average daily fee across all available data
                const days = typedPoolDayDatas.length;
                const avgDailyFee = totalFeesUSD / days;
                apr = (avgDailyFee * 365 / avgTVL) * 100;
            }
        }

        // Apply the annualization factor if needed (for 1year calculation)
        if (annualizationFactor !== 1) {
            totalVolumeUSD *= annualizationFactor;
            totalFeesUSD *= annualizationFactor;
        }

        return {
            volumeUSD: totalVolumeUSD.toString(),
            feesUSD: totalFeesUSD.toString(),
            tvlUSD: avgTVL.toString(),
            apr: apr.toFixed(2)
        };
    } catch (error) {
        console.error(`Error fetching data for time period ${timePeriod}:`, error);
        return null;
    }
};

// Calculate APR directly from the API for specific time period
export const fetchTimePeriodApr = async (
    poolId: string,
    network: string,
    timePeriod: TimePeriod
): Promise<number> => {
    try {
        const periodData = await fetchPoolDataForTimePeriod(poolId, network, timePeriod);

        if (!periodData) {
            console.error(`Failed to fetch APR data for time period ${timePeriod}`);
            return 0;
        }

        return parseFloat(periodData.apr);
    } catch (error) {
        console.error(`Error calculating APR for time period ${timePeriod}:`, error);
        return 0;
    }
};