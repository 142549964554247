import React, {Context, ContextType, useContext} from "react";
import Row from "react-bootstrap/Row";
import {Button, Carousel, Form, Image} from "react-bootstrap";
import banner1 from "./images/banners/instant-updates-banner.png";
import banner2 from "./images/banners/trade-notifications-banner.png";
import banner3light from "./images/trade-copy-light.jpg";
import banner3 from "./images/banners/copy-trading-banner.png";
import banner3dark from "./images/trade-copy-dark.png";
import stockMarket from "./images/stock-market-no-bg.png";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import LayoutPublic from "../../../components/layout/LayoutPublic";
import SignUpComponent from "../../../components/public/sign-up/SignUpComponent";
import banner4 from '././images/banner4.jpg'
import {ThemeContext} from "../../../App";
import UniswapSearchForm from "../../../components/crypto/uniswap/UniswapSearchForm";
import BlockLivesMatterPromo from "../../../components/public/BlockLivesMatterPromo";
import CryptoBanner from "../../../components/home/CryptoBanner";
import {Activity, Globe, LineChart, Search, Shield, TrendingUp, Users, Wallet} from "lucide-react";


function carousel(context: { theme: string; setTheme: (theme: string) => void; } | undefined) {

    return (
        <>

            <Carousel className="d-none d-lg-block d-lg-block"
                      fade
                      controls={true}
                      indicators={true}
                      indicatorLabels={["111", "222"]}
                      keyboard={true}
                      touch={true}
            >

                <Carousel.Item>
                    <Image alt="First slide" src={banner1} className="img-fluid w-100"/>
                    <Carousel.Caption>
                        <div className="main-banner-background text-secondary">
                            <h2 className="display-5 fw-normal">Instant updates</h2>
                            <h5 className="fw-medium">Receive real-time notifications of your friend&apos;s trading
                                activity.</h5>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>


                <Carousel.Item>
                    <Image alt="First slide" src={banner2} className="w-100"/>
                    <Carousel.Caption>
                        <div className="main-banner-background text-secondary">
                            <h2 className="display-5 fw-normal">Real-time portfolios</h2>
                            <h5 className="fw-medium">Monitor your friend&apos;s investment accounts in real-time.</h5>
                        </div>
                        <div className="d-block d-lg-none">
                            <h3>Create communities</h3>
                            <p>Follow group of traders to receive track of median activity.</p>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>

                <Carousel.Item>
                    <Image alt="First slide" src={banner3} className="w-100"/>
                    <Carousel.Caption>
                        <div className="main-banner-background text-secondary">
                            <h2 className="display-5 fw-normal">Copy trading</h2>
                            <h5 className="fw-medium">Automatically follow the trades of successful traders to utilize
                                their expertise.</h5>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>


            </Carousel>
        </>
    );
}


const Homepage = () => {
    const context = useContext(ThemeContext);
    return (
        <>
            <LayoutPublic fluid={carousel(context)}>

                <Row className="">
                    <Col className="d-block d-lg-none text-center bg-body-tertiary">
                        <Image src={stockMarket} alt="Stock market"
                               className=" w-100 mb-2"></Image>
                        <h1 className="text-body-emphasis">Real-time portfolios</h1>
                        <p className="col-lg-8 mx-auto fs-5 px-4 pb-2 text-muted">
                            Track your mate&apos;s investment accounts in real-time.
                        </p>
                        <h1 className="text-body-emphasis">Share your ideas</h1>
                        <p className="col-lg-8 mx-auto px-4 fs-5 text-muted">
                            You can share your financial decisions with your mates
                            and get notifications of friends&apos; actions in exchange.
                        </p>
                    </Col>

                    {/*              <div className="b-example-divider"></div>*/}


{/*                    <Container>
                        <Row>
                            <Col>
                                <BlockLivesMatterPromo/>
                            </Col>
                        </Row>
                    </Container>*/}


                    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
                        {/* Hero Section */}
                        <div className="relative overflow-hidden bg-white">
                            <div className="max-w-7xl mx-auto">
                                <div className="relative z-10 pb-8 sm:pb-16 md:pb-20 lg:w-full lg:pb-28 xl:pb-32">
                                    <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
                                        <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                                            <div className="sm:text-center lg:text-left lg:flex lg:items-center lg:gap-8">
                                                <div className="lg:w-1/2">
                                                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl">
                                                        <span className="block text-indigo-600">Finmates</span>
                                                        <span className="block mt-2">Where Investors Connect</span>
                                                    </h1>
                                                    <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                                                        Your social network for smart investing. Connect with fellow investors, track top-performing pools, and manage your crypto portfolio across multiple wallets.
                                                    </p>
                                                </div>
                                                <div className="lg:w-1/2 mt-12 lg:mt-0">
                                                    <img
                                                        src="/api/placeholder/600/400"
                                                        alt="Investment Platform Dashboard"
                                                        className="rounded-lg shadow-xl"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Statistics Section */}
                        <div className="bg-indigo-50 py-12">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
                                    <div className="text-center">
                                        <div className="text-4xl font-bold text-indigo-600">10K+</div>
                                        <div className="mt-2 text-gray-600">Active Users</div>
                                    </div>
                                    <div className="text-center">
                                        <div className="text-4xl font-bold text-indigo-600">$50M+</div>
                                        <div className="mt-2 text-gray-600">Assets Tracked</div>
                                    </div>
                                    <div className="text-center">
                                        <div className="text-4xl font-bold text-indigo-600">1000+</div>
                                        <div className="mt-2 text-gray-600">Pools Analyzed</div>
                                    </div>
                                    <div className="text-center">
                                        <div className="text-4xl font-bold text-indigo-600">5+</div>
                                        <div className="mt-2 text-gray-600">Networks Supported</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Features Section with Images */}
                        <div className="py-16 bg-white">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="text-center">
                                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                                        Empowering Your Investment Journey
                                    </h2>
                                    <p className="mt-4 text-lg text-gray-500">
                                        Everything you need to make informed investment decisions
                                    </p>
                                </div>

                                {/* Feature 1 */}
                                <div className="mt-16">
                                    <div className="lg:flex lg:items-center lg:gap-8">
                                        <div className="lg:w-1/2">
                                            <h3 className="text-2xl font-bold text-gray-900">Find Top Pools</h3>
                                            <p className="mt-4 text-lg text-gray-500">
                                                Discover the highest-performing liquidity pools across multiple networks. Our advanced analytics help you identify the most profitable opportunities with real-time performance metrics, volatility analysis, and comprehensive risk assessment.
                                            </p>
                                            <ul className="mt-8 space-y-2">
                                                <li className="flex items-center">
                                                    <TrendingUp className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Real-time performance tracking</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <Activity className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Advanced volatility metrics</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <Shield className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Risk assessment tools</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mt-8 lg:mt-0 lg:w-1/2">
                                            <img
                                                src="/api/placeholder/600/400"
                                                alt="Pool Analytics Dashboard"
                                                className="rounded-lg shadow-xl"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Feature 2 */}
                                <div className="mt-24">
                                    <div className="lg:flex lg:items-center lg:gap-8 flex-row-reverse">
                                        <div className="lg:w-1/2">
                                            <h3 className="text-2xl font-bold text-gray-900">Multi-Wallet Portfolio</h3>
                                            <p className="mt-4 text-lg text-gray-500">
                                                Keep track of all your investments in one place. Monitor multiple wallets across different networks, analyze your portfolio performance, and make informed decisions about your investments.
                                            </p>
                                            <ul className="mt-8 space-y-2">
                                                <li className="flex items-center">
                                                    <Wallet className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Multiple wallet support</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <Globe className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Cross-chain monitoring</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <LineChart className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Portfolio analytics</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mt-8 lg:mt-0 lg:w-1/2">
                                            <img
                                                src="/api/placeholder/600/400"
                                                alt="Portfolio Management Interface"
                                                className="rounded-lg shadow-xl"
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Feature 3 */}
                                <div className="mt-24">
                                    <div className="lg:flex lg:items-center lg:gap-8">
                                        <div className="lg:w-1/2">
                                            <h3 className="text-2xl font-bold text-gray-900">Social Investment Network</h3>
                                            <p className="mt-4 text-lg text-gray-500">
                                                Connect with experienced investors, share insights, and learn from the community. Build your network and make better investment decisions together.
                                            </p>
                                            <ul className="mt-8 space-y-2">
                                                <li className="flex items-center">
                                                    <Users className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Connect with investors</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <Search className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Discover strategies</span>
                                                </li>
                                                <li className="flex items-center">
                                                    <Activity className="h-5 w-5 text-indigo-500 mr-2" />
                                                    <span>Share insights</span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="mt-8 lg:mt-0 lg:w-1/2">
                                            <img
                                                src="/api/placeholder/600/400"
                                                alt="Social Network Features"
                                                className="rounded-lg shadow-xl"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* CTA Section */}
                        <div className="bg-indigo-600">
                            <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
                                <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
                                    <span className="block">Ready to get started?</span>
                                    <span className="block">Join Finmates today.</span>
                                </h2>
                                <p className="mt-4 text-lg leading-6 text-indigo-200">
                                    Connect with fellow investors, discover opportunities, and grow your portfolio.
                                </p>
                                <button
                                    className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50 sm:w-auto"
                                >
                                    Sign up for free
                                </button>
                            </div>
                        </div>
                    </div>




                    <Container>
                        <Row>
                            <Col>
                                <CryptoBanner />
                            </Col>
                        </Row>
                    </Container>


                    <Container fluid>
                        <Row>
                            <Col>
                                <UniswapSearchForm/>
                            </Col>
                        </Row>
                    </Container>


                    <Container className="container col-xl-10 col-xxl-10 px-4 py-2">
                        <Row className="row align-items-center g-lg-5 py-5">
                            <Col className="col-md-12 col-lg-6 text-center text-lg-start text-body">
                                <h1 className="display-4 fw-bold lh-1 text-body-emphasis mb-3">Join our community</h1>
                                <p className="col-lg-10 fs-4">Create account by filling the form or use authorization
                                    providers below.</p>
                                <Button role="link" href="/sign-up" className=" d-lg-none mx-3">SignUp</Button>
                            </Col>
                            <Col className="d-none d-lg-block col-lg-6 mx-auto">
                                <SignUpComponent/>
                            </Col>
                        </Row>
                    </Container>

                </Row>
            </LayoutPublic>
        </>
    );
};

export default Homepage;
