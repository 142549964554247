import React, {useContext, useState} from "react";
import {Container, Row, Col, Table, CardBody} from "react-bootstrap";
import {ThemeContext} from "../../../../App";
import Card from "react-bootstrap/Card";
import {UniswapPosition} from "../../../../model/crypto/CryptoModels";
import Nav from "react-bootstrap/Nav";
import FormatUtils from "../../../../service/utils/FormatUtils";
import {number} from "yup";
import InfoTooltip from "../../../utils/InfoTooltip";


const PriceRangeIndicator: React.FC<{ position: UniswapPosition }> = ({position}) => {
    const context = useContext(ThemeContext);
    const isDarkTheme = context?.theme === "dark";

    const [token0Active, setToken0Active] = useState(true)
    const [token1Active, setToken1Active] = useState(false)

    // Extract price data
    const lower = position.priceRange?.lower.token1PerToken0;
    const upper = position.priceRange?.upper.token1PerToken0;
    const current = position.currentPrice?.token1PerToken0;

    // Extract price data in token0 terms
    const lowerToken0 = position.priceRange?.lower.token0PerToken1;
    const upperToken0 = position.priceRange?.upper.token0PerToken1;
    const currentToken0 = position.currentPrice?.token0PerToken1;

    // Extend width by 10% on each side
    const minValue = Math.min(lower, upper, current);
    const maxValue = Math.max(lower, upper, current);
    const extendedMin = minValue - (maxValue - minValue) * 0.1;
    const extendedMax = maxValue + (maxValue - minValue) * 0.1;

    // Calculate positions as percentages
    const lowerPos = ((lower - extendedMin) / (extendedMax - extendedMin)) * 100;
    const upperPos = ((upper - extendedMin) / (extendedMax - extendedMin)) * 100;
    const currentPos = ((current - extendedMin) / (extendedMax - extendedMin)) * 100;


    // Determine the fill color based on the current price
    //const fillColor = current >= lower && current <= upper ? "#2ECC71" : "#FF0044"; // Green if current >= lower, otherwise Red
    const fillColor = rangeColor(position.priceRange?.lower.token1PerToken0, position.priceRange?.upper.token1PerToken0, position.currentPrice?.token1PerToken0)


    // Theme-based styling
    const backgroundColor = isDarkTheme ? "#222" : "#DDD";
    const borderColor = fillColor
    /*const markerColor = isDarkTheme ? "white" : "black";*/
    const markerColor = fillColor
    const markerColorCurrent = "#1d74f7"
    const textColor = isDarkTheme ? "white" : "black";


    function rangeColor(lower: number, upper: number, price: number): string {

        if (price < lower || price > upper)
            return '#DC3545'

        const delta = (upper - lower) * 0.15

        if ((price > lower && price < (lower + delta)) || (price > (upper - delta) && price < upper))
            return '#FFC107'

        else return '#28A745'

    }


    function handleToke0Select() {
        setToken0Active(true)
        setToken1Active(false)
    }

    function handleToke1Select() {
        setToken1Active(true)
        setToken0Active(false)
    }


    return (
        <Container fluid className="px-0">

            <Row>
                <Col className="mb-3">
                    <h5 className="float-start">
                        PRICE RANGE <InfoTooltip  text={
                        "Diagram shows price range for the position and current price. \
                                            It is green when current price is within the range and in good standing, red when outside; \
                                            yellow when price is within the range but close to the edges by 15% of range value or less. \
                                            It is suggested to move position in this case."}/>
                    </h5>
                    <div className="float-end">
                        <Nav variant="pills" defaultActiveKey="/home">
                            <Nav.Item>
                                <Nav.Link onClick={() => handleToke0Select()}
                                          active={token0Active}>{position.token0?.symbol}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => handleToke1Select()}
                                          active={token1Active}>{position.token1?.symbol}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </Col>
            </Row>

            <Row>
                {/* First Column - Price Range Indicator */}
                <Col md={6} className="d-flex align-items-center justify-content-center px-2 mt-2 mb-4">
                    <div
                        className="position-relative"
                        style={{
                            width: "100%",
                            maxWidth: "500px",
                            height: "12px",
                            backgroundColor: backgroundColor,
                            borderRadius: "6px",
                            border: `2px solid ${borderColor}`,
                        }}
                    >
                        {/* Filled Range */}
                        <div
                            className="position-absolute"
                            style={{
                                height: "100%",
                                backgroundColor: fillColor,
                                borderRadius: "6px",
                                left: `${lowerPos}%`,
                                width: `${upperPos - lowerPos}%`,
                            }}
                        />

                        {/* Lower Marker */}
                        <div
                            className="position-absolute"
                            style={{
                                top: "50%",
                                left: `${lowerPos}%`,
                                transform: "translate(-50%, -50%)",
                                width: "14px",
                                height: "28px",
                                backgroundColor: markerColor,
                                borderRadius: "6px",
                                border: `2px solid ${markerColor}`,
                            }}
                        />

                        {/* Upper Marker */}
                        <div
                            className="position-absolute"
                            style={{
                                top: "50%",
                                left: `${upperPos}%`,
                                transform: "translate(-50%, -50%)",
                                width: "14px",
                                height: "28px",
                                backgroundColor: markerColor,
                                borderRadius: "6px",
                                border: `2px solid ${markerColor}`,
                            }}
                        ></div>

                        {/* Current Price Marker */}
                        <div
                            className="position-absolute"
                            style={{
                                top: "50%",
                                left: `${currentPos}%`,
                                transform: "translate(-50%, -50%)",
                                width: "14px",
                                height: "28px",
                                backgroundColor: markerColorCurrent,
                                borderRadius: "6px",
                                border: `2px solid ${markerColor}`,
                            }}
                        />
                    </div>
                </Col>

                {/* Second Column - Price Table */}
                <Col md={6}>

                    <Row>
                        <Col className="mb-2">
                            <Card className="bg-body-tertiary">
                                <CardBody className="text-center">

                                    <Row><Col xs={12}>
                                        <div className="text-sm text-body-tertiary">Current Price</div>
                                        <h2>{FormatUtils.formatNumber(token0Active ? 1 / current : current)}</h2>
                                        <div
                                            className="text-sm text-body-tertiary">{token0Active ? position.token0?.symbol : position.token1?.symbol} per {token0Active ? position.token1?.symbol : position.token0?.symbol}</div>
                                    </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={6} className="pe-1">
                            <Card className="bg-body-tertiary">
                                <CardBody className="text-center">
                                    <div className="text-sm text-body-tertiary">Minimum Price</div>
                                    <h2>{FormatUtils.formatNumber(token0Active ? (1 / lower) : lower)}</h2>
                                    <div
                                        className="text-sm text-body-tertiary">{token0Active ? position.token0?.symbol : position.token1?.symbol} per {token0Active ? position.token1?.symbol : position.token0?.symbol}</div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col xs={6} className="ps-1">
                            <Card className="bg-body-tertiary">
                                <CardBody className="text-center">
                                    <div className="text-sm text-body-tertiary">Maximum Price</div>
                                    <h2>{FormatUtils.formatNumber(token0Active ? (1 / upper) : upper)}</h2>
                                    <div
                                        className="text-sm text-body-tertiary">{token0Active ? position.token0?.symbol : position.token1?.symbol} per {token0Active ? position.token1?.symbol : position.token0?.symbol}</div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>


                    {/*                    <Table bordered size="sm" className="text-center" style={{color: textColor}}>
                        <thead>
                        <tr>
                            <th></th>
                            <th>{position.token0?.symbol} per {position.token1?.symbol}</th>
                            <th>{position.token1?.symbol} per {position.token0?.symbol}</th>
                        </tr>
                        </thead>
                        <tbody>
                         Row 1: Price in terms of Token0
                        <tr>
                            <td>Lower</td>
                            <td>{lower?.toFixed(6)}</td>
                            <td>{lowerToken0?.toFixed(6)}</td>
                        </tr>
                        <tr>
                            <td>Current</td>
                            <td>{current?.toFixed(6)}</td>
                            <td>{currentToken0?.toFixed(6)}</td>
                        </tr>
                        <tr>
                            <td>Upper</td>
                            <td>{upper?.toFixed(6)}</td>
                            <td>{upperToken0?.toFixed(6)}</td>
                        </tr>
                        </tbody>
                    </Table>*/}
                </Col>
            </Row>
        </Container>
    )
        ;
};

export default PriceRangeIndicator;
