import React from 'react';
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import './layout.css';
import {TickerTape} from "react-ts-tradingview-widgets";


interface LayoutPublicProps {
    fluid?: any
}

function LayoutPublic(props: any) {

    const x = props.center ? 'd-flex justify-content-center align-items-center' : '';

    return (
        <>
            <Header/>
            <Container className="px-0" fluid>{props.fluid}</Container>
            <Container fluid className={"main-container " + x}>
                {props.children}
            </Container>
            <Footer/>
        </>
    )
}


export default LayoutPublic;
