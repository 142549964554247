import {ethers, formatUnits, JsonRpcProvider, keccak256, toBeHex, toUtf8Bytes} from "ethers";
import {Token} from '@uniswap/sdk-core';
import {Pool, computePoolAddress, FACTORY_ADDRESS} from '@uniswap/v3-sdk';
import IUniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json';
import NonfungiblePositionManagerArtifact
    from "@uniswap/v3-periphery/artifacts/contracts/NonfungiblePositionManager.sol/NonfungiblePositionManager.json";
import ERC20ABI from '../../assets/crypto/abi/ERC20.json'
import UniswapV3PoolABI from '@uniswap/v3-core/artifacts/contracts/UniswapV3Pool.sol/UniswapV3Pool.json';


// Uniswap V3 Nonfungible Position Manager Address on Arbitrum
const UNISWAP_V3_POSITION_MANAGER = "0xC36442b4a4522E871399CD717aBDD847Ab11FE88";
const UNISWAP_V3_FACTORY_ADDRESS = "0x1F98431c8aD98523631AE4a59f267346ea31F984";
// Use default export to get the ABI
const positionManagerABI = NonfungiblePositionManagerArtifact.abi;
const poolAbi = UniswapV3PoolABI.abi;


import {Alchemy, Network} from "alchemy-sdk";
import {
    GroupedEvents,
    PositionEvent, LogEvent,
    PositionEventsResult,
    UniswapPosition
} from "../../model/crypto/CryptoModels";


const ABBITRUM_RPC_URL = "https://arb1.arbitrum.io/rpc";

const COINGECKO_API_URL = 'https://api.coingecko.com/api/v3/simple/token_price/ethereum';

const provider = new ethers.JsonRpcProvider("https://arb-mainnet.g.alchemy.com/v2/zAztGaJpmhEJZo1f1wIns665r07-RGgB");
const arbitrumProvider = new ethers.JsonRpcProvider(ABBITRUM_RPC_URL);


const apiKey = "zAztGaJpmhEJZo1f1wIns665r07-RGgB";
const settings = {
    apiKey: apiKey,
    network: Network.ARB_MAINNET
};

const alchemy = new Alchemy(settings);

const ARBITRUM_RPC_URL = "https://arb1.arbitrum.io/rpc"; // Replace with your RPC provider if needed
const MAX_UINT128 = (BigInt(2) ** BigInt(128)) - BigInt(1); // Fix for TypeScript




class CryptoPositionsService {

    async getUnclaimedFees(positionId: number): Promise<{ amount0: number; amount1: number }> {
        try {
            // Get position details
            const positionManager = new ethers.Contract(
                UNISWAP_V3_POSITION_MANAGER,
                positionManagerABI,
                provider
            );
            const position = await positionManager.positions(positionId);

            // Get token addresses and information
            const token0Address = position.token0;
            const token1Address = position.token1;
            const fee = position.fee;
            const tickLower = position.tickLower;
            const tickUpper = position.tickUpper;

            // Get token decimals
            const tokenABI = ["function decimals() view returns (uint8)"];
            const token0 = new ethers.Contract(token0Address, tokenABI, provider);
            const token1 = new ethers.Contract(token1Address, tokenABI, provider);
            const token0Decimals = await token0.decimals();
            const token1Decimals = await token1.decimals();

            // Get the base amounts from tokensOwed in the position
            const baseAmount0 = parseFloat(ethers.formatUnits(position.tokensOwed0, token0Decimals));
            const baseAmount1 = parseFloat(ethers.formatUnits(position.tokensOwed1, token1Decimals));

            console.log(`Base unclaimed fees from position: Token0: ${baseAmount0}, Token1: ${baseAmount1}`);

            // Get pool contract to calculate additional fees
            // First get the pool address
            const factoryContract = new ethers.Contract(
                "0x1F98431c8aD98523631AE4a59f267346ea31F984", // Uniswap V3 Factory
                ["function getPool(address,address,uint24) view returns (address)"],
                provider
            );

            const poolAddress = await factoryContract.getPool(token0Address, token1Address, fee);
            console.log(`Pool address: ${poolAddress}`);

            // Now interact with the pool to get fee data
            const poolABI = [
                "function slot0() external view returns (uint160 sqrtPriceX96, int24 tick, uint16 observationIndex, uint16 observationCardinality, uint16 observationCardinalityNext, uint8 feeProtocol, bool unlocked)",
                "function feeGrowthGlobal0X128() external view returns (uint256)",
                "function feeGrowthGlobal1X128() external view returns (uint256)",
                "function ticks(int24 tick) external view returns (uint128 liquidityGross, int128 liquidityNet, uint256 feeGrowthOutside0X128, uint256 feeGrowthOutside1X128, int56 tickCumulativeOutside, uint160 secondsPerLiquidityOutsideX128, uint32 secondsOutside, bool initialized)"
            ];

            const poolContract = new ethers.Contract(poolAddress, poolABI, provider);

            // Get current tick and fee growth data
            const [slot0Data, feeGrowthGlobal0, feeGrowthGlobal1, tickLowerData, tickUpperData] = await Promise.all([
                poolContract.slot0(),
                poolContract.feeGrowthGlobal0X128(),
                poolContract.feeGrowthGlobal1X128(),
                poolContract.ticks(tickLower),
                poolContract.ticks(tickUpper)
            ]);

            const currentTick = slot0Data.tick;

            // Calculate fee growth inside the range
            // This is a simplified version of the calculation
            let feeGrowthInside0X128 = BigInt(0);
            let feeGrowthInside1X128 = BigInt(0);

            // These calculations follow the Uniswap V3 whitepaper
            const tickLowerFeeGrowthOutside0X128 = BigInt(tickLowerData.feeGrowthOutside0X128.toString());
            const tickLowerFeeGrowthOutside1X128 = BigInt(tickLowerData.feeGrowthOutside1X128.toString());
            const tickUpperFeeGrowthOutside0X128 = BigInt(tickUpperData.feeGrowthOutside0X128.toString());
            const tickUpperFeeGrowthOutside1X128 = BigInt(tickUpperData.feeGrowthOutside1X128.toString());

            const feeGrowthGlobal0X128 = BigInt(feeGrowthGlobal0.toString());
            const feeGrowthGlobal1X128 = BigInt(feeGrowthGlobal1.toString());

            // Determine which fee growth values to use based on current tick
            if (currentTick >= Number(tickLower) && currentTick < Number(tickUpper)) {
                // Current price is within range
                feeGrowthInside0X128 = feeGrowthGlobal0X128 - tickLowerFeeGrowthOutside0X128 - tickUpperFeeGrowthOutside0X128;
                feeGrowthInside1X128 = feeGrowthGlobal1X128 - tickLowerFeeGrowthOutside1X128 - tickUpperFeeGrowthOutside1X128;
            } else if (currentTick >= Number(tickUpper)) {
                // Current price is above range
                feeGrowthInside0X128 = tickUpperFeeGrowthOutside0X128 - tickLowerFeeGrowthOutside0X128;
                feeGrowthInside1X128 = tickUpperFeeGrowthOutside1X128 - tickLowerFeeGrowthOutside1X128;
            } else {
                // Current price is below range
                feeGrowthInside0X128 = tickLowerFeeGrowthOutside0X128 - tickUpperFeeGrowthOutside0X128;
                feeGrowthInside1X128 = tickLowerFeeGrowthOutside1X128 - tickUpperFeeGrowthOutside1X128;
            }

            // Calculate uncollected fees
            const Q128 = 2n ** 128n;
            const liquidity = BigInt(position.liquidity.toString());
            const feeGrowthInside0LastX128 = BigInt(position.feeGrowthInside0LastX128.toString());
            const feeGrowthInside1LastX128 = BigInt(position.feeGrowthInside1LastX128.toString());

            // Calculate fee deltas
            let feeDelta0 = 0n;
            let feeDelta1 = 0n;

            // Handle potential underflows in subtraction with modular arithmetic
            if (feeGrowthInside0X128 >= feeGrowthInside0LastX128) {
                feeDelta0 = (feeGrowthInside0X128 - feeGrowthInside0LastX128) * liquidity / Q128;
            } else {
                feeDelta0 = ((feeGrowthInside0X128 + (2n ** 256n - 1n)) - feeGrowthInside0LastX128) * liquidity / Q128;
            }

            if (feeGrowthInside1X128 >= feeGrowthInside1LastX128) {
                feeDelta1 = (feeGrowthInside1X128 - feeGrowthInside1LastX128) * liquidity / Q128;
            } else {
                feeDelta1 = ((feeGrowthInside1X128 + (2n ** 256n - 1n)) - feeGrowthInside1LastX128) * liquidity / Q128;
            }

            // Add calculated fees to base amounts
            const additionalAmount0 = parseFloat(ethers.formatUnits(feeDelta0, token0Decimals));
            const additionalAmount1 = parseFloat(ethers.formatUnits(feeDelta1, token1Decimals));

            console.log(`Additional calculated fees: Token0: ${additionalAmount0}, Token1: ${additionalAmount1}`);

            // Final unclaimed fees
            const totalAmount0 = baseAmount0 + additionalAmount0;
            const totalAmount1 = baseAmount1 + additionalAmount1;

            console.log(`Total unclaimed fees for position ${positionId}:`);
            console.log(`Token0: ${totalAmount0}`);
            console.log(`Token1: ${totalAmount1}`);

            return { amount0: totalAmount0, amount1: totalAmount1 };
        } catch (error: unknown) {
            console.error("Error calculating unclaimed fees:", error);
            if (error instanceof Error) {
                console.error("Stack trace:", error.stack);
            }
            return { amount0: 0, amount1: 0 };
        }
    }

    /**
     * Fetches all fee collection events for a position
     * @param positionId - The position ID to fetch events for
     * @returns Array of PositionEvent objects for fee collections
     */
    async fetchPositionEvents(positionId: number): Promise<{
        collects: PositionEvent[];
        increaseLiquidity: PositionEvent[];
        decreaseLiquidity: PositionEvent[];
        allEvents: PositionEvent[];
    }> {
        if (positionId <= 0) {
            console.error("Invalid position ID. Must be a positive integer.");
            return {
                collects: [],
                increaseLiquidity: [],
                decreaseLiquidity: [],
                allEvents: []
            };
        }

        const query = `
        {
          collects(where: {tokenId: "${positionId}"}, orderBy: blockTimestamp) {
            amount0
            amount1
            blockNumber
            blockTimestamp
            transactionHash
            recipient
            id
            amountUSD
          }
          increaseLiquidityEvents(where: {tokenId: "${positionId}"}, orderBy: blockTimestamp) {
            amount0
            amount1
            blockNumber
            blockTimestamp
            transactionHash
            liquidity
            id
          }
          decreaseLiquidityEvents(where: {tokenId: "${positionId}"}, orderBy: blockTimestamp) {
            amount0
            amount1
            blockNumber
            blockTimestamp
            transactionHash
            liquidity
            id
          }
        }`;

        const SUBGRAPH_ENDPOINT = `https://gateway.thegraph.com/api/${apiKey}/subgraphs/id/CeXVNSNdYN4adbdXtKWJRxvG9nKa7Lg6bzevBAG6T7ub`;

        try {
            const response = await fetch(SUBGRAPH_ENDPOINT, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({query})
            });

            const json = await response.json();
            if (json.errors) {
                throw new Error("Error fetching subgraph data: " + JSON.stringify(json.errors));
            }

            // Get contract to fetch token details
            const contract = new ethers.Contract(
                UNISWAP_V3_POSITION_MANAGER,
                positionManagerABI,
                provider
            );

            // Fetch position data to get token information
            const position = await contract.positions(positionId);
            const token0Address = position.token0;
            const token1Address = position.token1;

            // Fetch token decimals
            const [token0Decimals, token1Decimals] = await Promise.all([
                this.getTokenDecimals(token0Address),
                this.getTokenDecimals(token1Address),
            ]);

            // Transform collect events
            const collectEvents: PositionEvent[] = json.data.collects.map((event: any): PositionEvent => ({
                id: event.id,
                blockNumber: Number(event.blockNumber),
                timestamp: Number(event.blockTimestamp),
                amount0: Number(event.amount0) / 10 ** token0Decimals, // Convert from wei
                amount1: Number(event.amount1) / 10 ** token1Decimals,  // Convert from token decimals
                amountUSD: event.amountUSD ? Number(event.amountUSD) : undefined,
                transactionHash: event.transactionHash || undefined,
                recipient: event.recipient || undefined,
                eventType: 'collect'
            }));

            // Transform increaseLiquidity events
            const increaseEvents: PositionEvent[] = json.data.increaseLiquidityEvents.map((event: any): PositionEvent => ({
                id: event.id,
                blockNumber: Number(event.blockNumber),
                timestamp: Number(event.blockTimestamp),
                amount0: Number(event.amount0) / 10 ** token0Decimals, // Convert from wei
                amount1: Number(event.amount1) / 10 ** token1Decimals,  // Convert from token decimals
                transactionHash: event.transactionHash || undefined,
                liquidity: event.liquidity ? BigInt(event.liquidity) : undefined,
                eventType: 'increaseLiquidity'
            }));

            // Transform decreaseLiquidity events
            const decreaseEvents: PositionEvent[] = json.data.decreaseLiquidityEvents.map((event: any): PositionEvent => ({
                id: event.id,
                blockNumber: Number(event.blockNumber),
                timestamp: Number(event.blockTimestamp),
                // Use negative values for decrease events to indicate removal
                amount0: -Number(event.amount0) / 10 ** token0Decimals, // Convert from wei
                amount1: -Number(event.amount1) / 10 ** token1Decimals,  // Convert from token decimals
                transactionHash: event.transactionHash || undefined,
                liquidity: event.liquidity ? BigInt(event.liquidity) : undefined,
                eventType: 'decreaseLiquidity'
            }));

            // Combine all events into a single array and sort by timestamp
            const allEvents = [
                ...collectEvents,
                ...increaseEvents,
                ...decreaseEvents
            ].sort((a, b) => a.timestamp - b.timestamp);

            return {
                collects: collectEvents,
                increaseLiquidity: increaseEvents,
                decreaseLiquidity: decreaseEvents,
                allEvents: allEvents
            };

        } catch (error) {
            console.error("Error fetching events:", error);
            return {
                collects: [],
                increaseLiquidity: [],
                decreaseLiquidity: [],
                allEvents: []
            };
        }
    }

    /**
     * Fetches token decimals from contract
     * @param tokenAddress - The token address
     * @returns Number of decimals for the token
     */
    async getTokenDecimals(tokenAddress: string): Promise<number> {
        if (tokenAddress === ethers.ZeroAddress) {
            console.warn(`Skipping decimals fetch for invalid token address: ${tokenAddress}`);
            return 0; // Return 0 decimals for an invalid token
        }
        // ERC-20 Token ABI (for fetching decimals)
        const ERC20_ABI = ["function decimals() view returns (uint8)"];
        const tokenContract = new ethers.Contract(tokenAddress, ERC20_ABI, provider);
        try {
            return await tokenContract.decimals();
        } catch (error) {
            console.error(`Error fetching decimals for token ${tokenAddress}:`, error);
            return 18; // Default to 18 if the token call fails
        }
    }

    /**
     * Converts raw blockchain events to the unified PositionEvent format
     * @param positionId - Position ID to fetch events for
     * @returns Object containing separate event arrays and a combined array
     */
    async fetchAllPositionEventsUnified(positionId: number): Promise<{
        collects: PositionEvent[];
        increaseLiquidity: PositionEvent[];
        decreaseLiquidity: PositionEvent[];
        allEvents: PositionEvent[];
    }> {
        if (positionId <= 0) {
            console.error("Invalid position ID. Must be a positive integer.");
            return {
                collects: [],
                increaseLiquidity: [],
                decreaseLiquidity: [],
                allEvents: []
            };
        }

        try {
            // Get position details to get token decimals
            const contract = new ethers.Contract(
                UNISWAP_V3_POSITION_MANAGER,
                positionManagerABI,
                provider
            );

            // Fetch position data to get token information
            const position = await contract.positions(positionId);
            const token0Address = position.token0;
            const token1Address = position.token1;

            // Fetch token decimals
            const [token0Decimals, token1Decimals] = await Promise.all([
                this.getTokenDecimals(token0Address),
                this.getTokenDecimals(token1Address),
            ]);

            // Get raw events from the blockchain
            const rawEvents = await this.fetchAllPositionEvents(positionId);

            // Transform collect events
            const collectEvents: PositionEvent[] = rawEvents.groupedEvents.collect.map(event => {
                // Event data: [recipient, amount0, amount1]
                const amount0 = event.decodedData && event.decodedData.length > 1
                    ? Number(ethers.formatUnits(event.decodedData[1], token0Decimals))
                    : 0;

                const amount1 = event.decodedData && event.decodedData.length > 2
                    ? Number(ethers.formatUnits(event.decodedData[2], token1Decimals))
                    : 0;

                return {
                    id: `${event.blockNumber}-${event.transactionHash}-collect`,
                    blockNumber: event.blockNumber,
                    timestamp: event.timestamp,
                    amount0: amount0,
                    amount1: amount1,
                    transactionHash: event.transactionHash,
                    eventType: 'collect'
                };
            });

            // Transform increaseLiquidity events
            const increaseLiquidityEvents: PositionEvent[] = rawEvents.groupedEvents.increaseLiquidity.map(event => {
                // Event data: [liquidity, amount0, amount1]
                const amount0 = event.decodedData && event.decodedData.length > 1
                    ? Number(ethers.formatUnits(event.decodedData[1], token0Decimals))
                    : 0;

                const amount1 = event.decodedData && event.decodedData.length > 2
                    ? Number(ethers.formatUnits(event.decodedData[2], token1Decimals))
                    : 0;

                // For increaseLiquidity, first param is liquidity
                const liquidity = event.decodedData && event.decodedData.length > 0
                    ? BigInt(event.decodedData[0])
                    : 0n;

                return {
                    id: `${event.blockNumber}-${event.transactionHash}-increase`,
                    blockNumber: event.blockNumber,
                    timestamp: event.timestamp,
                    amount0: amount0,
                    amount1: amount1,
                    liquidity: liquidity,
                    transactionHash: event.transactionHash,
                    eventType: 'increaseLiquidity'
                };
            });

            // Transform decreaseLiquidity events
            const decreaseLiquidityEvents: PositionEvent[] = rawEvents.groupedEvents.decreaseLiquidity.map(event => {
                // Event data: [liquidity, amount0, amount1]
                const amount0 = event.decodedData && event.decodedData.length > 1
                    ? Number(ethers.formatUnits(event.decodedData[1], token0Decimals))
                    : 0;

                const amount1 = event.decodedData && event.decodedData.length > 2
                    ? Number(ethers.formatUnits(event.decodedData[2], token1Decimals))
                    : 0;

                // For decreaseLiquidity, first param is liquidity
                const liquidity = event.decodedData && event.decodedData.length > 0
                    ? BigInt(event.decodedData[0])
                    : 0n;

                return {
                    id: `${event.blockNumber}-${event.transactionHash}-decrease`,
                    blockNumber: event.blockNumber,
                    timestamp: event.timestamp,
                    // For decreases, use negative amounts
                    amount0: -amount0,
                    amount1: -amount1,
                    liquidity: liquidity,
                    transactionHash: event.transactionHash,
                    eventType: 'decreaseLiquidity'
                };
            });

            // Combine all events and sort by timestamp
            const allEvents = [
                ...collectEvents,
                ...increaseLiquidityEvents,
                ...decreaseLiquidityEvents
            ].sort((a, b) => a.timestamp - b.timestamp);

            return {
                collects: collectEvents,
                increaseLiquidity: increaseLiquidityEvents,
                decreaseLiquidity: decreaseLiquidityEvents,
                allEvents
            };
        } catch (error) {
            console.error(`Error fetching unified position events for position ${positionId}:`, error);
            return {
                collects: [],
                increaseLiquidity: [],
                decreaseLiquidity: [],
                allEvents: []
            };
        }
    }



    /**
     * Get all position events directly from contract events and convert to PositionEvent format
     * @param positionId - Position ID to fetch events for
     * @returns Object containing separate event arrays and a combined array
     */
    async getDirectPositionEvents(positionId: number): Promise<{
        collects: PositionEvent[];
        increaseLiquidity: PositionEvent[];
        decreaseLiquidity: PositionEvent[];
        allEvents: PositionEvent[];
    }> {
        // First get all events from the contract
        const allEvents = await this.getAllUniswapV3Events(positionId);

        // Get position details for token decimals
        const contract = new ethers.Contract(
            UNISWAP_V3_POSITION_MANAGER,
            positionManagerABI,
            provider
        );

        // Fetch position data to get token information
        const position = await contract.positions(positionId);
        const token0Address = position.token0;
        const token1Address = position.token1;

        // Fetch token decimals
        const [token0Decimals, token1Decimals] = await Promise.all([
            this.getTokenDecimals(token0Address),
            this.getTokenDecimals(token1Address),
        ]);

        // Pre-fetch blocks for all events if needed
        const blockCache = new Map<number, number>();  // blockNumber -> timestamp

        // Helper to get timestamp safely
        const getTimestamp = async (event: any): Promise<number> => {
            // If the event already has a timestamp, use it
            if ('blockTimestamp' in event && event.blockTimestamp) {
                return Number(event.blockTimestamp);
            }

            // If we have the block number, try to get its timestamp
            if ('blockNumber' in event && event.blockNumber) {
                const blockNumber = Number(event.blockNumber);

                // Check if we already have this block's timestamp in our cache
                if (blockCache.has(blockNumber)) {
                    return blockCache.get(blockNumber) || Math.floor(Date.now() / 1000);
                }

                // Otherwise fetch the block and cache its timestamp
                try {
                    const block = await provider.getBlock(blockNumber);
                    if (block && block.timestamp) {
                        blockCache.set(blockNumber, Number(block.timestamp));
                        return Number(block.timestamp);
                    }
                } catch (error) {
                    console.warn(`Failed to get timestamp for block ${blockNumber}:`, error);
                }
            }

            // Default to current time if we couldn't get a timestamp
            return Math.floor(Date.now() / 1000);
        };

        // Helper function to safely format token amounts
        const safeFormatUnits = (value: any, decimals: number): number => {
            if (value === null || value === undefined) {
                return 0;
            }

            try {
                // Try to convert to string first to handle different types
                const valueStr = String(value);
                return Number(ethers.formatUnits(valueStr, decimals));
            } catch (error) {
                console.warn(`Error formatting units: ${error}`);
                return 0;
            }
        };

        // Helper function to safely convert to BigInt
        const safeToBigInt = (value: any): bigint => {
            if (value === null || value === undefined) {
                return 0n;
            }

            try {
                // Convert to string first to handle different types
                return BigInt(String(value));
            } catch (error) {
                console.warn(`Error converting to BigInt: ${error}`);
                return 0n;
            }
        };

        // Process collect events
        const collectEvents: PositionEvent[] = await Promise.all(allEvents.collect.map(async (event: any) => {
            // Use any type to avoid TypeScript property access errors
            // Extract data from event
            const blockNumber = 'blockNumber' in event ? Number(event.blockNumber) : 0;
            const timestamp = await getTimestamp(event);

            // Extract amounts safely regardless of how they're stored
            let amount0 = 0;
            let amount1 = 0;

            // Try different ways the amount might be stored
            if ('args' in event && event.args) {
                // ethers v6 format
                amount0 = event.args.amount0 !== undefined ? safeFormatUnits(event.args.amount0, token0Decimals) : 0;
                amount1 = event.args.amount1 !== undefined ? safeFormatUnits(event.args.amount1, token1Decimals) : 0;
            } else {
                // Try to access properties directly, but safely
                if ('amount0' in event) amount0 = safeFormatUnits(event.amount0, token0Decimals);
                if ('amount1' in event) amount1 = safeFormatUnits(event.amount1, token1Decimals);
            }

            return {
                id: `${blockNumber}-${event.transactionHash || ''}-collect`,
                blockNumber,
                timestamp,
                amount0,
                amount1,
                transactionHash: event.transactionHash || '',
                eventType: 'collect'
            } as PositionEvent;
        }));

        // Process increaseLiquidity events
        const increaseLiquidityEvents: PositionEvent[] = await Promise.all(allEvents.increaseLiquidity.map(async (event: any) => {
            // Use any type to avoid TypeScript property access errors
            // Extract data from event
            const blockNumber = 'blockNumber' in event ? Number(event.blockNumber) : 0;
            const timestamp = await getTimestamp(event);

            // Extract amounts safely regardless of how they're stored
            let amount0 = 0;
            let amount1 = 0;
            let liquidity = 0n;

            // Try different ways the amount might be stored
            if ('args' in event && event.args) {
                // ethers v6 format
                amount0 = event.args.amount0 !== undefined ? safeFormatUnits(event.args.amount0, token0Decimals) : 0;
                amount1 = event.args.amount1 !== undefined ? safeFormatUnits(event.args.amount1, token1Decimals) : 0;
                liquidity = event.args.liquidity !== undefined ? safeToBigInt(event.args.liquidity) : 0n;
            } else {
                // Try to access properties directly, but safely
                if ('amount0' in event) amount0 = safeFormatUnits(event.amount0, token0Decimals);
                if ('amount1' in event) amount1 = safeFormatUnits(event.amount1, token1Decimals);
                if ('liquidity' in event) liquidity = safeToBigInt(event.liquidity);
            }

            return {
                id: `${blockNumber}-${event.transactionHash || ''}-increase`,
                blockNumber,
                timestamp,
                amount0,
                amount1,
                liquidity,
                transactionHash: event.transactionHash || '',
                eventType: 'increaseLiquidity'
            } as PositionEvent;
        }));

        // Process decreaseLiquidity events
        const decreaseLiquidityEvents: PositionEvent[] = await Promise.all(allEvents.decreaseLiquidity.map(async (event: any) => {
            // Use any type to avoid TypeScript property access errors
            // Extract data from event
            const blockNumber = 'blockNumber' in event ? Number(event.blockNumber) : 0;
            const timestamp = await getTimestamp(event);

            // Extract amounts safely regardless of how they're stored
            let amount0 = 0;
            let amount1 = 0;
            let liquidity = 0n;

            // Try different ways the amount might be stored
            if ('args' in event && event.args) {
                // ethers v6 format
                amount0 = event.args.amount0 !== undefined ? safeFormatUnits(event.args.amount0, token0Decimals) : 0;
                amount1 = event.args.amount1 !== undefined ? safeFormatUnits(event.args.amount1, token1Decimals) : 0;
                liquidity = event.args.liquidity !== undefined ? safeToBigInt(event.args.liquidity) : 0n;
            } else {
                // Try to access properties directly, but safely
                if ('amount0' in event) amount0 = safeFormatUnits(event.amount0, token0Decimals);
                if ('amount1' in event) amount1 = safeFormatUnits(event.amount1, token1Decimals);
                if ('liquidity' in event) liquidity = safeToBigInt(event.liquidity);
            }

            return {
                id: `${blockNumber}-${event.transactionHash || ''}-decrease`,
                blockNumber,
                timestamp,
                // For decreases, use negative amounts
                amount0: -amount0,
                amount1: -amount1,
                liquidity,
                transactionHash: event.transactionHash || '',
                eventType: 'decreaseLiquidity'
            } as PositionEvent;
        }));

        // Combine all events and sort by timestamp
        const sortedEvents = [
            ...collectEvents,
            ...increaseLiquidityEvents,
            ...decreaseLiquidityEvents
        ].sort((a, b) => a.timestamp - b.timestamp);

        return {
            collects: collectEvents,
            increaseLiquidity: increaseLiquidityEvents,
            decreaseLiquidity: decreaseLiquidityEvents,
            allEvents: sortedEvents
        };
    }



    async getPositionTokens(positionId: number, providerUrl: string) {
        try {
            const provider = new ethers.JsonRpcProvider(providerUrl);

            // Connect to Uniswap V3 Position Manager
            const positionManager = new ethers.Contract(
                UNISWAP_V3_POSITION_MANAGER,
                positionManagerABI,
                provider
            );

            // Get position details
            const position = await positionManager.positions(positionId);
            const token0Address = position.token0;
            const token1Address = position.token1;

            console.log(`Token0 Address: ${token0Address}`);
            console.log(`Token1 Address: ${token1Address}`);

            // Retrieve token names
            const token0Contract = new ethers.Contract(token0Address, ERC20ABI, provider);
            const token1Contract = new ethers.Contract(token1Address, ERC20ABI, provider);

            const token0Name = await token0Contract.name();
            const token1Name = await token1Contract.name();

            return {
                token0: {address: token0Address, name: token0Name},
                token1: {address: token1Address, name: token1Name}
            };
        } catch (error) {
            console.error("Error retrieving position tokens:", error);
            throw error;
        }
    }

    async getPosition(positionId: number, providerUrl: string) {
        const UNISWAP_V3_POOL_ABI = [
            "function slot0() external view returns (uint160 sqrtPriceX96, int24 tick, uint16 observationIndex, uint16 observationCardinality, uint16 observationCardinalityNext, uint8 feeProtocol, bool unlocked)"
        ];
        const TOKEN_ABI = [
            "function decimals() external view returns (uint8)",
            "function name() external view returns (string)",
            "function symbol() external view returns (string)"
        ];

        try {
            const provider = new ethers.JsonRpcProvider(providerUrl);

            const positionManager = new ethers.Contract(
                UNISWAP_V3_POSITION_MANAGER,
                [
                    "function positions(uint256 tokenId) external view returns (uint96 nonce, address operator, address token0, address token1, uint24 fee, int24 tickLower, int24 tickUpper, uint128 liquidity, uint256 feeGrowthInside0LastX128, uint256 feeGrowthInside1LastX128, uint128 tokensOwed0, uint128 tokensOwed1)"
                ],
                provider
            );

            // Fetch Position Details
            const position = await positionManager.positions(positionId);
            const {token0, token1, fee, tickLower, tickUpper, liquidity, tokensOwed0, tokensOwed1} = position;

            // Fetch Token Details
            const token0Contract = new ethers.Contract(token0, TOKEN_ABI, provider);
            const token1Contract = new ethers.Contract(token1, TOKEN_ABI, provider);

            const [decimals0, decimals1, token0Name, token1Name, token0Symbol, token1Symbol] = await Promise.all([
                token0Contract.decimals(),
                token1Contract.decimals(),
                token0Contract.name(),
                token1Contract.name(),
                token0Contract.symbol(),
                token1Contract.symbol()
            ]);

            // Compute the Uniswap V3 Pool Address
            const poolAddress = await this.getPoolAddress(token0, token1, fee);

            const poolContract = new ethers.Contract(String(poolAddress), UNISWAP_V3_POOL_ABI, provider);
            console.log("🔍 Checking Pool Address:", poolAddress);
            // Fetch Pool State (`slot0()`)
            const slot0Data = await poolContract.slot0();

            const sqrtPriceX96 = BigInt(slot0Data.sqrtPriceX96);
            const currentTick = Number(slot0Data.tick);

            // Use correct tick price calculation without extra scaling
            const tickToPrice = (tick: number): number => {
                return 1.0001 ** tick;
            };

            // Compute Scale Factor
            const scaleFactor = 10 ** Number(decimals1 - decimals0);

            // Corrected Price Calculation for `sqrtPriceX96`
            const priceToken1PerToken0 = Number((sqrtPriceX96 * sqrtPriceX96) / (2n ** 192n)) * Number(scaleFactor);
            const priceToken0PerToken1 = 1 / priceToken1PerToken0;

            // Correct Lower and Upper Bound Prices
            const lowerEdgePriceToken1PerToken0 = tickToPrice(Number(tickLower)) / scaleFactor;
            const upperEdgePriceToken1PerToken0 = tickToPrice(Number(tickUpper)) / scaleFactor;

            const lowerEdgePriceToken0PerToken1 = 1 / upperEdgePriceToken1PerToken0;
            const upperEdgePriceToken0PerToken1 = 1 / lowerEdgePriceToken1PerToken0;

            const currentPriceToken1PerToken0 = tickToPrice(currentTick) / scaleFactor;
            const currentPriceToken0PerToken1 = 1 / (tickToPrice(currentTick) / scaleFactor);

            // Get position events
            const positionEvents = await this.fetchPositionEvents(positionId);

            // Construct & Return Result
            return {
                positionId,
                token0: {
                    address: token0,
                    name: token0Name,
                    symbol: token0Symbol,
                    decimals: decimals0
                },
                token1: {
                    address: token1,
                    name: token1Name,
                    symbol: token1Symbol,
                    decimals: decimals1
                },
                id: positionId,
                fee: Number(fee),
                address: position.address,
                tickLower: Number(tickLower),
                tickUpper: Number(tickUpper),
                liquidity: BigInt(liquidity), // Keep as BigInt
                tokensOwed0: parseFloat(ethers.formatUnits(tokensOwed0, decimals0)),
                tokensOwed1: parseFloat(ethers.formatUnits(tokensOwed1, decimals1)),
                currentPrice: {
                    token1PerToken0: Number(currentPriceToken1PerToken0),
                    token0PerToken1: Number(currentPriceToken0PerToken1),
                },
                priceRange: {
                    lower: {
                        token1PerToken0: Number(lowerEdgePriceToken1PerToken0),
                        token0PerToken1: Number(lowerEdgePriceToken0PerToken1)
                    },
                    upper: {
                        token1PerToken0: Number(upperEdgePriceToken1PerToken0),
                        token0PerToken1: Number(upperEdgePriceToken0PerToken1)
                    }
                },
                // Add all position events using the new format
                collects: positionEvents.collects,
                increaseLiquidity: positionEvents.increaseLiquidity,
                decreaseLiquidity: positionEvents.decreaseLiquidity,
                positionEvents: positionEvents.allEvents,
                // Keep original fields for backward compatibility
                feeCollectionEvents: positionEvents.collects
            };

        } catch (error) {
            console.error(`❌ Error retrieving position details for NFT ID ${positionId}:`, error);
            throw new Error("Could not fetch position details. Please try again later.");
        }
    }

    // Helper function to fetch token details (symbol and decimals)
    async getTokenDetails(tokenAddress: string, provider: ethers.JsonRpcProvider) {
        const ERC20ABI = [
            {"constant": true, "inputs": [], "name": "symbol", "outputs": [{"type": "string"}], "type": "function"},
            {"constant": true, "inputs": [], "name": "decimals", "outputs": [{"type": "uint8"}], "type": "function"},
            {
                "constant": true,
                "inputs": [],
                "name": "name",
                "outputs": [{"name": "", "type": "string"}],
                "type": "function"
            }
        ];

        const tokenContract = new ethers.Contract(tokenAddress, ERC20ABI, provider);

        let name = await tokenContract.name();
        let symbol = 'UNKNOWN';
        let decimals = 18; // Default to 18 decimals

        try {
            name = await tokenContract.name();
        } catch (error) {
            console.warn(`Could not fetch name for token at ${tokenAddress}:`, error);
        }

        try {
            symbol = await tokenContract.symbol();
        } catch (error) {
            console.warn(`Could not fetch symbol for token at ${tokenAddress}:`, error);
        }

        try {
            decimals = await tokenContract.decimals();
            console.warn(`Decimal = ${tokenAddress}:`, decimals);
        } catch (error) {
            console.warn(`Could not fetch decimals for token at ${tokenAddress}:`, error);
        }

        return {
            name: name.toString(),
            symbol: symbol.toString(),
            decimals: Number(decimals)
        };
    }

    // Convert tick index to sqrt price
    tickToSqrtPriceX96(tick: number): bigint {
        return BigInt(Math.floor(1.0001 ** (tick / 2) * 2 ** 96));
    }

    // Compute token amounts from liquidity
    getTokenAmountsFromLiquidity(
        liquidity: bigint, sqrtPriceX96: bigint, sqrtPriceLowerX96: bigint, sqrtPriceUpperX96: bigint
    ): { token0Amount: bigint, token1Amount: bigint } {
        let token0Amount = 0n;
        let token1Amount = 0n;

        if (sqrtPriceX96 <= sqrtPriceLowerX96) {
            // Current price is below the provided range; only token0 is required
            token0Amount =
                (liquidity *
                    ((BigInt(1) << BigInt(192)) / sqrtPriceLowerX96 -
                        (BigInt(1) << BigInt(192)) / sqrtPriceUpperX96)) >>
                BigInt(96);
            token1Amount = BigInt(0);
        } else if (sqrtPriceX96 >= sqrtPriceUpperX96) {
            // Current price is above the provided range; only token1 is required
            token0Amount = BigInt(0);
            token1Amount =
                (liquidity * (sqrtPriceUpperX96 - sqrtPriceLowerX96)) >> BigInt(96);
        } else {
            // Current price is within the provided range; both tokens are required
            token0Amount =
                (liquidity *
                    ((BigInt(1) << BigInt(192)) / sqrtPriceX96 -
                        (BigInt(1) << BigInt(192)) / sqrtPriceUpperX96)) >>
                BigInt(96);
            token1Amount =
                (liquidity * (sqrtPriceX96 - sqrtPriceLowerX96)) >> BigInt(96);
        }

        return {token0Amount, token1Amount};
    }

    // Main function to get token amounts
    async getLiquidityTokenAmounts(tokenId: number): Promise<{ token0Amount: number, token1Amount: number } | null> {
        const positionManager = new ethers.Contract(UNISWAP_V3_POSITION_MANAGER, positionManagerABI, provider);

        try {
            // Fetch position details
            const position = await positionManager.positions(tokenId);
            const {token0, token1, tickLower, tickUpper, liquidity} = position;

            // Fetch current pool state
            const poolAddress = await this.getPoolAddress(token0, token1, Number(position.fee));
            if (!poolAddress) {
                console.error("No pool found for this position.");
                return null;
            }

            const poolContract = new ethers.Contract(poolAddress, poolAbi, provider);
            const {sqrtPriceX96} = await poolContract.slot0();

            // Convert ticks to sqrt price
            const sqrtPriceLowerX96 = this.tickToSqrtPriceX96(Number(tickLower));
            const sqrtPriceUpperX96 = this.tickToSqrtPriceX96(Number(tickUpper));

            // Compute raw token amounts
            const {token0Amount, token1Amount} = this.getTokenAmountsFromLiquidity(
                BigInt(liquidity), BigInt(sqrtPriceX96), BigInt(sqrtPriceLowerX96), BigInt(sqrtPriceUpperX96)
            );

            // Fetch token decimals dynamically
            const token0Decimals = await this.getTokenDecimals(token0);
            const token1Decimals = await this.getTokenDecimals(token1);

            // Convert from wei to human-readable format
            const formattedToken0 = ethers.formatUnits(token0Amount, token0Decimals);
            const formattedToken1 = ethers.formatUnits(token1Amount, token1Decimals);

            console.log(`Token 0 Amount: ${formattedToken0} (${token0})`);
            console.log(`Token 1 Amount: ${formattedToken1} (${token1})`);

            return {token0Amount: Number(formattedToken0), token1Amount: Number(formattedToken1)};
        } catch (error) {
            console.error("Error fetching token amounts:", error);
            return null;
        }
    }

    // Function to get Uniswap V3 pool address
    async getPoolAddress(tokenA: string, tokenB: string, fee: number): Promise<string | null> {
        const factoryContract = new ethers.Contract("0x1F98431c8aD98523631AE4a59f267346ea31F984", [
            "function getPool(address tokenA, address tokenB, uint24 fee) external view returns (address)"
        ], provider);

        try {
            const poolAddress = await factoryContract.getPool(tokenA, tokenB, fee);
            return poolAddress !== ethers.ZeroAddress ? poolAddress : null;
        } catch (error) {
            console.error("Error fetching pool address:", error);
            return null;
        }
    }

    /**
     * Retrieves the Uniswap V3 pool address for an NFT position ID.
     */
    getPositionPoolAddress = async (nftPositionId: number): Promise<string | null> => {
        console.log(`🔍 Fetching position details for Pool ID: ${nftPositionId}`);

        // ABI for Position Manager (to fetch NFT details)
        const POSITION_MANAGER_ABI = [
            "function positions(uint256 tokenId) external view returns (uint96 nonce, address operator, address token0, address token1, uint24 fee, int24 tickLower, int24 tickUpper, uint128 liquidity, uint256 feeGrowthInside0LastX128, uint256 feeGrowthInside1LastX128, uint128 tokensOwed0, uint128 tokensOwed1)"
        ];

        // ABI for Factory (to check if a pool exists)
        const POOL_FACTORY_ABI = [
            "function getPool(address tokenA, address tokenB, uint24 fee) external view returns (address)"
        ];

        const provider = new ethers.JsonRpcProvider("https://arb1.arbitrum.io/rpc");
        const positionManager = new ethers.Contract(UNISWAP_V3_POSITION_MANAGER, POSITION_MANAGER_ABI, provider);
        const factoryContract = new ethers.Contract(UNISWAP_V3_FACTORY_ADDRESS, POOL_FACTORY_ABI, provider);

        try {
            // Fetch position details
            const positionData = await positionManager.positions(nftPositionId);
            let token0 = positionData[2]; // Address of token0
            let token1 = positionData[3]; // Address of token1
            const fee = positionData[4];  // Pool fee tier (500, 3000, 10000)

            // Validate Token Addresses
            if (!token0 || !token1 || token0 === ethers.ZeroAddress || token1 === ethers.ZeroAddress) {
                console.error("❌ Error: Invalid token addresses.");
                return null;
            }

            // Ensure Token Addresses are Sorted
            [token0, token1] = token0.toLowerCase() < token1.toLowerCase()
                ? [token0, token1]
                : [token1, token0];

            console.log(`✅ Sorted Tokens -> Token0: ${token0}, Token1: ${token1}, Fee: ${fee}`);

            // Check if the Pool Exists
            const existingPoolAddress = await factoryContract.getPool(token0, token1, fee);
            if (existingPoolAddress === ethers.ZeroAddress) {
                console.error("❌ No existing Uniswap V3 pool for this token pair and fee.");
                return null;
            }

            console.log(`🎯 Verified Pool Address: ${existingPoolAddress}`);

            return existingPoolAddress;
        } catch (error) {
            console.error("❌ Error fetching pool address:", error);
            return null;
        }
    };

    getTokenPriceRatio = async (poolAddress: string): Promise<number> => {
        if (!poolAddress) {
            console.error("Error: poolAddress is null or undefined.");
            return 0;
        }

        const UNISWAP_V3_POOL_ABI = [
            "function slot0() external view returns (uint160 sqrtPriceX96, int24 tick, uint16 observationIndex, uint16 observationCardinality, uint16 observationCardinalityNext, uint8 feeProtocol, bool unlocked)"
        ];

        const provider = new ethers.JsonRpcProvider("https://arb1.arbitrum.io/rpc");
        const poolContract = new ethers.Contract(poolAddress, UNISWAP_V3_POOL_ABI, provider);

        try {
            // Fetch `slot0()` from Uniswap V3 Pool
            const slot0Data = await poolContract.slot0();

            // Extract `sqrtPriceX96` (BigInt)
            const sqrtPriceX96 = BigInt(slot0Data.sqrtPriceX96); // Ensure correct type

            // Correct Price Ratio Calculation using BigInt
            const priceRatioBigInt = (sqrtPriceX96 ** 2n) / (2n ** 192n);

            // Convert BigInt to Number Safely
            const priceRatio = Number(priceRatioBigInt);

            console.log(`✅ Token Price Ratio (Token1/Token0): ${priceRatio}`);
            return priceRatio;
        } catch (error) {
            console.error("❌ Error fetching token price ratio:", error);
            return 0; // Return 0 in case of an error
        }
    };

    async getMintBlock(positionId: number): Promise<ethers.Block | null> {
        try {
            const transferEventSignature = ethers.keccak256(
                ethers.toUtf8Bytes("Transfer(address,address,uint256)")
            );

            const logs = await provider.getLogs({
                address: UNISWAP_V3_POSITION_MANAGER,
                topics: [
                    transferEventSignature,
                    ethers.zeroPadValue("0x0000000000000000000000000000000000000000", 32), // "from" address (0x0 indicates minting)
                    null,
                    ethers.zeroPadValue(ethers.toBeHex(positionId), 32), // Token ID (position ID)
                ],
                fromBlock: 0,
                toBlock: "latest",
            });

            if (logs.length === 0) {
                console.log(`No Transfer event found for position ID ${positionId}.`);
                return null;
            }

            // Get the block number of the first log (mint event)
            const mintBlockNumber = logs[0].blockNumber;
            const mintBlock = await provider.getBlock(mintBlockNumber);

            if (!mintBlock) {
                console.log(`Could not retrieve block information for block number ${mintBlockNumber}.`);
                return null;
            }

            console.log(`Position ${positionId} minted at block number ${mintBlockNumber}.`);
            return mintBlock;
        } catch (error) {
            console.error("Error fetching mint block:", error);
            return null;
        }
    }





    // Function to get all events related to a Uniswap V3 position
    async getAllUniswapV3Events(tokenId: number) {
        // ABI with all relevant events
        const POSITION_MANAGER_ABI = [
            "event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)",
            "event IncreaseLiquidity(uint256 indexed tokenId, uint128 liquidity, uint256 amount0, uint256 amount1)",
            "event DecreaseLiquidity(uint256 indexed tokenId, uint128 liquidity, uint256 amount0, uint256 amount1)",
            "event Collect(uint256 indexed tokenId, address recipient, uint256 amount0, uint256 amount1)",
            "event Burn(address indexed owner, uint256 indexed tokenId)"
        ];

        const provider = new ethers.JsonRpcProvider(ARBITRUM_RPC_URL);
        const positionManager = new ethers.Contract(UNISWAP_V3_POSITION_MANAGER, POSITION_MANAGER_ABI, provider);

        // Querying position-related events
        const transferEvents = await positionManager.queryFilter(positionManager.filters.Transfer(null, null, tokenId));
        const increaseLiquidity = await positionManager.queryFilter(positionManager.filters.IncreaseLiquidity(tokenId));
        const decreaseLiquidity = await positionManager.queryFilter(positionManager.filters.DecreaseLiquidity(tokenId));
        const collectEvents = await positionManager.queryFilter(positionManager.filters.Collect(tokenId));
        const burnEvents = await positionManager.queryFilter(positionManager.filters.Burn(null, tokenId));

        return {
            transfers: transferEvents,
            increaseLiquidity: increaseLiquidity,
            decreaseLiquidity: decreaseLiquidity,
            collect: collectEvents,
            burn: burnEvents
        };
    }

    // Fetch Position Events from blockchain logs
    async fetchAllPositionEvents(positionId: number): Promise<PositionEventsResult> {
        if (positionId <= 0) {
            console.error("Invalid position ID. Must be a positive integer.");
            return {
                groupedEvents: {
                    mint: [],
                    burn: [],
                    collect: [],
                    increaseLiquidity: [],
                    decreaseLiquidity: [],
                    transfer: [],
                    unknown: []
                }
            };
        }

        // Event Type Mapping
        function getEventType(topic: string): keyof GroupedEvents {
            const eventSignatures: { [key: string]: keyof GroupedEvents } = {
                "0x7a1a7a49a0dc87bd7fd90f9d747d4920d03ef74cf2cd1d36cfcbb857a83dbd76": "mint",
                "0x0c396cd989d61f2305b4e3eec856b0867921d9e49a76906a85a4b1baf7f70e72": "burn",
                "0x40d0efd1a53d60ecbf40971b9daf7dc90178c3aadc7aab1765632738fa8b8f01": "collect",
                "0x3067048beee31b25b2f1681f88dac838c8bba36af25bfb2b7cf7473a5847e35f": "increaseLiquidity",
                "0x8e8a7f2b32a003d74d38d4f142fc095716f3e1bf21b8cd8469c4f2fd7f32c46a": "decreaseLiquidity",
                "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef": "transfer"
            };
            return eventSignatures[topic] || "unknown";
        }

        // Decoding Function
        function decodeEventData(eventType: keyof GroupedEvents, data: string): any {
            const abiCoder = ethers.AbiCoder.defaultAbiCoder();

            try {
                switch (eventType) {
                    case "mint":
                        return abiCoder.decode(["address", "uint256", "uint256"], data);

                    case "burn":
                        return abiCoder.decode(["address", "uint256", "uint256"], data);

                    case "collect":
                        return abiCoder.decode(["address", "uint256", "uint256"], data);

                    case "increaseLiquidity":
                        return abiCoder.decode(["uint256", "uint256", "uint256"], data);

                    case "decreaseLiquidity":
                        return abiCoder.decode(["uint256", "uint256", "uint256"], data);

                    case "transfer":
                        return abiCoder.decode(["address", "address", "uint256"], data);

                    default:
                        return {};
                }
            } catch (error) {
                console.error(`Error decoding ${eventType} event:`, error);
                return {};
            }
        }

        try {
            const latestBlock = await provider.getBlockNumber();
            const fromBlock = 22000;

            console.log(`Fetching all events for position ${positionId} from block ${fromBlock} to ${latestBlock}...`);

            // Query all logs in a single request
            const logs = await provider.getLogs({
                address: UNISWAP_V3_POSITION_MANAGER,
                fromBlock,
                toBlock: latestBlock,
                topics: [
                    null,
                    ethers.zeroPadValue(ethers.toBeHex(positionId), 32)
                ]
            });

            if (logs.length === 0) {
                console.log(`No events found for position ${positionId}.`);
                return {
                    groupedEvents: {
                        mint: [],
                        burn: [],
                        collect: [],
                        increaseLiquidity: [],
                        decreaseLiquidity: [],
                        transfer: [],
                        unknown: []
                    }
                };
            }

            // Fetch all block timestamps in parallel
            const blocks = await Promise.all(logs.map(log => provider.getBlock(log.blockNumber)));

            let mintBlock: number | undefined;
            let mintTimestamp: number | undefined;
            const groupedEvents: GroupedEvents = {
                mint: [],
                burn: [],
                collect: [],
                increaseLiquidity: [],
                decreaseLiquidity: [],
                transfer: [],
                unknown: []
            };

            logs.forEach((log, index) => {
                const block = blocks[index];
                if (!block) {
                    console.warn(`Block ${log.blockNumber} not found. Skipping event.`);
                    return;
                }

                const eventType = getEventType(log.topics[0]);
                const decodedData = decodeEventData(eventType, log.data);

                const logEvent: LogEvent = {
                    blockNumber: log.blockNumber,
                    timestamp: block.timestamp,
                    transactionHash: log.transactionHash,
                    eventType,
                    decodedData
                };

                if (eventType === "mint" && !mintBlock) {
                    mintBlock = log.blockNumber;
                    mintTimestamp = block.timestamp;
                }

                groupedEvents[eventType].push(logEvent);
            });

            console.log(`Fetched and grouped ${logs.length} events for position ${positionId}.`);

            return {mintBlock, mintTimestamp, groupedEvents};
        } catch (error) {
            console.error(`Error fetching events for position ${positionId}:`, error);
            return {
                groupedEvents: {
                    mint: [],
                    burn: [],
                    collect: [],
                    increaseLiquidity: [],
                    decreaseLiquidity: [],
                    transfer: [],
                    unknown: []
                }
            };
        }
    }

    fetchUniswapV3PoolsForWallet = async (walletAddress: string): Promise<number[]> => {
        try {
            const provider = new JsonRpcProvider(ARBITRUM_RPC_URL);
            const contractAddress = "0xc36442b4a4522e871399cd717abdd847ab11fe88"; // Uniswap V3 Positions NFT Contract
            const transferTopic = keccak256(toUtf8Bytes("Transfer(address,address,uint256)")); // Hash of Transfer event

            const latestBlock = await provider.getBlockNumber();
            const blockStep = 500; // Max allowed range per request
            const fromBlock = 0x55f0; // Adjust if needed
            const toBlock = latestBlock;

            const positionIds = new Set<number>(); // Use a Set to avoid duplicates

            for (let start = fromBlock; start < toBlock; start += blockStep) {
                const end = Math.min(start + blockStep, toBlock);
                console.log(`Fetching logs from ${start} to ${end}...`);

                const logs = await provider.getLogs({
                    address: contractAddress,
                    fromBlock: toBeHex(start), // Ethers v6 requires `toBeHex`
                    toBlock: toBeHex(end),
                    topics: [transferTopic, null, [`0x000000000000000000000000${walletAddress.slice(2).toLowerCase()}`]],
                });

                logs.forEach((log) => {
                    if (log.topics.length === 3) {
                        // The 3rd topic (log.topics[2]) contains the position ID as a hex string
                        const positionId = parseInt(log.topics[2], 16);
                        positionIds.add(positionId);
                    }
                });
            }

            return Array.from(positionIds); // Convert Set to an array before returning
        } catch (error) {
            console.error("Error fetching Uniswap V3 position IDs:", error);
            return [];
        }
    }
}

export default new CryptoPositionsService();