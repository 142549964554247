import React, {useState, useEffect} from 'react';
import {useParams, useNavigate, Link} from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
    Badge,
    Table,
    Nav,
    Spinner,
    OverlayTrigger,
    Tooltip,
    Form
} from 'react-bootstrap';
import {
    ArrowLeft, ExternalLink, Info, TrendingUp, DollarSign, Percent, Clock, BarChart2, CreditCard,
    Globe, ChevronRight, Star, ChevronDown, Activity
} from 'lucide-react';
import {Pool} from '../../../model/crypto/CryptoModels';
import {
    formatFee,
    formatNumber,
    fetchPoolsData,
    fetchPoolHistoricalData
} from '../../../service/crypto/pool/PoolService';
import {
    TimePeriod,
    toNumber,
    getAprColor,
    getNetworkBadgeInfo,
    calculateTimeBasedApr
} from '../../../components/crypto/pools/PoolUtils';
import {fetchTimePeriodApr} from '../../../service/crypto/pool/TimePeriodService';
import Layout from '../../../components/layout/Layout';
import PoolCharts from './../../../components/crypto/pools/charts/PoolCharts';
import {calculateVolatilityIndex} from "../../../service/crypto/pool/PollAnalyticsService";
import VolatilityIndexChart from "../../../components/crypto/pools/charts/VolatilityIndexChart";
import LiquidityDistributionChart from "../../../components/crypto/pools/charts/LiquidityDistributionChart";

// Function to fetch a specific pool by ID and network
const fetchPoolDetail = async (poolId: string, networkId: string): Promise<Pool | null> => {
    try {
        // First fetch all pools from the specific network
        const allPools = await fetchPoolsData(networkId);

        // Find the pool with the matching ID
        const pool = allPools.find(pool => pool.id === poolId);

        if (!pool) {
            console.error(`Pool with ID ${poolId} not found on network ${networkId}`);
            return null;
        }

        return pool;
    } catch (error) {
        console.error('Error fetching pool details:', error);
        throw error;
    }
};

const PoolDetailsPage: React.FC = () => {
    const {id, network} = useParams<{ id: string; network: string }>();
    const navigate = useNavigate();
    const [pool, setPool] = useState<Pool | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<string>('overview');
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriod>('1year');
    const [isFavorite, setIsFavorite] = useState<boolean>(false);

    // State for period-specific APR
    const [currentApr, setCurrentApr] = useState<number>(0);
    const [isLoadingApr, setIsLoadingApr] = useState<boolean>(false);

    // Time period options for APR calculation
    const timePeriodOptions = [
        {value: '1day', label: '1 Day'},
        {value: '7days', label: '7 Days'},
        {value: '1month', label: '1 Month'},
        {value: '1year', label: '1 Year'},
        {value: 'alltime', label: 'All Time'}
    ];

    useEffect(() => {
        const loadPoolData = async () => {
            if (!id || !network) {
                setErrorMessage('Invalid pool ID or network');
                setIsLoading(false);
                return;
            }

            setIsLoading(true);
            setErrorMessage(null);

            try {
                const poolData = await fetchPoolDetail(id, network);

                if (poolData) {
                    setPool(poolData);

                    // Check if this pool is in favorites
                    try {
                        const storedFavorites = localStorage.getItem('favoritePools');
                        if (storedFavorites) {
                            const favorites = JSON.parse(storedFavorites);
                            setIsFavorite(favorites.includes(id));
                        }
                    } catch (error) {
                        console.error('Error checking favorites:', error);
                    }
                } else {
                    setErrorMessage('Failed to load pool data');
                }
            } catch (error) {
                console.error('Error in loadPoolData:', error);
                setErrorMessage('An error occurred while loading pool data');
            } finally {
                setIsLoading(false);
            }
        };

        loadPoolData();
    }, [id, network]);

    // Fetch APR data for the selected time period
    useEffect(() => {
        const fetchAprData = async () => {
            if (!id || !network) return;

            setIsLoadingApr(true);

            try {
                // Fetch period-specific APR directly from API
                const aprValue = await fetchTimePeriodApr(id, network, selectedTimePeriod);
                setCurrentApr(aprValue);
            } catch (error) {
                console.error('Error fetching APR data:', error);
                // Fall back to calculated APR
                // Only calculate APR if pool exists
                if (pool) {
                    const calculatedApr = calculateTimeBasedApr(pool, selectedTimePeriod);
                    setCurrentApr(calculatedApr);
                } else {
                    setCurrentApr(0); // Default to 0 if no pool data
                }
            } finally {
                setIsLoadingApr(false);
            }
        };

        fetchAprData();
    }, [id, network, selectedTimePeriod, pool]);


    // State for volatility index
    const [volatilityIndex, setVolatilityIndex] = useState<number>(0);

    // Fetch volatility index
    useEffect(() => {
        const fetchVolatilityIndex = async () => {
            if (!id || !network) return;

            try {
                const historicalData = await fetchPoolHistoricalData(id, network, '1y');
                const volatility = calculateVolatilityIndex(historicalData);
                setVolatilityIndex(volatility);
            } catch (error) {
                console.error('Error calculating volatility index:', error);
            }
        };

        fetchVolatilityIndex();
    }, [id, network]);


    const aprColor = getAprColor(currentApr);

    // Toggle favorite status
    const toggleFavorite = () => {
        if (!id) return;

        try {
            const storedFavorites = localStorage.getItem('favoritePools');
            const favorites = storedFavorites ? JSON.parse(storedFavorites) : [];

            let updatedFavorites;
            if (isFavorite) {
                // Remove from favorites
                updatedFavorites = favorites.filter((poolId: string) => poolId !== id);
            } else {
                // Add to favorites
                updatedFavorites = [...favorites, id];
            }

            localStorage.setItem('favoritePools', JSON.stringify(updatedFavorites));
            setIsFavorite(!isFavorite);
        } catch (error) {
            console.error('Error updating favorites:', error);
        }
    };

    // Handle APR time period change
    const handleTimePeriodChange = (timePeriod: TimePeriod) => {
        setSelectedTimePeriod(timePeriod);
    };

    // Get Uniswap URL for this pool
    const getUniswapUrl = () => {
        if (!pool || !network) return '#';

        const baseUrl = 'https://app.uniswap.org/explore/pools/';
        return `${baseUrl}${network}/${id}`;
    };

    // Get time period text
    const getTimePeriodText = (timePeriod: TimePeriod): string => {
        switch (timePeriod) {
            case '1day':
                return '24h';
            case '7days':
                return '7d';
            case '1month':
                return '30d';
            case '1year':
                return 'Annual';
            case 'alltime':
                return 'All-time';
            default:
                return 'APR';
        }
    };

    const renderContent = () => {
        // Render loading state
        if (isLoading) {
            return (
                <Container fluid className="py-5">
                    <div className="text-center text-body py-5">
                        <Spinner animation="border" variant="primary" className="mb-3"/>
                        <h3>Loading Pool Data</h3>
                        <p className="text-secondary">Fetching the latest information...</p>
                    </div>
                </Container>
            );
        }

        // Render error state
        if (errorMessage || !pool) {
            return (
                <Container fluid className="py-5">
                    <div className="text-center py-5">
                        <div className="mb-4 text-danger">
                            <Info size={48}/>
                        </div>
                        <h3>Error Loading Pool</h3>
                        <p className="text-secondary">{errorMessage || 'Pool data not available'}</p>
                        <Button
                            onClick={() => navigate('/crypto/pools')}
                            variant="primary"
                            className="mt-3"
                        >
                            <ArrowLeft size={16} className="me-2"/>
                            Back to Pools
                        </Button>
                    </div>
                </Container>
            );
        }

        return (
            <Container fluid>
                {/* Breadcrumb and back button */}
                {/* Top Navigation and Breadcrumb */}
                <div className="d-flex flex-wrap align-items-center mb-3 gap-2">
                    <Button
                        onClick={() => navigate('/crypto/pools')}
                        variant="outline-primary"
                        className="d-flex align-items-center"
                    >
                        <ArrowLeft size={16} className="me-2"/>
                        Back to Pools
                    </Button>

                    <div className="breadcrumb-separator d-flex align-items-center text-secondary">
                        <Link to="/crypto/pools" className="text-decoration-none">
                            Pools
                        </Link>
                        <ChevronRight size={16} className="mx-1"/>
                        <span>{pool.token0.symbol}/{pool.token1.symbol}</span>
                    </div>
                </div>


                {/* Key metrics cards */}
                <Row className="mb-4">
                    <Col md={3} className="mb-3 mb-md-0">
                        <Card className="h-100 shadow-sm border-0">
                            <Card.Body className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <DollarSign size={20} className="text-success me-2"/>
                                    <h6 className="mb-0">Total Value Locked</h6>
                                </div>
                                <div className="mt-2">
                                    <h2>${formatNumber(pool.tvlUSD, 2)}</h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3 mb-md-0">
                        <Card className="h-100 shadow-sm border-0">
                            <Card.Body className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <TrendingUp size={20} className="text-primary me-2"/>
                                    <h6 className="mb-0">24h Volume</h6>
                                </div>
                                <div className="mt-2">
                                    <h2>${formatNumber(pool.volumeUSD24h, 2)}</h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3 mb-md-0">
                        <Card className="h-100 shadow-sm border-0">
                            <Card.Body className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <Percent size={20} className="text-warning me-2"/>
                                    <h6 className="mb-0">
                                        APR
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip id="apr-tooltip">
                                                    <div className="text-start">
                                                        <strong>APR Time Period</strong>
                                                        <p className="mb-1 mt-1">
                                                            Currently
                                                            showing: {timePeriodOptions.find(t => t.value === selectedTimePeriod)?.label} APR
                                                        </p>
                                                    </div>
                                                </Tooltip>
                                            }
                                        >
                                            <Info size={14} className="ms-1 text-secondary opacity-75"/>
                                        </OverlayTrigger>
                                    </h6>
                                </div>
                                <div className="d-flex flex-column flex-sm-row align-items-sm-center">
                                    {isLoadingApr ? (
                                        <div className="d-flex align-items-center mb-2 mb-sm-0">
                                            <Spinner animation="border" variant={aprColor} size="sm" className="me-2"/>
                                            <h2 className="mb-0">Loading...</h2>
                                        </div>
                                    ) : (
                                        <h2 className={`text-${aprColor} mb-2 mb-sm-0`}>{currentApr.toFixed(2)}%</h2>
                                    )}
                                    <div className="d-flex flex-wrap ms-sm-3">
                                        {timePeriodOptions.map(option => (
                                            <Badge
                                                key={option.value}
                                                bg={selectedTimePeriod === option.value ? 'primary' : 'secondary'}
                                                text="white"
                                                className="me-1 mb-1"
                                                style={{cursor: 'pointer'}}
                                                onClick={() => handleTimePeriodChange(option.value as TimePeriod)}
                                            >
                                                <span className="d-none d-sm-inline">{option.label}</span>
                                                <span className="d-sm-none">
                        {option.value === '1day' ? '24H' :
                            option.value === '7days' ? '7D' :
                                option.value === '1month' ? '30D' :
                                    option.value === '1year' ? '1Y' : 'ALL'}
                    </span>
                                            </Badge>
                                        ))}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={3} className="mb-3 mb-md-0">
                        <Card className="h-100 shadow-sm border-0">
                            <Card.Body className="d-flex flex-column">
                                <div className="d-flex align-items-center mb-2">
                                    <CreditCard size={20} className="text-info me-2"/>
                                    <h6 className="mb-0">24h Fees</h6>
                                </div>
                                <div className="mt-2">
                                    <h2>${formatNumber(pool.feesUSD24h || 0, 2)}</h2>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Tabs */}
                <Row>
                    <Col>
                        <Card className="shadow-sm border-0">
                            <Card.Header className="bg-transparent">
                                <Nav variant="tabs" activeKey={activeTab} onSelect={(k) => k && setActiveTab(k)}>

                                    <Nav.Item>
                                        <Nav.Link eventKey="overview" className="d-flex align-items-center">
                                            <BarChart2 size={16} className="me-2"/>
                                            Overview
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="volatility" className="d-flex align-items-center">
                                            <BarChart2 size={16} className="me-2"/>
                                            Volatility
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="liquidity" className="d-flex align-items-center">
                                            <BarChart2 size={16} className="me-2"/>
                                            Liquidity Distribution
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="transactions" className="d-flex align-items-center">
                                            <Clock size={16} className="me-2"/>
                                            Transactions
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Card.Header>

                            <Card.Body>


                                {activeTab === 'overview' && (
                                    <div>
                                        {/*   <h3 className="mb-4">Pool Overview</h3>*/}


                                        <Row>
                                            <Col lg={6}>
                                                <h5 className="mb-3">Pool Details</h5>
                                                <Table striped bordered responsive className="table-hover">
                                                    <tbody>
                                                    <tr>
                                                        <td className="fw-bold" style={{width: '35%'}}>Pool Address</td>
                                                        <td className="text-break">
                                                            {id}

                                                            <a href={`https://etherscan.io/address/${id}`}
                                                               target="_blank"
                                                               rel="noopener noreferrer"
                                                               className="ms-2"
                                                            >
                                                                <ExternalLink size={14}/>
                                                            </a>
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Token 0</td>
                                                        <td>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <span
                                                                        className="fw-medium">{pool.token0.symbol}</span> - {pool.token0.name}
                                                                </div>

                                                                <a href={`https://etherscan.io/address/${pool.token0.address}`}
                                                                   target="_blank"
                                                                   rel="noopener noreferrer"
                                                                >
                                                                    <ExternalLink size={14}/>
                                                                </a>
                                                            </div>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Token 1</td>
                                                        <td>
                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <span
                                                                        className="fw-medium">{pool.token1.symbol}</span> - {pool.token1.name}
                                                                </div>

                                                                <a href={`https://etherscan.io/address/${pool.token1.address}`}
                                                                   target="_blank"
                                                                   rel="noopener noreferrer"
                                                                >
                                                                    <ExternalLink size={14}/>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Fee Tier</td>
                                                        <td>{formatFee(String(pool.feeTier))}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Protocol Version</td>
                                                        <td>Uniswap {pool.protocol.toUpperCase()}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">Network</td>
                                                        <td>
                                                            {pool.network && (
                                                                <Badge
                                                                    bg={getNetworkBadgeInfo(pool.network).bg}
                                                                    className="px-2 py-1 d-flex align-items-center"
                                                                    style={{width: 'fit-content'}}
                                                                >
                                                                    <Globe size={12} className="me-1"/>
                                                                    {pool.network.charAt(0).toUpperCase() + pool.network.slice(1)}
                                                                </Badge>
                                                            )}
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>

                                            <Col lg={6}>
                                                <h5 className="mb-3">Performance Metrics</h5>
                                                <Table striped bordered responsive className="table-hover">
                                                    <tbody>
                                                    <tr>
                                                        <td className="fw-bold" style={{width: '40%'}}>TVL</td>
                                                        <td>${formatNumber(pool.tvlUSD, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">24h Volume</td>
                                                        <td>${formatNumber(pool.volumeUSD24h, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">7d Volume</td>
                                                        <td>${formatNumber(pool.volumeUSD7d || 0, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">30d Volume</td>
                                                        <td>${formatNumber(pool.volumeUSD30d || 0, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">24h Fees</td>
                                                        <td>${formatNumber(pool.feesUSD24h || 0, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">7d Fees</td>
                                                        <td>${formatNumber(pool.feesUSD7d || 0, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">30d Fees</td>
                                                        <td>${formatNumber(pool.feesUSD30d || 0, 2)}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className="fw-bold">
                                                            {getTimePeriodText(selectedTimePeriod)} APR
                                                        </td>
                                                        <td>
                                                            <Badge
                                                                bg={aprColor}
                                                                className="py-2 px-2 d-flex align-items-center"
                                                                style={{width: 'fit-content'}}
                                                            >
                                                                {currentApr.toFixed(2)}%
                                                            </Badge>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </div>
                                )}


                                {activeTab === 'volatility' && (
                                    <div>
                                        {/* Volatility Index Chart */}
                                        <Row className="mt-4">
                                            <Col>
                                                <Card className="shadow-sm border-0">
                                                    <Card.Header className="bg-transparent">
                                                        <div className="d-flex align-items-center">
                                                            <Activity size={16} className="me-2"/>
                                                            <h5 className="mb-0">
                                                                Volatility Index
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={
                                                                        <Tooltip id="volatility-index-tooltip">
                                                                            <div className="text-start">
                                                                                <p className="mb-1">
                                                                                    The Volatility Index measures the
                                                                                    degree of variation of the
                                                                                    pool&apos;s token ratio over time. A
                                                                                    higher index indicates greater
                                                                                    volatility.
                                                                                </p>
                                                                                <ul className="mb-0">
                                                                                    <li>Calculated from historical token
                                                                                        ratio data
                                                                                    </li>
                                                                                    <li>Annualized standard deviation of
                                                                                        daily returns
                                                                                    </li>
                                                                                    <li>Expressed as a percentage</li>
                                                                                </ul>
                                                                            </div>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <Info size={14}
                                                                          className="ms-1 text-secondary opacity-75 cursor-pointer"/>
                                                                </OverlayTrigger>
                                                            </h5>
                                                        </div>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <VolatilityIndexChart
                                                            poolId={id || ""}
                                                            network={network || "ethereum"}
                                                            timePeriod={selectedTimePeriod}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                )}


                                {activeTab === 'liquidity' && (
                                    <div>
                                        {/* Liquidity Distribution Chart */}
                                        <Row className="mt-4">
                                            <Col>
                                                <Card className="shadow-sm border-0">
                                                    <Card.Body>
                                                        <LiquidityDistributionChart
                                                            poolId={id || ""}
                                                            network={network || "arbitrum"}
                                                            pool={pool}
                                                        />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                )}


                                {activeTab === 'transactions' && (
                                    <div>
                                        <h3 className="mb-4">Recent Transactions</h3>
                                        <div className="text-center py-4 rounded bg-opacity-25 bg-secondary">
                                            <p className="mb-0">
                                                Transaction history coming soon
                                            </p>
                                        </div>
                                    </div>
                                )}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Historical Performance Card - Moved to separate card */}
                <Row className="mt-4">
                    <Col>
                        <Card className="shadow-sm border-0">
                            <Card.Header className="bg-transparent">
                                <div className="d-flex align-items-center">
                                    <Activity size={16} className="me-2"/>
                                    <h5 className="mb-0">Historical Performance</h5>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <PoolCharts pool={pool}/>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    };

    return <Layout>{renderContent()}</Layout>;
};

export default PoolDetailsPage;