import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../../../components/layout/Layout';
import PoolsExplorer from '../../../components/crypto/pools/PoolsExplorer';


const CryptoPoolsPage = () => {
    return (
        <Layout>
            <Container fluid className="py-4">
                <Row className="mb-4">
                    <Col>
                        <h1 className="fs-2 fw-bold text-body">Uniswap Liquidity Pools</h1>
                        <p className="text-secondary">Explore liquidity pools on different networks</p>
                    </Col>
                </Row>

                <PoolsExplorer />
            </Container>
        </Layout>
    );
};

export default CryptoPoolsPage;