import React, { createContext, useEffect, useState } from 'react';
import './App.scss';
import { Route, Routes, Navigate } from 'react-router-dom';
import Profile from "./components/profile/ProfileComponent";
import { Toaster } from "react-hot-toast";
import NotFound from "./components/error-pages/NotFound";
import InfoComponent from "./components/info/InfoComponent";
import FaqComponent from "./components/public/faq/FaqComponent";
import OfflineComponent from "./components/public/offline/OfflineComponent";
import HelpCenterComponent from "./components/public/help-center/HelpCenterComponent";
import LogoutComponent from "./components/routing/LogoutComponent";
import ProfileEditComponent from "./components/profile/edit/ProfileEditComponent";
import StockPage from "./components/stock/StockPage";
import WatchlistComponent from "./components/watchlist/WatchlistComponent";
import ConnectionsPage from "./components/user/ConnectionsPage";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import SearchPage from "./pages/search/SearchPage";
import SignInPage from "./pages/public/sign-in/SignInPage";
import SignUpPage from "./pages/public/sign-up/SignUpPage";
import Homepage from "./pages/public/homepage/Homepage";
import Homepage2 from "./pages/public/homepage/Homepage2";
import PublicRoutes from "./components/routing/PublicRoutes";
import ProtectedRoutes from "./components/routing/ProtectedRoutes";
import DashboardPage from "./pages/DashboardPage";
import OAuth2RedirectHandler from "./components/OAuth2RedirectHandler";
import PrivacyPolicyPage from "./pages/public/privacy-policy/PrivacyPolicyPage";
import TermsAndConditionsPage from "./pages/public/terms-and-conditions/TermsAndConditionsPage";
import StocksPage from "./pages/StocksPage";
import { CookiesProvider, useCookies } from "react-cookie";
import { PositionsPage } from "./pages/positions/PositionsPage";
import { CryptoPage } from "./pages/crypto/CryptoPage";
import CryptoPositionArbitrumPage from "./pages/crypto/CryptoPositionArbitrumPage";
import { WalletProvider } from './components/context/WalletContext';
import BlockLivesMatterPage from "./pages/public/BlockLivesMatterPage";
import UniswapBestPoolRangesComponent from "./components/crypto/uniswap/predictions/UniswapBestPoolRangesComponent";
import UniswapLiquidityChart from "./components/crypto/pools/charts/UniswapLiquidityChart";
import UniswapPositionsManager from "./pages/crypto/positions/UniswapPositionsManager";
import UniswapV3PositionFinder from "./pages/public/crypto/UniswapV3PositionFinder";
import AlertComponent from "./components/Alert/AlertComponent";
import {CryptoWalletsPage} from "./pages/crypto/CryptoWalletsPage";
import AuthService from "./service/auth/AuthService";
import {CryptoPositionsPage} from "./pages/crypto/positions/CryptoPositionsPage";
import CryptoPoolsPage from "./pages/crypto/pools/CryptoPoolsPage";
import PoolDetailsPage from './pages/crypto/pools/PoolDetailsPage';
import LandingPage from "./pages/public/LandingPage";


// Create a new query client
const queryClient = new QueryClient();

// Define theme context types
type ThemeContextType = "light" | "dark";
type ContextType = {
    theme: string,
    setTheme: (theme: string) => void
};

// Create theme context
export const ThemeContext = createContext<ContextType | undefined>(undefined);

function App() {
    // Theme state management
    const [theme, setTheme] = useState<ContextType["theme"]>("light");
    const [cookie, setCookie, removeCookie] = useCookies(['theme']);

    // Initialize theme from cookie
    useEffect(() => {
        setTheme(cookie.theme === 'dark' ? 'dark' : 'light');
    }, [cookie.theme]);

    return (
        <QueryClientProvider client={queryClient}>
            <WalletProvider>
                <ThemeContext.Provider value={{ theme, setTheme }}>
                    <CookiesProvider />
                    <section className="bg-body-tertiary" data-bs-theme={theme}>
                        <Toaster gutter={15} position="top-center" containerStyle={{ top: '65px' }} />

                        <Routes>
                            {/* Root path redirect based on authentication */}
                            <Route path='/' element={
                                AuthService.isLoggedIn() ? <Navigate to="/profile" replace /> : <Homepage2 />
                            } />

                            {/* Public routes (accessible when not logged in) */}
                            <Route element={<PublicRoutes redirect="/profile" />}>
                                <Route path='/home' element={<Homepage />} />
                                <Route path='/sign-in' element={<SignInPage />} />
                                <Route path='/sign-up' element={<SignUpPage />} />
                            </Route>

                            {/* Protected routes (require authentication) */}
                            <Route element={<ProtectedRoutes redirect="/sign-in" />}>
                                <Route path='/dashboard' element={<DashboardPage />} />
                                <Route path='/positions' element={<PositionsPage />} />
                                <Route path='/profile' element={<Profile />} />
                                <Route path='/profile/edit' element={<ProfileEditComponent />} />
                                <Route path='/watchlist' element={<WatchlistComponent />} />
                                <Route path='/connections' element={<ConnectionsPage />} />
                            </Route>

                            {/* Public accessible routes */}
                            <Route path='/landing' element={<LandingPage />} />
                            <Route path='/block-lives-matter' element={<BlockLivesMatterPage />} />
                            <Route path='/stock/:symbol?' element={<StockPage />} />
                            <Route path='/stock-market' element={<StocksPage />} />
                            <Route path='/crypto' element={<CryptoPage />} />
                            <Route path='/crypto/wallets' element={<CryptoWalletsPage />} />
                            <Route path='/crypto/positions' element={<CryptoPositionsPage />} />
                            <Route
                                path='/crypto/pool/:network/:id'
                                element={<PoolDetailsPage />}
                            />
                            <Route path='/crypto/bestrange' element={<UniswapBestPoolRangesComponent />} />
                            <Route path='/crypto/pools' element={<CryptoPoolsPage />} />
                            <Route
                                path='/crypto/uniswap/positions/v3/arbitrum/:id'
                                element={<CryptoPositionArbitrumPage />}
                            />
                            <Route path='/user/:id?' element={<Profile />} />
                            <Route path='/search' element={<SearchPage />} />
                            <Route path='/info' element={<InfoComponent />} />
                            <Route path='/faq' element={<FaqComponent />} />
                            <Route path='/help' element={<HelpCenterComponent />} />
                            <Route
                                path='/best-liquidity-pool-finder-strategy'
                                element={<UniswapV3PositionFinder />}
                            />
                            <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
                            <Route path='/terms-and-conditions' element={<TermsAndConditionsPage />} />
                            <Route path='/offline' element={<OfflineComponent />} />
                            <Route path='/logout' element={<LogoutComponent />} />
                            <Route path='/oauth2/redirect' element={<OAuth2RedirectHandler />} />

                            {/* Catch-all route for 404s */}
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </section>
                </ThemeContext.Provider>
            </WalletProvider>
        </QueryClientProvider>
    );
}

export default App;