import React, {useContext} from "react";
import {ThemeContext} from "../../../App";
import {useCookies} from "react-cookie";
import {NavLink, useNavigate} from "react-router-dom";
import {IUser} from "../../../model/User";
import AuthService from "../../../service/auth/AuthService";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import {Button, Image, NavDropdown, NavItem} from "react-bootstrap";
import {BsMoonStars, BsSun, BsCoin} from "react-icons/bs";


export default function OffcanvasMenu(props: any) {

    const {children} = props;

    const context = useContext(ThemeContext);
    const [themeCookies, setThemeCookie, removeThemeCookie] = useCookies(['theme']);
    const navigate = useNavigate();
    const user: IUser = AuthService.getCurrentUser();

    /*    const {keycloak, initialized} = useKeycloak()*/


    function logout() {
        AuthService.logout();
        navigate("/")
    }


    return (
        <>
            <Navbar.Toggle className="d-md-none px-0 w-100" aria-controls="nav-offcanvas"/>
            <Navbar.Offcanvas
                className="d-md-none text-bg-dark w-50"
                id="nav-offcanvas"
                aria-labelledby="nav-offcanvas"
                placement="end"
            >
                <Offcanvas.Header closeButton closeVariant="white">
                    <Offcanvas.Title id="nav-offcanvas">FinMates</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="h-100 flex-grow-1 ms-2 justify-content-between">

                        {AuthService.isLoggedIn() ?
                            <section className=" align-top">
                                <Nav.Link href="/home">Dashboard</Nav.Link>

                                {/* Crypto Dropdown Menu */}
                                <NavDropdown
                                    title="Crypto"
                                    id="crypto-dropdown"
                                    className="nav-item-dropdown-dark"
                                    menuVariant="dark"
                                    align="end"
                                >
                                    <NavDropdown.Item
                                        href="/crypto/positions"
                                    >
                                        My Positions
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        href="/crypto/pools"
                                    >
                                        My Pools
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        href="/crypto/wallet"
                                    >
                                        My Wallet
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link href="/profile">My Profile</Nav.Link>
                                <Nav.Link href="/messages">My Messages</Nav.Link>
                                <Nav.Link href="/connections">My Connections</Nav.Link>

                                <Nav.Link href="/watchlist">Watchlist</Nav.Link>
                            </section>
                            :
                            <section>
                                <Nav.Link href="/faq">FAQs</Nav.Link>
                                <Nav.Link className="me-2" eventKey={2} href="#memes">About</Nav.Link>

                                {/* Crypto Dropdown Menu */}
                                <NavDropdown
                                    title="Crypto"
                                    id="crypto-dropdown"
                                    className="nav-item-dropdown-dark"
                                    menuVariant="dark"
                                    align="end"
                                >
                                    <NavDropdown.Item
                                        href="/crypto/positions"
                                    >
                                        My Positions
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        href="/crypto/pools"
                                    >
                                        My Pools
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        href="/crypto/wallet"
                                    >
                                        My Wallet
                                    </NavDropdown.Item>
                                </NavDropdown>

                                <hr className="text-light my-1"/>
                                <div className="d-flex d-inline-block pt-2 justify-content-center">
                                    <Nav.Link href="/sign-in">
                                        <Button variant="outline-light"
                                                className="mx-2 text-nowrap"> Login</Button>
                                    </Nav.Link>
                                    <Nav.Link href="/sign-up">
                                        <Button variant="primary"
                                                className="mx-2 text-nowrap"> Sign-up</Button>
                                    </Nav.Link>
                                </div>
                            </section>
                        }


                        {/*lower menu*/}

                        <section>
                            {AuthService.isLoggedIn() &&
                                <>
                                    <Nav.Link href="/profile/edit">Settings</Nav.Link>

                                    <hr className="text-light my-2"/>
                                    <NavItem onClick={logout} className="">Sign Out</NavItem>
                                </>
                            }

                            <hr className="text-light"/>

                            <NavItem
                                className="modeswitch-item d-flex justify-content-start gap-3 align-items-center ">

                                <span className="">Mode:</span>
                                <Button
                                    className={`btn-modeswitch nav-link text-primary-hover ${context?.theme === 'light' ? 'active' : ''}`}
                                    title="Light"
                                    onClick={() => {
                                        console.log("light")
                                        console.log("context theme: " + context?.theme)
                                        context?.setTheme("light")
                                        removeThemeCookie("theme")
                                        setThemeCookie("theme", "light")
                                    }}>
                                    <BsSun/>
                                </Button>
                                <Button
                                    className={`btn-modeswitch nav-link text-primary-hover  ${context?.theme === 'dark' ? 'active' : ''}`}
                                    title="Dark"
                                    onClick={() => {
                                        console.log("dark")
                                        console.log("context theme: " + context?.theme)
                                        context?.setTheme("dark")
                                        removeThemeCookie("theme")
                                        setThemeCookie("theme", "dark")
                                    }}>
                                    <BsMoonStars/>
                                </Button>

                            </NavItem>
                        </section>

                    </Nav>

                </Offcanvas.Body>
            </Navbar.Offcanvas>
        </>
    );
}