import axios, { AxiosError, AxiosResponse } from 'axios';
import toast from "react-hot-toast";
import AuthService from "../auth/AuthService";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const errorToast = (message: string) => toast.error(message, {position: "top-right"});

// Create axios instance
const axiosClient = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

// Add a request interceptor
axiosClient.interceptors.request.use(
    config => {
        const token = AuthService.getAccessToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        console.error('Request error:', error);
        return Promise.reject(error);
    }
);

// Create a custom event to display alerts in React components
export const showAlert = (variant: 'success' | 'danger' | 'warning' | 'info', message: string) => {
    const event = new CustomEvent('show-alert', {
        detail: { variant, message }
    });
    window.dispatchEvent(event);
};

// Add a response interceptor
axiosClient.interceptors.response.use(
    (response) => {
        // If this is a successful auth response, redirect immediately
        if (response.config.url?.includes('/auth/login') && response.status === 200) {
            // Immediate redirect handled by AuthService.login
            return response;
        }
        return response;
    },
    async (error) => {
        const originalRequest = error.config;

        // Handle expired token (401) or forbidden (403)
        if (error.response && (error.response.status === 401 || error.response.status === 403) && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                // Attempt to refresh the token
                await AuthService.refreshToken();

                // Retry the original request with the new token
                const token = AuthService.getAccessToken();
                originalRequest.headers.Authorization = `Bearer ${token}`;
                return axiosClient(originalRequest);
            } catch (refreshError) {
                // If refresh fails, log out the user
                console.error('Token refresh failed:', refreshError);
                AuthService.logout();

                // Show bootstrap alert via custom event
                //showAlert('danger', 'Your session has expired. Please sign in again.');
                toast.error('Your session has expired. Please sign in again.');

                // Redirect to login immediately
                window.location.href = '/sign-in';

                return Promise.reject(refreshError);
            }
        }

        // For other errors, display the error message if available using Bootstrap alert
        if (error.response && error.response.data && error.response.data.message) {
            toast('danger', error.response.data.message);
        } else if (error.message) {
            toast.error( error.message);
        } else {
            toast.error('An unexpected error occurred');
        }

        return Promise.reject(error);
    }
);

export default axiosClient;