import React, {useState, useEffect} from 'react';
import {Row, Col, Card, Form, Spinner, Badge, Modal} from 'react-bootstrap';
import {
    LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid,
    Tooltip as RechartsTooltip, Legend, ResponsiveContainer, Area, AreaChart,
    ReferenceLine
} from 'recharts';
import {Maximize2} from 'lucide-react';
import {Pool} from "../../../../model/crypto/CryptoModels";
import {formatNumber} from '../../../../service/crypto/pool/PoolService';
import {fetchPoolHistoricalData, PoolHistoricalData} from '../../../../service/crypto/pool/PoolService';

interface PoolChartsProps {
    pool: Pool;
}

const formatDate = (dateString: string, period: string) => {
    const date = new Date(dateString);

    switch (period) {
        case '1d':
            return date.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
        case '7d':
            return date.toLocaleDateString([], {month: 'short', day: 'numeric'});
        case '30d':
            return date.toLocaleDateString([], {month: 'short', day: 'numeric'});
        case '90d':
            return date.toLocaleDateString([], {month: 'short'});
        case '1y':
            return date.toLocaleDateString([], {month: 'short', year: 'numeric'});
        default:
            return dateString;
    }
};

// Function to format tooltip date properly
const formatTooltipDate = (timestamp: any, period = '30d') => {
    try {
        let date: Date;

        // Handle different timestamp formats
        if (typeof timestamp === 'string') {
            if (timestamp.includes('T')) {
                // ISO string
                date = new Date(timestamp);
            } else if (!isNaN(Number(timestamp))) {
                // String containing a number (Unix timestamp)
                const timeNumber = Number(timestamp);
                date = timeNumber.toString().length <= 10
                    ? new Date(timeNumber * 1000)  // Seconds to milliseconds
                    : new Date(timeNumber);        // Already in milliseconds
            } else {
                // Other string format, try to parse
                date = new Date(timestamp);
            }
        } else if (typeof timestamp === 'number') {
            // Numeric timestamp
            date = timestamp.toString().length <= 10
                ? new Date(timestamp * 1000)
                : new Date(timestamp);
        } else if (timestamp instanceof Date) {
            // Already a Date object
            date = timestamp;
        } else {
            // Fallback
            return String(timestamp);
        }

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            return String(timestamp);
        }

        // Format based on period
        switch (period) {
            case '1d':
                // For 1-day period, show proper local time with explicit formatting
                return date.toLocaleTimeString([], {
                    hour: 'numeric', // Use 'numeric' instead of '2-digit' for more natural display
                    minute: '2-digit',
                    hour12: true,    // Use 12-hour format with AM/PM
                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Explicitly use local time zone
                });

            case '7d':
                return `${date.toLocaleDateString([], {
                    month: 'short',
                    day: 'numeric'
                })} ${date.toLocaleTimeString([], {
                    hour: 'numeric',
                    minute: '2-digit',
                    hour12: true
                })}`;

            case '30d':
                return date.toLocaleDateString([], {
                    month: 'short',
                    day: 'numeric'
                });

            case '90d':
                return date.toLocaleDateString([], {
                    month: 'short',
                    day: 'numeric'
                });

            case '1y':
                return date.toLocaleDateString([], {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });

            default:
                return date.toLocaleDateString([], {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                });
        }
    } catch (error) {
        console.error("Error formatting date:", error);
        return String(timestamp);
    }
};

// Format price values with appropriate precision
const formatPriceValue = (value: any) => {
    if (value === 0 || value === null || value === undefined) {
        return "N/A";
    }

    if (typeof value !== 'number') return value;

    if (value < 0.000001) return value.toExponential(6);
    if (value < 0.001) return value.toFixed(8);
    if (value < 0.1) return value.toFixed(6);
    if (value < 10) return value.toFixed(4);
    if (value < 1000) return value.toFixed(2);

    return formatNumber(value, 2);
};

const ChartModal = ({
                        show,
                        onHide,
                        chartType,
                        chartProps,
                        pool,
                        selectedChartPeriod,
                        isInvertedTokenRatio
                    }: {
    show: boolean,
    onHide: () => void,
    chartType: 'TokenPrice' | 'PriceRatio' | 'Liquidity' | 'Volume' | 'Fees' | null,
    chartProps: any,
    pool: Pool,
    selectedChartPeriod: string,
    isInvertedTokenRatio?: boolean
}) => {
    // Helper function for tooltip formatting by chart type
    const getTooltipFormatter = (chartType: string) => {
        switch (chartType) {
            case 'TokenPrice':
                return (value: any, name: any) => {
                    if (value === 0 || value === null || value === undefined) {
                        return ["Data unavailable", name];
                    }
                    return [
                        `${formatPriceValue(value)}`,
                        !isInvertedTokenRatio ? `${pool.token0.symbol}/USD` : `${pool.token1.symbol}/USD`
                    ];
                };

            case 'PriceRatio':
                return (value: any, name: any) => {
                    if (value === 0 || value === null || value === undefined) {
                        return ["Data unavailable", name];
                    }
                    return [
                        formatPriceValue(value),
                        !isInvertedTokenRatio ? `${pool.token0.symbol}/${pool.token1.symbol}` : `${pool.token1.symbol}/${pool.token0.symbol}`
                    ];
                };

            case 'Liquidity':
                return (value: any, name: any) => {
                    if (value === 0 || value === null || value === undefined) {
                        return ["Data unavailable", "Liquidity"];
                    }
                    return [`${formatNumber(value as number, 2)}`, 'Liquidity'];
                };

            case 'Volume':
                return (value: any, name: any) => {
                    if (value === 0 || value === null || value === undefined) {
                        return ["$0", "Volume"];
                    }
                    return [`${formatNumber(value as number, 2)}`, 'Volume'];
                };

            case 'Fees':
                return (value: any, name: any) => {
                    if (value === 0 || value === null || value === undefined) {
                        return ["$0", "Fees"];
                    }
                    return [`${formatNumber(value as number, 2)}`, 'Fees Collected'];
                };

            default:
                return (value: any, name: any) => [value, name];
        }
    };

    const renderChart = () => {
        switch (chartType) {
            case 'TokenPrice':
                return (
                    <LineChart {...chartProps}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                        <XAxis
                            dataKey="date"
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                            interval="preserveEnd"
                        />
                        <YAxis
                            tick={{fontSize: 12}}
                            domain={['auto', 'auto']}
                            tickFormatter={(value) => `${value}`}
                        />
                        <RechartsTooltip
                            formatter={getTooltipFormatter('TokenPrice')}
                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                            contentStyle={{
                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                border: '1px solid #495057',
                                borderRadius: '4px',
                                color: '#fff',
                                padding: '10px'
                            }}
                        />
                        <Line
                            type="monotone"
                            dataKey={!isInvertedTokenRatio ? "token0Price" : "token1Price"}
                            name={!isInvertedTokenRatio ? `${pool.token0.symbol}/USD` : `${pool.token1.symbol}/USD`}
                            stroke="#0d6efd"
                            strokeWidth={2}
                            dot={false}
                            activeDot={{r: 5}}
                            isAnimationActive={false}
                        />
                    </LineChart>
                );
            case 'PriceRatio':
                return (
                    <AreaChart {...chartProps}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                        <XAxis
                            dataKey="date"
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                            interval="preserveEnd"
                        />
                        <YAxis
                            tick={{fontSize: 12}}
                        />
                        <RechartsTooltip
                            formatter={getTooltipFormatter('PriceRatio')}
                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                            contentStyle={{
                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                border: '1px solid #495057',
                                borderRadius: '4px',
                                color: '#fff',
                                padding: '10px'
                            }}
                        />
                        <Area
                            type="monotone"
                            dataKey={!isInvertedTokenRatio ? "ratio" : "invertedRatio"}
                            name={!isInvertedTokenRatio ? `${pool.token0.symbol}/${pool.token1.symbol}` : `${pool.token1.symbol}/${pool.token0.symbol}`}
                            stroke="#6f42c1"
                            fill="#6f42c1"
                            fillOpacity={0.2}
                            isAnimationActive={false}
                        />
                    </AreaChart>
                );
            case 'Liquidity':
                return (
                    <AreaChart {...chartProps}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                        <XAxis
                            dataKey="date"
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                            interval="preserveEnd"
                        />
                        <YAxis
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => `${formatNumber(value, 0)}`}
                        />
                        <RechartsTooltip
                            formatter={getTooltipFormatter('Liquidity')}
                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                            contentStyle={{
                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                border: '1px solid #495057',
                                borderRadius: '4px',
                                color: '#fff',
                                padding: '10px'
                            }}
                        />
                        <Area
                            type="monotone"
                            dataKey="liquidity"
                            name="Liquidity"
                            fill="#20c997"
                            stroke="#20c997"
                            fillOpacity={0.3}
                            isAnimationActive={false}
                        />
                    </AreaChart>
                );
            case 'Volume':
                return (
                    <BarChart {...chartProps}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                        <XAxis
                            dataKey="date"
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                            interval="preserveEnd"
                        />
                        <YAxis
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => `${formatNumber(value, 0)}`}
                        />
                        <RechartsTooltip
                            formatter={getTooltipFormatter('Volume')}
                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                            contentStyle={{
                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                border: '1px solid #495057',
                                borderRadius: '4px',
                                color: '#fff',
                                padding: '10px'
                            }}
                        />
                        <Bar
                            dataKey="volumeUSD"
                            name="Volume"
                            fill="#0dcaf0"
                            barSize={selectedChartPeriod === '7d' ? 20 : 10}
                            isAnimationActive={false}
                        />
                    </BarChart>
                );
            case 'Fees':
                return (
                    <BarChart {...chartProps}>
                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                        <XAxis
                            dataKey="date"
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                            interval="preserveEnd"
                        />
                        <YAxis
                            tick={{fontSize: 12}}
                            tickFormatter={(value) => `${formatNumber(value, 0)}`}
                        />
                        <RechartsTooltip
                            formatter={getTooltipFormatter('Fees')}
                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                            contentStyle={{
                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                border: '1px solid #495057',
                                borderRadius: '4px',
                                color: '#fff',
                                padding: '10px'
                            }}
                        />
                        <Bar
                            dataKey="feesUSD"
                            name="Fees Collected"
                            fill="#fd7e14"
                            barSize={selectedChartPeriod === '7d' ? 20 : 10}
                            isAnimationActive={false}
                        />
                    </BarChart>
                );
            default:
                return <div></div>; // Return an empty div instead of null
        }
    };

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            centered
            contentClassName="bg-dark"
        >
            <Modal.Header closeButton closeVariant="white" className="border-secondary">
                <Modal.Title className="text-white">
                    {chartType} Chart
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ResponsiveContainer width="100%" height={500}>
                    {renderChart()}
                </ResponsiveContainer>
            </Modal.Body>
        </Modal>
    );
};

const PoolCharts: React.FC<PoolChartsProps> = ({pool}) => {
    const [selectedChartPeriod, setSelectedChartPeriod] = useState<string>('30d');
    const [isLoadingCharts, setIsLoadingCharts] = useState<boolean>(true);
    const [chartData, setChartData] = useState<PoolHistoricalData[]>([]);
    const [isInvertedTokenRatio, setIsInvertedTokenRatio] = useState<boolean>(false);

    // For reference line display
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    // Modal state
    const [expandedChart, setExpandedChart] = useState<{
        type: 'TokenPrice' | 'PriceRatio' | 'Liquidity' | 'Volume' | 'Fees' | null,
        show: boolean
    }>({type: null, show: false});

    // Fetch historical chart data
    useEffect(() => {
        const fetchChartData = async () => {
            try {
                setIsLoadingCharts(true);
                const historicalData = await fetchPoolHistoricalData(
                    pool.id,
                    pool.network || 'ethereum',
                    selectedChartPeriod
                );

                // Calculate ratio safely
                const calculateRatio = (token0Price: number, token1Price: number): number => {
                    if (!token0Price || !token1Price || token1Price === 0) return 0;
                    return token0Price / token1Price;
                };

                const calculateInvertedRatio = (token0Price: number, token1Price: number): number => {
                    if (!token0Price || !token1Price || token0Price === 0) return 0;
                    return token1Price / token0Price;
                };

                // Preprocess data to include invertedRatio and calculate fees
                const processedData = historicalData.map(item => ({
                    ...item,
                    ratio: calculateRatio(item.token0Price, item.token1Price),
                    invertedRatio: calculateInvertedRatio(item.token0Price, item.token1Price),
                    // Assuming fees are 0.3% of volume, adjust this calculation according to your actual fee structure
                    feesUSD: item.volumeUSD * 0.003
                }));

                setChartData(processedData);
            } catch (error) {
                console.error('Error fetching chart data:', error);
            } finally {
                setIsLoadingCharts(false);
            }
        };

        fetchChartData();
    }, [pool, selectedChartPeriod]);

    // Handle mouse movements for reference line
    const handleMouseMove = (e: any) => {
        if (e && e.activeTooltipIndex !== undefined) {
            setActiveIndex(e.activeTooltipIndex);
        }
    };

    const handleMouseLeave = () => {
        setActiveIndex(null);
    };

    // Render loading state
    if (isLoadingCharts) {
        return (
            <div className="text-center py-5">
                <Spinner animation="border" variant="primary"/>
                <p className="mt-3 mb-0">Loading chart data...</p>
            </div>
        );
    }

    return (
        <Row className="mt-4">
            <Col>
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="d-flex align-items-center">
                        <Form.Check
                            type="switch"
                            id="token-ratio-switch"
                            className="ms-2"
                            label={`${!isInvertedTokenRatio ? `${pool.token0.symbol}/${pool.token1.symbol}` : `${pool.token1.symbol}/${pool.token0.symbol}`}`}
                            checked={isInvertedTokenRatio}
                            onChange={() => setIsInvertedTokenRatio(!isInvertedTokenRatio)}
                        />
                    </h4>
                    <div className="d-flex align-items-center">
                        <span className="me-2">Time Period:</span>
                        <Form.Select
                            size="sm"
                            style={{width: '120px'}}
                            value={selectedChartPeriod}
                            onChange={(e) => setSelectedChartPeriod(e.target.value)}
                        >
                            <option value="1d">1 Day</option>
                            <option value="7d">7 Days</option>
                            <option value="30d">30 Days</option>
                            <option value="90d">90 Days</option>
                            <option value="1y">1 Year</option>
                        </Form.Select>
                    </div>
                </div>

                {/* Token Price History - Now full width */}
                <Row className="mb-4">
                    <Col lg={12}>
                        <Card className="shadow-sm border-0 h-100">
                            <Card.Header className="bg-transparent border-bottom">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Token Price History</h6>
                                    <div className="d-flex align-items-center">
                                        <Badge bg="primary" className="me-1">
                                            {!isInvertedTokenRatio ? `${pool.token0.symbol}/USD` : `${pool.token1.symbol}/USD`}
                                        </Badge>
                                        <button
                                            className="btn btn-link text-secondary p-0 ms-2"
                                            onClick={() => setExpandedChart({type: 'TokenPrice', show: true})}
                                        >
                                            <Maximize2 size={16}/>
                                        </button>
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart
                                        data={chartData}
                                        margin={{top: 5, right: 5, left: 5, bottom: 5}}
                                        onMouseMove={handleMouseMove}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                                        <XAxis
                                            dataKey="date"
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                                            interval="preserveEnd"
                                        />
                                        <YAxis
                                            tick={{fontSize: 12}}
                                            domain={['auto', 'auto']}
                                            tickFormatter={(value) => `${formatPriceValue(value)}`}
                                        />
                                        {activeIndex !== null && (
                                            <ReferenceLine
                                                y={chartData[activeIndex]?.[!isInvertedTokenRatio ? "token0Price" : "token1Price"]}
                                                stroke="#777"
                                                strokeDasharray="3 3"
                                            />
                                        )}
                                        <RechartsTooltip
                                            formatter={(value, name) => {
                                                if (value === 0 || value === null || value === undefined) {
                                                    return ["Data unavailable", name];
                                                }
                                                return [
                                                    `${formatPriceValue(value)}`,
                                                    !isInvertedTokenRatio ? `${pool.token0.symbol}/USD` : `${pool.token1.symbol}/USD`
                                                ];
                                            }}
                                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                                            contentStyle={{
                                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                                border: '1px solid #495057',
                                                borderRadius: '4px',
                                                color: '#fff',
                                                padding: '10px'
                                            }}
                                        />
                                        <Line
                                            type="monotone"
                                            dataKey={!isInvertedTokenRatio ? "token0Price" : "token1Price"}
                                            name={!isInvertedTokenRatio ? `${pool.token0.symbol}/USD` : `${pool.token1.symbol}/USD`}
                                            stroke="#0d6efd"
                                            strokeWidth={2}
                                            dot={false}
                                            activeDot={{r: 5}}
                                            isAnimationActive={false}
                                        />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} className="mb-4">
                        <Card className="shadow-sm border-0 h-100">
                            <Card.Header className="bg-transparent border-bottom">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Price Ratio</h6>
                                    <button
                                        className="btn btn-link text-secondary p-0"
                                        onClick={() => setExpandedChart({type: 'PriceRatio', show: true})}
                                    >
                                        <Maximize2 size={16}/>
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={250}>
                                    <AreaChart
                                        data={chartData}
                                        margin={{top: 5, right: 5, left: 5, bottom: 5}}
                                        onMouseMove={handleMouseMove}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                                        <XAxis
                                            dataKey="date"
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                                            interval="preserveEnd"
                                        />
                                        <YAxis
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => formatPriceValue(value)}
                                        />
                                        {activeIndex !== null && (
                                            <ReferenceLine
                                                y={chartData[activeIndex]?.[!isInvertedTokenRatio ? "ratio" : "invertedRatio"]}
                                                stroke="#777"
                                                strokeDasharray="3 3"
                                            />
                                        )}
                                        <RechartsTooltip
                                            formatter={(value, name) => {
                                                if (value === 0 || value === null || value === undefined) {
                                                    return ["Data unavailable", name];
                                                }
                                                return [
                                                    formatPriceValue(value),
                                                    !isInvertedTokenRatio ? `${pool.token0.symbol}/${pool.token1.symbol}` : `${pool.token1.symbol}/${pool.token0.symbol}`
                                                ];
                                            }}
                                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                                            contentStyle={{
                                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                                border: '1px solid #495057',
                                                borderRadius: '4px',
                                                color: '#fff',
                                                padding: '10px'
                                            }}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey={!isInvertedTokenRatio ? "ratio" : "invertedRatio"}
                                            name={!isInvertedTokenRatio ? `${pool.token0.symbol}/${pool.token1.symbol}` : `${pool.token1.symbol}/${pool.token0.symbol}`}
                                            stroke="#6f42c1"
                                            fill="#6f42c1"
                                            fillOpacity={0.2}
                                            isAnimationActive={false}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={6} className="mb-4">
                        <Card className="shadow-sm border-0 h-100">
                            <Card.Header className="bg-transparent border-bottom">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Liquidity</h6>
                                    <button
                                        className="btn btn-link text-secondary p-0"
                                        onClick={() => setExpandedChart({type: 'Liquidity', show: true})}
                                    >
                                        <Maximize2 size={16}/>
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={250}>
                                    <AreaChart
                                        data={chartData}
                                        margin={{top: 5, right: 5, left: 5, bottom: 5}}
                                        onMouseMove={handleMouseMove}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                                        <XAxis
                                            dataKey="date"
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                                            interval="preserveEnd"
                                        />
                                        <YAxis
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => `${formatNumber(value, 0)}`}
                                        />
                                        {activeIndex !== null && (
                                            <ReferenceLine
                                                y={chartData[activeIndex]?.liquidity}
                                                stroke="#777"
                                                strokeDasharray="3 3"
                                            />
                                        )}
                                        <RechartsTooltip
                                            formatter={(value) => {
                                                if (value === 0 || value === null || value === undefined) {
                                                    return ["Data unavailable", "Liquidity"];
                                                }
                                                return [`${formatNumber(value as number, 2)}`, 'Liquidity'];
                                            }}
                                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                                            contentStyle={{
                                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                                border: '1px solid #495057',
                                                borderRadius: '4px',
                                                color: '#fff',
                                                padding: '10px'
                                            }}
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="liquidity"
                                            name="Liquidity"
                                            fill="#20c997"
                                            stroke="#20c997"
                                            fillOpacity={0.3}
                                            isAnimationActive={false}
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col lg={6} className="mb-4">
                        <Card className="shadow-sm border-0 h-100">
                            <Card.Header className="bg-transparent border-bottom">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Volume</h6>
                                    <button
                                        className="btn btn-link text-secondary p-0"
                                        onClick={() => setExpandedChart({type: 'Volume', show: true})}
                                    >
                                        <Maximize2 size={16}/>
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={250}>
                                    <BarChart
                                        data={chartData}
                                        margin={{top: 5, right: 5, left: 5, bottom: 5}}
                                        onMouseMove={handleMouseMove}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                                        <XAxis
                                            dataKey="date"
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                                            interval="preserveEnd"
                                        />
                                        <YAxis
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => `${formatNumber(value, 0)}`}
                                        />
                                        {activeIndex !== null && (
                                            <ReferenceLine
                                                y={chartData[activeIndex]?.volumeUSD}
                                                stroke="#777"
                                                strokeDasharray="3 3"
                                            />
                                        )}
                                        <RechartsTooltip
                                            formatter={(value) => {
                                                if (value === 0 || value === null || value === undefined) {
                                                    return ["$0", "Volume"];
                                                }
                                                return [`${formatNumber(value as number, 2)}`, 'Volume'];
                                            }}
                                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                                            contentStyle={{
                                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                                border: '1px solid #495057',
                                                borderRadius: '4px',
                                                color: '#fff',
                                                padding: '10px'
                                            }}
                                        />
                                        <Bar
                                            dataKey="volumeUSD"
                                            name="Volume"
                                            fill="#0dcaf0"
                                            barSize={selectedChartPeriod === '7d' ? 20 : 10}
                                            isAnimationActive={false}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={6} className="mb-4">
                        <Card className="shadow-sm border-0 h-100">
                            <Card.Header className="bg-transparent border-bottom">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">24h Fees Collected</h6>
                                    <button
                                        className="btn btn-link text-secondary p-0"
                                        onClick={() => setExpandedChart({type: 'Fees', show: true})}
                                    >
                                        <Maximize2 size={16}/>
                                    </button>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <ResponsiveContainer width="100%" height={250}>
                                    <BarChart
                                        data={chartData}
                                        margin={{top: 5, right: 5, left: 5, bottom: 5}}
                                        onMouseMove={handleMouseMove}
                                        onMouseLeave={handleMouseLeave}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" stroke="#cccccc" opacity={0.15}/>
                                        <XAxis
                                            dataKey="date"
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => formatDate(value, selectedChartPeriod)}
                                            interval="preserveEnd"
                                        />
                                        <YAxis
                                            tick={{fontSize: 12}}
                                            tickFormatter={(value) => `${formatNumber(value, 0)}`}
                                        />
                                        {activeIndex !== null && (
                                            <ReferenceLine
                                                y={chartData[activeIndex]?.feesUSD}
                                                stroke="#777"
                                                strokeDasharray="3 3"
                                            />
                                        )}
                                        <RechartsTooltip
                                            formatter={(value) => {
                                                if (value === 0 || value === null || value === undefined) {
                                                    return ["$0", "Fees Collected"];
                                                }
                                                return [`${formatNumber(value as number, 2)}`, 'Fees Collected'];
                                            }}
                                            labelFormatter={(label) => `Date: ${formatTooltipDate(label, selectedChartPeriod)}`}
                                            contentStyle={{
                                                backgroundColor: 'rgba(33, 37, 41, 0.85)',
                                                border: '1px solid #495057',
                                                borderRadius: '4px',
                                                color: '#fff',
                                                padding: '10px'
                                            }}
                                        />
                                        <Bar
                                            dataKey="feesUSD"
                                            name="Fees Collected"
                                            fill="#fd7e14" // Orange color for fees
                                            barSize={selectedChartPeriod === '7d' ? 20 : 10}
                                            isAnimationActive={false}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                {/* Expanded Chart Modal */}
                <ChartModal
                    show={expandedChart.show}
                    onHide={() => setExpandedChart({type: null, show: false})}
                    chartType={expandedChart.type}
                    chartProps={{
                        data: chartData,
                        margin: {top: 5, right: 5, left: 5, bottom: 5}
                    }}
                    pool={pool}
                    selectedChartPeriod={selectedChartPeriod}
                    isInvertedTokenRatio={isInvertedTokenRatio}
                />
            </Col>
        </Row>
    );
}

export default PoolCharts;


