import React, { useState, useEffect } from 'react';
import { Spinner, Alert, ToggleButton, ButtonGroup } from 'react-bootstrap';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, ReferenceLine } from 'recharts';
import { fetchPoolHistoricalData, formatNumber } from '../../../../service/crypto/pool/PoolService';
import { Pool } from "../../../../model/crypto/CryptoModels";
import { NETWORK_CONFIGS } from '../../../../service/crypto/pool/PoolService';

interface LiquidityDistributionChartProps {
    poolId: string;
    network: string;
    pool: Pool;
}

interface PriceBucket {
    minPrice: number;       // Lower price bound for this bucket
    maxPrice: number;       // Upper price bound for this bucket
    minPriceInverted: number; // Inverted lower price
    maxPriceInverted: number; // Inverted upper price
    activeLiquidity: number; // Active liquidity in this bucket
    liquidityUSD: number;   // Liquidity in USD
    centerPrice: number;    // Center price for display on x-axis
    centerPriceInverted: number; // Inverted center price
    tickIdxLower: number;   // Lower tick bound (for display)
    tickIdxUpper: number;   // Upper tick bound (for display)
    ticksCount: number;     // How many ticks fall in this bucket
    hasLiquidity: boolean;  // Whether this bucket has any liquidity
    priceDisplayNormal: string; // Formatted price display
    priceDisplayInverted: string; // Formatted inverted price display
    priceRangeDisplayNormal: string; // Formatted price range
    priceRangeDisplayInverted: string; // Formatted inverted price range
}

const LiquidityDistributionChart: React.FC<LiquidityDistributionChartProps> = ({ poolId, network, pool }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [bucketData, setBucketData] = useState<PriceBucket[]>([]);
    const [currentPrice, setCurrentPrice] = useState<number | null>(null);
    const [showInverted, setShowInverted] = useState<boolean>(false);
    const [currentTick, setCurrentTick] = useState<number | null>(null);
    const [bucketCount, setBucketCount] = useState<number>(30); // Default number of buckets

    // Helper function to format price values in a readable way
    const formatPriceForDisplay = (price: number): string => {
        if (!isFinite(price) || isNaN(price)) return "N/A";

        if (Math.abs(price) < 0.0000001) return price.toExponential(2);
        if (Math.abs(price) < 0.0001) return price.toExponential(4);
        if (Math.abs(price) < 0.01) return price.toFixed(6);
        if (Math.abs(price) < 1) return price.toFixed(4);
        if (Math.abs(price) < 1000) return price.toFixed(2);
        if (Math.abs(price) >= 1e9) return price.toExponential(2);
        return formatNumber(price, 2);
    };

    // Convert tick index to price
    const tickToPrice = (tick: number, token0Decimals: number, token1Decimals: number): { price0: number, price1: number } => {
        // Uniswap v3 tick math: price = 1.0001^tick
        const priceRaw = Math.pow(1.0001, tick);

        // Apply decimal adjustment to get the actual exchange rate
        const decimalAdjustment = Math.pow(10, token0Decimals - token1Decimals);
        const price0 = priceRaw * decimalAdjustment;
        const price1 = 1 / price0;

        return { price0, price1 };
    };

    // Convert price to tick index (inverse of tickToPrice)
    const priceToTick = (price0: number, token0Decimals: number, token1Decimals: number): number => {
        const decimalAdjustment = Math.pow(10, token0Decimals - token1Decimals);
        const priceRaw = price0 / decimalAdjustment;
        // Use logarithm to find tick: tick = log(price) / log(1.0001)
        return Math.floor(Math.log(priceRaw) / Math.log(1.0001));
    };

    useEffect(() => {
        const fetchLiquidityData = async () => {
            if (!poolId || !network) return;

            setIsLoading(true);
            setError(null);

            try {
                // Find the network configuration
                const networkConfig = NETWORK_CONFIGS.find(config => config.networkId === network);
                if (!networkConfig) {
                    throw new Error(`Network ${network} not configured`);
                }

                // Get API key and construct GraphQL URL
                const apiKey = process.env.REACT_APP_GRAPH_API_KEY;
                if (!apiKey) {
                    throw new Error('Missing Graph API key');
                }

                const GRAPH_API_URL = `${networkConfig.apiUrl}${apiKey}/subgraphs/id/${networkConfig.subgraphId}`;

                // Fetch historical data to get current price
                const historicalData = await fetchPoolHistoricalData(poolId, network, '1d');
                if (historicalData.length === 0) {
                    throw new Error('No historical data available for this pool');
                }

                // Get the most recent price data from historical data
                const latestData = historicalData[historicalData.length - 1];
                setCurrentPrice(latestData.token0Price);

                // Get pool info for token decimals and current tick
                const poolInfoQuery = `
                {
                    pool(id: "${poolId}") {
                        token0 {
                            decimals
                        }
                        token1 {
                            decimals
                        }
                        tick
                        liquidity
                        feeTier
                    }
                }`;

                const poolInfoResponse = await fetch(GRAPH_API_URL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ query: poolInfoQuery }),
                });

                const poolInfoResult = await poolInfoResponse.json();

                if (poolInfoResult.errors) {
                    console.error("Error fetching pool info:", poolInfoResult.errors);
                    throw new Error("Failed to fetch pool information");
                }

                const poolData = poolInfoResult.data.pool;
                if (!poolData) {
                    throw new Error("Pool not found");
                }

                const token0Decimals = parseInt(poolData.token0.decimals);
                const token1Decimals = parseInt(poolData.token1.decimals);
                const currentTickValue = parseInt(poolData.tick || '0');
                setCurrentTick(currentTickValue);

                // Determine tick spacing based on fee tier
                let tickSpacing = 60; // Default to 0.3% fee tier
                if (poolData.feeTier) {
                    const feeTier = parseInt(poolData.feeTier);
                    if (feeTier === 100) tickSpacing = 1;       // 0.01% fee
                    else if (feeTier === 500) tickSpacing = 10; // 0.05% fee
                    else if (feeTier === 3000) tickSpacing = 60; // 0.3% fee
                    else if (feeTier === 10000) tickSpacing = 200; // 1% fee
                }

                // Determine tick range to fetch (±5000 ticks around current tick)
                const tickRange = 5000;
                const minTick = currentTickValue - tickRange;
                const maxTick = currentTickValue + tickRange;

                // Query for tick data
                const ticksQuery = `
                {
                    ticks(
                        where: {
                            pool: "${poolId}",
                            tickIdx_gte: ${minTick},
                            tickIdx_lte: ${maxTick}
                        }
                        orderBy: tickIdx
                        first: 1000
                    ) {
                        tickIdx
                        liquidityGross
                        liquidityNet
                    }
                }`;

                const ticksResponse = await fetch(GRAPH_API_URL, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ query: ticksQuery }),
                });

                const ticksResult = await ticksResponse.json();

                if (ticksResult.errors) {
                    console.error("Error fetching ticks:", ticksResult.errors);
                    throw new Error("Failed to fetch tick data");
                }

                const ticks = ticksResult.data.ticks;
                if (!ticks || ticks.length === 0) {
                    throw new Error("No tick data available");
                }

                console.log(`Fetched ${ticks.length} ticks from the subgraph`);

                // Sort ticks by tickIdx
                const sortedTicks = [...ticks].sort((a, b) =>
                    parseInt(a.tickIdx) - parseInt(b.tickIdx)
                );

                // STEP 1: Calculate active liquidity at the current tick
                // This is the sum of all liquidityNet values for ticks at or below the current tick
                let currentActiveLiquidity = BigInt(0);
                for (const tick of sortedTicks) {
                    const tickIdx = parseInt(tick.tickIdx);
                    if (tickIdx <= currentTickValue) {
                        currentActiveLiquidity += BigInt(tick.liquidityNet);
                    }
                }

                console.log(`Active liquidity at current tick ${currentTickValue}: ${currentActiveLiquidity}`);

                // STEP 2: Calculate active liquidity for all ticks
                // Initialize with the liquidity at the current tick
                const liquidityByTick: { [tickIdx: number]: number } = {};
                liquidityByTick[currentTickValue] = Number(currentActiveLiquidity > 0 ? currentActiveLiquidity : 0);

                // For ticks above current tick (ascending order)
                let aboveLiquidity = currentActiveLiquidity;
                for (const tick of sortedTicks) {
                    const tickIdx = parseInt(tick.tickIdx);
                    if (tickIdx > currentTickValue) {
                        aboveLiquidity += BigInt(tick.liquidityNet);
                        liquidityByTick[tickIdx] = Number(aboveLiquidity > 0 ? aboveLiquidity : 0);
                    }
                }

                // For ticks below current tick (descending order)
                const belowTicks = sortedTicks
                    .filter(tick => parseInt(tick.tickIdx) < currentTickValue)
                    .sort((a, b) => parseInt(b.tickIdx) - parseInt(a.tickIdx));

                let belowLiquidity = currentActiveLiquidity;
                for (const tick of belowTicks) {
                    const tickIdx = parseInt(tick.tickIdx);
                    belowLiquidity -= BigInt(tick.liquidityNet);
                    liquidityByTick[tickIdx] = Number(belowLiquidity > 0 ? belowLiquidity : 0);
                }

                // STEP 3: Find maximum active liquidity for scaling purposes
                let maxActiveLiquidity = 0;
                for (const liquidity of Object.values(liquidityByTick)) {
                    if (liquidity > maxActiveLiquidity) {
                        maxActiveLiquidity = liquidity;
                    }
                }

                // STEP 4: Create price buckets for the chart
                // Instead of skipping ticks, we'll create consistent price range buckets

                // Determine the full price range to display
                const minPrice = tickToPrice(minTick, token0Decimals, token1Decimals).price0;
                const maxPrice = tickToPrice(maxTick, token0Decimals, token1Decimals).price0;

                // Get current price for reference
                const currentTokenPrice = tickToPrice(currentTickValue, token0Decimals, token1Decimals).price0;

                // Define a more reasonable range for better visualization
                // Using an asymmetric range to better capture liquidity across the pool
                // Show more data to the right (above current price) since that's what's missing
                const displayMinPrice = currentTokenPrice * 0.25; // 75% below current price
                const displayMaxPrice = currentTokenPrice * 5;    // 400% above current price

                // Calculate bucket size in price terms
                const priceRange = displayMaxPrice - displayMinPrice;
                const bucketSize = priceRange / bucketCount;

                // Create price buckets
                const priceBuckets: PriceBucket[] = [];

                for (let i = 0; i < bucketCount; i++) {
                    const bucketMinPrice = displayMinPrice + (i * bucketSize);
                    const bucketMaxPrice = bucketMinPrice + bucketSize;
                    const bucketCenterPrice = (bucketMinPrice + bucketMaxPrice) / 2;

                    // Calculate tick bounds for this price bucket
                    const lowerTick = priceToTick(bucketMinPrice, token0Decimals, token1Decimals);
                    const upperTick = priceToTick(bucketMaxPrice, token0Decimals, token1Decimals);

                    // Find all ticks that fall within this price range
                    const ticksInBucket = sortedTicks.filter(tick => {
                        const tickIdx = parseInt(tick.tickIdx);
                        return tickIdx >= lowerTick && tickIdx < upperTick;
                    });

                    // Calculate the weighted average active liquidity for this price bucket
                    let bucketActiveLiquidity = 0;
                    let ticksWithLiquidity = 0;

                    // If no ticks in this exact bucket range, use nearest tick's liquidity
                    if (ticksInBucket.length === 0) {
                        // Find the nearest tick to the center of this bucket
                        const centerTick = priceToTick(bucketCenterPrice, token0Decimals, token1Decimals);

                        // Find the nearest initialized tick
                        let nearestTick = null;
                        let smallestDistance = Number.MAX_VALUE;

                        for (const tick of sortedTicks) {
                            const tickIdx = parseInt(tick.tickIdx);
                            const distance = Math.abs(tickIdx - centerTick);

                            if (distance < smallestDistance) {
                                smallestDistance = distance;
                                nearestTick = tickIdx;
                            }
                        }

                        if (nearestTick !== null && liquidityByTick[nearestTick] !== undefined) {
                            bucketActiveLiquidity = liquidityByTick[nearestTick];
                        }
                    } else {
                        // Calculate weighted average of all ticks in bucket
                        let liquiditySum = 0;

                        for (const tick of ticksInBucket) {
                            const tickIdx = parseInt(tick.tickIdx);
                            const tickLiquidity = liquidityByTick[tickIdx] || 0;

                            if (tickLiquidity > 0) {
                                liquiditySum += tickLiquidity;
                                ticksWithLiquidity++;
                            }
                        }

                        // If we found any liquidity, calculate the average
                        if (ticksWithLiquidity > 0) {
                            bucketActiveLiquidity = liquiditySum / ticksWithLiquidity;
                        }
                    }

                    // Scale the liquidity to USD value using TVL
                    const tvlUSD = pool.tvlUSD;
                    const liquidityProportion = maxActiveLiquidity > 0
                        ? bucketActiveLiquidity / maxActiveLiquidity
                        : 0;
                    const liquidityUSD = tvlUSD * liquidityProportion;

                    // Create the bucket data object
                    priceBuckets.push({
                        minPrice: bucketMinPrice,
                        maxPrice: bucketMaxPrice,
                        minPriceInverted: 1 / bucketMaxPrice, // Note: inverted bounds swap
                        maxPriceInverted: 1 / bucketMinPrice, // Larger price becomes smaller when inverted
                        centerPrice: bucketCenterPrice,
                        centerPriceInverted: 1 / bucketCenterPrice,
                        activeLiquidity: bucketActiveLiquidity,
                        liquidityUSD: liquidityUSD,
                        tickIdxLower: lowerTick,
                        tickIdxUpper: upperTick,
                        ticksCount: ticksInBucket.length,
                        hasLiquidity: bucketActiveLiquidity > 0,
                        priceDisplayNormal: `${formatPriceForDisplay(bucketCenterPrice)} ${pool.token0.symbol}/${pool.token1.symbol}`,
                        priceDisplayInverted: `${formatPriceForDisplay(1 / bucketCenterPrice)} ${pool.token1.symbol}/${pool.token0.symbol}`,
                        priceRangeDisplayNormal: `${formatPriceForDisplay(bucketMinPrice)} - ${formatPriceForDisplay(bucketMaxPrice)} ${pool.token0.symbol}/${pool.token1.symbol}`,
                        priceRangeDisplayInverted: `${formatPriceForDisplay(1 / bucketMaxPrice)} - ${formatPriceForDisplay(1 / bucketMinPrice)} ${pool.token1.symbol}/${pool.token0.symbol}`
                    });
                }

                setBucketData(priceBuckets);
            } catch (err) {
                console.error('Error fetching liquidity data:', err);
                setError(err instanceof Error ? err.message : 'Failed to load liquidity distribution data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchLiquidityData();
    }, [poolId, network, pool.tvlUSD, bucketCount]);

    // Custom tooltip for the chart
    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div className="custom-tooltip" style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.85)',
                    color: 'white',
                    padding: '12px',
                    border: '1px solid #444',
                    borderRadius: '4px',
                    boxShadow: '0 2px 10px rgba(0,0,0,0.2)'
                }}>
                    <p className="mb-1">
                        <strong>Price Range:</strong> {showInverted
                        ? data.priceRangeDisplayInverted
                        : data.priceRangeDisplayNormal}
                    </p>
                    <p className="mb-1">
                        <strong>Tick Range:</strong> {data.tickIdxLower} to {data.tickIdxUpper}
                    </p>
                    <p className="mb-1">
                        <strong>Ticks in Range:</strong> {data.ticksCount}
                    </p>
                    <p className="mb-0">
                        <strong>Liquidity:</strong> ${formatNumber(data.liquidityUSD, 2)}
                        {!data.hasLiquidity && " (inactive range)"}
                    </p>
                </div>
            );
        }
        return null;
    };

    // Get color for bars based on proximity to current price
    const getBarColor = (centerPrice: number) => {
        if (!currentPrice) return "#8884d8";

        // Calculate the relevant price comparison based on displayed format
        const comparePriceA = showInverted ? (1 / currentPrice) : currentPrice;
        const comparePriceB = centerPrice;

        const priceDiff = Math.abs(comparePriceB - comparePriceA) / comparePriceA;

        if (priceDiff < 0.05) return "#4caf50"; // Very close to current price (green)
        if (priceDiff < 0.15) return "#2196f3"; // Near current price (blue)
        if (priceDiff < 0.30) return "#ff9800"; // Further from current price (orange)
        return "#9e9e9e"; // Far from current price (gray)
    };

    // Render loading state
    if (isLoading) {
        return (
            <div className="text-center py-5">
                <Spinner animation="border" variant="primary" />
                <p className="mt-2">Loading liquidity distribution data...</p>
            </div>
        );
    }

    // Render error state
    if (error) {
        return (
            <Alert variant="danger">
                <Alert.Heading>Error</Alert.Heading>
                <p>{error}</p>
            </Alert>
        );
    }

    // Render no data state
    if (bucketData.length === 0) {
        return (
            <Alert variant="info">
                <Alert.Heading>No Data Available</Alert.Heading>
                <p>There is no liquidity distribution data available for this pool.</p>
            </Alert>
        );
    }

    return (
        <div>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <div>
                    <h5 className="mb-1">Liquidity Distribution by Price Range</h5>
                    <p className="text-muted mb-0">
                        Showing active liquidity across {bucketData.length} price buckets
                    </p>
                </div>
                <div>
                    <ButtonGroup className="mb-2">
                        <ToggleButton
                            id="toggle-token0"
                            type="radio"
                            variant="outline-primary"
                            name="token-ratio"
                            value="0"
                            checked={!showInverted}
                            onChange={() => setShowInverted(false)}
                        >
                            {pool.token0.symbol}/{pool.token1.symbol}
                        </ToggleButton>
                        <ToggleButton
                            id="toggle-token1"
                            type="radio"
                            variant="outline-primary"
                            name="token-ratio"
                            value="1"
                            checked={showInverted}
                            onChange={() => setShowInverted(true)}
                        >
                            {pool.token1.symbol}/{pool.token0.symbol}
                        </ToggleButton>
                    </ButtonGroup>
                </div>
            </div>

            <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={bucketData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    barGap={0}
                    barCategoryGap={1}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey={showInverted ? "centerPriceInverted" : "centerPrice"}
                        tickFormatter={(value) => formatPriceForDisplay(value)}
                        label={{
                            value: `Price (${showInverted ? `${pool.token1.symbol}/${pool.token0.symbol}` : `${pool.token0.symbol}/${pool.token1.symbol}`})`,
                            position: 'insideBottom',
                            offset: -5
                        }}
                        minTickGap={30}
                        scale="log" // Using log scale for better visibility of wide price ranges
                        domain={['auto', 'auto']}
                        allowDataOverflow={false}
                    />
                    <YAxis
                        tickFormatter={(value) => `${formatNumber(value, 0)}`}
                        label={{ value: 'Liquidity (USD)', angle: -90, position: 'insideLeft' }}
                        domain={[0, 'auto']} // Force Y-axis to start at 0
                        allowDataOverflow={false} // Allow outliers to be properly shown
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend />
                    {currentPrice && (
                        <ReferenceLine
                            x={showInverted ? (1 / currentPrice) : currentPrice}
                            stroke="#ff5722"
                            strokeWidth={2}
                            strokeDasharray="3 3"
                            label={{
                                value: "Current Price",
                                position: "top",
                                fill: "#ff5722"
                            }}
                        />
                    )}
                    <Bar
                        dataKey="liquidityUSD"
                        name="Liquidity (USD)"
                        fill="#8884d8"
                        isAnimationActive={false}
                        minPointSize={3}
                    >
                        {bucketData.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={getBarColor(showInverted ? entry.centerPriceInverted : entry.centerPrice)}
                                opacity={entry.hasLiquidity ? 1 : 0.2} // Lower opacity for inactive ranges
                            />
                        ))}
                    </Bar>
                </BarChart>
            </ResponsiveContainer>

            {currentPrice && (
                <div className="mt-3 text-center">
                    <p>
                        <strong>Current Price:</strong> {' '}
                        {showInverted
                            ? `${formatPriceForDisplay(1 / currentPrice)} ${pool.token1.symbol}/${pool.token0.symbol}`
                            : `${formatPriceForDisplay(currentPrice)} ${pool.token0.symbol}/${pool.token1.symbol}`
                        }
                        {' | '}
                        <strong>Inverse:</strong> {' '}
                        {showInverted
                            ? `${formatPriceForDisplay(currentPrice)} ${pool.token0.symbol}/${pool.token1.symbol}`
                            : `${formatPriceForDisplay(1 / currentPrice)} ${pool.token1.symbol}/${pool.token0.symbol}`
                        }
                    </p>
                    {currentTick !== null && (
                        <p className="text-muted">
                            <small>Current Tick: {currentTick}</small>
                        </p>
                    )}
                </div>
            )}

            <div className="mt-4">
                <h6>Understanding Liquidity Distribution in Uniswap V3</h6>
                <ul className="text-muted">
                    <li><span className="badge bg-success me-1">Green</span> Liquidity near current price (most active, highest fee generation)</li>
                    <li><span className="badge bg-primary me-1">Blue</span> Liquidity in active range (moderate activity)</li>
                    <li><span className="badge bg-warning me-1">Orange</span> Liquidity in wider range (activates during larger price movements)</li>
                    <li><span className="badge bg-secondary me-1">Gray</span> Liquidity in extreme ranges (rarely used, activates during high volatility)</li>
                </ul>
                <div className="mt-2 text-muted">
                    <small>
                        <em>Note: This chart displays liquidity using price buckets across a wide range (from 75% below to 400% above current price). Each bar shows the average active liquidity within that price range. In Uniswap V3, liquidity providers concentrate their assets in specific price ranges rather than distributing them evenly, which is why liquidity varies significantly across different price levels.</em>
                    </small>
                </div>
            </div>
        </div>
    );
};

export default LiquidityDistributionChart;