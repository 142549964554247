import { Pool, SubgraphPool, PoolDayData } from "../../../model/crypto/CryptoModels";

// Define network configuration to support multiple networks
export interface NetworkConfig {
    networkId: string;
    name: string;
    subgraphId: string;
    apiUrl: string;
}

// Configuration for different networks
export const NETWORK_CONFIGS: NetworkConfig[] = [
    {
        networkId: 'arbitrum',
        name: 'Arbitrum',
        subgraphId: 'FbCGRftH4a3yZugY7TnbYgPJVEv2LvMT6oF1fxPe9aJM', // This is your working Arbitrum subgraph ID
        apiUrl: 'https://gateway.thegraph.com/api/'
    },
    // Uncomment and update these when you have the correct subgraph IDs

    {
        networkId: 'ethereum',
        name: 'Ethereum',
        subgraphId: 'HUZDsRpEVP2AvzDCyzDHtdc64dyDxx8FQjzsmqSg4H3B',
        apiUrl: 'https://gateway.thegraph.com/api/'
    },

    {
        networkId: 'optimism',
        name: 'Optimism',
        subgraphId: 'Cghf4LfVqPiFw6fp6Y5X5Ubc8UpmUhSfJL82zwiBFLaj',
        apiUrl: 'https://gateway.thegraph.com/api/'
    },

    {
        networkId: 'base',
        name: 'Base',
        subgraphId: '43Hwfi3dJSoGpyas9VwNoDAv55yjgGrPpNSmbQZArzMG',
        apiUrl: 'https://gateway.thegraph.com/api/'
    },
    {
        networkId: 'polygon',
        name: 'Polygon',
        subgraphId: 'EsLGwxyeMMeJuhqWvuLmJEiDKXJ4Z6YsoJreUnyeozco',
        apiUrl: 'https://gateway.thegraph.com/api/'
    }

];

// Function to fetch 7-day metrics
export const fetch7DayMetrics = async (poolId: string, network: NetworkConfig): Promise<any> => {
    try {
        const apiKey = process.env.REACT_APP_GRAPH_API_KEY;

        // Validate inputs
        if (!poolId) {
            console.warn(`Skipping 7-day metrics: No pool ID provided for ${network.name}`);
            return null;
        }

        if (!apiKey) {
            console.error('Missing Graph API key. Please set REACT_APP_GRAPH_API_KEY');
            return null;
        }

        const GRAPH_API_URL = `${network.apiUrl}${apiKey}/subgraphs/id/${network.subgraphId}`;

        // GraphQL query to fetch 7 days of data
        const query = `
        {
          poolDayDatas(
            where: {pool: "${poolId}"}
            orderBy: date
            orderDirection: desc
            first: 7
          ) {
            date
            volumeUSD
            feesUSD
            tvlUSD
          }
        }
      `;

        const response = await fetch(GRAPH_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query }),
        });

        // Check for network-level errors
        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}, network: ${network.name}, pool: ${poolId}`);
            return null;
        }

        const result = await response.json();

        // Detailed error logging
        if (result.errors) {
            console.error(`Detailed GraphQL Error for pool ${poolId} on ${network.name}:`,
                JSON.stringify(result.errors, null, 2)
            );
            return null;
        }

        // Process 7-day data
        const dayDatas = result.data.poolDayDatas || [];

        if (dayDatas.length === 0) {
            console.warn(`No 7-day data found for pool ${poolId} on ${network.name}`);
            return {
                volumeUSD7d: "0",
                feesUSD7d: "0",
                averageTvlUSD: "0",
                sevenDayApr: "0"
            };
        }

        // Calculate 7-day totals and averages
        let totalVolumeUSD = 0;
        let totalFeesUSD = 0;
        let totalTVL = 0;

        dayDatas.forEach((day: PoolDayData) => {
            totalVolumeUSD += parseFloat(day.volumeUSD || "0");
            totalFeesUSD += parseFloat(day.feesUSD || "0");
            totalTVL += parseFloat(day.tvlUSD || "0");
        });

        const averageTVL = totalTVL / dayDatas.length;

        // Calculate 7-day APR
        const annualizedFees = totalFeesUSD * (365 / 7);
        const sevenDayApr = averageTVL > 0 ? (annualizedFees / averageTVL) * 100 : 0;

        return {
            volumeUSD7d: totalVolumeUSD.toString(),
            feesUSD7d: totalFeesUSD.toString(),
            averageTvlUSD: averageTVL.toString(),
            sevenDayApr: sevenDayApr.toFixed(2)
        };
    } catch (error) {
        console.error(`Comprehensive error fetching 7-day metrics for pool:`, {
            poolId,
            network: network.name,
            error: error instanceof Error ? error.message : String(error)
        });
        return null;
    }
};

// Modify the existing fetchPoolsData function to include 30-day APR calculation
export const fetchPoolsData = async (networkFilter = 'arbitrum'): Promise<Pool[]> => {
    try {
        let allPools: Pool[] = [];

        // If a specific network is requested (not 'all')
        if (networkFilter !== 'all') {
            const networkConfig = NETWORK_CONFIGS.find(config => config.networkId === networkFilter);
            if (!networkConfig) {
                console.warn(`Network ${networkFilter} not configured, defaulting to Arbitrum`);
                const arbitrumConfig = NETWORK_CONFIGS.find(config => config.networkId === 'arbitrum');
                if (!arbitrumConfig) {
                    throw new Error('Arbitrum network configuration missing');
                }
                return await fetchNetworkPools(arbitrumConfig);
            }
            return await fetchNetworkPools(networkConfig);
        }

        // Fetch from all networks in parallel
        const promises = NETWORK_CONFIGS.map(network => fetchNetworkPools(network));
        const results = await Promise.allSettled(promises);

        // Combine results from all networks
        results.forEach((result, index) => {
            if (result.status === 'fulfilled') {
                allPools = [...allPools, ...result.value];
            } else {
                console.error(`Failed to fetch from ${NETWORK_CONFIGS[index].name}:`, result.reason);
            }
        });

        return allPools;
    } catch (error) {
        console.error('Error in fetchPoolsData:', error);
        throw error;
    }
};

// Update the fetchNetworkPools function to include 30-day metrics calculation
const fetchNetworkPools = async (network: NetworkConfig): Promise<Pool[]> => {
    try {
        const apiKey = process.env.REACT_APP_GRAPH_API_KEY;
        const GRAPH_API_URL = `${network.apiUrl}${apiKey}/subgraphs/id/${network.subgraphId}`;

        // First, let's get the list of pools
        const poolsQuery = `
        {
          pools(
            first: 100, 
            orderBy: totalValueLockedUSD, 
            orderDirection: desc,
            where: { liquidity_gt: "0" }
          ) {
            id
            feeTier
            liquidity
            sqrtPrice
            token0 {
              id
              symbol
              name
              decimals
            }
            token1 {
              id
              symbol
              name
              decimals
            }
            totalValueLockedUSD
            volumeUSD
          }
        }
      `;

        const poolsResponse = await fetch(GRAPH_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query: poolsQuery }),
        });

        const poolsResult = await poolsResponse.json();

        if (poolsResult.errors) {
            console.error(`Error fetching ${network.name} pools:`, poolsResult.errors);
            return []; // Return empty array instead of throwing
        }

        // Transform the data into our expected format
        const formattedPools = await Promise.all(poolsResult.data.pools.map(async (pool: any) => {
            // For each pool, get the most recent day data
            const dayDataQuery = `
            {
              poolDayDatas(
                where: { pool: "${pool.id}" }
                orderBy: date
                orderDirection: desc
                first: 1
              ) {
                date
                volumeUSD
                feesUSD
                tvlUSD
              }
            }`;

            const dayDataResponse = await fetch(GRAPH_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: dayDataQuery }),
            });

            const dayDataResult = await dayDataResponse.json();

            // Get 24h metrics from poolDayData
            const dayData = dayDataResult.data?.poolDayDatas && dayDataResult.data.poolDayDatas.length > 0
                ? dayDataResult.data.poolDayDatas[0]
                : { date: "0", volumeUSD: "0", feesUSD: "0", tvlUSD: "0" };

            // Get 7-day metrics
            const sevenDayData = await fetch7DayMetrics(pool.id, network);

            // Get 30-day metrics
            const query30Days = `
            {
              poolDayDatas(
                where: { pool: "${pool.id}" }
                orderBy: date
                orderDirection: desc
                first: 30
              ) {
                date
                volumeUSD
                feesUSD
                tvlUSD
              }
            }`;

            const response30Days = await fetch(GRAPH_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ query: query30Days }),
            });

            const result30Days = await response30Days.json();

            // Calculate 30-day metrics
            const thirtyDayDatas = result30Days.data?.poolDayDatas || [];
            let totalVolumeUSD30 = 0;
            let totalFeesUSD30 = 0;
            let totalTVL30 = 0;

            thirtyDayDatas.forEach((day: PoolDayData) => {
                totalVolumeUSD30 += parseFloat(day.volumeUSD || "0");
                totalFeesUSD30 += parseFloat(day.feesUSD || "0");
                totalTVL30 += parseFloat(day.tvlUSD || "0");
            });

            const averageTVL30 = totalTVL30 / (thirtyDayDatas.length || 1);
            const thirtyDayApr = averageTVL30 > 0
                ? ((totalFeesUSD30 * (365 / 30)) / averageTVL30) * 100
                : 0;

            // Store the raw fee tier as an integer (e.g., 500, 3000, 10000)
            const rawFeeTier = parseInt(pool.feeTier);

            // For APR calculations, convert to decimal (divide by 10000)
            const feeDecimal = rawFeeTier / 10000;
            const tvlUSD = parseFloat(pool.totalValueLockedUSD);

            // Calculate APR using fee data
            const dailyFeeRevenue = parseFloat(dayData.feesUSD || "0");
            const apr = tvlUSD > 0
                ? ((dailyFeeRevenue / tvlUSD) * 365 * 100).toFixed(2)
                : '0';

            // Determine protocol version
            let protocol = 'v3'; // Default to v3

            // Fee tier heuristic for protocol version
            if (rawFeeTier <= 500) {
                protocol = 'v4'; // Lower fee tiers like 100 (0.01%) and 500 (0.05%) are likely v4
            } else if (rawFeeTier === 3000 && pool.id.startsWith('0x')) {
                protocol = 'v2';
            }

            return {
                id: pool.id,
                token0: {
                    symbol: pool.token0.symbol,
                    name: pool.token0.name,
                    address: pool.token0.id
                },
                token1: {
                    symbol: pool.token1.symbol,
                    name: pool.token1.name,
                    address: pool.token1.id
                },
                tvlUSD: pool.totalValueLockedUSD,
                volumeUSD24h: dayData.volumeUSD || "0",
                feesUSD24h: dayData.feesUSD || "0",
                volumeUSD7d: sevenDayData?.volumeUSD7d || "0",
                feesUSD7d: sevenDayData?.feesUSD7d || "0",
                volumeUSD30d: totalVolumeUSD30.toString(),
                feesUSD30d: totalFeesUSD30.toString(),
                feeTier: rawFeeTier.toString(), // Store the raw fee tier
                apr: apr,
                sevenDayApr: sevenDayData?.sevenDayApr || "0",
                thirtyDayApr: thirtyDayApr.toFixed(2),
                protocol: protocol,
                network: network.networkId // Add the network ID
            };
        }));

        return formattedPools;
    } catch (error) {
        console.error(`Error fetching ${network.name} pools:`, error);
        return []; // Return empty array on error
    }
};

// Helper functions for formatting display values
export const formatNumber = (num: string | number, decimals = 0) => {
    const number = parseFloat(num.toString());
    if (number >= 1e9) {
        return (number / 1e9).toFixed(decimals) + 'B';
    } else if (number >= 1e6) {
        return (number / 1e6).toFixed(decimals) + 'M';
    } else if (number >= 1e3) {
        return (number / 1e3).toFixed(decimals) + 'K';
    }
    return number.toFixed(decimals);
};

export const formatFee = (feeTier: string): string => {
    // Convert from basis points to percentage
    const feeValue = parseInt(feeTier);

    // Standard Uniswap fee tiers
    switch (feeValue) {
        case 100:
            return '0.01%';
        case 500:
            return '0.05%';
        case 3000:
            return '0.3%';
        case 10000:
            return '1%';
        default:
            // For non-standard tiers, calculate percentage
            return (feeValue / 10000).toFixed(2) + '%';
    }
};



export interface PoolHistoricalData {
    date: string;
    timestamp: number;
    token0Price: number;
    token1Price: number;
    liquidity: number;
    volumeUSD: number;
    feesUSD: number;
    ratio: number;
    invertedRatio: number;
}

export const fetchPoolHistoricalData = async (
    poolId: string,
    network: string,
    period: string
): Promise<PoolHistoricalData[]> => {
    try {
        const networkConfig = NETWORK_CONFIGS.find(config => config.networkId === network);
        if (!networkConfig) {
            throw new Error(`Network ${network} not configured`);
        }

        const apiKey = process.env.REACT_APP_GRAPH_API_KEY;
        if (!apiKey) {
            throw new Error('Missing Graph API key');
        }

        const GRAPH_API_URL = `${networkConfig.apiUrl}${apiKey}/subgraphs/id/${networkConfig.subgraphId}`;

        // Determine the number of data points based on the period and add extra to ensure we get today
        const getDataPointsFromPeriod = (period: string): number => {
            switch (period) {
                case '1d': return 25; // 24 hours + buffer
                case '7d': return 8;  // 7 days + buffer
                case '30d': return 32; // 30 days + buffer
                case '90d': return 92; // 90 days + buffer
                case '1y': return 367; // 365 days + buffer
                default: return 32; // Default to 30 days + buffer
            }
        };

        const dataPoints = getDataPointsFromPeriod(period);
        const now = Math.floor(Date.now() / 1000); // Current time in seconds

        // Calculate start time based on period
        const getStartTime = (period: string): number => {
            switch (period) {
                case '1d': return now - 86400; // 24 hours ago
                case '7d': return now - 7 * 86400; // 7 days ago
                case '30d': return now - 30 * 86400; // 30 days ago
                case '90d': return now - 90 * 86400; // 90 days ago
                case '1y': return now - 365 * 86400; // 365 days ago
                default: return now - 30 * 86400; // Default to 30 days
            }
        };

        const startTime = getStartTime(period);

        // For debugging - log the time range we're querying
        console.log(`Fetching ${period} data from ${new Date(startTime * 1000).toISOString()} to ${new Date(now * 1000).toISOString()}`);

        // Different query for hourly data vs daily data
        let query;
        if (period === '1d') {
            // For 1d, use hourly data with specific time range
            query = `
            {
              poolHourDatas(
                where: { 
                  pool: "${poolId}",
                  periodStartUnix_gte: ${startTime}
                }
                orderBy: periodStartUnix
                orderDirection: asc
                first: ${dataPoints}
              ) {
                periodStartUnix
                token0Price
                token1Price
                liquidity
                volumeUSD
                feesUSD
              }
            }
            `;
        } else {
            // For other periods, use daily data with time range
            query = `
            {
              poolDayDatas(
                where: { 
                  pool: "${poolId}",
                  date_gte: ${startTime}
                }
                orderBy: date
                orderDirection: asc
                first: ${dataPoints}
              ) {
                date
                token0Price
                token1Price
                liquidity
                volumeUSD
                feesUSD
              }
            }
            `;
        }

        // Also get the latest pool data for most current price
        const latestDataQuery = `
        {
          pool(id: "${poolId}") {
            token0Price
            token1Price
            liquidity
            feeTier
          }
        }
        `;

        // Execute both queries in parallel
        const [historicalResponse, latestResponse] = await Promise.all([
            fetch(GRAPH_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query }),
            }),
            fetch(GRAPH_API_URL, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ query: latestDataQuery }),
            })
        ]);

        if (!historicalResponse.ok || !latestResponse.ok) {
            throw new Error(`HTTP error! Historical status: ${historicalResponse.status}, Latest status: ${latestResponse.status}`);
        }

        const historicalResult = await historicalResponse.json();
        const latestResult = await latestResponse.json();

        if (historicalResult.errors) {
            console.error("Historical data errors:", historicalResult.errors);
            throw new Error(JSON.stringify(historicalResult.errors));
        }

        if (latestResult.errors) {
            console.error("Latest data errors:", latestResult.errors);
        }

        // For debugging - log amount of data received
        if (period === '1d') {
            console.log(`Received ${historicalResult.data.poolHourDatas?.length || 0} hourly data points`);
        } else {
            console.log(`Received ${historicalResult.data.poolDayDatas?.length || 0} daily data points`);
        }

        // Calculate ratio safely
        const calculateRatio = (token0Price: number, token1Price: number): number => {
            if (!token0Price || !token1Price || token1Price === 0) return 0;
            return token0Price / token1Price;
        };

        const calculateInvertedRatio = (token0Price: number, token1Price: number): number => {
            if (!token0Price || !token1Price || token0Price === 0) return 0;
            return token1Price / token0Price;
        };

        // Transform the data based on period
        let historicalData: PoolHistoricalData[] = [];

        if (period === '1d') {
            // Process hourly data - already in ascending order (oldest to newest)
            historicalData = (historicalResult.data.poolHourDatas || []).map((hour: any) => {
                const timestamp = parseInt(hour.periodStartUnix);
                const token0Price = parseFloat(hour.token0Price || '0');
                const token1Price = parseFloat(hour.token1Price || '0');

                return {
                    date: new Date(timestamp * 1000).toISOString(), // Convert Unix timestamp to ISO string
                    timestamp: timestamp, // Keep the original timestamp for reference
                    token0Price: token0Price,
                    token1Price: token1Price,
                    liquidity: parseFloat(hour.liquidity || '0'),
                    volumeUSD: parseFloat(hour.volumeUSD || '0'),
                    feesUSD: parseFloat(hour.feesUSD || '0'),
                    ratio: calculateRatio(token0Price, token1Price),
                    invertedRatio: calculateInvertedRatio(token0Price, token1Price)
                };
            });
        } else {
            // Process daily data - already in ascending order (oldest to newest)
            historicalData = (historicalResult.data.poolDayDatas || []).map((day: any) => {
                const timestamp = parseInt(day.date);
                const token0Price = parseFloat(day.token0Price || '0');
                const token1Price = parseFloat(day.token1Price || '0');

                return {
                    date: new Date(timestamp * 1000).toISOString(), // Convert Unix timestamp to ISO string
                    timestamp: timestamp, // Keep the original timestamp for reference
                    token0Price: token0Price,
                    token1Price: token1Price,
                    liquidity: parseFloat(day.liquidity || '0'),
                    volumeUSD: parseFloat(day.volumeUSD || '0'),
                    feesUSD: parseFloat(day.feesUSD || '0'),
                    ratio: calculateRatio(token0Price, token1Price),
                    invertedRatio: calculateInvertedRatio(token0Price, token1Price)
                };
            });
        }

        // Get latest pool data
        const latestPoolData = latestResult.data?.pool;

        // Add current data point if not already present or not recent enough
        if (historicalData.length > 0) {
            const latestTimestamp = historicalData[historicalData.length - 1].timestamp || 0;
            const timeDiff = now - latestTimestamp;

            // Add current point if the latest data is older than expected
            // For 1d: if older than 1 hour; For other periods: if older than 12 hours
            const timeThreshold = period === '1d' ? 3600 : 43200; // seconds (1 hour or 12 hours)

            if (timeDiff > timeThreshold && latestPoolData) {
                const lastDataPoint = historicalData[historicalData.length - 1];

                // Use latest pool data if available, otherwise estimate from last data point
                const token0Price = parseFloat(latestPoolData.token0Price || '0') || lastDataPoint.token0Price;
                const token1Price = parseFloat(latestPoolData.token1Price || '0') || lastDataPoint.token1Price;
                const liquidity = parseFloat(latestPoolData.liquidity || '0') || lastDataPoint.liquidity;

                // Calculate time-based factor for volume/fees estimation
                const timeFactor = timeDiff / (period === '1d' ? 3600 : 86400);

                // Create current data point
                const currentPoint: PoolHistoricalData = {
                    date: new Date(now * 1000).toISOString(),
                    timestamp: now,
                    token0Price: token0Price,
                    token1Price: token1Price,
                    liquidity: liquidity,
                    // Estimate current period volume/fees based on time elapsed
                    volumeUSD: lastDataPoint.volumeUSD * Math.min(timeFactor, 1), // Cap at last point's volume
                    feesUSD: lastDataPoint.feesUSD * Math.min(timeFactor, 1),     // Cap at last point's fees
                    ratio: calculateRatio(token0Price, token1Price),
                    invertedRatio: calculateInvertedRatio(token0Price, token1Price)
                };

                // For debugging - log the current point
                console.log('Adding current data point:', currentPoint);

                // Add current point to data
                historicalData.push(currentPoint);
            }
        } else if (latestPoolData) {
            // If no historical data but we have latest pool data, create a single data point
            const token0Price = parseFloat(latestPoolData.token0Price || '0');
            const token1Price = parseFloat(latestPoolData.token1Price || '0');

            historicalData.push({
                date: new Date(now * 1000).toISOString(),
                timestamp: now,
                token0Price: token0Price,
                token1Price: token1Price,
                liquidity: parseFloat(latestPoolData.liquidity || '0'),
                volumeUSD: 0,
                feesUSD: 0,
                ratio: calculateRatio(token0Price, token1Price),
                invertedRatio: calculateInvertedRatio(token0Price, token1Price)
            });
        } else {
            // Last resort fallback if no data at all
            historicalData.push({
                date: new Date(now * 1000).toISOString(),
                timestamp: now,
                token0Price: 0,
                token1Price: 0,
                liquidity: 0,
                volumeUSD: 0,
                feesUSD: 0,
                ratio: 0,
                invertedRatio: 0
            });
        }

        // Final data check - remove any points with NaN/null/undefined values
        const validData = historicalData.map(point => ({
            ...point,
            token0Price: isNaN(point.token0Price) ? 0 : point.token0Price,
            token1Price: isNaN(point.token1Price) ? 0 : point.token1Price,
            liquidity: isNaN(point.liquidity) ? 0 : point.liquidity,
            volumeUSD: isNaN(point.volumeUSD) ? 0 : point.volumeUSD,
            feesUSD: isNaN(point.feesUSD) ? 0 : point.feesUSD,
            ratio: isNaN(point.ratio) ? 0 : point.ratio,
            invertedRatio: isNaN(point.invertedRatio) ? 0 : point.invertedRatio
        }));

        return validData;
    } catch (error) {
        console.error('Error fetching pool historical data:', error);
        throw error;
    }
};




