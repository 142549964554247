import {useState, useEffect} from "react";
import axios from "axios";

const apiKey = process.env.REACT_APP_GRAPH_API_KEY;
const GRAPH_API_URL = `https://gateway.thegraph.com/api/${apiKey}/subgraphs/id/FbCGRftH4a3yZugY7TnbYgPJVEv2LvMT6oF1fxPe9aJM`;

interface PoolHourData {
    periodStartUnix: number;
    volumeUSD: string;
    tvlUSD: string;
}

interface TickData {
    tickIdx: number;
    price0: string;
    price1: string;
    liquidityGross: string;
}

interface BestPoolRange {
    lowerPrice0: number;
    upperPrice0: number;
    lowerPrice1: number;
    upperPrice1: number;
    roi: number;
}

class UniswapBestPoolRangesService {
    private async fetchGraphQLData(query: string) {
        try {
            const response = await axios.post(GRAPH_API_URL, {query});
            if (!response.data?.data) {
                throw new Error(`Unexpected API response: ${JSON.stringify(response.data)}`);
            }
            return response.data.data;
        } catch (error) {
            console.error("GraphQL API Error:", error);
            throw error;
        }
    }


    /**
     * Fetches tick data (tick price ranges and liquidity) for a Uniswap V3 pool.
     * @param poolAddress - Uniswap V3 pool address
     * @returns Array of tick data
     */
    public async fetchTickData(poolAddress: string): Promise<TickData[]> {
        const query = `
      {
        ticks(where: { pool: "${poolAddress.toLowerCase()}" }) {
          tickIdx
          price0
          price1
          liquidityGross
        }
      }
    `;

        try {
            const data = await this.fetchGraphQLData(query);
            return data.ticks || [];
        } catch (error) {
            console.error("❌ Error fetching tick data:", error);
            return [];
        }
    }


    /**
     * Fetches historical pool hourly data (swap volume and TVL).
     */
    async fetchPoolHourlyData(poolAddress: string, hours: number): Promise<PoolHourData[]> {
        const startTime = Math.floor(Date.now() / 1000) - hours * 3600;
        const query = `
      {
        poolHourDatas(
          where: { pool: "${poolAddress.toLowerCase()}", periodStartUnix_gte: ${startTime} }
          orderBy: periodStartUnix
          orderDirection: desc
        ) {
          periodStartUnix
          volumeUSD
          tvlUSD
        }
      }
    `;

        const data = await this.fetchGraphQLData(query);
        return data.poolHourDatas || [];
    }

    /**
     * Fetches tick data (tick price ranges and liquidity).
     */
    async fetchBestPoolRanges(poolAddress: string): Promise<BestPoolRange> {
        const HOURS_LOOKBACK = 72;
        const [poolData, tickData] = await Promise.all([
            this.fetchPoolHourlyData(poolAddress, HOURS_LOOKBACK),
            this.fetchTickData(poolAddress),
        ]);

        if (!poolData.length || !tickData.length) {
            console.warn("⚠️ No sufficient data available.");
            return { lowerPrice0: 0, upperPrice0: 0, lowerPrice1: 0, upperPrice1: 0, roi: 0 };
        }

        const tickMap = new Map<number, { volumeSum: number; tvlSum: number }>();

        for (const data of poolData) {
            const volume = parseFloat(data.volumeUSD);
            const tvl = parseFloat(data.tvlUSD);

            for (const tick of tickData) {
                const { tickIdx, liquidityGross, price0: rawPrice0, price1: rawPrice1 } = tick;
                const liquidity = parseFloat(liquidityGross);

                if (!tickMap.has(tickIdx)) {
                    tickMap.set(tickIdx, { volumeSum: 0, tvlSum: 0 });
                }

                const tickEntry = tickMap.get(tickIdx)!;
                tickEntry.volumeSum += volume;
                tickEntry.tvlSum += liquidity > 0 ? tvl / liquidity : 0;
            }
        }

        const tickRatios = Array.from(tickMap.entries()).map(([tickIdx, { volumeSum, tvlSum }]) => {
            const foundTick: TickData | undefined = tickData.find((tick: TickData) => tick.tickIdx === tickIdx);
            if (!foundTick) return null;

            // 🔹 Correct Uniswap V3 Price Calculation from Tick Index
            const price0 = Math.pow(1.0001, tickIdx);  // Price0 = 1.0001^tickIdx
            const price1 = 1 / price0;  // Price1 = inverse of price0

            return {
                tickIdx,
                price0,
                price1,
                roi: tvlSum > 0 ? volumeSum / (tvlSum + 1) : 0, // Normalize ROI
            };
        }).filter(Boolean) as { tickIdx: number; price0: number; price1: number; roi: number }[];


        // 🔹 Sort ticks by price
        tickRatios.sort((a, b) => a.price0 - b.price0);

        // 🔹 Find the best contiguous price range maximizing ROI
        let bestLowerPrice0 = tickRatios[0].price0;
        let bestUpperPrice0 = tickRatios[0].price0;
        let bestLowerPrice1 = tickRatios[0].price1;
        let bestUpperPrice1 = tickRatios[0].price1;
        let bestROI = tickRatios[0].roi;

        let currentLowerPrice0 = tickRatios[0].price0;
        let currentUpperPrice0 = tickRatios[0].price0;
        let currentLowerPrice1 = tickRatios[0].price1;
        let currentUpperPrice1 = tickRatios[0].price1;
        let currentROI = tickRatios[0].roi;

        const ROI_THRESHOLD = 0.75; // Keep extending range if ROI stays within 75% of peak

        for (let i = 1; i < tickRatios.length; i++) {
            if (
                tickRatios[i].price0 - currentUpperPrice0 <= 0.02 * currentUpperPrice0 &&
                tickRatios[i].roi >= ROI_THRESHOLD * bestROI
            ) {
                currentUpperPrice0 = tickRatios[i].price0;
                currentUpperPrice1 = tickRatios[i].price1;
                currentROI += tickRatios[i].roi;
            } else {
                if (currentROI > bestROI) {
                    bestLowerPrice0 = currentLowerPrice0;
                    bestUpperPrice0 = currentUpperPrice0;
                    bestLowerPrice1 = currentLowerPrice1;
                    bestUpperPrice1 = currentUpperPrice1;
                    bestROI = currentROI;
                }
                currentLowerPrice0 = tickRatios[i].price0;
                currentUpperPrice0 = tickRatios[i].price0;
                currentLowerPrice1 = tickRatios[i].price1;
                currentUpperPrice1 = tickRatios[i].price1;
                currentROI = tickRatios[i].roi;
            }
        }

        if (currentROI > bestROI) {
            bestLowerPrice0 = currentLowerPrice0;
            bestUpperPrice0 = currentUpperPrice0;
            bestLowerPrice1 = currentLowerPrice1;
            bestUpperPrice1 = currentUpperPrice1;
            bestROI = currentROI;
        }

        if (bestLowerPrice0 === bestUpperPrice0) bestUpperPrice0 *= 1.02;
        if (bestLowerPrice1 === bestUpperPrice1) bestUpperPrice1 *= 1.02;

        // 🔹 Debugging Output
        console.log("✅ Best Price Range Found:", {
            lowerPrice0: bestLowerPrice0,
            upperPrice0: bestUpperPrice0,
            lowerPrice1: bestLowerPrice1,
            upperPrice1: bestUpperPrice1,
            roi: bestROI,
        });

        return {
            lowerPrice0: bestLowerPrice0,
            upperPrice0: bestUpperPrice0,
            lowerPrice1: bestLowerPrice1,
            upperPrice1: bestUpperPrice1,
            roi: bestROI,
        };
    }



    /**
     * React Hook to fetch the best Uniswap V3 pool price range for maximizing ROI.
     */
    useBestUniswapPoolRanges(poolAddress: string) {
        const [bestRange, setBestRange] = useState<BestPoolRange>({
            lowerPrice0: 0,
            upperPrice0: 0,
            lowerPrice1: 0,
            upperPrice1: 0,
            roi: 0,
        });
        const [loading, setLoading] = useState<boolean>(true);
        const [error, setError] = useState<string | null>(null);

        useEffect(() => {
            const fetchData = async () => {
                setLoading(true);
                setError(null);
                try {
                    const data = await new UniswapBestPoolRangesService().fetchBestPoolRanges(poolAddress);
                    setBestRange(data);
                } catch (err) {
                    setError(err instanceof Error ? err.message : "Failed to fetch Uniswap V3 best pool ranges.");
                } finally {
                    setLoading(false);
                }
            };

            fetchData();
        }, [poolAddress]);

        return {...bestRange, loading, error};
    }
}

export default new UniswapBestPoolRangesService();
