import React from "react";
import { Card, CardBody, Table } from "react-bootstrap";
import moment from "moment";
import { PositionEvent, UniswapPosition } from "../../../../model/crypto/CryptoModels";
import TokenUtilsService from "../../../../service/utils/TokenUtilsService";
import FormatUtils from "../../../../service/utils/FormatUtils";

interface FeeCollectionTableProps {
    position: UniswapPosition;
}

// Component to display fee collection events in a table
const FeeCollectionTable: React.FC<FeeCollectionTableProps> = ({ position }) => {
    // Filter for only collect events
    const events = position.positionEvents?.filter(event => event.eventType === 'collect') || [];

    if (!events.length) {
        return (
            <Card className="my-3">
                <CardBody>
                    <h5>Fee Collections</h5>
                    <p className="text-muted">No fee collection events found for this position</p>
                </CardBody>
            </Card>
        );
    }

    return (
        <Card className="my-3">
            <CardBody>
                <h5>Fee Collections</h5>
                <Table responsive hover className="align-middle">
                    <thead>
                    <tr>
                        <th>Date</th>
                        <th>{TokenUtilsService.normalizeSymbol(position.token0?.symbol)}</th>
                        <th>{TokenUtilsService.normalizeSymbol(position.token1?.symbol)}</th>
                        <th>USD Value</th>
                        <th>Transaction</th>
                    </tr>
                    </thead>
                    <tbody>
                    {events.map((event, index) => (
                        <tr key={event.id || `event-${index}`}>
                            <td>{moment(event.timestamp).format('MMM DD, YYYY h:mm a')}</td>
                            <td>
                                {event.amount0 > 0 ? (
                                    <span className="text-success">+{FormatUtils.formatNumber(event.amount0)}</span>
                                ) : event.amount0 < 0 ? (
                                    <span className="text-danger">{FormatUtils.formatNumber(event.amount0)}</span>
                                ) : (
                                    "0"
                                )}
                            </td>
                            <td>
                                {event.amount1 > 0 ? (
                                    <span className="text-success">+{FormatUtils.formatNumber(event.amount1)}</span>
                                ) : event.amount1 < 0 ? (
                                    <span className="text-danger">{FormatUtils.formatNumber(event.amount1)}</span>
                                ) : (
                                    "0"
                                )}
                            </td>
                            <td>
                                {event.amountUSD !== undefined ? (
                                    `$${FormatUtils.formatNumber(event.amountUSD)}`
                                ) : (
                                    // Estimate USD value based on token amounts and current prices
                                    `$${FormatUtils.formatNumber(
                                        (Math.abs(event.amount0) * (position.token0?.derivedUSD || 0)) +
                                        (Math.abs(event.amount1) * (position.token1?.derivedUSD || 0))
                                    )}`
                                )}
                            </td>
                            <td>
                                {event.transactionHash ? (
                                    <a
                                        href={`https://arbiscan.io/tx/${event.transactionHash}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-decoration-none"
                                    >
                                        {event.transactionHash.substring(0, 6)}...{event.transactionHash.substring(62)}
                                    </a>
                                ) : (
                                    "-"
                                )}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </CardBody>
        </Card>
    );
};

export default FeeCollectionTable;