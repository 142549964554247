


class UniswapPredictionService {


    getTokenAmountsOnPositionEdges(
        liquidity: bigint,
        priceLower: number,
        priceUpper: number,
        token0Decimals: number,
        token1Decimals: number
    ): { token0AtLower: number; token1AtLower: number; token0AtUpper: number; token1AtUpper: number } {
        if (priceUpper <= priceLower) {
            throw new Error("Upper price must be greater than lower price.");
        }

        const Q96 = 2n ** 96n; // ✅ Uniswap's fixed-point precision

        // ✅ Convert price to sqrtPriceX96 as BigInt
        const sqrtPriceLowerX96 = BigInt(Math.floor(Math.sqrt(priceLower) * Number(Q96)));
        const sqrtPriceUpperX96 = BigInt(Math.floor(Math.sqrt(priceUpper) * Number(Q96)));

        // ✅ Compute decimal adjustment factor
        const decimalDiff = token1Decimals - token0Decimals;
        const decimalFactor: bigint = decimalDiff > 0
            ? 10n ** BigInt(decimalDiff)  // Scale up if Token1 has more decimals
            : 10n ** BigInt(-decimalDiff); // Scale down if Token0 has more decimals

        // ✅ Compute denominator in `BigInt`
        const denominator = (sqrtPriceLowerX96 * sqrtPriceUpperX96) / Q96;
        if (denominator === 0n) {
            throw new Error("Denominator in liquidity calculation is zero.");
        }

        // ✅ Ensure all arithmetic is performed using `BigInt`
        const token0AtLowerRaw = (BigInt(liquidity) * (sqrtPriceUpperX96 - sqrtPriceLowerX96)) / denominator;
        const token1AtUpperRaw = (BigInt(liquidity) * (sqrtPriceUpperX96 - sqrtPriceLowerX96)) / Q96;

        // ✅ Convert `BigInt` to `number` with correct scaling
        const token0AtLower = Number(token0AtLowerRaw) / Number(decimalFactor);


        const token1AtLower = 0; // Always zero at lower tick

        const token0AtUpper = 0; // Always zero at upper tick

        const token1AtUpper = Number(token1AtUpperRaw) / Number(decimalFactor)
        // Divide when Token0 has more decimals

        return {token0AtLower, token1AtLower, token0AtUpper, token1AtUpper};
    }


}


export default new UniswapPredictionService();
