import React from "react";
import Col from "react-bootstrap/Col";
import { Toaster } from 'react-hot-toast';
import SignInComponent from "../../../components/public/sign-in/SignInComponent";
import LayoutEmpty from "../../../components/layout/LayoutEmpty";

function SignInPage() {
    const handleSignInSuccess = () => {
        // Handle successful login
    };

    const subtitle = (
        <>
            <span className="mx-2">Don&apos;t have an account?</span>
            <a href="sign-up">Sign up here</a>
        </>
    );

    const content = (
        <>
            <Col className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5 pb-2 align-content-center">
                <SignInComponent
                    title="Sign In"
                    subtitle={subtitle}
                    onSignInSuccess={handleSignInSuccess}
                />
            </Col>
            {/* Toaster component for toast notifications */}
            <Toaster position="top-right" />
        </>
    );

    return (
        <>
            <LayoutEmpty center>
                {content}
            </LayoutEmpty>
        </>
    );
}

export default SignInPage;