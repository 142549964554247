import { PoolHistoricalData } from "./PoolService";


export const calculateVolatilityIndex = (historicalData: PoolHistoricalData[]): number => {
    if (historicalData.length < 2) return 0;

    // Calculate daily returns
    const returns = historicalData.map((data, i) => {
        if (i === 0) return 0;
        const prevData = historicalData[i - 1];
        return (data.ratio - prevData.ratio) / prevData.ratio;
    });

    // Calculate average return
    const avgReturn = returns.reduce((sum, r) => sum + r, 0) / (returns.length - 1);

    // Calculate variance
    const variance = returns.reduce((sum, r) => sum + Math.pow(r - avgReturn, 2), 0) / (returns.length - 1);

    // Standard deviation is square root of variance
    const stdDev = Math.sqrt(variance);

    // Annualize standard deviation (volatility)
    const volatility = stdDev * Math.sqrt(365);

    // Volatility index is annualized volatility as a percentage
    return volatility * 100;
}