// First let's create a custom interface that extends the class methods
interface CryptoPositionsServiceInterface {
    fetchUniswapPositions(positionIds: number[]): Promise<any[]>;
    fetchActiveUniswapV3PositionsFromGraph(walletAddress: string): Promise<number[]>;
    fetchUnclaimedFees(positionId: number): Promise<{ amount0: number, amount1: number }>;
    getPositionDetails(positionId: number): Promise<any>;
}

// Then let's implement the service with all required methods
import { PositionEvent, UniswapPosition } from "../../model/crypto/CryptoModels";
import SubgraphUniswapService from "./SubgraphUniswapService";

const apiKey = 'd59545755cf1974d72a4b387c64e85e9';

const SUBGRAPH_URL =
    "https://gateway.thegraph.com/api/" + apiKey + "/subgraphs/id/3V7ZY6muhxaQL5qvntX1CFXJ32W7BxXZTGTwmpH5J4t3";

interface GraphQLResponse {
    data?: {
        positions: { id: number, liquidity: number }[];
    };
    errors?: any;
}

class CryptoPositionsManagementService implements CryptoPositionsServiceInterface {
    /**
     * Get position details for a single position ID
     * This delegates to the SubgraphUniswapService
     */
    getPositionDetails = async (positionId: number) => {
        return SubgraphUniswapService.getPositionDetails(positionId);
    };

    /**
     * Fetch unclaimed fees for a position
     */
    fetchUnclaimedFees = async (positionId: number): Promise<{ amount0: number, amount1: number }> => {
        try {
            const positionDetails = await SubgraphUniswapService.getPositionDetails(positionId);
            if (!positionDetails) {
                return { amount0: 0, amount1: 0 };
            }

            return {
                amount0: positionDetails.tokensOwed0,
                amount1: positionDetails.tokensOwed1
            };
        } catch (error) {
            console.error(`Error fetching unclaimed fees for position ${positionId}:`, error);
            return { amount0: 0, amount1: 0 };
        }
    };

    /**
     * Fetches Uniswap position details for multiple position IDs
     * @param positionIds Array of Uniswap position IDs to fetch
     * @returns Array of UniswapPosition objects
     */
    fetchUniswapPositions = async (positionIds: number[]): Promise<UniswapPosition[]> => {
        try {
            const positions: UniswapPosition[] = [];

            for (const positionId of positionIds) {
                try {
                    // Fetch position details
                    const positionDetails = await SubgraphUniswapService.getPositionDetails(positionId);
                    if (!positionDetails) continue;

                    // Get unclaimed fees directly from positionDetails
                    const unclaimedFees = {
                        amount0: positionDetails.tokensOwed0,
                        amount1: positionDetails.tokensOwed1
                    };

                    // Calculate uncollected fees in USD
                    const uncollectedFeesUSD = (unclaimedFees.amount0 * (positionDetails.token0?.derivedUSD || 0)) +
                        (unclaimedFees.amount1 * (positionDetails.token1?.derivedUSD || 0));

                    // Compute feesTotalUSD
                    const feesTotalUSD = positionDetails.feesCollectedUSD + uncollectedFeesUSD;

                    // Calculate age of position in seconds
                    const ageInSeconds = (Date.now() - positionDetails.mintTimestamp) / 1000;

                    // Determine initialLiquidityUSD from positionDetails if available, otherwise estimate
                    const initialLiquidityUSD = positionDetails.initialLiquidityUSD || positionDetails.liquidityUSD * 0.98;

                    // Calculate performance metrics
                    const profitLossUSD = positionDetails.liquidityUSD - initialLiquidityUSD;
                    const isProfitable = profitLossUSD >= 0;
                    const returnPercentage = initialLiquidityUSD > 0
                        ? ((positionDetails.liquidityUSD / initialLiquidityUSD) - 1) * 100
                        : 0;

                    // Calculate total current value (liquidity + fees)
                    const totalCurrentValue = positionDetails.liquidityUSD + feesTotalUSD;

                    // Calculate total return including fees
                    const totalReturnUSD = totalCurrentValue - initialLiquidityUSD;
                    const totalReturnPercentage = initialLiquidityUSD > 0
                        ? ((totalCurrentValue / initialLiquidityUSD) - 1) * 100
                        : 0;

                    // Calculate annualized return
                    const yearsElapsed = ageInSeconds / 31536000; // seconds in a year
                    const annualizedReturn = yearsElapsed > 0
                        ? totalReturnPercentage / yearsElapsed
                        : 0;

                    // Populate UniswapPosition object with all required properties
                    // IMPORTANT: Only include properties that exist in the interface
                    const populatedPosition: UniswapPosition = {
                        id: positionId,
                        owner: positionDetails.owner,
                        token0: positionDetails.token0,
                        token1: positionDetails.token1,
                        amount0: positionDetails.amount0,
                        amount1: positionDetails.amount1,
                        address: positionDetails.address,
                        pool: {
                            address: positionDetails.pool.address,
                            network: "arbitrum-one"
                        },
                        fee: positionDetails.fee,
                        ratio: positionDetails.currentPrice.token1PerToken0,
                        mintTimestamp: positionDetails.mintTimestamp,
                        mintBlock: positionDetails.mintBlock,
                        tickLower: positionDetails.tickLower,
                        tickUpper: positionDetails.tickUpper,
                        liquidity: BigInt(positionDetails.liquidity),
                        tokensOwed0: unclaimedFees.amount0,
                        tokensOwed1: unclaimedFees.amount1,
                        liquidityUSD: positionDetails.liquidityUSD,
                        feesCollectedToken0: positionDetails.feesCollectedToken0,
                        feesCollectedToken1: positionDetails.feesCollectedToken1,
                        feesCollectedUSD: positionDetails.feesCollectedUSD,
                        feesTotalUSD: feesTotalUSD,

                        // Performance metrics
                        initialLiquidityUSD,
                        uncollectedFeesUSD,
                        totalFeesUSD: feesTotalUSD,
                        profitLossUSD,
                        isProfitable,
                        returnPercentage,
                        totalCurrentValue,
                        ageInSeconds,
                        annualizedReturn,
                        totalReturnUSD,
                        totalReturnPercentage,

                        // Existing fields
                        apr: annualizedReturn, // Set apr to the calculated annualizedReturn
                        currentPrice: {
                            token1PerToken0: positionDetails.currentPrice.token1PerToken0,
                            token0PerToken1: positionDetails.currentPrice.token0PerToken1,
                        },
                        priceRange: {
                            lower: {
                                token1PerToken0: positionDetails.priceRange.lower.token1PerToken0,
                                token0PerToken1: positionDetails.priceRange.lower.token0PerToken1,
                            },
                            upper: {
                                token1PerToken0: positionDetails.priceRange.upper.token1PerToken0,
                                token0PerToken1: positionDetails.priceRange.upper.token0PerToken1,
                            },
                        },
                        // Only include positionEvents property
                        positionEvents: positionDetails.positionEvents,
                    };

                    positions.push(populatedPosition);
                } catch (error) {
                    console.error(`Error fetching position ${positionId}:`, error);
                }
            }

            return positions;
        } catch (error) {
            console.error("Error fetching Uniswap positions:", error);
            return [];
        }
    }

    /**
     * Fetches active Uniswap V3 position IDs for a given wallet address
     * @param walletAddress The Ethereum wallet address to check
     * @returns Array of position IDs that are active for the wallet
     */
    fetchActiveUniswapV3PositionsFromGraph = async (walletAddress: string): Promise<number[]> => {
        const query = `
        {
            positions(
                where: { owner: "${walletAddress.toLowerCase()}", liquidity_gt: "0" }
            ) {
                id
                liquidity
            }
        }
    `;

        try {
            const response = await fetch(SUBGRAPH_URL, {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({query}),
            });

            const result: GraphQLResponse = await response.json();

            if (result.errors) {
                console.error("GraphQL error:", result.errors);
                return [];
            }

            return result.data?.positions.map(position => Number(position.id)) || [];
        } catch (error) {
            console.error("Error fetching positions from The Graph:", error);
            return [];
        }
    };
}

export default new CryptoPositionsManagementService();