import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Card, Table, Badge, Spinner, Button, OverlayTrigger, Tooltip,
    Row, Col, Form, Nav, Tab
} from 'react-bootstrap';
import {
    ArrowUp, ArrowDown, ExternalLink, Info, TrendingUp, DollarSign,
    Percent, Globe, Coins, Star
} from 'lucide-react';
import { Pool } from "../../../model/crypto/CryptoModels";
import { formatFee, formatNumber } from '../../../service/crypto/pool/PoolService';
import { TimePeriod, toNumber, toString, getAprColor, getNetworkBadgeInfo, formatValue, getAprComparison, calculateTimeBasedApr } from './PoolUtils';
import { fetchTimePeriodApr } from '../../../service/crypto/pool/TimePeriodService';

// Define the props type to match what PoolsExplorer passes
type PoolsTableProps = {
    currentPools: Pool[];
    isLoading: boolean;
    filteredPools: Pool[];
    handleSort: (key: string) => void;
    sortConfig: {
        key: string;
        direction: string;
    };
    networkFilter: string;
};

const PoolsTable: React.FC<PoolsTableProps> = ({
                                                   currentPools,
                                                   isLoading,
                                                   filteredPools,
                                                   handleSort,
                                                   sortConfig,
                                                   networkFilter
                                               }) => {
    const navigate = useNavigate();
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<TimePeriod>('1year');
    const [poolsWithAllTimeApr, setPoolsWithAllTimeApr] = useState<Map<string, number>>(new Map());
    const [favoritePools, setFavoritePools] = useState<Set<string>>(new Set());
    const [activeTab, setActiveTab] = useState('available');

    // For pagination within the Favorites tab
    const [currentPage, setCurrentPage] = useState(1);
    const [poolsPerPage] = useState(10);

    // State to track period-specific APR values
    const [periodSpecificAprs, setPeriodSpecificAprs] = useState<Map<string, number>>(new Map());
    const [loadingAprs, setLoadingAprs] = useState<Set<string>>(new Set());

    // Time period options for the select box
    const timePeriodOptions = [
        { value: '1day', label: '1 Day' },
        { value: '7days', label: '7 Days' },
        { value: '1month', label: '1 Month' },
        { value: '1year', label: '1 Year' },
        { value: 'alltime', label: 'All Time' }
    ];

    // Reset pagination when tab changes
    useEffect(() => {
        setCurrentPage(1);
    }, [activeTab]);

    // Handle row click to navigate to pool details
    const handleRowClick = (poolId: string, network: string | undefined) => {
        // Default to arbitrum if network is undefined
        const poolNetwork = network || 'arbitrum';
        navigate(`/crypto/pool/${poolNetwork}/${poolId}`);
    };

    // Load favorites from localStorage on component mount
    useEffect(() => {
        try {
            const storedFavorites = localStorage.getItem('favoritePools');
            if (storedFavorites) {
                setFavoritePools(new Set(JSON.parse(storedFavorites)));
            }
        } catch (error) {
            console.error('Error loading favorites from localStorage:', error);
        }
    }, []);

    // Save favorites to localStorage whenever they change
    useEffect(() => {
        try {
            localStorage.setItem('favoritePools', JSON.stringify([...favoritePools]));
        } catch (error) {
            console.error('Error saving favorites to localStorage:', error);
        }
    }, [favoritePools]);

    // Effect to fetch period-specific APRs when time period changes
    useEffect(() => {
        const fetchPeriodAprs = async () => {
            // Only fetch for the displayed pools to avoid excessive API calls
            const poolsToFetch = getDisplayPools();
            const newAprs = new Map(periodSpecificAprs);
            const newLoading = new Set<string>();

            // Create a unique key for each pool+period combination
            const getAprKey = (poolId: string) => `${poolId}_${selectedTimePeriod}`;

            // Update loading state for pools being fetched
            poolsToFetch.forEach(pool => {
                newLoading.add(getAprKey(pool.id));
            });
            setLoadingAprs(newLoading);

            // Fetch APR for each pool in parallel
            const fetchPromises = poolsToFetch.map(async (pool) => {
                try {
                    if (!pool.network) return;

                    const aprKey = getAprKey(pool.id);

                    // Use the service to fetch period-specific APR directly from API
                    const aprValue = await fetchTimePeriodApr(
                        pool.id,
                        pool.network,
                        selectedTimePeriod
                    );

                    // Store the result
                    newAprs.set(aprKey, aprValue);
                } catch (error) {
                    console.error(`Failed to fetch APR for pool ${pool.id}`, error);
                }
            });

            await Promise.allSettled(fetchPromises);

            // Update the period-specific APRs
            setPeriodSpecificAprs(newAprs);

            // Clear loading state
            setLoadingAprs(new Set());
        };

        // Only fetch if we have pools to display
        if (getDisplayPools().length > 0) {
            fetchPeriodAprs();
        }
    }, [selectedTimePeriod, activeTab]);

    // Get time period display text
    const getTimePeriodText = (timePeriod: TimePeriod): string => {
        switch (timePeriod) {
            case '1day': return '24h';
            case '7days': return '7d';
            case '1month': return '30d';
            case '1year': return 'Annual';
            case 'alltime': return 'All-time';
            default: return 'APR';
        }
    };

    // Get sort icon based on current sort configuration
    const getSortIcon = (key: string) => {
        if (sortConfig.key !== key) {
            return null;
        }
        return sortConfig.direction === 'asc' ?
            <ArrowUp size={14} className="ms-1 text-primary"/> :
            <ArrowDown size={14} className="ms-1 text-primary"/>;
    };

    // Helper to render table headers with tooltips
    const renderSortableHeader = (key: string, label: string, tooltip: string) => (
        <th
            className="user-select-none"
            style={{cursor: 'pointer'}}
            onClick={() => handleSort(key)}
        >
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip>{tooltip}</Tooltip>}
            >
                <div className="d-flex align-items-center">
                    {label}
                    {getSortIcon(key)}
                    <Info size={14} className="ms-1 text-muted opacity-50" />
                </div>
            </OverlayTrigger>
        </th>
    );

    // Render APR badge based on the selected time period
    const renderAprBadge = (pool: Pool) => {
        const aprKey = `${pool.id}_${selectedTimePeriod}`;
        const isLoading = loadingAprs.has(aprKey);

        // If we have fetched this specific period's APR, use it
        const periodSpecificApr = periodSpecificAprs.get(aprKey);

        // If loading or no period-specific value yet, fall back to calculated value
        const aprValue = isLoading || periodSpecificApr === undefined
            ? calculateTimeBasedApr(pool, selectedTimePeriod)
            : periodSpecificApr;

        const variant = getAprColor(aprValue);

        return (
            <div className="d-flex align-items-center">
                {isLoading ? (
                    <Spinner animation="border" size="sm" className="me-2" />
                ) : (
                    <Badge
                        bg={variant}
                        className="py-2 px-2 d-flex align-items-center"
                        style={{ width: 'fit-content' }}
                    >
                        {aprValue.toFixed(2)}%
                    </Badge>
                )}
            </div>
        );
    };

    // Helper to get Uniswap URL based on network with the correct format
    const getUniswapUrl = (poolId: string, network: string | undefined) => {
        const baseUrl = 'https://app.uniswap.org/explore/pools/';
        let networkParam = 'arbitrum'; // Default to arbitrum

        switch(network) {
            case 'ethereum': networkParam = 'ethereum'; break;
            case 'optimism': networkParam = 'optimism'; break;
            case 'base': networkParam = 'base'; break;
            case 'polygon': networkParam = 'polygon'; break;
            case 'arbitrum': networkParam = 'arbitrum'; break;
            default: networkParam = 'arbitrum';
        }

        return `${baseUrl}${networkParam}/${poolId}`;
    };

    // Handle APR time period change
    const handleTimePeriodChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedTimePeriod(e.target.value as TimePeriod);
    };

    // Handle favoriting/unfavoriting a pool
    const toggleFavorite = (poolId: string) => {
        const newFavorites = new Set(favoritePools);
        if (newFavorites.has(poolId)) {
            newFavorites.delete(poolId);
        } else {
            newFavorites.add(poolId);
        }
        setFavoritePools(newFavorites);
    };

    // Get favorite pools from all filtered pools
    const getAllFavoritePools = () => {
        // Get from all filtered pools, not just current page
        return filteredPools.filter(pool => favoritePools.has(pool.id));
    };

    // Get paginated pools for the active tab
    const getDisplayPools = () => {
        if (activeTab === 'available') {
            // For available tab, use the pre-paginated currentPools
            return currentPools;
        } else {
            // For favorites tab, we need to apply pagination ourselves
            const allFavorites = getAllFavoritePools();
            const indexOfLastPool = currentPage * poolsPerPage;
            const indexOfFirstPool = indexOfLastPool - poolsPerPage;
            return allFavorites.slice(indexOfFirstPool, indexOfLastPool);
        }
    };

    // Render table content for selected tab
    const renderTableContent = (poolsToShow: Pool[]) => {
        if (isLoading) {
            return (
                <tr>
                    <td colSpan={networkFilter === 'all' ? 9 : 8} className="text-center py-5">
                        <Spinner animation="border" variant="primary" className="mb-2" />
                        <p className="mb-0 text-muted">Loading pools data...</p>
                    </td>
                </tr>
            );
        }

        // For favorites tab, check if we have any favorites at all
        if (activeTab === 'favorites' && getAllFavoritePools().length === 0) {
            return (
                <tr>
                    <td colSpan={networkFilter === 'all' ? 9 : 8} className="text-center py-5">
                        <div className="text-muted">
                            <p className="mb-2">No favorite pools yet. Mark pools as favorites to see them here.</p>
                            <p className="mb-0 small">Click the star icon next to a pool to add it to favorites</p>
                        </div>
                    </td>
                </tr>
            );
        }

        // For available tab, check if we have matching pools
        if (activeTab === 'available' && filteredPools.length === 0) {
            return (
                <tr>
                    <td colSpan={networkFilter === 'all' ? 9 : 8} className="text-center py-5">
                        <div className="text-muted">
                            <p className="mb-2">No pools found matching your search criteria</p>
                            <p className="mb-0 small">Try adjusting your filters or search term</p>
                        </div>
                    </td>
                </tr>
            );
        }

        // If we have pools to show but current page has none (could happen when removing favorites)
        if (poolsToShow.length === 0) {
            return (
                <tr>
                    <td colSpan={networkFilter === 'all' ? 9 : 8} className="text-center py-5">
                        <div className="text-muted">
                            <p className="mb-0">No pools on this page. Try going to the previous page.</p>
                        </div>
                    </td>
                </tr>
            );
        }

        return poolsToShow.map(pool => (
            <tr
                key={pool.id}
                onClick={() => handleRowClick(pool.id, pool.network)}
                style={{ cursor: 'pointer' }}
            >
                <td className="text-center" onClick={(e) => e.stopPropagation()}>
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleFavorite(pool.id);
                        }}
                        style={{ cursor: 'pointer' }}
                        className="d-flex justify-content-center"
                    >
                        <Star
                            size={16}
                            className={favoritePools.has(pool.id)
                                ? "text-warning fill-warning"
                                : "text-muted"}
                            fill={favoritePools.has(pool.id) ? "currentColor" : "none"}
                        />
                    </div>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <div className="me-2">
                            {/* Could add token icons here in the future */}
                        </div>
                        <div>
                            <div className="fw-medium">
                                {pool.token0.symbol}/{pool.token1.symbol}
                            </div>
                            <div className="small text-secondary">
                                {pool.token0.name}/{pool.token1.name}
                            </div>
                        </div>
                    </div>
                </td>
                <td>
                    <Badge
                        bg={pool.protocol === 'v4' ? 'info' : (pool.protocol === 'v3' ? 'primary' : 'secondary')}
                        pill
                        className="px-2 py-1"
                    >
                        {pool.protocol.toUpperCase()}
                    </Badge>
                </td>
                {networkFilter === 'all' && (
                    <td>
                        {pool.network && (
                            <Badge
                                bg={getNetworkBadgeInfo(pool.network).bg}
                                className="px-2 py-1 d-flex align-items-center"
                                style={{ width: 'fit-content' }}
                            >
                                <Globe size={12} className="me-1" />
                                {pool.network.charAt(0).toUpperCase() + pool.network.slice(1)}
                            </Badge>
                        )}
                    </td>
                )}
                <td>
                    <Badge bg="light" text="dark" className="border">
                        {formatFee(String(pool.feeTier))}
                    </Badge>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <DollarSign size={16} className="text-success me-1" />
                        <span className="fw-medium">{formatNumber(pool.tvlUSD, 2)}</span>
                    </div>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <TrendingUp size={16} className="text-primary me-1" />
                        <span>{formatNumber(pool.volumeUSD24h || 0, 2)}</span>
                    </div>
                </td>
                <td>
                    <div className="d-flex align-items-center">
                        <Coins size={16} className="text-warning me-1" />
                        <span>{formatNumber(pool.feesUSD24h || 0, 2)}</span>
                    </div>
                </td>
                <td>
                    {renderAprBadge(pool)}
                </td>
                <td className="text-end" onClick={(e) => e.stopPropagation()}>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip>View this pool on Uniswap</Tooltip>}
                    >
                        <Button
                            variant="outline-primary"
                            size="sm"
                            href={getUniswapUrl(pool.id, pool.network)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="d-inline-flex align-items-center"
                        >
                            View <ExternalLink size={14} className="ms-1"/>
                        </Button>
                    </OverlayTrigger>
                </td>
            </tr>
        ));
    };

    // Total number of pages for favorites tab pagination
    const totalFavoritePages = Math.ceil(getAllFavoritePools().length / poolsPerPage);

    // Pagination controls for favorites tab
    const renderFavoritesPagination = () => {
        if (activeTab !== 'favorites' || getAllFavoritePools().length <= poolsPerPage) {
            return null;
        }

        const pageNumbers = [];
        for (let i = 1; i <= totalFavoritePages; i++) {
            pageNumbers.push(i);
        }

        return (
            <div className="d-flex justify-content-center mt-3">
                <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                    className="me-1"
                >
                    Previous
                </Button>
                {pageNumbers.map(number => (
                    <Button
                        key={number}
                        variant={number === currentPage ? "primary" : "outline-secondary"}
                        size="sm"
                        className="mx-1"
                        onClick={() => setCurrentPage(number)}
                    >
                        {number}
                    </Button>
                ))}
                <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalFavoritePages))}
                    disabled={currentPage === totalFavoritePages}
                    className="ms-1"
                >
                    Next
                </Button>
            </div>
        );
    };

    return (
        <Card className="shadow-sm border-0">
            <Card.Header className="py-3">
                {/* Tabs for Available Pools and Favorites with Time Period Select */}
                <div className="d-flex justify-content-between align-items-center">
                    <Nav variant="tabs" className="mb-1 flex-grow-1" activeKey={activeTab} onSelect={(k) => k && setActiveTab(k)}>
                        <Nav.Item>
                            <Nav.Link eventKey="available" className="d-flex align-items-center">
                                <h5 className="mb-0 me-2">Pools</h5>
                                Available Pools
                                <Badge bg="primary" pill className="ms-2">
                                    {filteredPools.length}
                                </Badge>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="favorites" className="d-flex align-items-center">
                                <Star size={14} className="me-1" /> Favorites
                                <Badge bg="warning" pill className="ms-2">
                                    {getAllFavoritePools().length}
                                </Badge>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <div className="d-flex align-items-center" style={{ minWidth: '200px' }}>
                        <div className="d-flex align-items-center w-100">
                            <div className="flex-grow-1 me-2">
                                <Form.Select
                                    size="sm"
                                    value={selectedTimePeriod}
                                    onChange={handleTimePeriodChange}
                                    aria-label="Select time period for APR"
                                >
                                    {timePeriodOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="time-period-tooltip">
                                        <div className="text-start">
                                            <strong>APR Time Period</strong>
                                            <p className="mb-1 mt-1">Select the time period used to calculate pool APR:</p>
                                            <ul className="ps-3 mb-0">
                                                <li>1 Day: Uses 24h fee data</li>
                                                <li>7 Days: Uses weekly average</li>
                                                <li>1 Month: Uses 30-day average</li>
                                                <li>1 Year: Annual projection</li>
                                                <li>All Time: Long-term historical data</li>
                                            </ul>
                                        </div>
                                    </Tooltip>
                                }
                            >
                                <div style={{ position: "relative", top: "-1px" }}>
                                    <Info size={14} className="text-primary cursor-pointer" />
                                </div>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="p-0">
                <div className="table-responsive">
                    <Table hover striped className="mb-0 align-middle">
                        <thead className="bg-light">
                        <tr>
                            <th className="text-center">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Click to favorite a pool</Tooltip>}
                                >
                                    <div className="d-flex align-items-center justify-content-center">
                                        <Star size={14} className="text-muted opacity-50" />
                                    </div>
                                </OverlayTrigger>
                            </th>
                            {renderSortableHeader('pair', 'Pool', 'Token pair in this liquidity pool')}
                            <th>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip>Uniswap protocol version</Tooltip>}
                                >
                                    <div className="d-flex align-items-center">
                                        Protocol
                                        <Info size={14} className="ms-1 text-muted opacity-50" />
                                    </div>
                                </OverlayTrigger>
                            </th>
                            {networkFilter === 'all' && (
                                <th>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip>Blockchain network</Tooltip>}
                                    >
                                        <div className="d-flex align-items-center">
                                            Network
                                            <Info size={14} className="ms-1 text-muted opacity-50" />
                                        </div>
                                    </OverlayTrigger>
                                </th>
                            )}
                            {renderSortableHeader('feeTier', 'Fee', 'Trading fee percentage for this pool')}
                            {renderSortableHeader('tvlUSD', 'TVL', 'Total Value Locked in the pool')}
                            {renderSortableHeader('volumeUSD24h', '24h Volume', 'Trading volume in the last 24 hours')}
                            {renderSortableHeader('feesUSD24h', '24h Fees', 'Trading fees earned in the last 24 hours')}
                            {renderSortableHeader('apr', `${getTimePeriodText(selectedTimePeriod)} APR`, `APR calculated based on ${timePeriodOptions.find(t => t.value === selectedTimePeriod)?.label} data`)}
                            <th className="text-end">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {renderTableContent(getDisplayPools())}
                        </tbody>
                    </Table>
                </div>

                {/* Render pagination for favorites tab if needed */}
                {renderFavoritesPagination()}
            </Card.Body>
        </Card>
    );
};

export default PoolsTable;