import React, {useContext, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CreatePositionComponent from "../../components/crypto/uniswap/position/CreatePositionComponent";
import {UniswapPosition} from "../../model/crypto/CryptoModels";
import { ThemeContext } from '../../App';


const CreatePositionModal: React.FC<{ position: UniswapPosition }> = ({position}) => {
    const [show, setShow] = useState(false);
    const context = useContext(ThemeContext);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="warning"  onClick={handleShow}>
                Edit Position
            </Button>



            <Modal show={show} data-bs-theme={context?.theme} onHide={() => setShow(false)} centered>

                <Modal.Header closeButton>
                    <Modal.Title className="text-body">Create position</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CreatePositionComponent position={position}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}


export default CreatePositionModal;