import React, { createContext, useContext, useEffect, useState } from "react";

interface WalletContextType {
    wallet: string | null;
    setWallet: (address: string | null) => void;
    disconnectWallet: () => void;
}

const WalletContext = createContext<WalletContextType | undefined>(undefined);

export const WalletProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [wallet, setWallet] = useState<string | null>(() => {
        return localStorage.getItem("walletAddress");
    });

    useEffect(() => {
        if (wallet) {
            localStorage.setItem("walletAddress", wallet);
        } else {
            localStorage.removeItem("walletAddress");
        }
    }, [wallet]);

    const disconnectWallet = () => {
        setWallet(null);
        localStorage.removeItem("walletAddress");
    };

    return (
        <WalletContext.Provider value={{ wallet, setWallet, disconnectWallet }}>
            {children}
        </WalletContext.Provider>
    );
};

export const useWallet = (): WalletContextType => {
    const context = useContext(WalletContext);
    if (!context) {
        throw new Error("useWallet must be used within a WalletProvider");
    }
    return context;
};
