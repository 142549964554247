import React, {useState, useEffect} from 'react';
import {UniswapPosition} from "../../../../model/crypto/CryptoModels";
import {CardBody, Col, Container, Form} from "react-bootstrap";
import {FaCircle} from "react-icons/fa6";
import Row from "react-bootstrap/Row";
import {Box, Slider} from "@mui/material";

/**
 * Formats numbers with appropriate precision based on size
 */
const formatNumber = (value: number | undefined | null, maxDecimals = 2): string => {
    if (value === undefined || value === null) return '0';

    // For displaying token amounts, use more decimals for small values
    if (value < 0.0001) return value.toExponential(4);
    if (value < 0.01) return value.toFixed(6);
    if (value < 0.1) return value.toFixed(4);
    if (value < 1) return value.toFixed(3);
    if (value < 10) return value.toFixed(2);

    // For larger numbers (prices, etc)
    if (value < 1000) return value.toFixed(Math.min(2, maxDecimals));
    return value.toLocaleString('en-US', { maximumFractionDigits: Math.min(2, maxDecimals) });
};

interface TokenRatioResult {
    token0Amount: number;
    token1Amount: number;
    ratio: number; // token1/token0 ratio
    token0Symbol: string;
    token1Symbol: string;
    effectivePriceRange: {
        lower: number;
        upper: number;
    };
    token0USD: number;
    token1USD: number;
    totalUSD: number;
}

/**
 * Calculates token amounts using a LINEAR price curve instead of Uniswap's square root curve
 * This provides more intuitive token distributions for users
 */
function calculateTokenRatiosLinear(
    position: UniswapPosition,
    lowerPrice: number,
    upperPrice: number,
    currentPrice: number,
    investmentAmount = 100
): TokenRatioResult {
    // Initialize token amounts
    let token0Amount = 0; // Base token (e.g., WETH)
    let token1Amount = 0; // Quote token (e.g., USDT)

    // Check price position relative to range
    if (currentPrice < lowerPrice) {
        // Case 1: Price below range - all liquidity in token0
        token0Amount = investmentAmount / currentPrice;
        token1Amount = 0;
    } else if (currentPrice > upperPrice) {
        // Case 2: Price above range - all liquidity in token1
        token0Amount = 0;
        token1Amount = investmentAmount;
    } else {
        // Case 3: Price within range - use LINEAR distribution
        // Calculate relative position within range (0 to 1)
        const rangeWidth = upperPrice - lowerPrice;
        const positionInRange = (currentPrice - lowerPrice) / rangeWidth;

        // Calculate token amounts based on linear distribution
        // As price increases, we need less token0 and more token1
        const token0Portion = 1 - positionInRange;
        const token1Portion = positionInRange;

        // Adjust for price to maintain total value
        token0Amount = (investmentAmount * token0Portion) / currentPrice;
        token1Amount = investmentAmount * token1Portion;
    }

    // Calculate USD values
    const token0USD = token0Amount * currentPrice;
    const token1USD = token1Amount;
    const totalUSD = token0USD + token1USD;

    // Calculate the ratio (token1/token0)
    const ratio = token0Amount > 0 ? token1Amount / token0Amount : Infinity;

    return {
        token0Amount,
        token1Amount,
        ratio,
        token0Symbol: position.token0.symbol,
        token1Symbol: position.token1.symbol,
        effectivePriceRange: {
            lower: lowerPrice,
            upper: upperPrice
        },
        token0USD,
        token1USD,
        totalUSD
    };
}

/**
 * Alternative version that targets 50/50 distribution for symmetric ranges
 */
function calculateTokenRatiosBalanced(
    position: UniswapPosition,
    lowerPrice: number,
    upperPrice: number,
    currentPrice: number,
    investmentAmount = 100
): TokenRatioResult {
    // For a 50/50 split, simply divide the investment amount
    const halfInvestment = investmentAmount / 2;

    // Calculate token amounts
    const token0Amount = halfInvestment / currentPrice; // Base token (e.g., WETH)
    const token1Amount = halfInvestment; // Quote token (e.g., USDT)

    // Calculate USD values (should be 50/50)
    const token0USD = token0Amount * currentPrice;
    const token1USD = token1Amount;
    const totalUSD = token0USD + token1USD;

    // Calculate the ratio (token1/token0)
    const ratio = token1Amount / token0Amount;

    return {
        token0Amount,
        token1Amount,
        ratio,
        token0Symbol: position.token0.symbol,
        token1Symbol: position.token1.symbol,
        effectivePriceRange: {
            lower: lowerPrice,
            upper: upperPrice
        },
        token0USD,
        token1USD,
        totalUSD
    };
}

interface CreatePositionProps {
    position: UniswapPosition;
}

/**
 * CreatePositionComponent provides a UI for users to create Uniswap positions
 * with linear price curve calculations
 */
const CreatePositionComponent: React.FC<CreatePositionProps> = ({position}) => {
    const minDistance = 10;

    // Initialize state
    const [range, setRange] = useState<number[]>([0, 0]);
    const [sliderMin, setSliderMin] = useState<number>(0);
    const [sliderMax, setSliderMax] = useState<number>(0);
    const [lowerPrice, setLowerPrice] = useState<number>(0);
    const [upperPrice, setUpperPrice] = useState<number>(0);
    const [currentPrice, setCurrentPrice] = useState<number>(0);
    const [investmentAmount, setInvestmentAmount] = useState<number>(100);
    const [balancedMode, setBalancedMode] = useState<boolean>(false);

    // Token ratio calculation states
    const [tokenRatio, setTokenRatio] = useState<TokenRatioResult | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    // Make sure currentPrice is set from position
    useEffect(() => {
        if (position && position.currentPrice) {
            setCurrentPrice(position.currentPrice.token1PerToken0);
        }
    }, [position]);

    // Format value text for slider
    function valuetext(value: number): string {
        return `${formatNumber(value)}`;
    }

    // Initialize price range based on current price
    useEffect(() => {
        if (position && position.currentPrice) {
            const currentPrice = position.currentPrice.token1PerToken0;

            // Calculate a reasonable delta for range adjustment
            const calculatedDelta = currentPrice * 0.25;

            // Set slider min and max with appropriate padding
            const calculatedMin = Math.max(0, currentPrice - calculatedDelta);
            const calculatedMax = currentPrice + calculatedDelta;

            setSliderMin(calculatedMin);
            setSliderMax(calculatedMax);

            // Set initial range to be symmetric around current price (±100)
            const lowerBound = Math.max(calculatedMin, currentPrice - 100);
            const upperBound = Math.min(calculatedMax, currentPrice + 100);

            setRange([lowerBound, upperBound]);
            setLowerPrice(lowerBound);
            setUpperPrice(upperBound);
            setCurrentPrice(currentPrice);
        }
    }, [position]);

    // Calculate token ratio when range or investment amount changes
    useEffect(() => {
        if (position && lowerPrice > 0 && upperPrice > 0) {
            calculateTokenRatio();
        }
    }, [lowerPrice, upperPrice, investmentAmount, currentPrice, position, balancedMode]);

    const calculateTokenRatio = (): void => {
        setLoading(true);
        setError(null);

        try {
            // Use the appropriate calculation method based on mode
            const result = balancedMode
                ? calculateTokenRatiosBalanced(position, lowerPrice, upperPrice, currentPrice, investmentAmount)
                : calculateTokenRatiosLinear(position, lowerPrice, upperPrice, currentPrice, investmentAmount);

            setTokenRatio(result);
        } catch (err) {
            setError(`Error calculating token ratio: ${err instanceof Error ? err.message : String(err)}`);
        } finally {
            setLoading(false);
        }
    };

    const handleRangeChange = (
        event: Event,
        newValue: number | number[],
        activeThumb: number,
    ): void => {
        if (!Array.isArray(newValue)) {
            return;
        }

        let updatedRange = [...range];

        if (activeThumb === 0) {
            updatedRange = [Math.min(newValue[0], range[1] - minDistance), range[1]];
        } else {
            updatedRange = [range[0], Math.max(newValue[1], range[0] + minDistance)];
        }

        setRange(updatedRange);
        setLowerPrice(updatedRange[0]);
        setUpperPrice(updatedRange[1]);
    };

    // Toggle between linear and balanced mode
    const handleBalancedModeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setBalancedMode(event.target.checked);
    };

    // Generate the appropriate Uniswap deep link URL
    const generateUniswapLink = (): string => {
        if (!tokenRatio) return '#';

        return `https://app.uniswap.org/#/pools/new?chain=arbitrum&token0=${position.token0.address}&token1=${position.token1.address}&fee=${position.fee}&minPrice=${lowerPrice}&maxPrice=${upperPrice}&amount0=${tokenRatio.token0Amount}&amount1=${tokenRatio.token1Amount}`;
    };

    return (
        <Container fluid>
            <Row className="text-body">
                <Col sm={12} className="d-flex d-inline-block">
                    <Form.Group className="mb-3">
                        <Form.Label>Current
                            price {position.token1.symbol}/{position.token0.symbol}: <b>{formatNumber(position.currentPrice.token1PerToken0)}</b></Form.Label>
                    </Form.Group>
                </Col>

                <Col sm={12}>
                    <Form className="d-flex align-items-center gap-2 mb-3">
                        <Form.Label className="mb-0 me-2">Amount to Invest (USD):</Form.Label>
                        <Form.Control
                            style={{maxWidth: '85px'}}
                            type="number"
                            min="0.01"
                            step="0.01"
                            value={investmentAmount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInvestmentAmount(parseFloat(e.target.value) || 100)}
                        />
                    </Form>
                </Col>

                <Col sm={12} className="mb-3">
                    <div className="d-flex align-items-center">
                        <input
                            type="checkbox"
                            id="balancedMode"
                            className="me-2"
                            checked={balancedMode}
                            onChange={handleBalancedModeChange}
                        />
                        <label htmlFor="balancedMode" className="text-body mb-0">
                            Use 50/50 token distribution
                        </label>
                    </div>
                </Col>
            </Row>

            <Row>
                <CardBody>
                    <div className="mb-2 ms-3">
                        <Form.Label className="text-body">Price range:</Form.Label>
                    </div>
                    <Box className="my-2 mx-3">
                        <Slider
                            getAriaLabel={() => 'Price Range'}
                            value={range}
                            onChange={handleRangeChange}
                            min={sliderMin}
                            max={sliderMax}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            valueLabelFormat={(value: number) => `${formatNumber(value)}`}
                            marks={[
                                {
                                    value: position.currentPrice.token1PerToken0,
                                    label: <span
                                        className="text-body">{`Current: ${formatNumber(position.currentPrice.token1PerToken0)}`}</span>
                                }
                            ]}
                            sx={{
                                '& .MuiSlider-markActive, & .MuiSlider-mark': {
                                    width: 12,
                                    height: 12,
                                    borderRadius: '50%',
                                    backgroundColor: '#FFCA2C',
                                },
                                '& .MuiSlider-mark[data-index="0"]': {
                                    width: 18,
                                    height: 18,
                                    backgroundColor: '#FFCA2C',
                                }
                            }}
                            disableSwap
                        />

                        <div className="d-flex justify-content-between mt-2 text-body">
                            <div>Min: {formatNumber(range[0])}</div>
                            <div>Current: {formatNumber(position.currentPrice.token1PerToken0)}</div>
                            <div>Max: {formatNumber(range[1])}</div>
                        </div>
                    </Box>
                </CardBody>
            </Row>

            {/* Create Position Button Section */}
            {tokenRatio && !loading && (
                <Row className="mt-3 mb-4">
                    <div className="d-flex justify-content-center">
                        <a
                            href={generateUniswapLink()}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary btn-lg"
                        >
                            Create Position
                        </a>
                    </div>
                </Row>
            )}

            {/* Token Ratio Display Section */}
            <Row className="mt-3">
                <CardBody>
                    <div className="mb-2 ms-3 d-flex align-items-center">
                        <Form.Label className="text-body mb-0">Token Ratio for Position</Form.Label>
                        {balancedMode && (
                            <span className="badge bg-info ms-2">50/50 Split</span>
                        )}
                    </div>

                    {loading && (
                        <div className="text-center my-3">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p className="mt-2">Calculating token ratio...</p>
                        </div>
                    )}

                    {error && (
                        <div className="alert alert-danger" role="alert">
                            {error}
                        </div>
                    )}

                    {tokenRatio && !loading && (
                        <div className="p-3 border rounded text-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <h5 className="mb-3">Position Details</h5>
                                    <p><strong>Price
                                        Range:</strong> {formatNumber(tokenRatio.effectivePriceRange.lower)} - {formatNumber(tokenRatio.effectivePriceRange.upper)}
                                    </p>
                                    <p>
                                        <strong>{tokenRatio.token1Symbol}/{tokenRatio.token0Symbol} Ratio:</strong> {formatNumber(tokenRatio.ratio, 2)}
                                    </p>
                                    <p>
                                        <strong>USD Distribution:</strong> {formatNumber(tokenRatio.token0USD / tokenRatio.totalUSD * 100, 1)}% {tokenRatio.token0Symbol} / {formatNumber(tokenRatio.token1USD / tokenRatio.totalUSD * 100, 1)}% {tokenRatio.token1Symbol}
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="mb-3">Required Tokens to Provide</h5>
                                    <p>
                                        <strong>{tokenRatio.token0Symbol}:</strong> {formatNumber(tokenRatio.token0Amount, 6)}
                                        {tokenRatio.token0USD > 0 && ` (≈$${formatNumber(tokenRatio.token0USD, 2)})`}
                                    </p>
                                    <p>
                                        <strong>{tokenRatio.token1Symbol}:</strong> {formatNumber(tokenRatio.token1Amount, 2)}
                                        {tokenRatio.token1USD > 0 && ` (≈$${formatNumber(tokenRatio.token1USD, 2)})`}
                                    </p>
                                    <p>
                                        <strong>Total USD Value:</strong> ${formatNumber(tokenRatio.totalUSD, 2)}
                                    </p>
                                </div>
                            </div>

                            <div className="mt-3">
                                <h5 className="mb-2">Position Status</h5>
                                <div className="d-flex align-items-center gap-2">
                                    <FaCircle size={12} color={
                                        currentPrice < tokenRatio.effectivePriceRange.lower ? "#dc3545" :
                                            currentPrice > tokenRatio.effectivePriceRange.upper ? "#dc3545" : "#28a745"
                                    }/>
                                    <span>
                                        {currentPrice < tokenRatio.effectivePriceRange.lower ?
                                            `Price below range (all liquidity in ${tokenRatio.token0Symbol})` :
                                            currentPrice > tokenRatio.effectivePriceRange.upper ?
                                                `Price above range (all liquidity in ${tokenRatio.token1Symbol})` :
                                                "Price in range (earning fees)"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    )}
                </CardBody>
            </Row>
        </Container>
    );
};

export default CreatePositionComponent;